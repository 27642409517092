export const timetable = {
    Distelweg: {
        monday: [
            { time: '06:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '06:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '07:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '07:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '07:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '07:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '08:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '08:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '08:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '08:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '09:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '09:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '09:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '09:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '10:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '10:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '10:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '10:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '11:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '11:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '11:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '11:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '12:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '12:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '12:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '12:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '13:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '13:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '13:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '13:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '14:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '14:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '14:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '14:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '15:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '15:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '15:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '15:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '16:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '16:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '16:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '16:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '17:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '17:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '17:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '17:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '18:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '18:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '18:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '18:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '19:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '19:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '19:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '19:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '20:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '20:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '20:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '20:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '21:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '21:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '21:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '21:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '22:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '22:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '22:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '22:52:00', line: '900', to: ['Pontsteiger'] },
        ],
        tuesday: [
            { time: '06:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '06:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '07:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '07:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '07:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '07:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '08:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '08:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '08:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '08:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '09:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '09:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '09:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '09:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '10:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '10:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '10:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '10:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '11:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '11:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '11:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '11:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '12:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '12:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '12:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '12:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '13:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '13:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '13:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '13:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '14:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '14:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '14:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '14:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '15:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '15:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '15:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '15:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '16:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '16:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '16:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '16:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '17:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '17:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '17:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '17:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '18:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '18:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '18:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '18:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '19:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '19:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '19:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '19:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '20:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '20:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '20:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '20:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '21:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '21:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '21:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '21:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '22:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '22:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '22:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '22:52:00', line: '900', to: ['Pontsteiger'] },
        ],
        wednesday: [
            { time: '06:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '06:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '07:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '07:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '07:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '07:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '08:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '08:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '08:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '08:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '09:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '09:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '09:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '09:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '10:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '10:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '10:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '10:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '11:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '11:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '11:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '11:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '12:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '12:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '12:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '12:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '13:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '13:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '13:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '13:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '14:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '14:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '14:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '14:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '15:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '15:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '15:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '15:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '16:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '16:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '16:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '16:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '17:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '17:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '17:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '17:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '18:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '18:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '18:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '18:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '19:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '19:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '19:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '19:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '20:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '20:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '20:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '20:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '21:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '21:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '21:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '21:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '22:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '22:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '22:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '22:52:00', line: '900', to: ['Pontsteiger'] },
        ],
        thursday: [
            { time: '06:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '06:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '07:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '07:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '07:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '07:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '08:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '08:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '08:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '08:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '09:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '09:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '09:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '09:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '10:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '10:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '10:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '10:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '11:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '11:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '11:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '11:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '12:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '12:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '12:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '12:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '13:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '13:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '13:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '13:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '14:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '14:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '14:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '14:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '15:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '15:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '15:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '15:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '16:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '16:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '16:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '16:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '17:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '17:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '17:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '17:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '18:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '18:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '18:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '18:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '19:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '19:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '19:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '19:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '20:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '20:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '20:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '20:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '21:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '21:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '21:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '21:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '22:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '22:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '22:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '22:52:00', line: '900', to: ['Pontsteiger'] },
        ],
        friday: [
            { time: '06:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '06:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '07:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '07:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '07:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '07:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '08:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '08:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '08:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '08:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '09:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '09:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '09:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '09:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '10:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '10:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '10:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '10:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '11:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '11:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '11:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '11:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '12:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '12:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '12:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '12:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '13:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '13:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '13:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '13:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '14:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '14:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '14:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '14:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '15:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '15:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '15:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '15:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '16:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '16:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '16:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '16:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '17:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '17:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '17:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '17:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '18:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '18:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '18:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '18:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '19:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '19:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '19:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '19:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '20:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '20:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '20:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '20:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '21:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '21:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '21:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '21:52:00', line: '900', to: ['Pontsteiger'] },
            { time: '22:07:00', line: '900', to: ['Pontsteiger'] },
            { time: '22:22:00', line: '900', to: ['Pontsteiger'] },
            { time: '22:37:00', line: '900', to: ['Pontsteiger'] },
            { time: '22:52:00', line: '900', to: ['Pontsteiger'] },
        ],
        saturday: [],
        sunday: [],
    },
    Pontsteiger: {
        monday: [
            { time: '00:08:00', line: '905', to: ['Centraal Station'] },
            { time: '00:46:00', line: '905', to: ['Centraal Station'] },
            { time: '01:24:00', line: '905', to: ['Centraal Station'] },
            { time: '02:02:00', line: '905', to: ['Centraal Station'] },
            { time: '06:30:00', line: '900', to: ['Distelweg'] },
            { time: '06:40:00', line: '903', to: ['NDSM'] },
            { time: '06:45:00', line: '900', to: ['Distelweg'] },
            { time: '07:00:00', line: '900', to: ['Distelweg'] },
            { time: '07:00:00', line: '903', to: ['NDSM'] },
            { time: '07:15:00', line: '900', to: ['Distelweg'] },
            { time: '07:20:00', line: '903', to: ['NDSM'] },
            { time: '07:30:00', line: '900', to: ['Distelweg'] },
            { time: '07:40:00', line: '903', to: ['NDSM'] },
            { time: '07:45:00', line: '900', to: ['Distelweg'] },
            { time: '08:00:00', line: '900', to: ['Distelweg'] },
            { time: '08:00:00', line: '903', to: ['NDSM'] },
            { time: '08:15:00', line: '900', to: ['Distelweg'] },
            { time: '08:20:00', line: '903', to: ['NDSM'] },
            { time: '08:30:00', line: '900', to: ['Distelweg'] },
            { time: '08:40:00', line: '903', to: ['NDSM'] },
            { time: '08:45:00', line: '900', to: ['Distelweg'] },
            { time: '09:00:00', line: '900', to: ['Distelweg'] },
            { time: '09:00:00', line: '903', to: ['NDSM'] },
            { time: '09:15:00', line: '900', to: ['Distelweg'] },
            { time: '09:20:00', line: '903', to: ['NDSM'] },
            { time: '09:30:00', line: '900', to: ['Distelweg'] },
            { time: '09:40:00', line: '903', to: ['NDSM'] },
            { time: '09:45:00', line: '900', to: ['Distelweg'] },
            { time: '10:00:00', line: '900', to: ['Distelweg'] },
            { time: '10:00:00', line: '903', to: ['NDSM'] },
            { time: '10:15:00', line: '900', to: ['Distelweg'] },
            { time: '10:20:00', line: '903', to: ['NDSM'] },
            { time: '10:30:00', line: '900', to: ['Distelweg'] },
            { time: '10:40:00', line: '903', to: ['NDSM'] },
            { time: '10:45:00', line: '900', to: ['Distelweg'] },
            { time: '11:00:00', line: '900', to: ['Distelweg'] },
            { time: '11:00:00', line: '903', to: ['NDSM'] },
            { time: '11:15:00', line: '900', to: ['Distelweg'] },
            { time: '11:20:00', line: '903', to: ['NDSM'] },
            { time: '11:30:00', line: '900', to: ['Distelweg'] },
            { time: '11:40:00', line: '903', to: ['NDSM'] },
            { time: '11:45:00', line: '900', to: ['Distelweg'] },
            { time: '12:00:00', line: '900', to: ['Distelweg'] },
            { time: '12:00:00', line: '903', to: ['NDSM'] },
            { time: '12:15:00', line: '900', to: ['Distelweg'] },
            { time: '12:20:00', line: '903', to: ['NDSM'] },
            { time: '12:30:00', line: '900', to: ['Distelweg'] },
            { time: '12:40:00', line: '903', to: ['NDSM'] },
            { time: '12:45:00', line: '900', to: ['Distelweg'] },
            { time: '13:00:00', line: '900', to: ['Distelweg'] },
            { time: '13:00:00', line: '903', to: ['NDSM'] },
            { time: '13:15:00', line: '900', to: ['Distelweg'] },
            { time: '13:20:00', line: '903', to: ['NDSM'] },
            { time: '13:30:00', line: '900', to: ['Distelweg'] },
            { time: '13:40:00', line: '903', to: ['NDSM'] },
            { time: '13:45:00', line: '900', to: ['Distelweg'] },
            { time: '14:00:00', line: '900', to: ['Distelweg'] },
            { time: '14:00:00', line: '903', to: ['NDSM'] },
            { time: '14:15:00', line: '900', to: ['Distelweg'] },
            { time: '14:20:00', line: '903', to: ['NDSM'] },
            { time: '14:30:00', line: '900', to: ['Distelweg'] },
            { time: '14:40:00', line: '903', to: ['NDSM'] },
            { time: '14:45:00', line: '900', to: ['Distelweg'] },
            { time: '15:00:00', line: '900', to: ['Distelweg'] },
            { time: '15:00:00', line: '903', to: ['NDSM'] },
            { time: '15:15:00', line: '900', to: ['Distelweg'] },
            { time: '15:20:00', line: '903', to: ['NDSM'] },
            { time: '15:30:00', line: '900', to: ['Distelweg'] },
            { time: '15:40:00', line: '903', to: ['NDSM'] },
            { time: '15:45:00', line: '900', to: ['Distelweg'] },
            { time: '16:00:00', line: '900', to: ['Distelweg'] },
            { time: '16:00:00', line: '903', to: ['NDSM'] },
            { time: '16:10:00', line: '903', to: ['NDSM'] },
            { time: '16:15:00', line: '900', to: ['Distelweg'] },
            { time: '16:20:00', line: '903', to: ['NDSM'] },
            { time: '16:30:00', line: '900', to: ['Distelweg'] },
            { time: '16:30:00', line: '903', to: ['NDSM'] },
            { time: '16:40:00', line: '903', to: ['NDSM'] },
            { time: '16:45:00', line: '900', to: ['Distelweg'] },
            { time: '16:50:00', line: '903', to: ['NDSM'] },
            { time: '17:00:00', line: '900', to: ['Distelweg'] },
            { time: '17:00:00', line: '903', to: ['NDSM'] },
            { time: '17:10:00', line: '903', to: ['NDSM'] },
            { time: '17:15:00', line: '900', to: ['Distelweg'] },
            { time: '17:20:00', line: '903', to: ['NDSM'] },
            { time: '17:30:00', line: '900', to: ['Distelweg'] },
            { time: '17:30:00', line: '903', to: ['NDSM'] },
            { time: '17:40:00', line: '903', to: ['NDSM'] },
            { time: '17:45:00', line: '900', to: ['Distelweg'] },
            { time: '17:50:00', line: '903', to: ['NDSM'] },
            { time: '18:00:00', line: '900', to: ['Distelweg'] },
            { time: '18:00:00', line: '903', to: ['NDSM'] },
            { time: '18:10:00', line: '903', to: ['NDSM'] },
            { time: '18:15:00', line: '900', to: ['Distelweg'] },
            { time: '18:20:00', line: '903', to: ['NDSM'] },
            { time: '18:30:00', line: '900', to: ['Distelweg'] },
            { time: '18:30:00', line: '903', to: ['NDSM'] },
            { time: '18:40:00', line: '903', to: ['NDSM'] },
            { time: '18:45:00', line: '900', to: ['Distelweg'] },
            { time: '18:50:00', line: '903', to: ['NDSM'] },
            { time: '19:00:00', line: '900', to: ['Distelweg'] },
            { time: '19:00:00', line: '903', to: ['NDSM'] },
            { time: '19:10:00', line: '903', to: ['NDSM'] },
            { time: '19:15:00', line: '900', to: ['Distelweg'] },
            { time: '19:20:00', line: '903', to: ['NDSM'] },
            { time: '19:30:00', line: '900', to: ['Distelweg'] },
            { time: '19:30:00', line: '903', to: ['NDSM'] },
            { time: '19:40:00', line: '903', to: ['NDSM'] },
            { time: '19:45:00', line: '900', to: ['Distelweg'] },
            { time: '19:50:00', line: '903', to: ['NDSM'] },
            { time: '20:00:00', line: '900', to: ['Distelweg'] },
            { time: '20:00:00', line: '903', to: ['NDSM'] },
            { time: '20:15:00', line: '900', to: ['Distelweg'] },
            { time: '20:20:00', line: '903', to: ['NDSM'] },
            { time: '20:30:00', line: '900', to: ['Distelweg'] },
            { time: '20:40:00', line: '903', to: ['NDSM'] },
            { time: '20:45:00', line: '900', to: ['Distelweg'] },
            { time: '21:00:00', line: '900', to: ['Distelweg'] },
            { time: '21:00:00', line: '903', to: ['NDSM'] },
            { time: '21:15:00', line: '900', to: ['Distelweg'] },
            { time: '21:20:00', line: '903', to: ['NDSM'] },
            { time: '21:30:00', line: '900', to: ['Distelweg'] },
            { time: '21:40:00', line: '903', to: ['NDSM'] },
            { time: '21:45:00', line: '900', to: ['Distelweg'] },
            { time: '22:00:00', line: '900', to: ['Distelweg'] },
            { time: '22:00:00', line: '903', to: ['NDSM'] },
            { time: '22:15:00', line: '900', to: ['Distelweg'] },
            { time: '22:20:00', line: '903', to: ['NDSM'] },
            { time: '22:30:00', line: '900', to: ['Distelweg'] },
            { time: '22:40:00', line: '903', to: ['NDSM'] },
            { time: '22:45:00', line: '900', to: ['Distelweg'] },
            { time: '23:00:00', line: '903', to: ['NDSM'] },
            { time: '23:20:00', line: '903', to: ['NDSM'] },
            { time: '23:40:00', line: '903', to: ['NDSM'] },
        ],
        tuesday: [
            { time: '00:08:00', line: '905', to: ['Centraal Station'] },
            { time: '00:46:00', line: '905', to: ['Centraal Station'] },
            { time: '01:24:00', line: '905', to: ['Centraal Station'] },
            { time: '02:02:00', line: '905', to: ['Centraal Station'] },
            { time: '06:30:00', line: '900', to: ['Distelweg'] },
            { time: '06:40:00', line: '903', to: ['NDSM'] },
            { time: '06:45:00', line: '900', to: ['Distelweg'] },
            { time: '07:00:00', line: '900', to: ['Distelweg'] },
            { time: '07:00:00', line: '903', to: ['NDSM'] },
            { time: '07:15:00', line: '900', to: ['Distelweg'] },
            { time: '07:20:00', line: '903', to: ['NDSM'] },
            { time: '07:30:00', line: '900', to: ['Distelweg'] },
            { time: '07:40:00', line: '903', to: ['NDSM'] },
            { time: '07:45:00', line: '900', to: ['Distelweg'] },
            { time: '08:00:00', line: '900', to: ['Distelweg'] },
            { time: '08:00:00', line: '903', to: ['NDSM'] },
            { time: '08:15:00', line: '900', to: ['Distelweg'] },
            { time: '08:20:00', line: '903', to: ['NDSM'] },
            { time: '08:30:00', line: '900', to: ['Distelweg'] },
            { time: '08:40:00', line: '903', to: ['NDSM'] },
            { time: '08:45:00', line: '900', to: ['Distelweg'] },
            { time: '09:00:00', line: '900', to: ['Distelweg'] },
            { time: '09:00:00', line: '903', to: ['NDSM'] },
            { time: '09:15:00', line: '900', to: ['Distelweg'] },
            { time: '09:20:00', line: '903', to: ['NDSM'] },
            { time: '09:30:00', line: '900', to: ['Distelweg'] },
            { time: '09:40:00', line: '903', to: ['NDSM'] },
            { time: '09:45:00', line: '900', to: ['Distelweg'] },
            { time: '10:00:00', line: '900', to: ['Distelweg'] },
            { time: '10:00:00', line: '903', to: ['NDSM'] },
            { time: '10:15:00', line: '900', to: ['Distelweg'] },
            { time: '10:20:00', line: '903', to: ['NDSM'] },
            { time: '10:30:00', line: '900', to: ['Distelweg'] },
            { time: '10:40:00', line: '903', to: ['NDSM'] },
            { time: '10:45:00', line: '900', to: ['Distelweg'] },
            { time: '11:00:00', line: '900', to: ['Distelweg'] },
            { time: '11:00:00', line: '903', to: ['NDSM'] },
            { time: '11:15:00', line: '900', to: ['Distelweg'] },
            { time: '11:20:00', line: '903', to: ['NDSM'] },
            { time: '11:30:00', line: '900', to: ['Distelweg'] },
            { time: '11:40:00', line: '903', to: ['NDSM'] },
            { time: '11:45:00', line: '900', to: ['Distelweg'] },
            { time: '12:00:00', line: '900', to: ['Distelweg'] },
            { time: '12:00:00', line: '903', to: ['NDSM'] },
            { time: '12:15:00', line: '900', to: ['Distelweg'] },
            { time: '12:20:00', line: '903', to: ['NDSM'] },
            { time: '12:30:00', line: '900', to: ['Distelweg'] },
            { time: '12:40:00', line: '903', to: ['NDSM'] },
            { time: '12:45:00', line: '900', to: ['Distelweg'] },
            { time: '13:00:00', line: '900', to: ['Distelweg'] },
            { time: '13:00:00', line: '903', to: ['NDSM'] },
            { time: '13:15:00', line: '900', to: ['Distelweg'] },
            { time: '13:20:00', line: '903', to: ['NDSM'] },
            { time: '13:30:00', line: '900', to: ['Distelweg'] },
            { time: '13:40:00', line: '903', to: ['NDSM'] },
            { time: '13:45:00', line: '900', to: ['Distelweg'] },
            { time: '14:00:00', line: '900', to: ['Distelweg'] },
            { time: '14:00:00', line: '903', to: ['NDSM'] },
            { time: '14:15:00', line: '900', to: ['Distelweg'] },
            { time: '14:20:00', line: '903', to: ['NDSM'] },
            { time: '14:30:00', line: '900', to: ['Distelweg'] },
            { time: '14:40:00', line: '903', to: ['NDSM'] },
            { time: '14:45:00', line: '900', to: ['Distelweg'] },
            { time: '15:00:00', line: '900', to: ['Distelweg'] },
            { time: '15:00:00', line: '903', to: ['NDSM'] },
            { time: '15:15:00', line: '900', to: ['Distelweg'] },
            { time: '15:20:00', line: '903', to: ['NDSM'] },
            { time: '15:30:00', line: '900', to: ['Distelweg'] },
            { time: '15:40:00', line: '903', to: ['NDSM'] },
            { time: '15:45:00', line: '900', to: ['Distelweg'] },
            { time: '16:00:00', line: '900', to: ['Distelweg'] },
            { time: '16:00:00', line: '903', to: ['NDSM'] },
            { time: '16:10:00', line: '903', to: ['NDSM'] },
            { time: '16:15:00', line: '900', to: ['Distelweg'] },
            { time: '16:20:00', line: '903', to: ['NDSM'] },
            { time: '16:30:00', line: '900', to: ['Distelweg'] },
            { time: '16:30:00', line: '903', to: ['NDSM'] },
            { time: '16:40:00', line: '903', to: ['NDSM'] },
            { time: '16:45:00', line: '900', to: ['Distelweg'] },
            { time: '16:50:00', line: '903', to: ['NDSM'] },
            { time: '17:00:00', line: '900', to: ['Distelweg'] },
            { time: '17:00:00', line: '903', to: ['NDSM'] },
            { time: '17:10:00', line: '903', to: ['NDSM'] },
            { time: '17:15:00', line: '900', to: ['Distelweg'] },
            { time: '17:20:00', line: '903', to: ['NDSM'] },
            { time: '17:30:00', line: '900', to: ['Distelweg'] },
            { time: '17:30:00', line: '903', to: ['NDSM'] },
            { time: '17:40:00', line: '903', to: ['NDSM'] },
            { time: '17:45:00', line: '900', to: ['Distelweg'] },
            { time: '17:50:00', line: '903', to: ['NDSM'] },
            { time: '18:00:00', line: '900', to: ['Distelweg'] },
            { time: '18:00:00', line: '903', to: ['NDSM'] },
            { time: '18:10:00', line: '903', to: ['NDSM'] },
            { time: '18:15:00', line: '900', to: ['Distelweg'] },
            { time: '18:20:00', line: '903', to: ['NDSM'] },
            { time: '18:30:00', line: '900', to: ['Distelweg'] },
            { time: '18:30:00', line: '903', to: ['NDSM'] },
            { time: '18:40:00', line: '903', to: ['NDSM'] },
            { time: '18:45:00', line: '900', to: ['Distelweg'] },
            { time: '18:50:00', line: '903', to: ['NDSM'] },
            { time: '19:00:00', line: '900', to: ['Distelweg'] },
            { time: '19:00:00', line: '903', to: ['NDSM'] },
            { time: '19:10:00', line: '903', to: ['NDSM'] },
            { time: '19:15:00', line: '900', to: ['Distelweg'] },
            { time: '19:20:00', line: '903', to: ['NDSM'] },
            { time: '19:30:00', line: '900', to: ['Distelweg'] },
            { time: '19:30:00', line: '903', to: ['NDSM'] },
            { time: '19:40:00', line: '903', to: ['NDSM'] },
            { time: '19:45:00', line: '900', to: ['Distelweg'] },
            { time: '19:50:00', line: '903', to: ['NDSM'] },
            { time: '20:00:00', line: '900', to: ['Distelweg'] },
            { time: '20:00:00', line: '903', to: ['NDSM'] },
            { time: '20:15:00', line: '900', to: ['Distelweg'] },
            { time: '20:20:00', line: '903', to: ['NDSM'] },
            { time: '20:30:00', line: '900', to: ['Distelweg'] },
            { time: '20:40:00', line: '903', to: ['NDSM'] },
            { time: '20:45:00', line: '900', to: ['Distelweg'] },
            { time: '21:00:00', line: '900', to: ['Distelweg'] },
            { time: '21:00:00', line: '903', to: ['NDSM'] },
            { time: '21:15:00', line: '900', to: ['Distelweg'] },
            { time: '21:20:00', line: '903', to: ['NDSM'] },
            { time: '21:30:00', line: '900', to: ['Distelweg'] },
            { time: '21:40:00', line: '903', to: ['NDSM'] },
            { time: '21:45:00', line: '900', to: ['Distelweg'] },
            { time: '22:00:00', line: '900', to: ['Distelweg'] },
            { time: '22:00:00', line: '903', to: ['NDSM'] },
            { time: '22:15:00', line: '900', to: ['Distelweg'] },
            { time: '22:20:00', line: '903', to: ['NDSM'] },
            { time: '22:30:00', line: '900', to: ['Distelweg'] },
            { time: '22:40:00', line: '903', to: ['NDSM'] },
            { time: '22:45:00', line: '900', to: ['Distelweg'] },
            { time: '23:00:00', line: '903', to: ['NDSM'] },
            { time: '23:20:00', line: '903', to: ['NDSM'] },
            { time: '23:40:00', line: '903', to: ['NDSM'] },
        ],
        wednesday: [
            { time: '00:08:00', line: '905', to: ['Centraal Station'] },
            { time: '00:46:00', line: '905', to: ['Centraal Station'] },
            { time: '01:24:00', line: '905', to: ['Centraal Station'] },
            { time: '02:02:00', line: '905', to: ['Centraal Station'] },
            { time: '06:30:00', line: '900', to: ['Distelweg'] },
            { time: '06:40:00', line: '903', to: ['NDSM'] },
            { time: '06:45:00', line: '900', to: ['Distelweg'] },
            { time: '07:00:00', line: '900', to: ['Distelweg'] },
            { time: '07:00:00', line: '903', to: ['NDSM'] },
            { time: '07:15:00', line: '900', to: ['Distelweg'] },
            { time: '07:20:00', line: '903', to: ['NDSM'] },
            { time: '07:30:00', line: '900', to: ['Distelweg'] },
            { time: '07:40:00', line: '903', to: ['NDSM'] },
            { time: '07:45:00', line: '900', to: ['Distelweg'] },
            { time: '08:00:00', line: '900', to: ['Distelweg'] },
            { time: '08:00:00', line: '903', to: ['NDSM'] },
            { time: '08:15:00', line: '900', to: ['Distelweg'] },
            { time: '08:20:00', line: '903', to: ['NDSM'] },
            { time: '08:30:00', line: '900', to: ['Distelweg'] },
            { time: '08:40:00', line: '903', to: ['NDSM'] },
            { time: '08:45:00', line: '900', to: ['Distelweg'] },
            { time: '09:00:00', line: '900', to: ['Distelweg'] },
            { time: '09:00:00', line: '903', to: ['NDSM'] },
            { time: '09:15:00', line: '900', to: ['Distelweg'] },
            { time: '09:20:00', line: '903', to: ['NDSM'] },
            { time: '09:30:00', line: '900', to: ['Distelweg'] },
            { time: '09:40:00', line: '903', to: ['NDSM'] },
            { time: '09:45:00', line: '900', to: ['Distelweg'] },
            { time: '10:00:00', line: '900', to: ['Distelweg'] },
            { time: '10:00:00', line: '903', to: ['NDSM'] },
            { time: '10:15:00', line: '900', to: ['Distelweg'] },
            { time: '10:20:00', line: '903', to: ['NDSM'] },
            { time: '10:30:00', line: '900', to: ['Distelweg'] },
            { time: '10:40:00', line: '903', to: ['NDSM'] },
            { time: '10:45:00', line: '900', to: ['Distelweg'] },
            { time: '11:00:00', line: '900', to: ['Distelweg'] },
            { time: '11:00:00', line: '903', to: ['NDSM'] },
            { time: '11:15:00', line: '900', to: ['Distelweg'] },
            { time: '11:20:00', line: '903', to: ['NDSM'] },
            { time: '11:30:00', line: '900', to: ['Distelweg'] },
            { time: '11:40:00', line: '903', to: ['NDSM'] },
            { time: '11:45:00', line: '900', to: ['Distelweg'] },
            { time: '12:00:00', line: '900', to: ['Distelweg'] },
            { time: '12:00:00', line: '903', to: ['NDSM'] },
            { time: '12:15:00', line: '900', to: ['Distelweg'] },
            { time: '12:20:00', line: '903', to: ['NDSM'] },
            { time: '12:30:00', line: '900', to: ['Distelweg'] },
            { time: '12:40:00', line: '903', to: ['NDSM'] },
            { time: '12:45:00', line: '900', to: ['Distelweg'] },
            { time: '13:00:00', line: '900', to: ['Distelweg'] },
            { time: '13:00:00', line: '903', to: ['NDSM'] },
            { time: '13:15:00', line: '900', to: ['Distelweg'] },
            { time: '13:20:00', line: '903', to: ['NDSM'] },
            { time: '13:30:00', line: '900', to: ['Distelweg'] },
            { time: '13:40:00', line: '903', to: ['NDSM'] },
            { time: '13:45:00', line: '900', to: ['Distelweg'] },
            { time: '14:00:00', line: '900', to: ['Distelweg'] },
            { time: '14:00:00', line: '903', to: ['NDSM'] },
            { time: '14:15:00', line: '900', to: ['Distelweg'] },
            { time: '14:20:00', line: '903', to: ['NDSM'] },
            { time: '14:30:00', line: '900', to: ['Distelweg'] },
            { time: '14:40:00', line: '903', to: ['NDSM'] },
            { time: '14:45:00', line: '900', to: ['Distelweg'] },
            { time: '15:00:00', line: '900', to: ['Distelweg'] },
            { time: '15:00:00', line: '903', to: ['NDSM'] },
            { time: '15:15:00', line: '900', to: ['Distelweg'] },
            { time: '15:20:00', line: '903', to: ['NDSM'] },
            { time: '15:30:00', line: '900', to: ['Distelweg'] },
            { time: '15:40:00', line: '903', to: ['NDSM'] },
            { time: '15:45:00', line: '900', to: ['Distelweg'] },
            { time: '16:00:00', line: '900', to: ['Distelweg'] },
            { time: '16:00:00', line: '903', to: ['NDSM'] },
            { time: '16:10:00', line: '903', to: ['NDSM'] },
            { time: '16:15:00', line: '900', to: ['Distelweg'] },
            { time: '16:20:00', line: '903', to: ['NDSM'] },
            { time: '16:30:00', line: '900', to: ['Distelweg'] },
            { time: '16:30:00', line: '903', to: ['NDSM'] },
            { time: '16:40:00', line: '903', to: ['NDSM'] },
            { time: '16:45:00', line: '900', to: ['Distelweg'] },
            { time: '16:50:00', line: '903', to: ['NDSM'] },
            { time: '17:00:00', line: '900', to: ['Distelweg'] },
            { time: '17:00:00', line: '903', to: ['NDSM'] },
            { time: '17:10:00', line: '903', to: ['NDSM'] },
            { time: '17:15:00', line: '900', to: ['Distelweg'] },
            { time: '17:20:00', line: '903', to: ['NDSM'] },
            { time: '17:30:00', line: '900', to: ['Distelweg'] },
            { time: '17:30:00', line: '903', to: ['NDSM'] },
            { time: '17:40:00', line: '903', to: ['NDSM'] },
            { time: '17:45:00', line: '900', to: ['Distelweg'] },
            { time: '17:50:00', line: '903', to: ['NDSM'] },
            { time: '18:00:00', line: '900', to: ['Distelweg'] },
            { time: '18:00:00', line: '903', to: ['NDSM'] },
            { time: '18:10:00', line: '903', to: ['NDSM'] },
            { time: '18:15:00', line: '900', to: ['Distelweg'] },
            { time: '18:20:00', line: '903', to: ['NDSM'] },
            { time: '18:30:00', line: '900', to: ['Distelweg'] },
            { time: '18:30:00', line: '903', to: ['NDSM'] },
            { time: '18:40:00', line: '903', to: ['NDSM'] },
            { time: '18:45:00', line: '900', to: ['Distelweg'] },
            { time: '18:50:00', line: '903', to: ['NDSM'] },
            { time: '19:00:00', line: '900', to: ['Distelweg'] },
            { time: '19:00:00', line: '903', to: ['NDSM'] },
            { time: '19:10:00', line: '903', to: ['NDSM'] },
            { time: '19:15:00', line: '900', to: ['Distelweg'] },
            { time: '19:20:00', line: '903', to: ['NDSM'] },
            { time: '19:30:00', line: '900', to: ['Distelweg'] },
            { time: '19:30:00', line: '903', to: ['NDSM'] },
            { time: '19:40:00', line: '903', to: ['NDSM'] },
            { time: '19:45:00', line: '900', to: ['Distelweg'] },
            { time: '19:50:00', line: '903', to: ['NDSM'] },
            { time: '20:00:00', line: '900', to: ['Distelweg'] },
            { time: '20:00:00', line: '903', to: ['NDSM'] },
            { time: '20:15:00', line: '900', to: ['Distelweg'] },
            { time: '20:20:00', line: '903', to: ['NDSM'] },
            { time: '20:30:00', line: '900', to: ['Distelweg'] },
            { time: '20:40:00', line: '903', to: ['NDSM'] },
            { time: '20:45:00', line: '900', to: ['Distelweg'] },
            { time: '21:00:00', line: '900', to: ['Distelweg'] },
            { time: '21:00:00', line: '903', to: ['NDSM'] },
            { time: '21:15:00', line: '900', to: ['Distelweg'] },
            { time: '21:20:00', line: '903', to: ['NDSM'] },
            { time: '21:30:00', line: '900', to: ['Distelweg'] },
            { time: '21:40:00', line: '903', to: ['NDSM'] },
            { time: '21:45:00', line: '900', to: ['Distelweg'] },
            { time: '22:00:00', line: '900', to: ['Distelweg'] },
            { time: '22:00:00', line: '903', to: ['NDSM'] },
            { time: '22:15:00', line: '900', to: ['Distelweg'] },
            { time: '22:20:00', line: '903', to: ['NDSM'] },
            { time: '22:30:00', line: '900', to: ['Distelweg'] },
            { time: '22:40:00', line: '903', to: ['NDSM'] },
            { time: '22:45:00', line: '900', to: ['Distelweg'] },
            { time: '23:00:00', line: '903', to: ['NDSM'] },
            { time: '23:20:00', line: '903', to: ['NDSM'] },
            { time: '23:40:00', line: '903', to: ['NDSM'] },
        ],
        thursday: [
            { time: '00:08:00', line: '905', to: ['Centraal Station'] },
            { time: '00:46:00', line: '905', to: ['Centraal Station'] },
            { time: '01:24:00', line: '905', to: ['Centraal Station'] },
            { time: '02:02:00', line: '905', to: ['Centraal Station'] },
            { time: '06:30:00', line: '900', to: ['Distelweg'] },
            { time: '06:40:00', line: '903', to: ['NDSM'] },
            { time: '06:45:00', line: '900', to: ['Distelweg'] },
            { time: '07:00:00', line: '900', to: ['Distelweg'] },
            { time: '07:00:00', line: '903', to: ['NDSM'] },
            { time: '07:15:00', line: '900', to: ['Distelweg'] },
            { time: '07:20:00', line: '903', to: ['NDSM'] },
            { time: '07:30:00', line: '900', to: ['Distelweg'] },
            { time: '07:40:00', line: '903', to: ['NDSM'] },
            { time: '07:45:00', line: '900', to: ['Distelweg'] },
            { time: '08:00:00', line: '900', to: ['Distelweg'] },
            { time: '08:00:00', line: '903', to: ['NDSM'] },
            { time: '08:15:00', line: '900', to: ['Distelweg'] },
            { time: '08:20:00', line: '903', to: ['NDSM'] },
            { time: '08:30:00', line: '900', to: ['Distelweg'] },
            { time: '08:40:00', line: '903', to: ['NDSM'] },
            { time: '08:45:00', line: '900', to: ['Distelweg'] },
            { time: '09:00:00', line: '900', to: ['Distelweg'] },
            { time: '09:00:00', line: '903', to: ['NDSM'] },
            { time: '09:15:00', line: '900', to: ['Distelweg'] },
            { time: '09:20:00', line: '903', to: ['NDSM'] },
            { time: '09:30:00', line: '900', to: ['Distelweg'] },
            { time: '09:40:00', line: '903', to: ['NDSM'] },
            { time: '09:45:00', line: '900', to: ['Distelweg'] },
            { time: '10:00:00', line: '900', to: ['Distelweg'] },
            { time: '10:00:00', line: '903', to: ['NDSM'] },
            { time: '10:15:00', line: '900', to: ['Distelweg'] },
            { time: '10:20:00', line: '903', to: ['NDSM'] },
            { time: '10:30:00', line: '900', to: ['Distelweg'] },
            { time: '10:40:00', line: '903', to: ['NDSM'] },
            { time: '10:45:00', line: '900', to: ['Distelweg'] },
            { time: '11:00:00', line: '900', to: ['Distelweg'] },
            { time: '11:00:00', line: '903', to: ['NDSM'] },
            { time: '11:15:00', line: '900', to: ['Distelweg'] },
            { time: '11:20:00', line: '903', to: ['NDSM'] },
            { time: '11:30:00', line: '900', to: ['Distelweg'] },
            { time: '11:40:00', line: '903', to: ['NDSM'] },
            { time: '11:45:00', line: '900', to: ['Distelweg'] },
            { time: '12:00:00', line: '900', to: ['Distelweg'] },
            { time: '12:00:00', line: '903', to: ['NDSM'] },
            { time: '12:15:00', line: '900', to: ['Distelweg'] },
            { time: '12:20:00', line: '903', to: ['NDSM'] },
            { time: '12:30:00', line: '900', to: ['Distelweg'] },
            { time: '12:40:00', line: '903', to: ['NDSM'] },
            { time: '12:45:00', line: '900', to: ['Distelweg'] },
            { time: '13:00:00', line: '900', to: ['Distelweg'] },
            { time: '13:00:00', line: '903', to: ['NDSM'] },
            { time: '13:15:00', line: '900', to: ['Distelweg'] },
            { time: '13:20:00', line: '903', to: ['NDSM'] },
            { time: '13:30:00', line: '900', to: ['Distelweg'] },
            { time: '13:40:00', line: '903', to: ['NDSM'] },
            { time: '13:45:00', line: '900', to: ['Distelweg'] },
            { time: '14:00:00', line: '900', to: ['Distelweg'] },
            { time: '14:00:00', line: '903', to: ['NDSM'] },
            { time: '14:15:00', line: '900', to: ['Distelweg'] },
            { time: '14:20:00', line: '903', to: ['NDSM'] },
            { time: '14:30:00', line: '900', to: ['Distelweg'] },
            { time: '14:40:00', line: '903', to: ['NDSM'] },
            { time: '14:45:00', line: '900', to: ['Distelweg'] },
            { time: '15:00:00', line: '900', to: ['Distelweg'] },
            { time: '15:00:00', line: '903', to: ['NDSM'] },
            { time: '15:15:00', line: '900', to: ['Distelweg'] },
            { time: '15:20:00', line: '903', to: ['NDSM'] },
            { time: '15:30:00', line: '900', to: ['Distelweg'] },
            { time: '15:40:00', line: '903', to: ['NDSM'] },
            { time: '15:45:00', line: '900', to: ['Distelweg'] },
            { time: '16:00:00', line: '900', to: ['Distelweg'] },
            { time: '16:00:00', line: '903', to: ['NDSM'] },
            { time: '16:10:00', line: '903', to: ['NDSM'] },
            { time: '16:15:00', line: '900', to: ['Distelweg'] },
            { time: '16:20:00', line: '903', to: ['NDSM'] },
            { time: '16:30:00', line: '900', to: ['Distelweg'] },
            { time: '16:30:00', line: '903', to: ['NDSM'] },
            { time: '16:40:00', line: '903', to: ['NDSM'] },
            { time: '16:45:00', line: '900', to: ['Distelweg'] },
            { time: '16:50:00', line: '903', to: ['NDSM'] },
            { time: '17:00:00', line: '900', to: ['Distelweg'] },
            { time: '17:00:00', line: '903', to: ['NDSM'] },
            { time: '17:10:00', line: '903', to: ['NDSM'] },
            { time: '17:15:00', line: '900', to: ['Distelweg'] },
            { time: '17:20:00', line: '903', to: ['NDSM'] },
            { time: '17:30:00', line: '900', to: ['Distelweg'] },
            { time: '17:30:00', line: '903', to: ['NDSM'] },
            { time: '17:40:00', line: '903', to: ['NDSM'] },
            { time: '17:45:00', line: '900', to: ['Distelweg'] },
            { time: '17:50:00', line: '903', to: ['NDSM'] },
            { time: '18:00:00', line: '900', to: ['Distelweg'] },
            { time: '18:00:00', line: '903', to: ['NDSM'] },
            { time: '18:10:00', line: '903', to: ['NDSM'] },
            { time: '18:15:00', line: '900', to: ['Distelweg'] },
            { time: '18:20:00', line: '903', to: ['NDSM'] },
            { time: '18:30:00', line: '900', to: ['Distelweg'] },
            { time: '18:30:00', line: '903', to: ['NDSM'] },
            { time: '18:40:00', line: '903', to: ['NDSM'] },
            { time: '18:45:00', line: '900', to: ['Distelweg'] },
            { time: '18:50:00', line: '903', to: ['NDSM'] },
            { time: '19:00:00', line: '900', to: ['Distelweg'] },
            { time: '19:00:00', line: '903', to: ['NDSM'] },
            { time: '19:10:00', line: '903', to: ['NDSM'] },
            { time: '19:15:00', line: '900', to: ['Distelweg'] },
            { time: '19:20:00', line: '903', to: ['NDSM'] },
            { time: '19:30:00', line: '900', to: ['Distelweg'] },
            { time: '19:30:00', line: '903', to: ['NDSM'] },
            { time: '19:40:00', line: '903', to: ['NDSM'] },
            { time: '19:45:00', line: '900', to: ['Distelweg'] },
            { time: '19:50:00', line: '903', to: ['NDSM'] },
            { time: '20:00:00', line: '900', to: ['Distelweg'] },
            { time: '20:00:00', line: '903', to: ['NDSM'] },
            { time: '20:15:00', line: '900', to: ['Distelweg'] },
            { time: '20:20:00', line: '903', to: ['NDSM'] },
            { time: '20:30:00', line: '900', to: ['Distelweg'] },
            { time: '20:40:00', line: '903', to: ['NDSM'] },
            { time: '20:45:00', line: '900', to: ['Distelweg'] },
            { time: '21:00:00', line: '900', to: ['Distelweg'] },
            { time: '21:00:00', line: '903', to: ['NDSM'] },
            { time: '21:15:00', line: '900', to: ['Distelweg'] },
            { time: '21:20:00', line: '903', to: ['NDSM'] },
            { time: '21:30:00', line: '900', to: ['Distelweg'] },
            { time: '21:40:00', line: '903', to: ['NDSM'] },
            { time: '21:45:00', line: '900', to: ['Distelweg'] },
            { time: '22:00:00', line: '900', to: ['Distelweg'] },
            { time: '22:00:00', line: '903', to: ['NDSM'] },
            { time: '22:15:00', line: '900', to: ['Distelweg'] },
            { time: '22:20:00', line: '903', to: ['NDSM'] },
            { time: '22:30:00', line: '900', to: ['Distelweg'] },
            { time: '22:40:00', line: '903', to: ['NDSM'] },
            { time: '22:45:00', line: '900', to: ['Distelweg'] },
            { time: '23:00:00', line: '903', to: ['NDSM'] },
            { time: '23:20:00', line: '903', to: ['NDSM'] },
            { time: '23:40:00', line: '903', to: ['NDSM'] },
        ],
        friday: [
            { time: '00:08:00', line: '905', to: ['Centraal Station'] },
            { time: '00:46:00', line: '905', to: ['Centraal Station'] },
            { time: '01:24:00', line: '905', to: ['Centraal Station'] },
            { time: '02:02:00', line: '905', to: ['Centraal Station'] },
            { time: '06:30:00', line: '900', to: ['Distelweg'] },
            { time: '06:40:00', line: '903', to: ['NDSM'] },
            { time: '06:45:00', line: '900', to: ['Distelweg'] },
            { time: '07:00:00', line: '900', to: ['Distelweg'] },
            { time: '07:00:00', line: '903', to: ['NDSM'] },
            { time: '07:15:00', line: '900', to: ['Distelweg'] },
            { time: '07:20:00', line: '903', to: ['NDSM'] },
            { time: '07:30:00', line: '900', to: ['Distelweg'] },
            { time: '07:40:00', line: '903', to: ['NDSM'] },
            { time: '07:45:00', line: '900', to: ['Distelweg'] },
            { time: '08:00:00', line: '900', to: ['Distelweg'] },
            { time: '08:00:00', line: '903', to: ['NDSM'] },
            { time: '08:15:00', line: '900', to: ['Distelweg'] },
            { time: '08:20:00', line: '903', to: ['NDSM'] },
            { time: '08:30:00', line: '900', to: ['Distelweg'] },
            { time: '08:40:00', line: '903', to: ['NDSM'] },
            { time: '08:45:00', line: '900', to: ['Distelweg'] },
            { time: '09:00:00', line: '900', to: ['Distelweg'] },
            { time: '09:00:00', line: '903', to: ['NDSM'] },
            { time: '09:15:00', line: '900', to: ['Distelweg'] },
            { time: '09:20:00', line: '903', to: ['NDSM'] },
            { time: '09:30:00', line: '900', to: ['Distelweg'] },
            { time: '09:40:00', line: '903', to: ['NDSM'] },
            { time: '09:45:00', line: '900', to: ['Distelweg'] },
            { time: '10:00:00', line: '900', to: ['Distelweg'] },
            { time: '10:00:00', line: '903', to: ['NDSM'] },
            { time: '10:15:00', line: '900', to: ['Distelweg'] },
            { time: '10:20:00', line: '903', to: ['NDSM'] },
            { time: '10:30:00', line: '900', to: ['Distelweg'] },
            { time: '10:40:00', line: '903', to: ['NDSM'] },
            { time: '10:45:00', line: '900', to: ['Distelweg'] },
            { time: '11:00:00', line: '900', to: ['Distelweg'] },
            { time: '11:00:00', line: '903', to: ['NDSM'] },
            { time: '11:15:00', line: '900', to: ['Distelweg'] },
            { time: '11:20:00', line: '903', to: ['NDSM'] },
            { time: '11:30:00', line: '900', to: ['Distelweg'] },
            { time: '11:40:00', line: '903', to: ['NDSM'] },
            { time: '11:45:00', line: '900', to: ['Distelweg'] },
            { time: '12:00:00', line: '900', to: ['Distelweg'] },
            { time: '12:00:00', line: '903', to: ['NDSM'] },
            { time: '12:15:00', line: '900', to: ['Distelweg'] },
            { time: '12:20:00', line: '903', to: ['NDSM'] },
            { time: '12:30:00', line: '900', to: ['Distelweg'] },
            { time: '12:40:00', line: '903', to: ['NDSM'] },
            { time: '12:45:00', line: '900', to: ['Distelweg'] },
            { time: '13:00:00', line: '900', to: ['Distelweg'] },
            { time: '13:00:00', line: '903', to: ['NDSM'] },
            { time: '13:15:00', line: '900', to: ['Distelweg'] },
            { time: '13:20:00', line: '903', to: ['NDSM'] },
            { time: '13:30:00', line: '900', to: ['Distelweg'] },
            { time: '13:40:00', line: '903', to: ['NDSM'] },
            { time: '13:45:00', line: '900', to: ['Distelweg'] },
            { time: '14:00:00', line: '900', to: ['Distelweg'] },
            { time: '14:00:00', line: '903', to: ['NDSM'] },
            { time: '14:15:00', line: '900', to: ['Distelweg'] },
            { time: '14:20:00', line: '903', to: ['NDSM'] },
            { time: '14:30:00', line: '900', to: ['Distelweg'] },
            { time: '14:40:00', line: '903', to: ['NDSM'] },
            { time: '14:45:00', line: '900', to: ['Distelweg'] },
            { time: '15:00:00', line: '900', to: ['Distelweg'] },
            { time: '15:00:00', line: '903', to: ['NDSM'] },
            { time: '15:15:00', line: '900', to: ['Distelweg'] },
            { time: '15:20:00', line: '903', to: ['NDSM'] },
            { time: '15:30:00', line: '900', to: ['Distelweg'] },
            { time: '15:40:00', line: '903', to: ['NDSM'] },
            { time: '15:45:00', line: '900', to: ['Distelweg'] },
            { time: '16:00:00', line: '900', to: ['Distelweg'] },
            { time: '16:00:00', line: '903', to: ['NDSM'] },
            { time: '16:10:00', line: '903', to: ['NDSM'] },
            { time: '16:15:00', line: '900', to: ['Distelweg'] },
            { time: '16:20:00', line: '903', to: ['NDSM'] },
            { time: '16:30:00', line: '900', to: ['Distelweg'] },
            { time: '16:30:00', line: '903', to: ['NDSM'] },
            { time: '16:40:00', line: '903', to: ['NDSM'] },
            { time: '16:45:00', line: '900', to: ['Distelweg'] },
            { time: '16:50:00', line: '903', to: ['NDSM'] },
            { time: '17:00:00', line: '900', to: ['Distelweg'] },
            { time: '17:00:00', line: '903', to: ['NDSM'] },
            { time: '17:10:00', line: '903', to: ['NDSM'] },
            { time: '17:15:00', line: '900', to: ['Distelweg'] },
            { time: '17:20:00', line: '903', to: ['NDSM'] },
            { time: '17:30:00', line: '900', to: ['Distelweg'] },
            { time: '17:30:00', line: '903', to: ['NDSM'] },
            { time: '17:40:00', line: '903', to: ['NDSM'] },
            { time: '17:45:00', line: '900', to: ['Distelweg'] },
            { time: '17:50:00', line: '903', to: ['NDSM'] },
            { time: '18:00:00', line: '900', to: ['Distelweg'] },
            { time: '18:00:00', line: '903', to: ['NDSM'] },
            { time: '18:10:00', line: '903', to: ['NDSM'] },
            { time: '18:15:00', line: '900', to: ['Distelweg'] },
            { time: '18:20:00', line: '903', to: ['NDSM'] },
            { time: '18:30:00', line: '900', to: ['Distelweg'] },
            { time: '18:30:00', line: '903', to: ['NDSM'] },
            { time: '18:40:00', line: '903', to: ['NDSM'] },
            { time: '18:45:00', line: '900', to: ['Distelweg'] },
            { time: '18:50:00', line: '903', to: ['NDSM'] },
            { time: '19:00:00', line: '900', to: ['Distelweg'] },
            { time: '19:00:00', line: '903', to: ['NDSM'] },
            { time: '19:10:00', line: '903', to: ['NDSM'] },
            { time: '19:15:00', line: '900', to: ['Distelweg'] },
            { time: '19:20:00', line: '903', to: ['NDSM'] },
            { time: '19:30:00', line: '900', to: ['Distelweg'] },
            { time: '19:30:00', line: '903', to: ['NDSM'] },
            { time: '19:40:00', line: '903', to: ['NDSM'] },
            { time: '19:45:00', line: '900', to: ['Distelweg'] },
            { time: '19:50:00', line: '903', to: ['NDSM'] },
            { time: '20:00:00', line: '900', to: ['Distelweg'] },
            { time: '20:00:00', line: '903', to: ['NDSM'] },
            { time: '20:15:00', line: '900', to: ['Distelweg'] },
            { time: '20:20:00', line: '903', to: ['NDSM'] },
            { time: '20:30:00', line: '900', to: ['Distelweg'] },
            { time: '20:40:00', line: '903', to: ['NDSM'] },
            { time: '20:45:00', line: '900', to: ['Distelweg'] },
            { time: '21:00:00', line: '900', to: ['Distelweg'] },
            { time: '21:00:00', line: '903', to: ['NDSM'] },
            { time: '21:15:00', line: '900', to: ['Distelweg'] },
            { time: '21:20:00', line: '903', to: ['NDSM'] },
            { time: '21:30:00', line: '900', to: ['Distelweg'] },
            { time: '21:40:00', line: '903', to: ['NDSM'] },
            { time: '21:45:00', line: '900', to: ['Distelweg'] },
            { time: '22:00:00', line: '900', to: ['Distelweg'] },
            { time: '22:00:00', line: '903', to: ['NDSM'] },
            { time: '22:15:00', line: '900', to: ['Distelweg'] },
            { time: '22:20:00', line: '903', to: ['NDSM'] },
            { time: '22:30:00', line: '900', to: ['Distelweg'] },
            { time: '22:40:00', line: '903', to: ['NDSM'] },
            { time: '22:45:00', line: '900', to: ['Distelweg'] },
            { time: '23:00:00', line: '903', to: ['NDSM'] },
            { time: '23:20:00', line: '903', to: ['NDSM'] },
            { time: '23:40:00', line: '903', to: ['NDSM'] },
        ],
        saturday: [
            { time: '00:08:00', line: '905', to: ['Centraal Station'] },
            { time: '00:46:00', line: '905', to: ['Centraal Station'] },
            { time: '01:23:00', line: '905', to: ['Centraal Station'] },
            { time: '02:02:00', line: '905', to: ['Centraal Station'] },
            { time: '02:40:00', line: '905', to: ['Centraal Station'] },
            { time: '03:18:00', line: '905', to: ['Centraal Station'] },
            { time: '09:00:00', line: '903', to: ['NDSM'] },
            { time: '09:20:00', line: '903', to: ['NDSM'] },
            { time: '09:40:00', line: '903', to: ['NDSM'] },
            { time: '10:00:00', line: '903', to: ['NDSM'] },
            { time: '10:20:00', line: '903', to: ['NDSM'] },
            { time: '10:40:00', line: '903', to: ['NDSM'] },
            { time: '11:00:00', line: '903', to: ['NDSM'] },
            { time: '11:10:00', line: '903', to: ['NDSM'] },
            { time: '11:20:00', line: '903', to: ['NDSM'] },
            { time: '11:30:00', line: '903', to: ['NDSM'] },
            { time: '11:40:00', line: '903', to: ['NDSM'] },
            { time: '11:50:00', line: '903', to: ['NDSM'] },
            { time: '12:00:00', line: '903', to: ['NDSM'] },
            { time: '12:10:00', line: '903', to: ['NDSM'] },
            { time: '12:20:00', line: '903', to: ['NDSM'] },
            { time: '12:30:00', line: '903', to: ['NDSM'] },
            { time: '12:40:00', line: '903', to: ['NDSM'] },
            { time: '12:50:00', line: '903', to: ['NDSM'] },
            { time: '13:00:00', line: '903', to: ['NDSM'] },
            { time: '13:10:00', line: '903', to: ['NDSM'] },
            { time: '13:20:00', line: '903', to: ['NDSM'] },
            { time: '13:30:00', line: '903', to: ['NDSM'] },
            { time: '13:40:00', line: '903', to: ['NDSM'] },
            { time: '13:50:00', line: '903', to: ['NDSM'] },
            { time: '14:00:00', line: '903', to: ['NDSM'] },
            { time: '14:10:00', line: '903', to: ['NDSM'] },
            { time: '14:20:00', line: '903', to: ['NDSM'] },
            { time: '14:30:00', line: '903', to: ['NDSM'] },
            { time: '14:40:00', line: '903', to: ['NDSM'] },
            { time: '14:50:00', line: '903', to: ['NDSM'] },
            { time: '15:00:00', line: '903', to: ['NDSM'] },
            { time: '15:10:00', line: '903', to: ['NDSM'] },
            { time: '15:20:00', line: '903', to: ['NDSM'] },
            { time: '15:30:00', line: '903', to: ['NDSM'] },
            { time: '15:40:00', line: '903', to: ['NDSM'] },
            { time: '15:50:00', line: '903', to: ['NDSM'] },
            { time: '16:00:00', line: '903', to: ['NDSM'] },
            { time: '16:10:00', line: '903', to: ['NDSM'] },
            { time: '16:20:00', line: '903', to: ['NDSM'] },
            { time: '16:30:00', line: '903', to: ['NDSM'] },
            { time: '16:40:00', line: '903', to: ['NDSM'] },
            { time: '16:50:00', line: '903', to: ['NDSM'] },
            { time: '17:00:00', line: '903', to: ['NDSM'] },
            { time: '17:10:00', line: '903', to: ['NDSM'] },
            { time: '17:20:00', line: '903', to: ['NDSM'] },
            { time: '17:30:00', line: '903', to: ['NDSM'] },
            { time: '17:40:00', line: '903', to: ['NDSM'] },
            { time: '17:50:00', line: '903', to: ['NDSM'] },
            { time: '18:00:00', line: '903', to: ['NDSM'] },
            { time: '18:20:00', line: '903', to: ['NDSM'] },
            { time: '18:40:00', line: '903', to: ['NDSM'] },
            { time: '19:00:00', line: '903', to: ['NDSM'] },
            { time: '19:20:00', line: '903', to: ['NDSM'] },
            { time: '19:40:00', line: '903', to: ['NDSM'] },
            { time: '20:00:00', line: '903', to: ['NDSM'] },
            { time: '20:20:00', line: '903', to: ['NDSM'] },
            { time: '20:40:00', line: '903', to: ['NDSM'] },
            { time: '21:00:00', line: '903', to: ['NDSM'] },
            { time: '21:20:00', line: '903', to: ['NDSM'] },
            { time: '21:40:00', line: '903', to: ['NDSM'] },
            { time: '22:00:00', line: '903', to: ['NDSM'] },
            { time: '22:20:00', line: '903', to: ['NDSM'] },
            { time: '22:40:00', line: '903', to: ['NDSM'] },
            { time: '23:00:00', line: '903', to: ['NDSM'] },
            { time: '23:20:00', line: '903', to: ['NDSM'] },
            { time: '23:40:00', line: '903', to: ['NDSM'] },
        ],
        sunday: [
            { time: '00:08:00', line: '905', to: ['Centraal Station'] },
            { time: '00:46:00', line: '905', to: ['Centraal Station'] },
            { time: '01:24:00', line: '905', to: ['Centraal Station'] },
            { time: '02:02:00', line: '905', to: ['Centraal Station'] },
            { time: '02:40:00', line: '905', to: ['Centraal Station'] },
            { time: '03:18:00', line: '905', to: ['Centraal Station'] },
            { time: '09:00:00', line: '903', to: ['NDSM'] },
            { time: '09:20:00', line: '903', to: ['NDSM'] },
            { time: '09:40:00', line: '903', to: ['NDSM'] },
            { time: '10:00:00', line: '903', to: ['NDSM'] },
            { time: '10:20:00', line: '903', to: ['NDSM'] },
            { time: '10:40:00', line: '903', to: ['NDSM'] },
            { time: '11:00:00', line: '903', to: ['NDSM'] },
            { time: '11:10:00', line: '903', to: ['NDSM'] },
            { time: '11:20:00', line: '903', to: ['NDSM'] },
            { time: '11:30:00', line: '903', to: ['NDSM'] },
            { time: '11:40:00', line: '903', to: ['NDSM'] },
            { time: '11:50:00', line: '903', to: ['NDSM'] },
            { time: '12:00:00', line: '903', to: ['NDSM'] },
            { time: '12:10:00', line: '903', to: ['NDSM'] },
            { time: '12:20:00', line: '903', to: ['NDSM'] },
            { time: '12:30:00', line: '903', to: ['NDSM'] },
            { time: '12:40:00', line: '903', to: ['NDSM'] },
            { time: '12:50:00', line: '903', to: ['NDSM'] },
            { time: '13:00:00', line: '903', to: ['NDSM'] },
            { time: '13:10:00', line: '903', to: ['NDSM'] },
            { time: '13:20:00', line: '903', to: ['NDSM'] },
            { time: '13:30:00', line: '903', to: ['NDSM'] },
            { time: '13:40:00', line: '903', to: ['NDSM'] },
            { time: '13:50:00', line: '903', to: ['NDSM'] },
            { time: '14:00:00', line: '903', to: ['NDSM'] },
            { time: '14:10:00', line: '903', to: ['NDSM'] },
            { time: '14:20:00', line: '903', to: ['NDSM'] },
            { time: '14:30:00', line: '903', to: ['NDSM'] },
            { time: '14:40:00', line: '903', to: ['NDSM'] },
            { time: '14:50:00', line: '903', to: ['NDSM'] },
            { time: '15:00:00', line: '903', to: ['NDSM'] },
            { time: '15:10:00', line: '903', to: ['NDSM'] },
            { time: '15:20:00', line: '903', to: ['NDSM'] },
            { time: '15:30:00', line: '903', to: ['NDSM'] },
            { time: '15:40:00', line: '903', to: ['NDSM'] },
            { time: '15:50:00', line: '903', to: ['NDSM'] },
            { time: '16:00:00', line: '903', to: ['NDSM'] },
            { time: '16:10:00', line: '903', to: ['NDSM'] },
            { time: '16:20:00', line: '903', to: ['NDSM'] },
            { time: '16:30:00', line: '903', to: ['NDSM'] },
            { time: '16:40:00', line: '903', to: ['NDSM'] },
            { time: '16:50:00', line: '903', to: ['NDSM'] },
            { time: '17:00:00', line: '903', to: ['NDSM'] },
            { time: '17:10:00', line: '903', to: ['NDSM'] },
            { time: '17:20:00', line: '903', to: ['NDSM'] },
            { time: '17:30:00', line: '903', to: ['NDSM'] },
            { time: '17:40:00', line: '903', to: ['NDSM'] },
            { time: '17:50:00', line: '903', to: ['NDSM'] },
            { time: '18:00:00', line: '903', to: ['NDSM'] },
            { time: '18:20:00', line: '903', to: ['NDSM'] },
            { time: '18:40:00', line: '903', to: ['NDSM'] },
            { time: '19:00:00', line: '903', to: ['NDSM'] },
            { time: '19:20:00', line: '903', to: ['NDSM'] },
            { time: '19:40:00', line: '903', to: ['NDSM'] },
            { time: '20:00:00', line: '903', to: ['NDSM'] },
            { time: '20:20:00', line: '903', to: ['NDSM'] },
            { time: '20:40:00', line: '903', to: ['NDSM'] },
            { time: '21:00:00', line: '903', to: ['NDSM'] },
            { time: '21:20:00', line: '903', to: ['NDSM'] },
            { time: '21:40:00', line: '903', to: ['NDSM'] },
            { time: '22:00:00', line: '903', to: ['NDSM'] },
            { time: '22:20:00', line: '903', to: ['NDSM'] },
            { time: '22:40:00', line: '903', to: ['NDSM'] },
            { time: '23:00:00', line: '903', to: ['NDSM'] },
            { time: '23:20:00', line: '903', to: ['NDSM'] },
            { time: '23:40:00', line: '903', to: ['NDSM'] },
        ],
    },
    'Centraal Station': {
        monday: [
            { time: '00:23:00', line: '905', to: ['NDSM'] },
            { time: '01:01:00', line: '905', to: ['NDSM'] },
            { time: '01:39:00', line: '905', to: ['NDSM'] },
            { time: '05:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:30:00', line: '906', to: ['NDSM'] },
            { time: '06:32:00', line: '902', to: ['IJplein'] },
            { time: '06:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:38:00', line: '902', to: ['IJplein'] },
            { time: '06:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:44:00', line: '902', to: ['IJplein'] },
            { time: '06:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:50:00', line: '902', to: ['IJplein'] },
            { time: '06:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:56:00', line: '902', to: ['IJplein'] },
            { time: '06:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:00:00', line: '906', to: ['NDSM'] },
            { time: '07:02:00', line: '902', to: ['IJplein'] },
            { time: '07:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:08:00', line: '902', to: ['IJplein'] },
            { time: '07:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:14:00', line: '902', to: ['IJplein'] },
            { time: '07:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:20:00', line: '902', to: ['IJplein'] },
            { time: '07:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:26:00', line: '902', to: ['IJplein'] },
            { time: '07:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:30:00', line: '906', to: ['NDSM'] },
            { time: '07:32:00', line: '902', to: ['IJplein'] },
            { time: '07:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:38:00', line: '902', to: ['IJplein'] },
            { time: '07:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:44:00', line: '902', to: ['IJplein'] },
            { time: '07:45:00', line: '906', to: ['NDSM'] },
            { time: '07:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:50:00', line: '902', to: ['IJplein'] },
            { time: '07:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:56:00', line: '902', to: ['IJplein'] },
            { time: '07:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:00:00', line: '906', to: ['NDSM'] },
            { time: '08:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:02:00', line: '902', to: ['IJplein'] },
            { time: '08:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:08:00', line: '902', to: ['IJplein'] },
            { time: '08:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:14:00', line: '902', to: ['IJplein'] },
            { time: '08:15:00', line: '906', to: ['NDSM'] },
            { time: '08:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:20:00', line: '902', to: ['IJplein'] },
            { time: '08:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:26:00', line: '902', to: ['IJplein'] },
            { time: '08:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:30:00', line: '906', to: ['NDSM'] },
            { time: '08:32:00', line: '902', to: ['IJplein'] },
            { time: '08:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:38:00', line: '902', to: ['IJplein'] },
            { time: '08:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:44:00', line: '902', to: ['IJplein'] },
            { time: '08:45:00', line: '906', to: ['NDSM'] },
            { time: '08:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:50:00', line: '902', to: ['IJplein'] },
            { time: '08:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:56:00', line: '902', to: ['IJplein'] },
            { time: '08:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:00:00', line: '906', to: ['NDSM'] },
            { time: '09:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:02:00', line: '902', to: ['IJplein'] },
            { time: '09:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:08:00', line: '902', to: ['IJplein'] },
            { time: '09:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:14:00', line: '902', to: ['IJplein'] },
            { time: '09:15:00', line: '906', to: ['NDSM'] },
            { time: '09:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:20:00', line: '902', to: ['IJplein'] },
            { time: '09:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:26:00', line: '902', to: ['IJplein'] },
            { time: '09:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:30:00', line: '906', to: ['NDSM'] },
            { time: '09:32:00', line: '902', to: ['IJplein'] },
            { time: '09:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:38:00', line: '902', to: ['IJplein'] },
            { time: '09:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:44:00', line: '902', to: ['IJplein'] },
            { time: '09:45:00', line: '906', to: ['NDSM'] },
            { time: '09:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:50:00', line: '902', to: ['IJplein'] },
            { time: '09:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:56:00', line: '902', to: ['IJplein'] },
            { time: '09:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:00:00', line: '906', to: ['NDSM'] },
            { time: '10:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:02:00', line: '902', to: ['IJplein'] },
            { time: '10:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:08:00', line: '902', to: ['IJplein'] },
            { time: '10:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:14:00', line: '902', to: ['IJplein'] },
            { time: '10:15:00', line: '906', to: ['NDSM'] },
            { time: '10:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:20:00', line: '902', to: ['IJplein'] },
            { time: '10:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:26:00', line: '902', to: ['IJplein'] },
            { time: '10:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:30:00', line: '906', to: ['NDSM'] },
            { time: '10:32:00', line: '902', to: ['IJplein'] },
            { time: '10:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:38:00', line: '902', to: ['IJplein'] },
            { time: '10:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:44:00', line: '902', to: ['IJplein'] },
            { time: '10:45:00', line: '906', to: ['NDSM'] },
            { time: '10:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:50:00', line: '902', to: ['IJplein'] },
            { time: '10:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:56:00', line: '902', to: ['IJplein'] },
            { time: '10:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:00:00', line: '906', to: ['NDSM'] },
            { time: '11:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:02:00', line: '902', to: ['IJplein'] },
            { time: '11:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:08:00', line: '902', to: ['IJplein'] },
            { time: '11:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:14:00', line: '902', to: ['IJplein'] },
            { time: '11:15:00', line: '906', to: ['NDSM'] },
            { time: '11:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:20:00', line: '902', to: ['IJplein'] },
            { time: '11:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:26:00', line: '902', to: ['IJplein'] },
            { time: '11:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:30:00', line: '906', to: ['NDSM'] },
            { time: '11:32:00', line: '902', to: ['IJplein'] },
            { time: '11:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:38:00', line: '902', to: ['IJplein'] },
            { time: '11:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:44:00', line: '902', to: ['IJplein'] },
            { time: '11:45:00', line: '906', to: ['NDSM'] },
            { time: '11:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:50:00', line: '902', to: ['IJplein'] },
            { time: '11:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:56:00', line: '902', to: ['IJplein'] },
            { time: '11:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:00:00', line: '906', to: ['NDSM'] },
            { time: '12:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:02:00', line: '902', to: ['IJplein'] },
            { time: '12:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:08:00', line: '902', to: ['IJplein'] },
            { time: '12:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:14:00', line: '902', to: ['IJplein'] },
            { time: '12:15:00', line: '906', to: ['NDSM'] },
            { time: '12:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:20:00', line: '902', to: ['IJplein'] },
            { time: '12:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:26:00', line: '902', to: ['IJplein'] },
            { time: '12:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:30:00', line: '906', to: ['NDSM'] },
            { time: '12:32:00', line: '902', to: ['IJplein'] },
            { time: '12:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:38:00', line: '902', to: ['IJplein'] },
            { time: '12:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:44:00', line: '902', to: ['IJplein'] },
            { time: '12:45:00', line: '906', to: ['NDSM'] },
            { time: '12:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:50:00', line: '902', to: ['IJplein'] },
            { time: '12:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:56:00', line: '902', to: ['IJplein'] },
            { time: '12:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:00:00', line: '906', to: ['NDSM'] },
            { time: '13:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:02:00', line: '902', to: ['IJplein'] },
            { time: '13:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:08:00', line: '902', to: ['IJplein'] },
            { time: '13:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:14:00', line: '902', to: ['IJplein'] },
            { time: '13:15:00', line: '906', to: ['NDSM'] },
            { time: '13:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:20:00', line: '902', to: ['IJplein'] },
            { time: '13:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:26:00', line: '902', to: ['IJplein'] },
            { time: '13:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:30:00', line: '906', to: ['NDSM'] },
            { time: '13:32:00', line: '902', to: ['IJplein'] },
            { time: '13:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:38:00', line: '902', to: ['IJplein'] },
            { time: '13:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:44:00', line: '902', to: ['IJplein'] },
            { time: '13:45:00', line: '906', to: ['NDSM'] },
            { time: '13:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:50:00', line: '902', to: ['IJplein'] },
            { time: '13:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:56:00', line: '902', to: ['IJplein'] },
            { time: '13:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:00:00', line: '906', to: ['NDSM'] },
            { time: '14:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:02:00', line: '902', to: ['IJplein'] },
            { time: '14:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:08:00', line: '902', to: ['IJplein'] },
            { time: '14:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:14:00', line: '902', to: ['IJplein'] },
            { time: '14:15:00', line: '906', to: ['NDSM'] },
            { time: '14:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:20:00', line: '902', to: ['IJplein'] },
            { time: '14:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:26:00', line: '902', to: ['IJplein'] },
            { time: '14:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:30:00', line: '906', to: ['NDSM'] },
            { time: '14:32:00', line: '902', to: ['IJplein'] },
            { time: '14:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:38:00', line: '902', to: ['IJplein'] },
            { time: '14:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:44:00', line: '902', to: ['IJplein'] },
            { time: '14:45:00', line: '906', to: ['NDSM'] },
            { time: '14:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:50:00', line: '902', to: ['IJplein'] },
            { time: '14:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:56:00', line: '902', to: ['IJplein'] },
            { time: '14:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:00:00', line: '906', to: ['NDSM'] },
            { time: '15:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:02:00', line: '902', to: ['IJplein'] },
            { time: '15:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:08:00', line: '902', to: ['IJplein'] },
            { time: '15:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:14:00', line: '902', to: ['IJplein'] },
            { time: '15:15:00', line: '906', to: ['NDSM'] },
            { time: '15:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:20:00', line: '902', to: ['IJplein'] },
            { time: '15:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:26:00', line: '902', to: ['IJplein'] },
            { time: '15:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:30:00', line: '906', to: ['NDSM'] },
            { time: '15:32:00', line: '902', to: ['IJplein'] },
            { time: '15:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:38:00', line: '902', to: ['IJplein'] },
            { time: '15:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:44:00', line: '902', to: ['IJplein'] },
            { time: '15:45:00', line: '906', to: ['NDSM'] },
            { time: '15:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:50:00', line: '902', to: ['IJplein'] },
            { time: '15:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:56:00', line: '902', to: ['IJplein'] },
            { time: '15:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:00:00', line: '906', to: ['NDSM'] },
            { time: '16:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:02:00', line: '902', to: ['IJplein'] },
            { time: '16:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:08:00', line: '902', to: ['IJplein'] },
            { time: '16:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:14:00', line: '902', to: ['IJplein'] },
            { time: '16:15:00', line: '906', to: ['NDSM'] },
            { time: '16:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:20:00', line: '902', to: ['IJplein'] },
            { time: '16:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:26:00', line: '902', to: ['IJplein'] },
            { time: '16:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:30:00', line: '906', to: ['NDSM'] },
            { time: '16:32:00', line: '902', to: ['IJplein'] },
            { time: '16:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:38:00', line: '902', to: ['IJplein'] },
            { time: '16:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:44:00', line: '902', to: ['IJplein'] },
            { time: '16:45:00', line: '906', to: ['NDSM'] },
            { time: '16:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:50:00', line: '902', to: ['IJplein'] },
            { time: '16:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:56:00', line: '902', to: ['IJplein'] },
            { time: '16:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:00:00', line: '906', to: ['NDSM'] },
            { time: '17:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:02:00', line: '902', to: ['IJplein'] },
            { time: '17:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:08:00', line: '902', to: ['IJplein'] },
            { time: '17:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:14:00', line: '902', to: ['IJplein'] },
            { time: '17:15:00', line: '906', to: ['NDSM'] },
            { time: '17:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:20:00', line: '902', to: ['IJplein'] },
            { time: '17:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:26:00', line: '902', to: ['IJplein'] },
            { time: '17:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:30:00', line: '906', to: ['NDSM'] },
            { time: '17:32:00', line: '902', to: ['IJplein'] },
            { time: '17:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:38:00', line: '902', to: ['IJplein'] },
            { time: '17:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:44:00', line: '902', to: ['IJplein'] },
            { time: '17:45:00', line: '906', to: ['NDSM'] },
            { time: '17:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:50:00', line: '902', to: ['IJplein'] },
            { time: '17:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:56:00', line: '902', to: ['IJplein'] },
            { time: '17:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:00:00', line: '906', to: ['NDSM'] },
            { time: '18:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:02:00', line: '902', to: ['IJplein'] },
            { time: '18:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:08:00', line: '902', to: ['IJplein'] },
            { time: '18:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:14:00', line: '902', to: ['IJplein'] },
            { time: '18:15:00', line: '906', to: ['NDSM'] },
            { time: '18:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:20:00', line: '902', to: ['IJplein'] },
            { time: '18:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:26:00', line: '902', to: ['IJplein'] },
            { time: '18:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:30:00', line: '906', to: ['NDSM'] },
            { time: '18:32:00', line: '902', to: ['IJplein'] },
            { time: '18:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:38:00', line: '902', to: ['IJplein'] },
            { time: '18:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:44:00', line: '902', to: ['IJplein'] },
            { time: '18:45:00', line: '906', to: ['NDSM'] },
            { time: '18:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:50:00', line: '902', to: ['IJplein'] },
            { time: '18:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:56:00', line: '902', to: ['IJplein'] },
            { time: '18:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:02:00', line: '902', to: ['IJplein'] },
            { time: '19:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:08:00', line: '902', to: ['IJplein'] },
            { time: '19:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:14:00', line: '902', to: ['IJplein'] },
            { time: '19:15:00', line: '906', to: ['NDSM'] },
            { time: '19:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:20:00', line: '902', to: ['IJplein'] },
            { time: '19:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:26:00', line: '902', to: ['IJplein'] },
            { time: '19:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:32:00', line: '902', to: ['IJplein'] },
            { time: '19:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:38:00', line: '902', to: ['IJplein'] },
            { time: '19:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:44:00', line: '902', to: ['IJplein'] },
            { time: '19:45:00', line: '906', to: ['NDSM'] },
            { time: '19:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:50:00', line: '902', to: ['IJplein'] },
            { time: '19:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:56:00', line: '902', to: ['IJplein'] },
            { time: '19:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:02:00', line: '902', to: ['IJplein'] },
            { time: '20:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:08:00', line: '902', to: ['IJplein'] },
            { time: '20:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:14:00', line: '902', to: ['IJplein'] },
            { time: '20:15:00', line: '906', to: ['NDSM'] },
            { time: '20:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:20:00', line: '902', to: ['IJplein'] },
            { time: '20:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:26:00', line: '902', to: ['IJplein'] },
            { time: '20:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:32:00', line: '902', to: ['IJplein'] },
            { time: '20:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:38:00', line: '902', to: ['IJplein'] },
            { time: '20:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:44:00', line: '902', to: ['IJplein'] },
            { time: '20:45:00', line: '906', to: ['NDSM'] },
            { time: '20:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:50:00', line: '902', to: ['IJplein'] },
            { time: '20:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:56:00', line: '902', to: ['IJplein'] },
            { time: '20:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:08:00', line: '902', to: ['IJplein'] },
            { time: '21:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:15:00', line: '906', to: ['NDSM'] },
            { time: '21:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:20:00', line: '902', to: ['IJplein'] },
            { time: '21:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:32:00', line: '902', to: ['IJplein'] },
            { time: '21:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:44:00', line: '902', to: ['IJplein'] },
            { time: '21:45:00', line: '906', to: ['NDSM'] },
            { time: '21:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:56:00', line: '902', to: ['IJplein'] },
            { time: '21:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:08:00', line: '902', to: ['IJplein'] },
            { time: '22:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:15:00', line: '906', to: ['NDSM'] },
            { time: '22:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:20:00', line: '902', to: ['IJplein'] },
            { time: '22:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:32:00', line: '902', to: ['IJplein'] },
            { time: '22:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:44:00', line: '902', to: ['IJplein'] },
            { time: '22:45:00', line: '906', to: ['NDSM'] },
            { time: '22:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:56:00', line: '902', to: ['IJplein'] },
            { time: '22:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:08:00', line: '902', to: ['IJplein'] },
            { time: '23:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:15:00', line: '906', to: ['NDSM'] },
            { time: '23:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:20:00', line: '902', to: ['IJplein'] },
            { time: '23:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:32:00', line: '902', to: ['IJplein'] },
            { time: '23:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:44:00', line: '902', to: ['IJplein'] },
            { time: '23:45:00', line: '906', to: ['NDSM'] },
            { time: '23:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:56:00', line: '902', to: ['IJplein'] },
            { time: '23:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:15:00', line: '906', to: ['NDSM'] },
            { time: '24:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:45:00', line: '906', to: ['NDSM'] },
            { time: '24:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:15:00', line: '906', to: ['NDSM'] },
            { time: '25:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:45:00', line: '906', to: ['NDSM'] },
            { time: '25:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:12:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:24:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:36:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:48:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:00:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:52:00', line: '901', to: ['Buiksloterweg'] },
        ],
        tuesday: [
            { time: '00:23:00', line: '905', to: ['NDSM'] },
            { time: '01:01:00', line: '905', to: ['NDSM'] },
            { time: '01:39:00', line: '905', to: ['NDSM'] },
            { time: '05:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:30:00', line: '906', to: ['NDSM'] },
            { time: '06:32:00', line: '902', to: ['IJplein'] },
            { time: '06:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:38:00', line: '902', to: ['IJplein'] },
            { time: '06:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:44:00', line: '902', to: ['IJplein'] },
            { time: '06:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:50:00', line: '902', to: ['IJplein'] },
            { time: '06:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:56:00', line: '902', to: ['IJplein'] },
            { time: '06:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:00:00', line: '906', to: ['NDSM'] },
            { time: '07:02:00', line: '902', to: ['IJplein'] },
            { time: '07:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:08:00', line: '902', to: ['IJplein'] },
            { time: '07:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:14:00', line: '902', to: ['IJplein'] },
            { time: '07:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:20:00', line: '902', to: ['IJplein'] },
            { time: '07:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:26:00', line: '902', to: ['IJplein'] },
            { time: '07:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:30:00', line: '906', to: ['NDSM'] },
            { time: '07:32:00', line: '902', to: ['IJplein'] },
            { time: '07:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:38:00', line: '902', to: ['IJplein'] },
            { time: '07:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:44:00', line: '902', to: ['IJplein'] },
            { time: '07:45:00', line: '906', to: ['NDSM'] },
            { time: '07:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:50:00', line: '902', to: ['IJplein'] },
            { time: '07:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:56:00', line: '902', to: ['IJplein'] },
            { time: '07:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:00:00', line: '906', to: ['NDSM'] },
            { time: '08:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:02:00', line: '902', to: ['IJplein'] },
            { time: '08:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:08:00', line: '902', to: ['IJplein'] },
            { time: '08:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:14:00', line: '902', to: ['IJplein'] },
            { time: '08:15:00', line: '906', to: ['NDSM'] },
            { time: '08:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:20:00', line: '902', to: ['IJplein'] },
            { time: '08:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:26:00', line: '902', to: ['IJplein'] },
            { time: '08:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:30:00', line: '906', to: ['NDSM'] },
            { time: '08:32:00', line: '902', to: ['IJplein'] },
            { time: '08:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:38:00', line: '902', to: ['IJplein'] },
            { time: '08:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:44:00', line: '902', to: ['IJplein'] },
            { time: '08:45:00', line: '906', to: ['NDSM'] },
            { time: '08:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:50:00', line: '902', to: ['IJplein'] },
            { time: '08:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:56:00', line: '902', to: ['IJplein'] },
            { time: '08:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:00:00', line: '906', to: ['NDSM'] },
            { time: '09:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:02:00', line: '902', to: ['IJplein'] },
            { time: '09:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:08:00', line: '902', to: ['IJplein'] },
            { time: '09:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:14:00', line: '902', to: ['IJplein'] },
            { time: '09:15:00', line: '906', to: ['NDSM'] },
            { time: '09:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:20:00', line: '902', to: ['IJplein'] },
            { time: '09:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:26:00', line: '902', to: ['IJplein'] },
            { time: '09:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:30:00', line: '906', to: ['NDSM'] },
            { time: '09:32:00', line: '902', to: ['IJplein'] },
            { time: '09:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:38:00', line: '902', to: ['IJplein'] },
            { time: '09:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:44:00', line: '902', to: ['IJplein'] },
            { time: '09:45:00', line: '906', to: ['NDSM'] },
            { time: '09:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:50:00', line: '902', to: ['IJplein'] },
            { time: '09:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:56:00', line: '902', to: ['IJplein'] },
            { time: '09:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:00:00', line: '906', to: ['NDSM'] },
            { time: '10:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:02:00', line: '902', to: ['IJplein'] },
            { time: '10:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:08:00', line: '902', to: ['IJplein'] },
            { time: '10:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:14:00', line: '902', to: ['IJplein'] },
            { time: '10:15:00', line: '906', to: ['NDSM'] },
            { time: '10:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:20:00', line: '902', to: ['IJplein'] },
            { time: '10:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:26:00', line: '902', to: ['IJplein'] },
            { time: '10:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:30:00', line: '906', to: ['NDSM'] },
            { time: '10:32:00', line: '902', to: ['IJplein'] },
            { time: '10:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:38:00', line: '902', to: ['IJplein'] },
            { time: '10:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:44:00', line: '902', to: ['IJplein'] },
            { time: '10:45:00', line: '906', to: ['NDSM'] },
            { time: '10:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:50:00', line: '902', to: ['IJplein'] },
            { time: '10:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:56:00', line: '902', to: ['IJplein'] },
            { time: '10:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:00:00', line: '906', to: ['NDSM'] },
            { time: '11:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:02:00', line: '902', to: ['IJplein'] },
            { time: '11:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:08:00', line: '902', to: ['IJplein'] },
            { time: '11:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:14:00', line: '902', to: ['IJplein'] },
            { time: '11:15:00', line: '906', to: ['NDSM'] },
            { time: '11:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:20:00', line: '902', to: ['IJplein'] },
            { time: '11:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:26:00', line: '902', to: ['IJplein'] },
            { time: '11:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:30:00', line: '906', to: ['NDSM'] },
            { time: '11:32:00', line: '902', to: ['IJplein'] },
            { time: '11:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:38:00', line: '902', to: ['IJplein'] },
            { time: '11:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:44:00', line: '902', to: ['IJplein'] },
            { time: '11:45:00', line: '906', to: ['NDSM'] },
            { time: '11:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:50:00', line: '902', to: ['IJplein'] },
            { time: '11:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:56:00', line: '902', to: ['IJplein'] },
            { time: '11:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:00:00', line: '906', to: ['NDSM'] },
            { time: '12:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:02:00', line: '902', to: ['IJplein'] },
            { time: '12:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:08:00', line: '902', to: ['IJplein'] },
            { time: '12:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:14:00', line: '902', to: ['IJplein'] },
            { time: '12:15:00', line: '906', to: ['NDSM'] },
            { time: '12:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:20:00', line: '902', to: ['IJplein'] },
            { time: '12:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:26:00', line: '902', to: ['IJplein'] },
            { time: '12:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:30:00', line: '906', to: ['NDSM'] },
            { time: '12:32:00', line: '902', to: ['IJplein'] },
            { time: '12:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:38:00', line: '902', to: ['IJplein'] },
            { time: '12:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:44:00', line: '902', to: ['IJplein'] },
            { time: '12:45:00', line: '906', to: ['NDSM'] },
            { time: '12:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:50:00', line: '902', to: ['IJplein'] },
            { time: '12:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:56:00', line: '902', to: ['IJplein'] },
            { time: '12:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:00:00', line: '906', to: ['NDSM'] },
            { time: '13:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:02:00', line: '902', to: ['IJplein'] },
            { time: '13:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:08:00', line: '902', to: ['IJplein'] },
            { time: '13:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:14:00', line: '902', to: ['IJplein'] },
            { time: '13:15:00', line: '906', to: ['NDSM'] },
            { time: '13:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:20:00', line: '902', to: ['IJplein'] },
            { time: '13:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:26:00', line: '902', to: ['IJplein'] },
            { time: '13:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:30:00', line: '906', to: ['NDSM'] },
            { time: '13:32:00', line: '902', to: ['IJplein'] },
            { time: '13:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:38:00', line: '902', to: ['IJplein'] },
            { time: '13:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:44:00', line: '902', to: ['IJplein'] },
            { time: '13:45:00', line: '906', to: ['NDSM'] },
            { time: '13:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:50:00', line: '902', to: ['IJplein'] },
            { time: '13:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:56:00', line: '902', to: ['IJplein'] },
            { time: '13:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:00:00', line: '906', to: ['NDSM'] },
            { time: '14:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:02:00', line: '902', to: ['IJplein'] },
            { time: '14:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:08:00', line: '902', to: ['IJplein'] },
            { time: '14:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:14:00', line: '902', to: ['IJplein'] },
            { time: '14:15:00', line: '906', to: ['NDSM'] },
            { time: '14:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:20:00', line: '902', to: ['IJplein'] },
            { time: '14:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:26:00', line: '902', to: ['IJplein'] },
            { time: '14:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:30:00', line: '906', to: ['NDSM'] },
            { time: '14:32:00', line: '902', to: ['IJplein'] },
            { time: '14:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:38:00', line: '902', to: ['IJplein'] },
            { time: '14:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:44:00', line: '902', to: ['IJplein'] },
            { time: '14:45:00', line: '906', to: ['NDSM'] },
            { time: '14:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:50:00', line: '902', to: ['IJplein'] },
            { time: '14:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:56:00', line: '902', to: ['IJplein'] },
            { time: '14:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:00:00', line: '906', to: ['NDSM'] },
            { time: '15:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:02:00', line: '902', to: ['IJplein'] },
            { time: '15:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:08:00', line: '902', to: ['IJplein'] },
            { time: '15:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:14:00', line: '902', to: ['IJplein'] },
            { time: '15:15:00', line: '906', to: ['NDSM'] },
            { time: '15:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:20:00', line: '902', to: ['IJplein'] },
            { time: '15:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:26:00', line: '902', to: ['IJplein'] },
            { time: '15:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:30:00', line: '906', to: ['NDSM'] },
            { time: '15:32:00', line: '902', to: ['IJplein'] },
            { time: '15:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:38:00', line: '902', to: ['IJplein'] },
            { time: '15:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:44:00', line: '902', to: ['IJplein'] },
            { time: '15:45:00', line: '906', to: ['NDSM'] },
            { time: '15:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:50:00', line: '902', to: ['IJplein'] },
            { time: '15:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:56:00', line: '902', to: ['IJplein'] },
            { time: '15:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:00:00', line: '906', to: ['NDSM'] },
            { time: '16:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:02:00', line: '902', to: ['IJplein'] },
            { time: '16:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:08:00', line: '902', to: ['IJplein'] },
            { time: '16:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:14:00', line: '902', to: ['IJplein'] },
            { time: '16:15:00', line: '906', to: ['NDSM'] },
            { time: '16:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:20:00', line: '902', to: ['IJplein'] },
            { time: '16:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:26:00', line: '902', to: ['IJplein'] },
            { time: '16:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:30:00', line: '906', to: ['NDSM'] },
            { time: '16:32:00', line: '902', to: ['IJplein'] },
            { time: '16:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:38:00', line: '902', to: ['IJplein'] },
            { time: '16:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:44:00', line: '902', to: ['IJplein'] },
            { time: '16:45:00', line: '906', to: ['NDSM'] },
            { time: '16:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:50:00', line: '902', to: ['IJplein'] },
            { time: '16:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:56:00', line: '902', to: ['IJplein'] },
            { time: '16:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:00:00', line: '906', to: ['NDSM'] },
            { time: '17:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:02:00', line: '902', to: ['IJplein'] },
            { time: '17:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:08:00', line: '902', to: ['IJplein'] },
            { time: '17:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:14:00', line: '902', to: ['IJplein'] },
            { time: '17:15:00', line: '906', to: ['NDSM'] },
            { time: '17:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:20:00', line: '902', to: ['IJplein'] },
            { time: '17:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:26:00', line: '902', to: ['IJplein'] },
            { time: '17:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:30:00', line: '906', to: ['NDSM'] },
            { time: '17:32:00', line: '902', to: ['IJplein'] },
            { time: '17:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:38:00', line: '902', to: ['IJplein'] },
            { time: '17:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:44:00', line: '902', to: ['IJplein'] },
            { time: '17:45:00', line: '906', to: ['NDSM'] },
            { time: '17:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:50:00', line: '902', to: ['IJplein'] },
            { time: '17:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:56:00', line: '902', to: ['IJplein'] },
            { time: '17:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:00:00', line: '906', to: ['NDSM'] },
            { time: '18:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:02:00', line: '902', to: ['IJplein'] },
            { time: '18:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:08:00', line: '902', to: ['IJplein'] },
            { time: '18:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:14:00', line: '902', to: ['IJplein'] },
            { time: '18:15:00', line: '906', to: ['NDSM'] },
            { time: '18:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:20:00', line: '902', to: ['IJplein'] },
            { time: '18:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:26:00', line: '902', to: ['IJplein'] },
            { time: '18:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:30:00', line: '906', to: ['NDSM'] },
            { time: '18:32:00', line: '902', to: ['IJplein'] },
            { time: '18:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:38:00', line: '902', to: ['IJplein'] },
            { time: '18:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:44:00', line: '902', to: ['IJplein'] },
            { time: '18:45:00', line: '906', to: ['NDSM'] },
            { time: '18:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:50:00', line: '902', to: ['IJplein'] },
            { time: '18:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:56:00', line: '902', to: ['IJplein'] },
            { time: '18:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:02:00', line: '902', to: ['IJplein'] },
            { time: '19:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:08:00', line: '902', to: ['IJplein'] },
            { time: '19:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:14:00', line: '902', to: ['IJplein'] },
            { time: '19:15:00', line: '906', to: ['NDSM'] },
            { time: '19:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:20:00', line: '902', to: ['IJplein'] },
            { time: '19:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:26:00', line: '902', to: ['IJplein'] },
            { time: '19:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:32:00', line: '902', to: ['IJplein'] },
            { time: '19:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:38:00', line: '902', to: ['IJplein'] },
            { time: '19:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:44:00', line: '902', to: ['IJplein'] },
            { time: '19:45:00', line: '906', to: ['NDSM'] },
            { time: '19:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:50:00', line: '902', to: ['IJplein'] },
            { time: '19:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:56:00', line: '902', to: ['IJplein'] },
            { time: '19:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:02:00', line: '902', to: ['IJplein'] },
            { time: '20:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:08:00', line: '902', to: ['IJplein'] },
            { time: '20:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:14:00', line: '902', to: ['IJplein'] },
            { time: '20:15:00', line: '906', to: ['NDSM'] },
            { time: '20:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:20:00', line: '902', to: ['IJplein'] },
            { time: '20:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:26:00', line: '902', to: ['IJplein'] },
            { time: '20:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:32:00', line: '902', to: ['IJplein'] },
            { time: '20:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:38:00', line: '902', to: ['IJplein'] },
            { time: '20:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:44:00', line: '902', to: ['IJplein'] },
            { time: '20:45:00', line: '906', to: ['NDSM'] },
            { time: '20:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:50:00', line: '902', to: ['IJplein'] },
            { time: '20:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:56:00', line: '902', to: ['IJplein'] },
            { time: '20:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:08:00', line: '902', to: ['IJplein'] },
            { time: '21:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:15:00', line: '906', to: ['NDSM'] },
            { time: '21:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:20:00', line: '902', to: ['IJplein'] },
            { time: '21:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:32:00', line: '902', to: ['IJplein'] },
            { time: '21:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:44:00', line: '902', to: ['IJplein'] },
            { time: '21:45:00', line: '906', to: ['NDSM'] },
            { time: '21:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:56:00', line: '902', to: ['IJplein'] },
            { time: '21:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:08:00', line: '902', to: ['IJplein'] },
            { time: '22:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:15:00', line: '906', to: ['NDSM'] },
            { time: '22:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:20:00', line: '902', to: ['IJplein'] },
            { time: '22:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:32:00', line: '902', to: ['IJplein'] },
            { time: '22:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:44:00', line: '902', to: ['IJplein'] },
            { time: '22:45:00', line: '906', to: ['NDSM'] },
            { time: '22:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:56:00', line: '902', to: ['IJplein'] },
            { time: '22:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:08:00', line: '902', to: ['IJplein'] },
            { time: '23:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:15:00', line: '906', to: ['NDSM'] },
            { time: '23:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:20:00', line: '902', to: ['IJplein'] },
            { time: '23:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:32:00', line: '902', to: ['IJplein'] },
            { time: '23:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:44:00', line: '902', to: ['IJplein'] },
            { time: '23:45:00', line: '906', to: ['NDSM'] },
            { time: '23:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:56:00', line: '902', to: ['IJplein'] },
            { time: '23:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:15:00', line: '906', to: ['NDSM'] },
            { time: '24:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:45:00', line: '906', to: ['NDSM'] },
            { time: '24:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:15:00', line: '906', to: ['NDSM'] },
            { time: '25:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:45:00', line: '906', to: ['NDSM'] },
            { time: '25:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:12:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:24:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:36:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:48:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:00:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:52:00', line: '901', to: ['Buiksloterweg'] },
        ],
        wednesday: [
            { time: '00:23:00', line: '905', to: ['NDSM'] },
            { time: '01:01:00', line: '905', to: ['NDSM'] },
            { time: '01:39:00', line: '905', to: ['NDSM'] },
            { time: '05:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:30:00', line: '906', to: ['NDSM'] },
            { time: '06:32:00', line: '902', to: ['IJplein'] },
            { time: '06:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:38:00', line: '902', to: ['IJplein'] },
            { time: '06:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:44:00', line: '902', to: ['IJplein'] },
            { time: '06:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:50:00', line: '902', to: ['IJplein'] },
            { time: '06:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:56:00', line: '902', to: ['IJplein'] },
            { time: '06:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:00:00', line: '906', to: ['NDSM'] },
            { time: '07:02:00', line: '902', to: ['IJplein'] },
            { time: '07:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:08:00', line: '902', to: ['IJplein'] },
            { time: '07:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:14:00', line: '902', to: ['IJplein'] },
            { time: '07:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:20:00', line: '902', to: ['IJplein'] },
            { time: '07:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:26:00', line: '902', to: ['IJplein'] },
            { time: '07:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:30:00', line: '906', to: ['NDSM'] },
            { time: '07:32:00', line: '902', to: ['IJplein'] },
            { time: '07:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:38:00', line: '902', to: ['IJplein'] },
            { time: '07:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:44:00', line: '902', to: ['IJplein'] },
            { time: '07:45:00', line: '906', to: ['NDSM'] },
            { time: '07:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:50:00', line: '902', to: ['IJplein'] },
            { time: '07:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:56:00', line: '902', to: ['IJplein'] },
            { time: '07:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:00:00', line: '906', to: ['NDSM'] },
            { time: '08:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:02:00', line: '902', to: ['IJplein'] },
            { time: '08:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:08:00', line: '902', to: ['IJplein'] },
            { time: '08:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:14:00', line: '902', to: ['IJplein'] },
            { time: '08:15:00', line: '906', to: ['NDSM'] },
            { time: '08:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:20:00', line: '902', to: ['IJplein'] },
            { time: '08:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:26:00', line: '902', to: ['IJplein'] },
            { time: '08:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:30:00', line: '906', to: ['NDSM'] },
            { time: '08:32:00', line: '902', to: ['IJplein'] },
            { time: '08:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:38:00', line: '902', to: ['IJplein'] },
            { time: '08:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:44:00', line: '902', to: ['IJplein'] },
            { time: '08:45:00', line: '906', to: ['NDSM'] },
            { time: '08:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:50:00', line: '902', to: ['IJplein'] },
            { time: '08:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:56:00', line: '902', to: ['IJplein'] },
            { time: '08:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:00:00', line: '906', to: ['NDSM'] },
            { time: '09:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:02:00', line: '902', to: ['IJplein'] },
            { time: '09:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:08:00', line: '902', to: ['IJplein'] },
            { time: '09:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:14:00', line: '902', to: ['IJplein'] },
            { time: '09:15:00', line: '906', to: ['NDSM'] },
            { time: '09:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:20:00', line: '902', to: ['IJplein'] },
            { time: '09:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:26:00', line: '902', to: ['IJplein'] },
            { time: '09:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:30:00', line: '906', to: ['NDSM'] },
            { time: '09:32:00', line: '902', to: ['IJplein'] },
            { time: '09:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:38:00', line: '902', to: ['IJplein'] },
            { time: '09:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:44:00', line: '902', to: ['IJplein'] },
            { time: '09:45:00', line: '906', to: ['NDSM'] },
            { time: '09:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:50:00', line: '902', to: ['IJplein'] },
            { time: '09:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:56:00', line: '902', to: ['IJplein'] },
            { time: '09:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:00:00', line: '906', to: ['NDSM'] },
            { time: '10:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:02:00', line: '902', to: ['IJplein'] },
            { time: '10:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:08:00', line: '902', to: ['IJplein'] },
            { time: '10:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:14:00', line: '902', to: ['IJplein'] },
            { time: '10:15:00', line: '906', to: ['NDSM'] },
            { time: '10:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:20:00', line: '902', to: ['IJplein'] },
            { time: '10:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:26:00', line: '902', to: ['IJplein'] },
            { time: '10:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:30:00', line: '906', to: ['NDSM'] },
            { time: '10:32:00', line: '902', to: ['IJplein'] },
            { time: '10:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:38:00', line: '902', to: ['IJplein'] },
            { time: '10:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:44:00', line: '902', to: ['IJplein'] },
            { time: '10:45:00', line: '906', to: ['NDSM'] },
            { time: '10:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:50:00', line: '902', to: ['IJplein'] },
            { time: '10:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:56:00', line: '902', to: ['IJplein'] },
            { time: '10:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:00:00', line: '906', to: ['NDSM'] },
            { time: '11:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:02:00', line: '902', to: ['IJplein'] },
            { time: '11:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:08:00', line: '902', to: ['IJplein'] },
            { time: '11:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:14:00', line: '902', to: ['IJplein'] },
            { time: '11:15:00', line: '906', to: ['NDSM'] },
            { time: '11:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:20:00', line: '902', to: ['IJplein'] },
            { time: '11:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:26:00', line: '902', to: ['IJplein'] },
            { time: '11:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:30:00', line: '906', to: ['NDSM'] },
            { time: '11:32:00', line: '902', to: ['IJplein'] },
            { time: '11:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:38:00', line: '902', to: ['IJplein'] },
            { time: '11:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:44:00', line: '902', to: ['IJplein'] },
            { time: '11:45:00', line: '906', to: ['NDSM'] },
            { time: '11:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:50:00', line: '902', to: ['IJplein'] },
            { time: '11:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:56:00', line: '902', to: ['IJplein'] },
            { time: '11:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:00:00', line: '906', to: ['NDSM'] },
            { time: '12:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:02:00', line: '902', to: ['IJplein'] },
            { time: '12:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:08:00', line: '902', to: ['IJplein'] },
            { time: '12:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:14:00', line: '902', to: ['IJplein'] },
            { time: '12:15:00', line: '906', to: ['NDSM'] },
            { time: '12:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:20:00', line: '902', to: ['IJplein'] },
            { time: '12:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:26:00', line: '902', to: ['IJplein'] },
            { time: '12:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:30:00', line: '906', to: ['NDSM'] },
            { time: '12:32:00', line: '902', to: ['IJplein'] },
            { time: '12:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:38:00', line: '902', to: ['IJplein'] },
            { time: '12:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:44:00', line: '902', to: ['IJplein'] },
            { time: '12:45:00', line: '906', to: ['NDSM'] },
            { time: '12:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:50:00', line: '902', to: ['IJplein'] },
            { time: '12:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:56:00', line: '902', to: ['IJplein'] },
            { time: '12:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:00:00', line: '906', to: ['NDSM'] },
            { time: '13:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:02:00', line: '902', to: ['IJplein'] },
            { time: '13:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:08:00', line: '902', to: ['IJplein'] },
            { time: '13:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:14:00', line: '902', to: ['IJplein'] },
            { time: '13:15:00', line: '906', to: ['NDSM'] },
            { time: '13:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:20:00', line: '902', to: ['IJplein'] },
            { time: '13:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:26:00', line: '902', to: ['IJplein'] },
            { time: '13:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:30:00', line: '906', to: ['NDSM'] },
            { time: '13:32:00', line: '902', to: ['IJplein'] },
            { time: '13:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:38:00', line: '902', to: ['IJplein'] },
            { time: '13:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:44:00', line: '902', to: ['IJplein'] },
            { time: '13:45:00', line: '906', to: ['NDSM'] },
            { time: '13:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:50:00', line: '902', to: ['IJplein'] },
            { time: '13:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:56:00', line: '902', to: ['IJplein'] },
            { time: '13:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:00:00', line: '906', to: ['NDSM'] },
            { time: '14:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:02:00', line: '902', to: ['IJplein'] },
            { time: '14:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:08:00', line: '902', to: ['IJplein'] },
            { time: '14:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:14:00', line: '902', to: ['IJplein'] },
            { time: '14:15:00', line: '906', to: ['NDSM'] },
            { time: '14:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:20:00', line: '902', to: ['IJplein'] },
            { time: '14:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:26:00', line: '902', to: ['IJplein'] },
            { time: '14:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:30:00', line: '906', to: ['NDSM'] },
            { time: '14:32:00', line: '902', to: ['IJplein'] },
            { time: '14:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:38:00', line: '902', to: ['IJplein'] },
            { time: '14:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:44:00', line: '902', to: ['IJplein'] },
            { time: '14:45:00', line: '906', to: ['NDSM'] },
            { time: '14:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:50:00', line: '902', to: ['IJplein'] },
            { time: '14:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:56:00', line: '902', to: ['IJplein'] },
            { time: '14:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:00:00', line: '906', to: ['NDSM'] },
            { time: '15:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:02:00', line: '902', to: ['IJplein'] },
            { time: '15:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:08:00', line: '902', to: ['IJplein'] },
            { time: '15:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:14:00', line: '902', to: ['IJplein'] },
            { time: '15:15:00', line: '906', to: ['NDSM'] },
            { time: '15:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:20:00', line: '902', to: ['IJplein'] },
            { time: '15:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:26:00', line: '902', to: ['IJplein'] },
            { time: '15:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:30:00', line: '906', to: ['NDSM'] },
            { time: '15:32:00', line: '902', to: ['IJplein'] },
            { time: '15:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:38:00', line: '902', to: ['IJplein'] },
            { time: '15:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:44:00', line: '902', to: ['IJplein'] },
            { time: '15:45:00', line: '906', to: ['NDSM'] },
            { time: '15:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:50:00', line: '902', to: ['IJplein'] },
            { time: '15:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:56:00', line: '902', to: ['IJplein'] },
            { time: '15:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:00:00', line: '906', to: ['NDSM'] },
            { time: '16:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:02:00', line: '902', to: ['IJplein'] },
            { time: '16:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:08:00', line: '902', to: ['IJplein'] },
            { time: '16:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:14:00', line: '902', to: ['IJplein'] },
            { time: '16:15:00', line: '906', to: ['NDSM'] },
            { time: '16:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:20:00', line: '902', to: ['IJplein'] },
            { time: '16:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:26:00', line: '902', to: ['IJplein'] },
            { time: '16:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:30:00', line: '906', to: ['NDSM'] },
            { time: '16:32:00', line: '902', to: ['IJplein'] },
            { time: '16:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:38:00', line: '902', to: ['IJplein'] },
            { time: '16:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:44:00', line: '902', to: ['IJplein'] },
            { time: '16:45:00', line: '906', to: ['NDSM'] },
            { time: '16:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:50:00', line: '902', to: ['IJplein'] },
            { time: '16:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:56:00', line: '902', to: ['IJplein'] },
            { time: '16:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:00:00', line: '906', to: ['NDSM'] },
            { time: '17:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:02:00', line: '902', to: ['IJplein'] },
            { time: '17:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:08:00', line: '902', to: ['IJplein'] },
            { time: '17:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:14:00', line: '902', to: ['IJplein'] },
            { time: '17:15:00', line: '906', to: ['NDSM'] },
            { time: '17:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:20:00', line: '902', to: ['IJplein'] },
            { time: '17:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:26:00', line: '902', to: ['IJplein'] },
            { time: '17:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:30:00', line: '906', to: ['NDSM'] },
            { time: '17:32:00', line: '902', to: ['IJplein'] },
            { time: '17:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:38:00', line: '902', to: ['IJplein'] },
            { time: '17:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:44:00', line: '902', to: ['IJplein'] },
            { time: '17:45:00', line: '906', to: ['NDSM'] },
            { time: '17:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:50:00', line: '902', to: ['IJplein'] },
            { time: '17:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:56:00', line: '902', to: ['IJplein'] },
            { time: '17:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:00:00', line: '906', to: ['NDSM'] },
            { time: '18:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:02:00', line: '902', to: ['IJplein'] },
            { time: '18:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:08:00', line: '902', to: ['IJplein'] },
            { time: '18:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:14:00', line: '902', to: ['IJplein'] },
            { time: '18:15:00', line: '906', to: ['NDSM'] },
            { time: '18:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:20:00', line: '902', to: ['IJplein'] },
            { time: '18:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:26:00', line: '902', to: ['IJplein'] },
            { time: '18:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:30:00', line: '906', to: ['NDSM'] },
            { time: '18:32:00', line: '902', to: ['IJplein'] },
            { time: '18:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:38:00', line: '902', to: ['IJplein'] },
            { time: '18:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:44:00', line: '902', to: ['IJplein'] },
            { time: '18:45:00', line: '906', to: ['NDSM'] },
            { time: '18:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:50:00', line: '902', to: ['IJplein'] },
            { time: '18:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:56:00', line: '902', to: ['IJplein'] },
            { time: '18:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:02:00', line: '902', to: ['IJplein'] },
            { time: '19:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:08:00', line: '902', to: ['IJplein'] },
            { time: '19:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:14:00', line: '902', to: ['IJplein'] },
            { time: '19:15:00', line: '906', to: ['NDSM'] },
            { time: '19:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:20:00', line: '902', to: ['IJplein'] },
            { time: '19:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:26:00', line: '902', to: ['IJplein'] },
            { time: '19:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:32:00', line: '902', to: ['IJplein'] },
            { time: '19:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:38:00', line: '902', to: ['IJplein'] },
            { time: '19:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:44:00', line: '902', to: ['IJplein'] },
            { time: '19:45:00', line: '906', to: ['NDSM'] },
            { time: '19:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:50:00', line: '902', to: ['IJplein'] },
            { time: '19:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:56:00', line: '902', to: ['IJplein'] },
            { time: '19:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:02:00', line: '902', to: ['IJplein'] },
            { time: '20:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:08:00', line: '902', to: ['IJplein'] },
            { time: '20:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:14:00', line: '902', to: ['IJplein'] },
            { time: '20:15:00', line: '906', to: ['NDSM'] },
            { time: '20:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:20:00', line: '902', to: ['IJplein'] },
            { time: '20:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:26:00', line: '902', to: ['IJplein'] },
            { time: '20:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:32:00', line: '902', to: ['IJplein'] },
            { time: '20:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:38:00', line: '902', to: ['IJplein'] },
            { time: '20:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:44:00', line: '902', to: ['IJplein'] },
            { time: '20:45:00', line: '906', to: ['NDSM'] },
            { time: '20:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:50:00', line: '902', to: ['IJplein'] },
            { time: '20:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:56:00', line: '902', to: ['IJplein'] },
            { time: '20:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:08:00', line: '902', to: ['IJplein'] },
            { time: '21:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:15:00', line: '906', to: ['NDSM'] },
            { time: '21:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:20:00', line: '902', to: ['IJplein'] },
            { time: '21:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:32:00', line: '902', to: ['IJplein'] },
            { time: '21:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:44:00', line: '902', to: ['IJplein'] },
            { time: '21:45:00', line: '906', to: ['NDSM'] },
            { time: '21:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:56:00', line: '902', to: ['IJplein'] },
            { time: '21:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:08:00', line: '902', to: ['IJplein'] },
            { time: '22:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:15:00', line: '906', to: ['NDSM'] },
            { time: '22:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:20:00', line: '902', to: ['IJplein'] },
            { time: '22:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:32:00', line: '902', to: ['IJplein'] },
            { time: '22:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:44:00', line: '902', to: ['IJplein'] },
            { time: '22:45:00', line: '906', to: ['NDSM'] },
            { time: '22:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:56:00', line: '902', to: ['IJplein'] },
            { time: '22:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:08:00', line: '902', to: ['IJplein'] },
            { time: '23:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:15:00', line: '906', to: ['NDSM'] },
            { time: '23:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:20:00', line: '902', to: ['IJplein'] },
            { time: '23:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:32:00', line: '902', to: ['IJplein'] },
            { time: '23:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:44:00', line: '902', to: ['IJplein'] },
            { time: '23:45:00', line: '906', to: ['NDSM'] },
            { time: '23:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:56:00', line: '902', to: ['IJplein'] },
            { time: '23:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:15:00', line: '906', to: ['NDSM'] },
            { time: '24:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:45:00', line: '906', to: ['NDSM'] },
            { time: '24:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:15:00', line: '906', to: ['NDSM'] },
            { time: '25:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:45:00', line: '906', to: ['NDSM'] },
            { time: '25:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:12:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:24:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:36:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:48:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:00:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:52:00', line: '901', to: ['Buiksloterweg'] },
        ],
        thursday: [
            { time: '00:23:00', line: '905', to: ['NDSM'] },
            { time: '01:01:00', line: '905', to: ['NDSM'] },
            { time: '01:39:00', line: '905', to: ['NDSM'] },
            { time: '05:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:30:00', line: '906', to: ['NDSM'] },
            { time: '06:32:00', line: '902', to: ['IJplein'] },
            { time: '06:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:38:00', line: '902', to: ['IJplein'] },
            { time: '06:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:44:00', line: '902', to: ['IJplein'] },
            { time: '06:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:50:00', line: '902', to: ['IJplein'] },
            { time: '06:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:56:00', line: '902', to: ['IJplein'] },
            { time: '06:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:00:00', line: '906', to: ['NDSM'] },
            { time: '07:02:00', line: '902', to: ['IJplein'] },
            { time: '07:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:08:00', line: '902', to: ['IJplein'] },
            { time: '07:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:14:00', line: '902', to: ['IJplein'] },
            { time: '07:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:20:00', line: '902', to: ['IJplein'] },
            { time: '07:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:26:00', line: '902', to: ['IJplein'] },
            { time: '07:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:30:00', line: '906', to: ['NDSM'] },
            { time: '07:32:00', line: '902', to: ['IJplein'] },
            { time: '07:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:38:00', line: '902', to: ['IJplein'] },
            { time: '07:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:44:00', line: '902', to: ['IJplein'] },
            { time: '07:45:00', line: '906', to: ['NDSM'] },
            { time: '07:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:50:00', line: '902', to: ['IJplein'] },
            { time: '07:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:56:00', line: '902', to: ['IJplein'] },
            { time: '07:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:00:00', line: '906', to: ['NDSM'] },
            { time: '08:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:02:00', line: '902', to: ['IJplein'] },
            { time: '08:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:08:00', line: '902', to: ['IJplein'] },
            { time: '08:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:14:00', line: '902', to: ['IJplein'] },
            { time: '08:15:00', line: '906', to: ['NDSM'] },
            { time: '08:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:20:00', line: '902', to: ['IJplein'] },
            { time: '08:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:26:00', line: '902', to: ['IJplein'] },
            { time: '08:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:30:00', line: '906', to: ['NDSM'] },
            { time: '08:32:00', line: '902', to: ['IJplein'] },
            { time: '08:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:38:00', line: '902', to: ['IJplein'] },
            { time: '08:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:44:00', line: '902', to: ['IJplein'] },
            { time: '08:45:00', line: '906', to: ['NDSM'] },
            { time: '08:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:50:00', line: '902', to: ['IJplein'] },
            { time: '08:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:56:00', line: '902', to: ['IJplein'] },
            { time: '08:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:00:00', line: '906', to: ['NDSM'] },
            { time: '09:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:02:00', line: '902', to: ['IJplein'] },
            { time: '09:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:08:00', line: '902', to: ['IJplein'] },
            { time: '09:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:14:00', line: '902', to: ['IJplein'] },
            { time: '09:15:00', line: '906', to: ['NDSM'] },
            { time: '09:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:20:00', line: '902', to: ['IJplein'] },
            { time: '09:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:26:00', line: '902', to: ['IJplein'] },
            { time: '09:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:30:00', line: '906', to: ['NDSM'] },
            { time: '09:32:00', line: '902', to: ['IJplein'] },
            { time: '09:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:38:00', line: '902', to: ['IJplein'] },
            { time: '09:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:44:00', line: '902', to: ['IJplein'] },
            { time: '09:45:00', line: '906', to: ['NDSM'] },
            { time: '09:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:50:00', line: '902', to: ['IJplein'] },
            { time: '09:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:56:00', line: '902', to: ['IJplein'] },
            { time: '09:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:00:00', line: '906', to: ['NDSM'] },
            { time: '10:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:02:00', line: '902', to: ['IJplein'] },
            { time: '10:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:08:00', line: '902', to: ['IJplein'] },
            { time: '10:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:14:00', line: '902', to: ['IJplein'] },
            { time: '10:15:00', line: '906', to: ['NDSM'] },
            { time: '10:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:20:00', line: '902', to: ['IJplein'] },
            { time: '10:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:26:00', line: '902', to: ['IJplein'] },
            { time: '10:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:30:00', line: '906', to: ['NDSM'] },
            { time: '10:32:00', line: '902', to: ['IJplein'] },
            { time: '10:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:38:00', line: '902', to: ['IJplein'] },
            { time: '10:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:44:00', line: '902', to: ['IJplein'] },
            { time: '10:45:00', line: '906', to: ['NDSM'] },
            { time: '10:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:50:00', line: '902', to: ['IJplein'] },
            { time: '10:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:56:00', line: '902', to: ['IJplein'] },
            { time: '10:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:00:00', line: '906', to: ['NDSM'] },
            { time: '11:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:02:00', line: '902', to: ['IJplein'] },
            { time: '11:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:08:00', line: '902', to: ['IJplein'] },
            { time: '11:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:14:00', line: '902', to: ['IJplein'] },
            { time: '11:15:00', line: '906', to: ['NDSM'] },
            { time: '11:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:20:00', line: '902', to: ['IJplein'] },
            { time: '11:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:26:00', line: '902', to: ['IJplein'] },
            { time: '11:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:30:00', line: '906', to: ['NDSM'] },
            { time: '11:32:00', line: '902', to: ['IJplein'] },
            { time: '11:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:38:00', line: '902', to: ['IJplein'] },
            { time: '11:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:44:00', line: '902', to: ['IJplein'] },
            { time: '11:45:00', line: '906', to: ['NDSM'] },
            { time: '11:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:50:00', line: '902', to: ['IJplein'] },
            { time: '11:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:56:00', line: '902', to: ['IJplein'] },
            { time: '11:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:00:00', line: '906', to: ['NDSM'] },
            { time: '12:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:02:00', line: '902', to: ['IJplein'] },
            { time: '12:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:08:00', line: '902', to: ['IJplein'] },
            { time: '12:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:14:00', line: '902', to: ['IJplein'] },
            { time: '12:15:00', line: '906', to: ['NDSM'] },
            { time: '12:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:20:00', line: '902', to: ['IJplein'] },
            { time: '12:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:26:00', line: '902', to: ['IJplein'] },
            { time: '12:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:30:00', line: '906', to: ['NDSM'] },
            { time: '12:32:00', line: '902', to: ['IJplein'] },
            { time: '12:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:38:00', line: '902', to: ['IJplein'] },
            { time: '12:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:44:00', line: '902', to: ['IJplein'] },
            { time: '12:45:00', line: '906', to: ['NDSM'] },
            { time: '12:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:50:00', line: '902', to: ['IJplein'] },
            { time: '12:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:56:00', line: '902', to: ['IJplein'] },
            { time: '12:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:00:00', line: '906', to: ['NDSM'] },
            { time: '13:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:02:00', line: '902', to: ['IJplein'] },
            { time: '13:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:08:00', line: '902', to: ['IJplein'] },
            { time: '13:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:14:00', line: '902', to: ['IJplein'] },
            { time: '13:15:00', line: '906', to: ['NDSM'] },
            { time: '13:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:20:00', line: '902', to: ['IJplein'] },
            { time: '13:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:26:00', line: '902', to: ['IJplein'] },
            { time: '13:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:30:00', line: '906', to: ['NDSM'] },
            { time: '13:32:00', line: '902', to: ['IJplein'] },
            { time: '13:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:38:00', line: '902', to: ['IJplein'] },
            { time: '13:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:44:00', line: '902', to: ['IJplein'] },
            { time: '13:45:00', line: '906', to: ['NDSM'] },
            { time: '13:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:50:00', line: '902', to: ['IJplein'] },
            { time: '13:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:56:00', line: '902', to: ['IJplein'] },
            { time: '13:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:00:00', line: '906', to: ['NDSM'] },
            { time: '14:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:02:00', line: '902', to: ['IJplein'] },
            { time: '14:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:08:00', line: '902', to: ['IJplein'] },
            { time: '14:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:14:00', line: '902', to: ['IJplein'] },
            { time: '14:15:00', line: '906', to: ['NDSM'] },
            { time: '14:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:20:00', line: '902', to: ['IJplein'] },
            { time: '14:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:26:00', line: '902', to: ['IJplein'] },
            { time: '14:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:30:00', line: '906', to: ['NDSM'] },
            { time: '14:32:00', line: '902', to: ['IJplein'] },
            { time: '14:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:38:00', line: '902', to: ['IJplein'] },
            { time: '14:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:44:00', line: '902', to: ['IJplein'] },
            { time: '14:45:00', line: '906', to: ['NDSM'] },
            { time: '14:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:50:00', line: '902', to: ['IJplein'] },
            { time: '14:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:56:00', line: '902', to: ['IJplein'] },
            { time: '14:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:00:00', line: '906', to: ['NDSM'] },
            { time: '15:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:02:00', line: '902', to: ['IJplein'] },
            { time: '15:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:08:00', line: '902', to: ['IJplein'] },
            { time: '15:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:14:00', line: '902', to: ['IJplein'] },
            { time: '15:15:00', line: '906', to: ['NDSM'] },
            { time: '15:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:20:00', line: '902', to: ['IJplein'] },
            { time: '15:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:26:00', line: '902', to: ['IJplein'] },
            { time: '15:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:30:00', line: '906', to: ['NDSM'] },
            { time: '15:32:00', line: '902', to: ['IJplein'] },
            { time: '15:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:38:00', line: '902', to: ['IJplein'] },
            { time: '15:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:44:00', line: '902', to: ['IJplein'] },
            { time: '15:45:00', line: '906', to: ['NDSM'] },
            { time: '15:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:50:00', line: '902', to: ['IJplein'] },
            { time: '15:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:56:00', line: '902', to: ['IJplein'] },
            { time: '15:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:00:00', line: '906', to: ['NDSM'] },
            { time: '16:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:02:00', line: '902', to: ['IJplein'] },
            { time: '16:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:08:00', line: '902', to: ['IJplein'] },
            { time: '16:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:14:00', line: '902', to: ['IJplein'] },
            { time: '16:15:00', line: '906', to: ['NDSM'] },
            { time: '16:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:20:00', line: '902', to: ['IJplein'] },
            { time: '16:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:26:00', line: '902', to: ['IJplein'] },
            { time: '16:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:30:00', line: '906', to: ['NDSM'] },
            { time: '16:32:00', line: '902', to: ['IJplein'] },
            { time: '16:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:38:00', line: '902', to: ['IJplein'] },
            { time: '16:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:44:00', line: '902', to: ['IJplein'] },
            { time: '16:45:00', line: '906', to: ['NDSM'] },
            { time: '16:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:50:00', line: '902', to: ['IJplein'] },
            { time: '16:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:56:00', line: '902', to: ['IJplein'] },
            { time: '16:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:00:00', line: '906', to: ['NDSM'] },
            { time: '17:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:02:00', line: '902', to: ['IJplein'] },
            { time: '17:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:08:00', line: '902', to: ['IJplein'] },
            { time: '17:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:14:00', line: '902', to: ['IJplein'] },
            { time: '17:15:00', line: '906', to: ['NDSM'] },
            { time: '17:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:20:00', line: '902', to: ['IJplein'] },
            { time: '17:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:26:00', line: '902', to: ['IJplein'] },
            { time: '17:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:30:00', line: '906', to: ['NDSM'] },
            { time: '17:32:00', line: '902', to: ['IJplein'] },
            { time: '17:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:38:00', line: '902', to: ['IJplein'] },
            { time: '17:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:44:00', line: '902', to: ['IJplein'] },
            { time: '17:45:00', line: '906', to: ['NDSM'] },
            { time: '17:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:50:00', line: '902', to: ['IJplein'] },
            { time: '17:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:56:00', line: '902', to: ['IJplein'] },
            { time: '17:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:00:00', line: '906', to: ['NDSM'] },
            { time: '18:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:02:00', line: '902', to: ['IJplein'] },
            { time: '18:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:08:00', line: '902', to: ['IJplein'] },
            { time: '18:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:14:00', line: '902', to: ['IJplein'] },
            { time: '18:15:00', line: '906', to: ['NDSM'] },
            { time: '18:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:20:00', line: '902', to: ['IJplein'] },
            { time: '18:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:26:00', line: '902', to: ['IJplein'] },
            { time: '18:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:30:00', line: '906', to: ['NDSM'] },
            { time: '18:32:00', line: '902', to: ['IJplein'] },
            { time: '18:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:38:00', line: '902', to: ['IJplein'] },
            { time: '18:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:44:00', line: '902', to: ['IJplein'] },
            { time: '18:45:00', line: '906', to: ['NDSM'] },
            { time: '18:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:50:00', line: '902', to: ['IJplein'] },
            { time: '18:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:56:00', line: '902', to: ['IJplein'] },
            { time: '18:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:02:00', line: '902', to: ['IJplein'] },
            { time: '19:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:08:00', line: '902', to: ['IJplein'] },
            { time: '19:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:14:00', line: '902', to: ['IJplein'] },
            { time: '19:15:00', line: '906', to: ['NDSM'] },
            { time: '19:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:20:00', line: '902', to: ['IJplein'] },
            { time: '19:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:26:00', line: '902', to: ['IJplein'] },
            { time: '19:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:32:00', line: '902', to: ['IJplein'] },
            { time: '19:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:38:00', line: '902', to: ['IJplein'] },
            { time: '19:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:44:00', line: '902', to: ['IJplein'] },
            { time: '19:45:00', line: '906', to: ['NDSM'] },
            { time: '19:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:50:00', line: '902', to: ['IJplein'] },
            { time: '19:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:56:00', line: '902', to: ['IJplein'] },
            { time: '19:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:02:00', line: '902', to: ['IJplein'] },
            { time: '20:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:08:00', line: '902', to: ['IJplein'] },
            { time: '20:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:14:00', line: '902', to: ['IJplein'] },
            { time: '20:15:00', line: '906', to: ['NDSM'] },
            { time: '20:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:20:00', line: '902', to: ['IJplein'] },
            { time: '20:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:26:00', line: '902', to: ['IJplein'] },
            { time: '20:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:32:00', line: '902', to: ['IJplein'] },
            { time: '20:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:38:00', line: '902', to: ['IJplein'] },
            { time: '20:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:44:00', line: '902', to: ['IJplein'] },
            { time: '20:45:00', line: '906', to: ['NDSM'] },
            { time: '20:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:50:00', line: '902', to: ['IJplein'] },
            { time: '20:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:56:00', line: '902', to: ['IJplein'] },
            { time: '20:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:08:00', line: '902', to: ['IJplein'] },
            { time: '21:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:15:00', line: '906', to: ['NDSM'] },
            { time: '21:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:20:00', line: '902', to: ['IJplein'] },
            { time: '21:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:32:00', line: '902', to: ['IJplein'] },
            { time: '21:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:44:00', line: '902', to: ['IJplein'] },
            { time: '21:45:00', line: '906', to: ['NDSM'] },
            { time: '21:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:56:00', line: '902', to: ['IJplein'] },
            { time: '21:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:08:00', line: '902', to: ['IJplein'] },
            { time: '22:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:15:00', line: '906', to: ['NDSM'] },
            { time: '22:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:20:00', line: '902', to: ['IJplein'] },
            { time: '22:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:32:00', line: '902', to: ['IJplein'] },
            { time: '22:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:44:00', line: '902', to: ['IJplein'] },
            { time: '22:45:00', line: '906', to: ['NDSM'] },
            { time: '22:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:56:00', line: '902', to: ['IJplein'] },
            { time: '22:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:08:00', line: '902', to: ['IJplein'] },
            { time: '23:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:15:00', line: '906', to: ['NDSM'] },
            { time: '23:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:20:00', line: '902', to: ['IJplein'] },
            { time: '23:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:32:00', line: '902', to: ['IJplein'] },
            { time: '23:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:44:00', line: '902', to: ['IJplein'] },
            { time: '23:45:00', line: '906', to: ['NDSM'] },
            { time: '23:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:56:00', line: '902', to: ['IJplein'] },
            { time: '23:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:15:00', line: '906', to: ['NDSM'] },
            { time: '24:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:45:00', line: '906', to: ['NDSM'] },
            { time: '24:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:15:00', line: '906', to: ['NDSM'] },
            { time: '25:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:45:00', line: '906', to: ['NDSM'] },
            { time: '25:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:12:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:24:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:36:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:48:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:00:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:52:00', line: '901', to: ['Buiksloterweg'] },
        ],
        friday: [
            { time: '00:23:00', line: '905', to: ['NDSM'] },
            { time: '01:01:00', line: '905', to: ['NDSM'] },
            { time: '01:39:00', line: '905', to: ['NDSM'] },
            { time: '05:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:30:00', line: '906', to: ['NDSM'] },
            { time: '06:32:00', line: '902', to: ['IJplein'] },
            { time: '06:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:38:00', line: '902', to: ['IJplein'] },
            { time: '06:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:44:00', line: '902', to: ['IJplein'] },
            { time: '06:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:50:00', line: '902', to: ['IJplein'] },
            { time: '06:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:56:00', line: '902', to: ['IJplein'] },
            { time: '06:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:00:00', line: '906', to: ['NDSM'] },
            { time: '07:02:00', line: '902', to: ['IJplein'] },
            { time: '07:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:08:00', line: '902', to: ['IJplein'] },
            { time: '07:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:14:00', line: '902', to: ['IJplein'] },
            { time: '07:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:20:00', line: '902', to: ['IJplein'] },
            { time: '07:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:26:00', line: '902', to: ['IJplein'] },
            { time: '07:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:30:00', line: '906', to: ['NDSM'] },
            { time: '07:32:00', line: '902', to: ['IJplein'] },
            { time: '07:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:38:00', line: '902', to: ['IJplein'] },
            { time: '07:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:44:00', line: '902', to: ['IJplein'] },
            { time: '07:45:00', line: '906', to: ['NDSM'] },
            { time: '07:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:50:00', line: '902', to: ['IJplein'] },
            { time: '07:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:56:00', line: '902', to: ['IJplein'] },
            { time: '07:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:00:00', line: '906', to: ['NDSM'] },
            { time: '08:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:02:00', line: '902', to: ['IJplein'] },
            { time: '08:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:08:00', line: '902', to: ['IJplein'] },
            { time: '08:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:14:00', line: '902', to: ['IJplein'] },
            { time: '08:15:00', line: '906', to: ['NDSM'] },
            { time: '08:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:20:00', line: '902', to: ['IJplein'] },
            { time: '08:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:26:00', line: '902', to: ['IJplein'] },
            { time: '08:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:30:00', line: '906', to: ['NDSM'] },
            { time: '08:32:00', line: '902', to: ['IJplein'] },
            { time: '08:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:38:00', line: '902', to: ['IJplein'] },
            { time: '08:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:44:00', line: '902', to: ['IJplein'] },
            { time: '08:45:00', line: '906', to: ['NDSM'] },
            { time: '08:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:50:00', line: '902', to: ['IJplein'] },
            { time: '08:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:56:00', line: '902', to: ['IJplein'] },
            { time: '08:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:00:00', line: '906', to: ['NDSM'] },
            { time: '09:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:02:00', line: '902', to: ['IJplein'] },
            { time: '09:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:08:00', line: '902', to: ['IJplein'] },
            { time: '09:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:14:00', line: '902', to: ['IJplein'] },
            { time: '09:15:00', line: '906', to: ['NDSM'] },
            { time: '09:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:20:00', line: '902', to: ['IJplein'] },
            { time: '09:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:26:00', line: '902', to: ['IJplein'] },
            { time: '09:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:30:00', line: '906', to: ['NDSM'] },
            { time: '09:32:00', line: '902', to: ['IJplein'] },
            { time: '09:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:38:00', line: '902', to: ['IJplein'] },
            { time: '09:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:44:00', line: '902', to: ['IJplein'] },
            { time: '09:45:00', line: '906', to: ['NDSM'] },
            { time: '09:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:50:00', line: '902', to: ['IJplein'] },
            { time: '09:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:56:00', line: '902', to: ['IJplein'] },
            { time: '09:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:00:00', line: '906', to: ['NDSM'] },
            { time: '10:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:02:00', line: '902', to: ['IJplein'] },
            { time: '10:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:08:00', line: '902', to: ['IJplein'] },
            { time: '10:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:14:00', line: '902', to: ['IJplein'] },
            { time: '10:15:00', line: '906', to: ['NDSM'] },
            { time: '10:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:20:00', line: '902', to: ['IJplein'] },
            { time: '10:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:26:00', line: '902', to: ['IJplein'] },
            { time: '10:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:30:00', line: '906', to: ['NDSM'] },
            { time: '10:32:00', line: '902', to: ['IJplein'] },
            { time: '10:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:38:00', line: '902', to: ['IJplein'] },
            { time: '10:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:44:00', line: '902', to: ['IJplein'] },
            { time: '10:45:00', line: '906', to: ['NDSM'] },
            { time: '10:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:50:00', line: '902', to: ['IJplein'] },
            { time: '10:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:56:00', line: '902', to: ['IJplein'] },
            { time: '10:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:00:00', line: '906', to: ['NDSM'] },
            { time: '11:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:02:00', line: '902', to: ['IJplein'] },
            { time: '11:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:08:00', line: '902', to: ['IJplein'] },
            { time: '11:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:14:00', line: '902', to: ['IJplein'] },
            { time: '11:15:00', line: '906', to: ['NDSM'] },
            { time: '11:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:20:00', line: '902', to: ['IJplein'] },
            { time: '11:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:26:00', line: '902', to: ['IJplein'] },
            { time: '11:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:30:00', line: '906', to: ['NDSM'] },
            { time: '11:32:00', line: '902', to: ['IJplein'] },
            { time: '11:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:38:00', line: '902', to: ['IJplein'] },
            { time: '11:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:44:00', line: '902', to: ['IJplein'] },
            { time: '11:45:00', line: '906', to: ['NDSM'] },
            { time: '11:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:50:00', line: '902', to: ['IJplein'] },
            { time: '11:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:56:00', line: '902', to: ['IJplein'] },
            { time: '11:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:00:00', line: '906', to: ['NDSM'] },
            { time: '12:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:02:00', line: '902', to: ['IJplein'] },
            { time: '12:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:08:00', line: '902', to: ['IJplein'] },
            { time: '12:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:14:00', line: '902', to: ['IJplein'] },
            { time: '12:15:00', line: '906', to: ['NDSM'] },
            { time: '12:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:20:00', line: '902', to: ['IJplein'] },
            { time: '12:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:26:00', line: '902', to: ['IJplein'] },
            { time: '12:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:30:00', line: '906', to: ['NDSM'] },
            { time: '12:32:00', line: '902', to: ['IJplein'] },
            { time: '12:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:38:00', line: '902', to: ['IJplein'] },
            { time: '12:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:44:00', line: '902', to: ['IJplein'] },
            { time: '12:45:00', line: '906', to: ['NDSM'] },
            { time: '12:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:50:00', line: '902', to: ['IJplein'] },
            { time: '12:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:56:00', line: '902', to: ['IJplein'] },
            { time: '12:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:00:00', line: '906', to: ['NDSM'] },
            { time: '13:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:02:00', line: '902', to: ['IJplein'] },
            { time: '13:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:08:00', line: '902', to: ['IJplein'] },
            { time: '13:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:14:00', line: '902', to: ['IJplein'] },
            { time: '13:15:00', line: '906', to: ['NDSM'] },
            { time: '13:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:20:00', line: '902', to: ['IJplein'] },
            { time: '13:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:26:00', line: '902', to: ['IJplein'] },
            { time: '13:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:30:00', line: '906', to: ['NDSM'] },
            { time: '13:32:00', line: '902', to: ['IJplein'] },
            { time: '13:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:38:00', line: '902', to: ['IJplein'] },
            { time: '13:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:44:00', line: '902', to: ['IJplein'] },
            { time: '13:45:00', line: '906', to: ['NDSM'] },
            { time: '13:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:50:00', line: '902', to: ['IJplein'] },
            { time: '13:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:56:00', line: '902', to: ['IJplein'] },
            { time: '13:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:00:00', line: '906', to: ['NDSM'] },
            { time: '14:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:02:00', line: '902', to: ['IJplein'] },
            { time: '14:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:08:00', line: '902', to: ['IJplein'] },
            { time: '14:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:14:00', line: '902', to: ['IJplein'] },
            { time: '14:15:00', line: '906', to: ['NDSM'] },
            { time: '14:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:20:00', line: '902', to: ['IJplein'] },
            { time: '14:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:26:00', line: '902', to: ['IJplein'] },
            { time: '14:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:30:00', line: '906', to: ['NDSM'] },
            { time: '14:32:00', line: '902', to: ['IJplein'] },
            { time: '14:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:38:00', line: '902', to: ['IJplein'] },
            { time: '14:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:44:00', line: '902', to: ['IJplein'] },
            { time: '14:45:00', line: '906', to: ['NDSM'] },
            { time: '14:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:50:00', line: '902', to: ['IJplein'] },
            { time: '14:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:56:00', line: '902', to: ['IJplein'] },
            { time: '14:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:00:00', line: '906', to: ['NDSM'] },
            { time: '15:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:02:00', line: '902', to: ['IJplein'] },
            { time: '15:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:08:00', line: '902', to: ['IJplein'] },
            { time: '15:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:14:00', line: '902', to: ['IJplein'] },
            { time: '15:15:00', line: '906', to: ['NDSM'] },
            { time: '15:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:20:00', line: '902', to: ['IJplein'] },
            { time: '15:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:26:00', line: '902', to: ['IJplein'] },
            { time: '15:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:30:00', line: '906', to: ['NDSM'] },
            { time: '15:32:00', line: '902', to: ['IJplein'] },
            { time: '15:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:38:00', line: '902', to: ['IJplein'] },
            { time: '15:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:44:00', line: '902', to: ['IJplein'] },
            { time: '15:45:00', line: '906', to: ['NDSM'] },
            { time: '15:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:50:00', line: '902', to: ['IJplein'] },
            { time: '15:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:56:00', line: '902', to: ['IJplein'] },
            { time: '15:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:00:00', line: '906', to: ['NDSM'] },
            { time: '16:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:02:00', line: '902', to: ['IJplein'] },
            { time: '16:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:08:00', line: '902', to: ['IJplein'] },
            { time: '16:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:14:00', line: '902', to: ['IJplein'] },
            { time: '16:15:00', line: '906', to: ['NDSM'] },
            { time: '16:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:20:00', line: '902', to: ['IJplein'] },
            { time: '16:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:26:00', line: '902', to: ['IJplein'] },
            { time: '16:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:30:00', line: '906', to: ['NDSM'] },
            { time: '16:32:00', line: '902', to: ['IJplein'] },
            { time: '16:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:38:00', line: '902', to: ['IJplein'] },
            { time: '16:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:44:00', line: '902', to: ['IJplein'] },
            { time: '16:45:00', line: '906', to: ['NDSM'] },
            { time: '16:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:50:00', line: '902', to: ['IJplein'] },
            { time: '16:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:56:00', line: '902', to: ['IJplein'] },
            { time: '16:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:00:00', line: '906', to: ['NDSM'] },
            { time: '17:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:02:00', line: '902', to: ['IJplein'] },
            { time: '17:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:08:00', line: '902', to: ['IJplein'] },
            { time: '17:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:14:00', line: '902', to: ['IJplein'] },
            { time: '17:15:00', line: '906', to: ['NDSM'] },
            { time: '17:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:20:00', line: '902', to: ['IJplein'] },
            { time: '17:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:26:00', line: '902', to: ['IJplein'] },
            { time: '17:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:30:00', line: '906', to: ['NDSM'] },
            { time: '17:32:00', line: '902', to: ['IJplein'] },
            { time: '17:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:38:00', line: '902', to: ['IJplein'] },
            { time: '17:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:44:00', line: '902', to: ['IJplein'] },
            { time: '17:45:00', line: '906', to: ['NDSM'] },
            { time: '17:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:50:00', line: '902', to: ['IJplein'] },
            { time: '17:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:56:00', line: '902', to: ['IJplein'] },
            { time: '17:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:00:00', line: '906', to: ['NDSM'] },
            { time: '18:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:02:00', line: '902', to: ['IJplein'] },
            { time: '18:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:08:00', line: '902', to: ['IJplein'] },
            { time: '18:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:14:00', line: '902', to: ['IJplein'] },
            { time: '18:15:00', line: '906', to: ['NDSM'] },
            { time: '18:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:20:00', line: '902', to: ['IJplein'] },
            { time: '18:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:26:00', line: '902', to: ['IJplein'] },
            { time: '18:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:30:00', line: '906', to: ['NDSM'] },
            { time: '18:32:00', line: '902', to: ['IJplein'] },
            { time: '18:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:38:00', line: '902', to: ['IJplein'] },
            { time: '18:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:44:00', line: '902', to: ['IJplein'] },
            { time: '18:45:00', line: '906', to: ['NDSM'] },
            { time: '18:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:50:00', line: '902', to: ['IJplein'] },
            { time: '18:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:56:00', line: '902', to: ['IJplein'] },
            { time: '18:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:02:00', line: '902', to: ['IJplein'] },
            { time: '19:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:08:00', line: '902', to: ['IJplein'] },
            { time: '19:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:14:00', line: '902', to: ['IJplein'] },
            { time: '19:15:00', line: '906', to: ['NDSM'] },
            { time: '19:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:20:00', line: '902', to: ['IJplein'] },
            { time: '19:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:26:00', line: '902', to: ['IJplein'] },
            { time: '19:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:32:00', line: '902', to: ['IJplein'] },
            { time: '19:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:38:00', line: '902', to: ['IJplein'] },
            { time: '19:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:44:00', line: '902', to: ['IJplein'] },
            { time: '19:45:00', line: '906', to: ['NDSM'] },
            { time: '19:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:50:00', line: '902', to: ['IJplein'] },
            { time: '19:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:56:00', line: '902', to: ['IJplein'] },
            { time: '19:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:02:00', line: '902', to: ['IJplein'] },
            { time: '20:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:08:00', line: '902', to: ['IJplein'] },
            { time: '20:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:14:00', line: '902', to: ['IJplein'] },
            { time: '20:15:00', line: '906', to: ['NDSM'] },
            { time: '20:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:20:00', line: '902', to: ['IJplein'] },
            { time: '20:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:26:00', line: '902', to: ['IJplein'] },
            { time: '20:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:32:00', line: '902', to: ['IJplein'] },
            { time: '20:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:38:00', line: '902', to: ['IJplein'] },
            { time: '20:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:44:00', line: '902', to: ['IJplein'] },
            { time: '20:45:00', line: '906', to: ['NDSM'] },
            { time: '20:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:50:00', line: '902', to: ['IJplein'] },
            { time: '20:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:56:00', line: '902', to: ['IJplein'] },
            { time: '20:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:08:00', line: '902', to: ['IJplein'] },
            { time: '21:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:15:00', line: '906', to: ['NDSM'] },
            { time: '21:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:20:00', line: '902', to: ['IJplein'] },
            { time: '21:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:32:00', line: '902', to: ['IJplein'] },
            { time: '21:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:44:00', line: '902', to: ['IJplein'] },
            { time: '21:45:00', line: '906', to: ['NDSM'] },
            { time: '21:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:56:00', line: '902', to: ['IJplein'] },
            { time: '21:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:08:00', line: '902', to: ['IJplein'] },
            { time: '22:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:15:00', line: '906', to: ['NDSM'] },
            { time: '22:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:20:00', line: '902', to: ['IJplein'] },
            { time: '22:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:32:00', line: '902', to: ['IJplein'] },
            { time: '22:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:44:00', line: '902', to: ['IJplein'] },
            { time: '22:45:00', line: '906', to: ['NDSM'] },
            { time: '22:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:56:00', line: '902', to: ['IJplein'] },
            { time: '22:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:08:00', line: '902', to: ['IJplein'] },
            { time: '23:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:15:00', line: '906', to: ['NDSM'] },
            { time: '23:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:20:00', line: '902', to: ['IJplein'] },
            { time: '23:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:32:00', line: '902', to: ['IJplein'] },
            { time: '23:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:44:00', line: '902', to: ['IJplein'] },
            { time: '23:45:00', line: '906', to: ['NDSM'] },
            { time: '23:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:56:00', line: '902', to: ['IJplein'] },
            { time: '23:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:15:00', line: '906', to: ['NDSM'] },
            { time: '24:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:45:00', line: '906', to: ['NDSM'] },
            { time: '24:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:15:00', line: '906', to: ['NDSM'] },
            { time: '25:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:45:00', line: '906', to: ['NDSM'] },
            { time: '25:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:15:00', line: '906', to: ['NDSM'] },
            { time: '26:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:45:00', line: '906', to: ['NDSM'] },
            { time: '26:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:12:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:15:00', line: '906', to: ['NDSM'] },
            { time: '27:24:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:36:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:48:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:00:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:52:00', line: '901', to: ['Buiksloterweg'] },
        ],
        saturday: [
            { time: '00:23:00', line: '905', to: ['NDSM'] },
            { time: '01:01:00', line: '905', to: ['NDSM'] },
            { time: '01:39:00', line: '905', to: ['NDSM'] },
            { time: '02:17:00', line: '905', to: ['NDSM'] },
            { time: '02:55:00', line: '905', to: ['NDSM'] },
            { time: '05:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:32:00', line: '902', to: ['IJplein'] },
            { time: '06:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:44:00', line: '902', to: ['IJplein'] },
            { time: '06:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:56:00', line: '902', to: ['IJplein'] },
            { time: '06:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:00:00', line: '906', to: ['NDSM'] },
            { time: '07:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:08:00', line: '902', to: ['IJplein'] },
            { time: '07:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:20:00', line: '902', to: ['IJplein'] },
            { time: '07:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:30:00', line: '906', to: ['NDSM'] },
            { time: '07:32:00', line: '902', to: ['IJplein'] },
            { time: '07:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:44:00', line: '902', to: ['IJplein'] },
            { time: '07:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:56:00', line: '902', to: ['IJplein'] },
            { time: '07:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:00:00', line: '906', to: ['NDSM'] },
            { time: '08:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:08:00', line: '902', to: ['IJplein'] },
            { time: '08:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:20:00', line: '902', to: ['IJplein'] },
            { time: '08:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:30:00', line: '906', to: ['NDSM'] },
            { time: '08:32:00', line: '902', to: ['IJplein'] },
            { time: '08:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:44:00', line: '902', to: ['IJplein'] },
            { time: '08:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:56:00', line: '902', to: ['IJplein'] },
            { time: '08:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:00:00', line: '906', to: ['NDSM'] },
            { time: '09:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:08:00', line: '902', to: ['IJplein'] },
            { time: '09:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:15:00', line: '906', to: ['NDSM'] },
            { time: '09:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:20:00', line: '902', to: ['IJplein'] },
            { time: '09:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:30:00', line: '906', to: ['NDSM'] },
            { time: '09:32:00', line: '902', to: ['IJplein'] },
            { time: '09:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:44:00', line: '902', to: ['IJplein'] },
            { time: '09:45:00', line: '906', to: ['NDSM'] },
            { time: '09:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:56:00', line: '902', to: ['IJplein'] },
            { time: '09:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:00:00', line: '906', to: ['NDSM'] },
            { time: '10:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:08:00', line: '902', to: ['IJplein'] },
            { time: '10:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:10:00', line: '906', to: ['NDSM'] },
            { time: '10:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:20:00', line: '902', to: ['IJplein'] },
            { time: '10:20:00', line: '906', to: ['NDSM'] },
            { time: '10:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:30:00', line: '906', to: ['NDSM'] },
            { time: '10:32:00', line: '902', to: ['IJplein'] },
            { time: '10:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:40:00', line: '906', to: ['NDSM'] },
            { time: '10:44:00', line: '902', to: ['IJplein'] },
            { time: '10:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:50:00', line: '906', to: ['NDSM'] },
            { time: '10:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:56:00', line: '902', to: ['IJplein'] },
            { time: '10:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:00:00', line: '906', to: ['NDSM'] },
            { time: '11:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:08:00', line: '902', to: ['IJplein'] },
            { time: '11:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:10:00', line: '906', to: ['NDSM'] },
            { time: '11:14:00', line: '902', to: ['IJplein'] },
            { time: '11:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:20:00', line: '902', to: ['IJplein'] },
            { time: '11:20:00', line: '906', to: ['NDSM'] },
            { time: '11:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:26:00', line: '902', to: ['IJplein'] },
            { time: '11:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:30:00', line: '906', to: ['NDSM'] },
            { time: '11:32:00', line: '902', to: ['IJplein'] },
            { time: '11:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:38:00', line: '902', to: ['IJplein'] },
            { time: '11:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:40:00', line: '906', to: ['NDSM'] },
            { time: '11:44:00', line: '902', to: ['IJplein'] },
            { time: '11:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:50:00', line: '902', to: ['IJplein'] },
            { time: '11:50:00', line: '906', to: ['NDSM'] },
            { time: '11:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:56:00', line: '902', to: ['IJplein'] },
            { time: '11:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:00:00', line: '906', to: ['NDSM'] },
            { time: '12:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:02:00', line: '902', to: ['IJplein'] },
            { time: '12:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:08:00', line: '902', to: ['IJplein'] },
            { time: '12:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:10:00', line: '906', to: ['NDSM'] },
            { time: '12:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:14:00', line: '902', to: ['IJplein'] },
            { time: '12:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:20:00', line: '902', to: ['IJplein'] },
            { time: '12:20:00', line: '906', to: ['NDSM'] },
            { time: '12:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:26:00', line: '902', to: ['IJplein'] },
            { time: '12:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:30:00', line: '906', to: ['NDSM'] },
            { time: '12:32:00', line: '902', to: ['IJplein'] },
            { time: '12:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:38:00', line: '902', to: ['IJplein'] },
            { time: '12:40:00', line: '906', to: ['NDSM'] },
            { time: '12:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:44:00', line: '902', to: ['IJplein'] },
            { time: '12:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:50:00', line: '902', to: ['IJplein'] },
            { time: '12:50:00', line: '906', to: ['NDSM'] },
            { time: '12:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:56:00', line: '902', to: ['IJplein'] },
            { time: '12:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:00:00', line: '906', to: ['NDSM'] },
            { time: '13:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:02:00', line: '902', to: ['IJplein'] },
            { time: '13:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:08:00', line: '902', to: ['IJplein'] },
            { time: '13:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:10:00', line: '906', to: ['NDSM'] },
            { time: '13:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:14:00', line: '902', to: ['IJplein'] },
            { time: '13:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:20:00', line: '902', to: ['IJplein'] },
            { time: '13:20:00', line: '906', to: ['NDSM'] },
            { time: '13:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:26:00', line: '902', to: ['IJplein'] },
            { time: '13:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:30:00', line: '906', to: ['NDSM'] },
            { time: '13:32:00', line: '902', to: ['IJplein'] },
            { time: '13:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:38:00', line: '902', to: ['IJplein'] },
            { time: '13:40:00', line: '906', to: ['NDSM'] },
            { time: '13:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:44:00', line: '902', to: ['IJplein'] },
            { time: '13:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:50:00', line: '902', to: ['IJplein'] },
            { time: '13:50:00', line: '906', to: ['NDSM'] },
            { time: '13:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:56:00', line: '902', to: ['IJplein'] },
            { time: '13:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:00:00', line: '906', to: ['NDSM'] },
            { time: '14:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:02:00', line: '902', to: ['IJplein'] },
            { time: '14:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:08:00', line: '902', to: ['IJplein'] },
            { time: '14:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:10:00', line: '906', to: ['NDSM'] },
            { time: '14:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:14:00', line: '902', to: ['IJplein'] },
            { time: '14:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:20:00', line: '902', to: ['IJplein'] },
            { time: '14:20:00', line: '906', to: ['NDSM'] },
            { time: '14:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:26:00', line: '902', to: ['IJplein'] },
            { time: '14:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:30:00', line: '906', to: ['NDSM'] },
            { time: '14:32:00', line: '902', to: ['IJplein'] },
            { time: '14:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:38:00', line: '902', to: ['IJplein'] },
            { time: '14:40:00', line: '906', to: ['NDSM'] },
            { time: '14:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:44:00', line: '902', to: ['IJplein'] },
            { time: '14:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:50:00', line: '902', to: ['IJplein'] },
            { time: '14:50:00', line: '906', to: ['NDSM'] },
            { time: '14:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:56:00', line: '902', to: ['IJplein'] },
            { time: '14:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:00:00', line: '906', to: ['NDSM'] },
            { time: '15:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:02:00', line: '902', to: ['IJplein'] },
            { time: '15:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:08:00', line: '902', to: ['IJplein'] },
            { time: '15:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:10:00', line: '906', to: ['NDSM'] },
            { time: '15:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:14:00', line: '902', to: ['IJplein'] },
            { time: '15:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:20:00', line: '902', to: ['IJplein'] },
            { time: '15:20:00', line: '906', to: ['NDSM'] },
            { time: '15:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:26:00', line: '902', to: ['IJplein'] },
            { time: '15:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:30:00', line: '906', to: ['NDSM'] },
            { time: '15:32:00', line: '902', to: ['IJplein'] },
            { time: '15:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:38:00', line: '902', to: ['IJplein'] },
            { time: '15:40:00', line: '906', to: ['NDSM'] },
            { time: '15:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:44:00', line: '902', to: ['IJplein'] },
            { time: '15:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:50:00', line: '902', to: ['IJplein'] },
            { time: '15:50:00', line: '906', to: ['NDSM'] },
            { time: '15:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:56:00', line: '902', to: ['IJplein'] },
            { time: '15:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:00:00', line: '906', to: ['NDSM'] },
            { time: '16:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:02:00', line: '902', to: ['IJplein'] },
            { time: '16:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:08:00', line: '902', to: ['IJplein'] },
            { time: '16:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:10:00', line: '906', to: ['NDSM'] },
            { time: '16:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:14:00', line: '902', to: ['IJplein'] },
            { time: '16:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:20:00', line: '902', to: ['IJplein'] },
            { time: '16:20:00', line: '906', to: ['NDSM'] },
            { time: '16:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:26:00', line: '902', to: ['IJplein'] },
            { time: '16:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:30:00', line: '906', to: ['NDSM'] },
            { time: '16:32:00', line: '902', to: ['IJplein'] },
            { time: '16:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:38:00', line: '902', to: ['IJplein'] },
            { time: '16:40:00', line: '906', to: ['NDSM'] },
            { time: '16:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:44:00', line: '902', to: ['IJplein'] },
            { time: '16:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:50:00', line: '902', to: ['IJplein'] },
            { time: '16:50:00', line: '906', to: ['NDSM'] },
            { time: '16:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:56:00', line: '902', to: ['IJplein'] },
            { time: '16:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:00:00', line: '906', to: ['NDSM'] },
            { time: '17:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:02:00', line: '902', to: ['IJplein'] },
            { time: '17:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:08:00', line: '902', to: ['IJplein'] },
            { time: '17:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:10:00', line: '906', to: ['NDSM'] },
            { time: '17:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:14:00', line: '902', to: ['IJplein'] },
            { time: '17:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:20:00', line: '902', to: ['IJplein'] },
            { time: '17:20:00', line: '906', to: ['NDSM'] },
            { time: '17:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:26:00', line: '902', to: ['IJplein'] },
            { time: '17:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:30:00', line: '906', to: ['NDSM'] },
            { time: '17:32:00', line: '902', to: ['IJplein'] },
            { time: '17:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:38:00', line: '902', to: ['IJplein'] },
            { time: '17:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:44:00', line: '902', to: ['IJplein'] },
            { time: '17:45:00', line: '906', to: ['NDSM'] },
            { time: '17:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:50:00', line: '902', to: ['IJplein'] },
            { time: '17:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:56:00', line: '902', to: ['IJplein'] },
            { time: '17:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:00:00', line: '906', to: ['NDSM'] },
            { time: '18:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:08:00', line: '902', to: ['IJplein'] },
            { time: '18:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:15:00', line: '906', to: ['NDSM'] },
            { time: '18:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:20:00', line: '902', to: ['IJplein'] },
            { time: '18:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:30:00', line: '906', to: ['NDSM'] },
            { time: '18:32:00', line: '902', to: ['IJplein'] },
            { time: '18:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:44:00', line: '902', to: ['IJplein'] },
            { time: '18:45:00', line: '906', to: ['NDSM'] },
            { time: '18:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:56:00', line: '902', to: ['IJplein'] },
            { time: '18:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:00:00', line: '906', to: ['NDSM'] },
            { time: '19:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:08:00', line: '902', to: ['IJplein'] },
            { time: '19:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:15:00', line: '906', to: ['NDSM'] },
            { time: '19:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:20:00', line: '902', to: ['IJplein'] },
            { time: '19:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:30:00', line: '906', to: ['NDSM'] },
            { time: '19:32:00', line: '902', to: ['IJplein'] },
            { time: '19:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:44:00', line: '902', to: ['IJplein'] },
            { time: '19:45:00', line: '906', to: ['NDSM'] },
            { time: '19:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:56:00', line: '902', to: ['IJplein'] },
            { time: '19:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:00:00', line: '906', to: ['NDSM'] },
            { time: '20:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:08:00', line: '902', to: ['IJplein'] },
            { time: '20:12:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:15:00', line: '906', to: ['NDSM'] },
            { time: '20:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:20:00', line: '902', to: ['IJplein'] },
            { time: '20:24:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:30:00', line: '906', to: ['NDSM'] },
            { time: '20:32:00', line: '902', to: ['IJplein'] },
            { time: '20:36:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:44:00', line: '902', to: ['IJplein'] },
            { time: '20:45:00', line: '906', to: ['NDSM'] },
            { time: '20:48:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:56:00', line: '902', to: ['IJplein'] },
            { time: '21:00:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:00:00', line: '906', to: ['NDSM'] },
            { time: '21:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:08:00', line: '902', to: ['IJplein'] },
            { time: '21:12:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:15:00', line: '906', to: ['NDSM'] },
            { time: '21:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:20:00', line: '902', to: ['IJplein'] },
            { time: '21:24:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:30:00', line: '906', to: ['NDSM'] },
            { time: '21:32:00', line: '902', to: ['IJplein'] },
            { time: '21:36:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:44:00', line: '902', to: ['IJplein'] },
            { time: '21:45:00', line: '906', to: ['NDSM'] },
            { time: '21:48:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:56:00', line: '902', to: ['IJplein'] },
            { time: '22:00:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:08:00', line: '902', to: ['IJplein'] },
            { time: '22:12:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:15:00', line: '906', to: ['NDSM'] },
            { time: '22:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:20:00', line: '902', to: ['IJplein'] },
            { time: '22:24:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:32:00', line: '902', to: ['IJplein'] },
            { time: '22:36:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:44:00', line: '902', to: ['IJplein'] },
            { time: '22:45:00', line: '906', to: ['NDSM'] },
            { time: '22:48:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:56:00', line: '902', to: ['IJplein'] },
            { time: '23:00:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:08:00', line: '902', to: ['IJplein'] },
            { time: '23:12:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:15:00', line: '906', to: ['NDSM'] },
            { time: '23:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:20:00', line: '902', to: ['IJplein'] },
            { time: '23:24:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:32:00', line: '902', to: ['IJplein'] },
            { time: '23:36:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:44:00', line: '902', to: ['IJplein'] },
            { time: '23:45:00', line: '906', to: ['NDSM'] },
            { time: '23:48:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:56:00', line: '902', to: ['IJplein'] },
            { time: '24:00:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:12:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:15:00', line: '906', to: ['NDSM'] },
            { time: '24:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:24:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:36:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:45:00', line: '906', to: ['NDSM'] },
            { time: '24:48:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:00:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:12:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:15:00', line: '906', to: ['NDSM'] },
            { time: '25:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:24:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:36:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:45:00', line: '906', to: ['NDSM'] },
            { time: '25:48:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:00:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:12:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:15:00', line: '906', to: ['NDSM'] },
            { time: '26:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:24:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:36:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:45:00', line: '906', to: ['NDSM'] },
            { time: '26:48:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:00:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:12:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:15:00', line: '906', to: ['NDSM'] },
            { time: '27:24:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:36:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:48:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:00:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:52:00', line: '901', to: ['Buiksloterweg'] },
        ],
        sunday: [
            { time: '00:23:00', line: '905', to: ['NDSM'] },
            { time: '01:01:00', line: '905', to: ['NDSM'] },
            { time: '01:39:00', line: '905', to: ['NDSM'] },
            { time: '02:17:00', line: '905', to: ['NDSM'] },
            { time: '02:55:00', line: '905', to: ['NDSM'] },
            { time: '05:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '06:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:00:00', line: '906', to: ['NDSM'] },
            { time: '07:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:30:00', line: '906', to: ['NDSM'] },
            { time: '07:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '07:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:00:00', line: '906', to: ['NDSM'] },
            { time: '08:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:30:00', line: '906', to: ['NDSM'] },
            { time: '08:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '08:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:00:00', line: '906', to: ['NDSM'] },
            { time: '09:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:08:00', line: '902', to: ['IJplein'] },
            { time: '09:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:15:00', line: '906', to: ['NDSM'] },
            { time: '09:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:20:00', line: '902', to: ['IJplein'] },
            { time: '09:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:30:00', line: '906', to: ['NDSM'] },
            { time: '09:32:00', line: '902', to: ['IJplein'] },
            { time: '09:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:44:00', line: '902', to: ['IJplein'] },
            { time: '09:45:00', line: '906', to: ['NDSM'] },
            { time: '09:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '09:56:00', line: '902', to: ['IJplein'] },
            { time: '09:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:00:00', line: '906', to: ['NDSM'] },
            { time: '10:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:08:00', line: '902', to: ['IJplein'] },
            { time: '10:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:10:00', line: '906', to: ['NDSM'] },
            { time: '10:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:20:00', line: '902', to: ['IJplein'] },
            { time: '10:20:00', line: '906', to: ['NDSM'] },
            { time: '10:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:30:00', line: '906', to: ['NDSM'] },
            { time: '10:32:00', line: '902', to: ['IJplein'] },
            { time: '10:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:40:00', line: '906', to: ['NDSM'] },
            { time: '10:44:00', line: '902', to: ['IJplein'] },
            { time: '10:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:50:00', line: '906', to: ['NDSM'] },
            { time: '10:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '10:56:00', line: '902', to: ['IJplein'] },
            { time: '10:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:00:00', line: '906', to: ['NDSM'] },
            { time: '11:04:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:08:00', line: '902', to: ['IJplein'] },
            { time: '11:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:10:00', line: '906', to: ['NDSM'] },
            { time: '11:14:00', line: '902', to: ['IJplein'] },
            { time: '11:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:20:00', line: '902', to: ['IJplein'] },
            { time: '11:20:00', line: '906', to: ['NDSM'] },
            { time: '11:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:26:00', line: '902', to: ['IJplein'] },
            { time: '11:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:30:00', line: '906', to: ['NDSM'] },
            { time: '11:32:00', line: '902', to: ['IJplein'] },
            { time: '11:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:38:00', line: '902', to: ['IJplein'] },
            { time: '11:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:40:00', line: '906', to: ['NDSM'] },
            { time: '11:44:00', line: '902', to: ['IJplein'] },
            { time: '11:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:50:00', line: '902', to: ['IJplein'] },
            { time: '11:50:00', line: '906', to: ['NDSM'] },
            { time: '11:52:00', line: '901', to: ['Buiksloterweg'] },
            { time: '11:56:00', line: '902', to: ['IJplein'] },
            { time: '11:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:00:00', line: '906', to: ['NDSM'] },
            { time: '12:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:02:00', line: '902', to: ['IJplein'] },
            { time: '12:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:08:00', line: '902', to: ['IJplein'] },
            { time: '12:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:10:00', line: '906', to: ['NDSM'] },
            { time: '12:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:14:00', line: '902', to: ['IJplein'] },
            { time: '12:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:20:00', line: '902', to: ['IJplein'] },
            { time: '12:20:00', line: '906', to: ['NDSM'] },
            { time: '12:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:26:00', line: '902', to: ['IJplein'] },
            { time: '12:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:30:00', line: '906', to: ['NDSM'] },
            { time: '12:32:00', line: '902', to: ['IJplein'] },
            { time: '12:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:38:00', line: '902', to: ['IJplein'] },
            { time: '12:40:00', line: '906', to: ['NDSM'] },
            { time: '12:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:44:00', line: '902', to: ['IJplein'] },
            { time: '12:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:50:00', line: '902', to: ['IJplein'] },
            { time: '12:50:00', line: '906', to: ['NDSM'] },
            { time: '12:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '12:56:00', line: '902', to: ['IJplein'] },
            { time: '12:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:00:00', line: '906', to: ['NDSM'] },
            { time: '13:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:02:00', line: '902', to: ['IJplein'] },
            { time: '13:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:08:00', line: '902', to: ['IJplein'] },
            { time: '13:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:10:00', line: '906', to: ['NDSM'] },
            { time: '13:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:14:00', line: '902', to: ['IJplein'] },
            { time: '13:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:20:00', line: '902', to: ['IJplein'] },
            { time: '13:20:00', line: '906', to: ['NDSM'] },
            { time: '13:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:26:00', line: '902', to: ['IJplein'] },
            { time: '13:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:30:00', line: '906', to: ['NDSM'] },
            { time: '13:32:00', line: '902', to: ['IJplein'] },
            { time: '13:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:38:00', line: '902', to: ['IJplein'] },
            { time: '13:40:00', line: '906', to: ['NDSM'] },
            { time: '13:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:44:00', line: '902', to: ['IJplein'] },
            { time: '13:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:50:00', line: '902', to: ['IJplein'] },
            { time: '13:50:00', line: '906', to: ['NDSM'] },
            { time: '13:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '13:56:00', line: '902', to: ['IJplein'] },
            { time: '13:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:00:00', line: '906', to: ['NDSM'] },
            { time: '14:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:02:00', line: '902', to: ['IJplein'] },
            { time: '14:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:08:00', line: '902', to: ['IJplein'] },
            { time: '14:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:10:00', line: '906', to: ['NDSM'] },
            { time: '14:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:14:00', line: '902', to: ['IJplein'] },
            { time: '14:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:20:00', line: '902', to: ['IJplein'] },
            { time: '14:20:00', line: '906', to: ['NDSM'] },
            { time: '14:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:26:00', line: '902', to: ['IJplein'] },
            { time: '14:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:30:00', line: '906', to: ['NDSM'] },
            { time: '14:32:00', line: '902', to: ['IJplein'] },
            { time: '14:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:38:00', line: '902', to: ['IJplein'] },
            { time: '14:40:00', line: '906', to: ['NDSM'] },
            { time: '14:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:44:00', line: '902', to: ['IJplein'] },
            { time: '14:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:50:00', line: '902', to: ['IJplein'] },
            { time: '14:50:00', line: '906', to: ['NDSM'] },
            { time: '14:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '14:56:00', line: '902', to: ['IJplein'] },
            { time: '14:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:00:00', line: '906', to: ['NDSM'] },
            { time: '15:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:02:00', line: '902', to: ['IJplein'] },
            { time: '15:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:08:00', line: '902', to: ['IJplein'] },
            { time: '15:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:10:00', line: '906', to: ['NDSM'] },
            { time: '15:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:14:00', line: '902', to: ['IJplein'] },
            { time: '15:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:20:00', line: '902', to: ['IJplein'] },
            { time: '15:20:00', line: '906', to: ['NDSM'] },
            { time: '15:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:26:00', line: '902', to: ['IJplein'] },
            { time: '15:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:30:00', line: '906', to: ['NDSM'] },
            { time: '15:32:00', line: '902', to: ['IJplein'] },
            { time: '15:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:38:00', line: '902', to: ['IJplein'] },
            { time: '15:40:00', line: '906', to: ['NDSM'] },
            { time: '15:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:44:00', line: '902', to: ['IJplein'] },
            { time: '15:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:50:00', line: '902', to: ['IJplein'] },
            { time: '15:50:00', line: '906', to: ['NDSM'] },
            { time: '15:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '15:56:00', line: '902', to: ['IJplein'] },
            { time: '15:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:00:00', line: '906', to: ['NDSM'] },
            { time: '16:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:02:00', line: '902', to: ['IJplein'] },
            { time: '16:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:08:00', line: '902', to: ['IJplein'] },
            { time: '16:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:10:00', line: '906', to: ['NDSM'] },
            { time: '16:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:14:00', line: '902', to: ['IJplein'] },
            { time: '16:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:20:00', line: '902', to: ['IJplein'] },
            { time: '16:20:00', line: '906', to: ['NDSM'] },
            { time: '16:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:26:00', line: '902', to: ['IJplein'] },
            { time: '16:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:30:00', line: '906', to: ['NDSM'] },
            { time: '16:32:00', line: '902', to: ['IJplein'] },
            { time: '16:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:38:00', line: '902', to: ['IJplein'] },
            { time: '16:40:00', line: '906', to: ['NDSM'] },
            { time: '16:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:44:00', line: '902', to: ['IJplein'] },
            { time: '16:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:50:00', line: '902', to: ['IJplein'] },
            { time: '16:50:00', line: '906', to: ['NDSM'] },
            { time: '16:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '16:56:00', line: '902', to: ['IJplein'] },
            { time: '16:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:00:00', line: '906', to: ['NDSM'] },
            { time: '17:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:02:00', line: '902', to: ['IJplein'] },
            { time: '17:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:08:00', line: '902', to: ['IJplein'] },
            { time: '17:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:10:00', line: '906', to: ['NDSM'] },
            { time: '17:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:14:00', line: '902', to: ['IJplein'] },
            { time: '17:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:20:00', line: '902', to: ['IJplein'] },
            { time: '17:20:00', line: '906', to: ['NDSM'] },
            { time: '17:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:26:00', line: '902', to: ['IJplein'] },
            { time: '17:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:30:00', line: '906', to: ['NDSM'] },
            { time: '17:32:00', line: '902', to: ['IJplein'] },
            { time: '17:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:38:00', line: '902', to: ['IJplein'] },
            { time: '17:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:44:00', line: '902', to: ['IJplein'] },
            { time: '17:45:00', line: '906', to: ['NDSM'] },
            { time: '17:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:50:00', line: '902', to: ['IJplein'] },
            { time: '17:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '17:56:00', line: '902', to: ['IJplein'] },
            { time: '17:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:00:00', line: '906', to: ['NDSM'] },
            { time: '18:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:08:00', line: '902', to: ['IJplein'] },
            { time: '18:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:15:00', line: '906', to: ['NDSM'] },
            { time: '18:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:20:00', line: '902', to: ['IJplein'] },
            { time: '18:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:30:00', line: '906', to: ['NDSM'] },
            { time: '18:32:00', line: '902', to: ['IJplein'] },
            { time: '18:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:44:00', line: '902', to: ['IJplein'] },
            { time: '18:45:00', line: '906', to: ['NDSM'] },
            { time: '18:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '18:56:00', line: '902', to: ['IJplein'] },
            { time: '18:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:00:00', line: '906', to: ['NDSM'] },
            { time: '19:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:08:00', line: '902', to: ['IJplein'] },
            { time: '19:10:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:15:00', line: '906', to: ['NDSM'] },
            { time: '19:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:20:00', line: '902', to: ['IJplein'] },
            { time: '19:22:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:30:00', line: '906', to: ['NDSM'] },
            { time: '19:32:00', line: '902', to: ['IJplein'] },
            { time: '19:34:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:44:00', line: '902', to: ['IJplein'] },
            { time: '19:45:00', line: '906', to: ['NDSM'] },
            { time: '19:46:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '19:56:00', line: '902', to: ['IJplein'] },
            { time: '19:58:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:00:00', line: '906', to: ['NDSM'] },
            { time: '20:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:08:00', line: '902', to: ['IJplein'] },
            { time: '20:12:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:15:00', line: '906', to: ['NDSM'] },
            { time: '20:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:20:00', line: '902', to: ['IJplein'] },
            { time: '20:24:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:30:00', line: '906', to: ['NDSM'] },
            { time: '20:32:00', line: '902', to: ['IJplein'] },
            { time: '20:36:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:44:00', line: '902', to: ['IJplein'] },
            { time: '20:45:00', line: '906', to: ['NDSM'] },
            { time: '20:48:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '20:56:00', line: '902', to: ['IJplein'] },
            { time: '21:00:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:00:00', line: '906', to: ['NDSM'] },
            { time: '21:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:08:00', line: '902', to: ['IJplein'] },
            { time: '21:12:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:15:00', line: '906', to: ['NDSM'] },
            { time: '21:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:20:00', line: '902', to: ['IJplein'] },
            { time: '21:24:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:30:00', line: '906', to: ['NDSM'] },
            { time: '21:32:00', line: '902', to: ['IJplein'] },
            { time: '21:36:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:44:00', line: '902', to: ['IJplein'] },
            { time: '21:45:00', line: '906', to: ['NDSM'] },
            { time: '21:48:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '21:56:00', line: '902', to: ['IJplein'] },
            { time: '22:00:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:08:00', line: '902', to: ['IJplein'] },
            { time: '22:12:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:15:00', line: '906', to: ['NDSM'] },
            { time: '22:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:20:00', line: '902', to: ['IJplein'] },
            { time: '22:24:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:32:00', line: '902', to: ['IJplein'] },
            { time: '22:36:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:44:00', line: '902', to: ['IJplein'] },
            { time: '22:45:00', line: '906', to: ['NDSM'] },
            { time: '22:48:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '22:56:00', line: '902', to: ['IJplein'] },
            { time: '23:00:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:06:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:08:00', line: '902', to: ['IJplein'] },
            { time: '23:12:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:15:00', line: '906', to: ['NDSM'] },
            { time: '23:18:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:20:00', line: '902', to: ['IJplein'] },
            { time: '23:24:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:30:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:32:00', line: '902', to: ['IJplein'] },
            { time: '23:36:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:42:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:44:00', line: '902', to: ['IJplein'] },
            { time: '23:45:00', line: '906', to: ['NDSM'] },
            { time: '23:48:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:54:00', line: '901', to: ['Buiksloterweg'] },
            { time: '23:56:00', line: '902', to: ['IJplein'] },
            { time: '24:00:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:12:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:15:00', line: '906', to: ['NDSM'] },
            { time: '24:24:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:36:00', line: '901', to: ['Buiksloterweg'] },
            { time: '24:45:00', line: '906', to: ['NDSM'] },
            { time: '24:48:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:00:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:12:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:15:00', line: '906', to: ['NDSM'] },
            { time: '25:24:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:36:00', line: '901', to: ['Buiksloterweg'] },
            { time: '25:45:00', line: '906', to: ['NDSM'] },
            { time: '25:48:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:00:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:12:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:24:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:36:00', line: '901', to: ['Buiksloterweg'] },
            { time: '26:48:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:00:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:12:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:24:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:36:00', line: '901', to: ['Buiksloterweg'] },
            { time: '27:48:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:00:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:14:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:26:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:38:00', line: '901', to: ['Buiksloterweg'] },
            { time: '28:50:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:02:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:16:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:28:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:40:00', line: '901', to: ['Buiksloterweg'] },
            { time: '29:52:00', line: '901', to: ['Buiksloterweg'] },
        ],
    },
    Buiksloterweg: {
        monday: [
            { time: '05:58:00', line: '901', to: ['Centraal Station'] },
            { time: '06:04:00', line: '901', to: ['Centraal Station'] },
            { time: '06:10:00', line: '901', to: ['Centraal Station'] },
            { time: '06:16:00', line: '901', to: ['Centraal Station'] },
            { time: '06:22:00', line: '901', to: ['Centraal Station'] },
            { time: '06:28:00', line: '901', to: ['Centraal Station'] },
            { time: '06:34:00', line: '901', to: ['Centraal Station'] },
            { time: '06:40:00', line: '901', to: ['Centraal Station'] },
            { time: '06:46:00', line: '901', to: ['Centraal Station'] },
            { time: '06:52:00', line: '901', to: ['Centraal Station'] },
            { time: '06:58:00', line: '901', to: ['Centraal Station'] },
            { time: '07:04:00', line: '901', to: ['Centraal Station'] },
            { time: '07:10:00', line: '901', to: ['Centraal Station'] },
            { time: '07:16:00', line: '901', to: ['Centraal Station'] },
            { time: '07:22:00', line: '901', to: ['Centraal Station'] },
            { time: '07:28:00', line: '901', to: ['Centraal Station'] },
            { time: '07:32:00', line: '901', to: ['Centraal Station'] },
            { time: '07:36:00', line: '901', to: ['Centraal Station'] },
            { time: '07:40:00', line: '901', to: ['Centraal Station'] },
            { time: '07:44:00', line: '901', to: ['Centraal Station'] },
            { time: '07:48:00', line: '901', to: ['Centraal Station'] },
            { time: '07:52:00', line: '901', to: ['Centraal Station'] },
            { time: '07:56:00', line: '901', to: ['Centraal Station'] },
            { time: '08:00:00', line: '901', to: ['Centraal Station'] },
            { time: '08:04:00', line: '901', to: ['Centraal Station'] },
            { time: '08:08:00', line: '901', to: ['Centraal Station'] },
            { time: '08:12:00', line: '901', to: ['Centraal Station'] },
            { time: '08:16:00', line: '901', to: ['Centraal Station'] },
            { time: '08:20:00', line: '901', to: ['Centraal Station'] },
            { time: '08:24:00', line: '901', to: ['Centraal Station'] },
            { time: '08:28:00', line: '901', to: ['Centraal Station'] },
            { time: '08:32:00', line: '901', to: ['Centraal Station'] },
            { time: '08:36:00', line: '901', to: ['Centraal Station'] },
            { time: '08:40:00', line: '901', to: ['Centraal Station'] },
            { time: '08:44:00', line: '901', to: ['Centraal Station'] },
            { time: '08:48:00', line: '901', to: ['Centraal Station'] },
            { time: '08:52:00', line: '901', to: ['Centraal Station'] },
            { time: '08:56:00', line: '901', to: ['Centraal Station'] },
            { time: '09:00:00', line: '901', to: ['Centraal Station'] },
            { time: '09:04:00', line: '901', to: ['Centraal Station'] },
            { time: '09:08:00', line: '901', to: ['Centraal Station'] },
            { time: '09:12:00', line: '901', to: ['Centraal Station'] },
            { time: '09:16:00', line: '901', to: ['Centraal Station'] },
            { time: '09:20:00', line: '901', to: ['Centraal Station'] },
            { time: '09:24:00', line: '901', to: ['Centraal Station'] },
            { time: '09:28:00', line: '901', to: ['Centraal Station'] },
            { time: '09:32:00', line: '901', to: ['Centraal Station'] },
            { time: '09:36:00', line: '901', to: ['Centraal Station'] },
            { time: '09:40:00', line: '901', to: ['Centraal Station'] },
            { time: '09:44:00', line: '901', to: ['Centraal Station'] },
            { time: '09:48:00', line: '901', to: ['Centraal Station'] },
            { time: '09:52:00', line: '901', to: ['Centraal Station'] },
            { time: '09:56:00', line: '901', to: ['Centraal Station'] },
            { time: '10:00:00', line: '901', to: ['Centraal Station'] },
            { time: '10:04:00', line: '901', to: ['Centraal Station'] },
            { time: '10:08:00', line: '901', to: ['Centraal Station'] },
            { time: '10:12:00', line: '901', to: ['Centraal Station'] },
            { time: '10:16:00', line: '901', to: ['Centraal Station'] },
            { time: '10:20:00', line: '901', to: ['Centraal Station'] },
            { time: '10:24:00', line: '901', to: ['Centraal Station'] },
            { time: '10:28:00', line: '901', to: ['Centraal Station'] },
            { time: '10:32:00', line: '901', to: ['Centraal Station'] },
            { time: '10:36:00', line: '901', to: ['Centraal Station'] },
            { time: '10:40:00', line: '901', to: ['Centraal Station'] },
            { time: '10:44:00', line: '901', to: ['Centraal Station'] },
            { time: '10:48:00', line: '901', to: ['Centraal Station'] },
            { time: '10:52:00', line: '901', to: ['Centraal Station'] },
            { time: '10:56:00', line: '901', to: ['Centraal Station'] },
            { time: '11:00:00', line: '901', to: ['Centraal Station'] },
            { time: '11:04:00', line: '901', to: ['Centraal Station'] },
            { time: '11:08:00', line: '901', to: ['Centraal Station'] },
            { time: '11:12:00', line: '901', to: ['Centraal Station'] },
            { time: '11:16:00', line: '901', to: ['Centraal Station'] },
            { time: '11:20:00', line: '901', to: ['Centraal Station'] },
            { time: '11:24:00', line: '901', to: ['Centraal Station'] },
            { time: '11:28:00', line: '901', to: ['Centraal Station'] },
            { time: '11:32:00', line: '901', to: ['Centraal Station'] },
            { time: '11:36:00', line: '901', to: ['Centraal Station'] },
            { time: '11:40:00', line: '901', to: ['Centraal Station'] },
            { time: '11:44:00', line: '901', to: ['Centraal Station'] },
            { time: '11:48:00', line: '901', to: ['Centraal Station'] },
            { time: '11:52:00', line: '901', to: ['Centraal Station'] },
            { time: '11:56:00', line: '901', to: ['Centraal Station'] },
            { time: '12:00:00', line: '901', to: ['Centraal Station'] },
            { time: '12:04:00', line: '901', to: ['Centraal Station'] },
            { time: '12:08:00', line: '901', to: ['Centraal Station'] },
            { time: '12:12:00', line: '901', to: ['Centraal Station'] },
            { time: '12:16:00', line: '901', to: ['Centraal Station'] },
            { time: '12:20:00', line: '901', to: ['Centraal Station'] },
            { time: '12:24:00', line: '901', to: ['Centraal Station'] },
            { time: '12:28:00', line: '901', to: ['Centraal Station'] },
            { time: '12:32:00', line: '901', to: ['Centraal Station'] },
            { time: '12:36:00', line: '901', to: ['Centraal Station'] },
            { time: '12:40:00', line: '901', to: ['Centraal Station'] },
            { time: '12:44:00', line: '901', to: ['Centraal Station'] },
            { time: '12:48:00', line: '901', to: ['Centraal Station'] },
            { time: '12:52:00', line: '901', to: ['Centraal Station'] },
            { time: '12:56:00', line: '901', to: ['Centraal Station'] },
            { time: '13:00:00', line: '901', to: ['Centraal Station'] },
            { time: '13:04:00', line: '901', to: ['Centraal Station'] },
            { time: '13:08:00', line: '901', to: ['Centraal Station'] },
            { time: '13:12:00', line: '901', to: ['Centraal Station'] },
            { time: '13:16:00', line: '901', to: ['Centraal Station'] },
            { time: '13:20:00', line: '901', to: ['Centraal Station'] },
            { time: '13:24:00', line: '901', to: ['Centraal Station'] },
            { time: '13:28:00', line: '901', to: ['Centraal Station'] },
            { time: '13:32:00', line: '901', to: ['Centraal Station'] },
            { time: '13:36:00', line: '901', to: ['Centraal Station'] },
            { time: '13:40:00', line: '901', to: ['Centraal Station'] },
            { time: '13:44:00', line: '901', to: ['Centraal Station'] },
            { time: '13:48:00', line: '901', to: ['Centraal Station'] },
            { time: '13:52:00', line: '901', to: ['Centraal Station'] },
            { time: '13:56:00', line: '901', to: ['Centraal Station'] },
            { time: '14:00:00', line: '901', to: ['Centraal Station'] },
            { time: '14:04:00', line: '901', to: ['Centraal Station'] },
            { time: '14:08:00', line: '901', to: ['Centraal Station'] },
            { time: '14:12:00', line: '901', to: ['Centraal Station'] },
            { time: '14:16:00', line: '901', to: ['Centraal Station'] },
            { time: '14:20:00', line: '901', to: ['Centraal Station'] },
            { time: '14:24:00', line: '901', to: ['Centraal Station'] },
            { time: '14:28:00', line: '901', to: ['Centraal Station'] },
            { time: '14:32:00', line: '901', to: ['Centraal Station'] },
            { time: '14:36:00', line: '901', to: ['Centraal Station'] },
            { time: '14:40:00', line: '901', to: ['Centraal Station'] },
            { time: '14:44:00', line: '901', to: ['Centraal Station'] },
            { time: '14:48:00', line: '901', to: ['Centraal Station'] },
            { time: '14:52:00', line: '901', to: ['Centraal Station'] },
            { time: '14:56:00', line: '901', to: ['Centraal Station'] },
            { time: '15:00:00', line: '901', to: ['Centraal Station'] },
            { time: '15:04:00', line: '901', to: ['Centraal Station'] },
            { time: '15:08:00', line: '901', to: ['Centraal Station'] },
            { time: '15:12:00', line: '901', to: ['Centraal Station'] },
            { time: '15:16:00', line: '901', to: ['Centraal Station'] },
            { time: '15:20:00', line: '901', to: ['Centraal Station'] },
            { time: '15:24:00', line: '901', to: ['Centraal Station'] },
            { time: '15:28:00', line: '901', to: ['Centraal Station'] },
            { time: '15:32:00', line: '901', to: ['Centraal Station'] },
            { time: '15:36:00', line: '901', to: ['Centraal Station'] },
            { time: '15:40:00', line: '901', to: ['Centraal Station'] },
            { time: '15:44:00', line: '901', to: ['Centraal Station'] },
            { time: '15:48:00', line: '901', to: ['Centraal Station'] },
            { time: '15:52:00', line: '901', to: ['Centraal Station'] },
            { time: '15:56:00', line: '901', to: ['Centraal Station'] },
            { time: '16:00:00', line: '901', to: ['Centraal Station'] },
            { time: '16:04:00', line: '901', to: ['Centraal Station'] },
            { time: '16:08:00', line: '901', to: ['Centraal Station'] },
            { time: '16:12:00', line: '901', to: ['Centraal Station'] },
            { time: '16:16:00', line: '901', to: ['Centraal Station'] },
            { time: '16:20:00', line: '901', to: ['Centraal Station'] },
            { time: '16:24:00', line: '901', to: ['Centraal Station'] },
            { time: '16:28:00', line: '901', to: ['Centraal Station'] },
            { time: '16:32:00', line: '901', to: ['Centraal Station'] },
            { time: '16:36:00', line: '901', to: ['Centraal Station'] },
            { time: '16:40:00', line: '901', to: ['Centraal Station'] },
            { time: '16:44:00', line: '901', to: ['Centraal Station'] },
            { time: '16:48:00', line: '901', to: ['Centraal Station'] },
            { time: '16:52:00', line: '901', to: ['Centraal Station'] },
            { time: '16:56:00', line: '901', to: ['Centraal Station'] },
            { time: '17:00:00', line: '901', to: ['Centraal Station'] },
            { time: '17:04:00', line: '901', to: ['Centraal Station'] },
            { time: '17:08:00', line: '901', to: ['Centraal Station'] },
            { time: '17:12:00', line: '901', to: ['Centraal Station'] },
            { time: '17:16:00', line: '901', to: ['Centraal Station'] },
            { time: '17:20:00', line: '901', to: ['Centraal Station'] },
            { time: '17:24:00', line: '901', to: ['Centraal Station'] },
            { time: '17:28:00', line: '901', to: ['Centraal Station'] },
            { time: '17:32:00', line: '901', to: ['Centraal Station'] },
            { time: '17:36:00', line: '901', to: ['Centraal Station'] },
            { time: '17:40:00', line: '901', to: ['Centraal Station'] },
            { time: '17:44:00', line: '901', to: ['Centraal Station'] },
            { time: '17:48:00', line: '901', to: ['Centraal Station'] },
            { time: '17:52:00', line: '901', to: ['Centraal Station'] },
            { time: '17:56:00', line: '901', to: ['Centraal Station'] },
            { time: '18:00:00', line: '901', to: ['Centraal Station'] },
            { time: '18:04:00', line: '901', to: ['Centraal Station'] },
            { time: '18:08:00', line: '901', to: ['Centraal Station'] },
            { time: '18:12:00', line: '901', to: ['Centraal Station'] },
            { time: '18:16:00', line: '901', to: ['Centraal Station'] },
            { time: '18:20:00', line: '901', to: ['Centraal Station'] },
            { time: '18:24:00', line: '901', to: ['Centraal Station'] },
            { time: '18:28:00', line: '901', to: ['Centraal Station'] },
            { time: '18:32:00', line: '901', to: ['Centraal Station'] },
            { time: '18:36:00', line: '901', to: ['Centraal Station'] },
            { time: '18:40:00', line: '901', to: ['Centraal Station'] },
            { time: '18:44:00', line: '901', to: ['Centraal Station'] },
            { time: '18:48:00', line: '901', to: ['Centraal Station'] },
            { time: '18:52:00', line: '901', to: ['Centraal Station'] },
            { time: '18:56:00', line: '901', to: ['Centraal Station'] },
            { time: '19:00:00', line: '901', to: ['Centraal Station'] },
            { time: '19:04:00', line: '901', to: ['Centraal Station'] },
            { time: '19:08:00', line: '901', to: ['Centraal Station'] },
            { time: '19:12:00', line: '901', to: ['Centraal Station'] },
            { time: '19:16:00', line: '901', to: ['Centraal Station'] },
            { time: '19:20:00', line: '901', to: ['Centraal Station'] },
            { time: '19:24:00', line: '901', to: ['Centraal Station'] },
            { time: '19:28:00', line: '901', to: ['Centraal Station'] },
            { time: '19:32:00', line: '901', to: ['Centraal Station'] },
            { time: '19:36:00', line: '901', to: ['Centraal Station'] },
            { time: '19:40:00', line: '901', to: ['Centraal Station'] },
            { time: '19:44:00', line: '901', to: ['Centraal Station'] },
            { time: '19:48:00', line: '901', to: ['Centraal Station'] },
            { time: '19:52:00', line: '901', to: ['Centraal Station'] },
            { time: '19:56:00', line: '901', to: ['Centraal Station'] },
            { time: '20:04:00', line: '901', to: ['Centraal Station'] },
            { time: '20:10:00', line: '901', to: ['Centraal Station'] },
            { time: '20:16:00', line: '901', to: ['Centraal Station'] },
            { time: '20:22:00', line: '901', to: ['Centraal Station'] },
            { time: '20:28:00', line: '901', to: ['Centraal Station'] },
            { time: '20:34:00', line: '901', to: ['Centraal Station'] },
            { time: '20:40:00', line: '901', to: ['Centraal Station'] },
            { time: '20:46:00', line: '901', to: ['Centraal Station'] },
            { time: '20:52:00', line: '901', to: ['Centraal Station'] },
            { time: '20:58:00', line: '901', to: ['Centraal Station'] },
            { time: '21:04:00', line: '901', to: ['Centraal Station'] },
            { time: '21:10:00', line: '901', to: ['Centraal Station'] },
            { time: '21:16:00', line: '901', to: ['Centraal Station'] },
            { time: '21:22:00', line: '901', to: ['Centraal Station'] },
            { time: '21:28:00', line: '901', to: ['Centraal Station'] },
            { time: '21:34:00', line: '901', to: ['Centraal Station'] },
            { time: '21:40:00', line: '901', to: ['Centraal Station'] },
            { time: '21:46:00', line: '901', to: ['Centraal Station'] },
            { time: '21:52:00', line: '901', to: ['Centraal Station'] },
            { time: '21:58:00', line: '901', to: ['Centraal Station'] },
            { time: '22:04:00', line: '901', to: ['Centraal Station'] },
            { time: '22:10:00', line: '901', to: ['Centraal Station'] },
            { time: '22:16:00', line: '901', to: ['Centraal Station'] },
            { time: '22:22:00', line: '901', to: ['Centraal Station'] },
            { time: '22:28:00', line: '901', to: ['Centraal Station'] },
            { time: '22:34:00', line: '901', to: ['Centraal Station'] },
            { time: '22:40:00', line: '901', to: ['Centraal Station'] },
            { time: '22:46:00', line: '901', to: ['Centraal Station'] },
            { time: '22:52:00', line: '901', to: ['Centraal Station'] },
            { time: '22:58:00', line: '901', to: ['Centraal Station'] },
            { time: '23:04:00', line: '901', to: ['Centraal Station'] },
            { time: '23:10:00', line: '901', to: ['Centraal Station'] },
            { time: '23:16:00', line: '901', to: ['Centraal Station'] },
            { time: '23:22:00', line: '901', to: ['Centraal Station'] },
            { time: '23:28:00', line: '901', to: ['Centraal Station'] },
            { time: '23:34:00', line: '901', to: ['Centraal Station'] },
            { time: '23:40:00', line: '901', to: ['Centraal Station'] },
            { time: '23:46:00', line: '901', to: ['Centraal Station'] },
            { time: '23:52:00', line: '901', to: ['Centraal Station'] },
            { time: '24:04:00', line: '901', to: ['Centraal Station'] },
            { time: '24:16:00', line: '901', to: ['Centraal Station'] },
            { time: '24:28:00', line: '901', to: ['Centraal Station'] },
            { time: '24:40:00', line: '901', to: ['Centraal Station'] },
            { time: '24:52:00', line: '901', to: ['Centraal Station'] },
            { time: '25:04:00', line: '901', to: ['Centraal Station'] },
            { time: '25:16:00', line: '901', to: ['Centraal Station'] },
            { time: '25:28:00', line: '901', to: ['Centraal Station'] },
            { time: '25:40:00', line: '901', to: ['Centraal Station'] },
            { time: '25:52:00', line: '901', to: ['Centraal Station'] },
            { time: '26:04:00', line: '901', to: ['Centraal Station'] },
            { time: '26:16:00', line: '901', to: ['Centraal Station'] },
            { time: '26:28:00', line: '901', to: ['Centraal Station'] },
            { time: '26:40:00', line: '901', to: ['Centraal Station'] },
            { time: '26:52:00', line: '901', to: ['Centraal Station'] },
            { time: '27:04:00', line: '901', to: ['Centraal Station'] },
            { time: '27:18:00', line: '901', to: ['Centraal Station'] },
            { time: '27:30:00', line: '901', to: ['Centraal Station'] },
            { time: '27:42:00', line: '901', to: ['Centraal Station'] },
            { time: '27:54:00', line: '901', to: ['Centraal Station'] },
            { time: '28:06:00', line: '901', to: ['Centraal Station'] },
            { time: '28:20:00', line: '901', to: ['Centraal Station'] },
            { time: '28:32:00', line: '901', to: ['Centraal Station'] },
            { time: '28:44:00', line: '901', to: ['Centraal Station'] },
            { time: '28:56:00', line: '901', to: ['Centraal Station'] },
            { time: '29:08:00', line: '901', to: ['Centraal Station'] },
            { time: '29:22:00', line: '901', to: ['Centraal Station'] },
            { time: '29:34:00', line: '901', to: ['Centraal Station'] },
            { time: '29:46:00', line: '901', to: ['Centraal Station'] },
        ],
        tuesday: [
            { time: '05:58:00', line: '901', to: ['Centraal Station'] },
            { time: '06:04:00', line: '901', to: ['Centraal Station'] },
            { time: '06:10:00', line: '901', to: ['Centraal Station'] },
            { time: '06:16:00', line: '901', to: ['Centraal Station'] },
            { time: '06:22:00', line: '901', to: ['Centraal Station'] },
            { time: '06:28:00', line: '901', to: ['Centraal Station'] },
            { time: '06:34:00', line: '901', to: ['Centraal Station'] },
            { time: '06:40:00', line: '901', to: ['Centraal Station'] },
            { time: '06:46:00', line: '901', to: ['Centraal Station'] },
            { time: '06:52:00', line: '901', to: ['Centraal Station'] },
            { time: '06:58:00', line: '901', to: ['Centraal Station'] },
            { time: '07:04:00', line: '901', to: ['Centraal Station'] },
            { time: '07:10:00', line: '901', to: ['Centraal Station'] },
            { time: '07:16:00', line: '901', to: ['Centraal Station'] },
            { time: '07:22:00', line: '901', to: ['Centraal Station'] },
            { time: '07:28:00', line: '901', to: ['Centraal Station'] },
            { time: '07:32:00', line: '901', to: ['Centraal Station'] },
            { time: '07:36:00', line: '901', to: ['Centraal Station'] },
            { time: '07:40:00', line: '901', to: ['Centraal Station'] },
            { time: '07:44:00', line: '901', to: ['Centraal Station'] },
            { time: '07:48:00', line: '901', to: ['Centraal Station'] },
            { time: '07:52:00', line: '901', to: ['Centraal Station'] },
            { time: '07:56:00', line: '901', to: ['Centraal Station'] },
            { time: '08:00:00', line: '901', to: ['Centraal Station'] },
            { time: '08:04:00', line: '901', to: ['Centraal Station'] },
            { time: '08:08:00', line: '901', to: ['Centraal Station'] },
            { time: '08:12:00', line: '901', to: ['Centraal Station'] },
            { time: '08:16:00', line: '901', to: ['Centraal Station'] },
            { time: '08:20:00', line: '901', to: ['Centraal Station'] },
            { time: '08:24:00', line: '901', to: ['Centraal Station'] },
            { time: '08:28:00', line: '901', to: ['Centraal Station'] },
            { time: '08:32:00', line: '901', to: ['Centraal Station'] },
            { time: '08:36:00', line: '901', to: ['Centraal Station'] },
            { time: '08:40:00', line: '901', to: ['Centraal Station'] },
            { time: '08:44:00', line: '901', to: ['Centraal Station'] },
            { time: '08:48:00', line: '901', to: ['Centraal Station'] },
            { time: '08:52:00', line: '901', to: ['Centraal Station'] },
            { time: '08:56:00', line: '901', to: ['Centraal Station'] },
            { time: '09:00:00', line: '901', to: ['Centraal Station'] },
            { time: '09:04:00', line: '901', to: ['Centraal Station'] },
            { time: '09:08:00', line: '901', to: ['Centraal Station'] },
            { time: '09:12:00', line: '901', to: ['Centraal Station'] },
            { time: '09:16:00', line: '901', to: ['Centraal Station'] },
            { time: '09:20:00', line: '901', to: ['Centraal Station'] },
            { time: '09:24:00', line: '901', to: ['Centraal Station'] },
            { time: '09:28:00', line: '901', to: ['Centraal Station'] },
            { time: '09:32:00', line: '901', to: ['Centraal Station'] },
            { time: '09:36:00', line: '901', to: ['Centraal Station'] },
            { time: '09:40:00', line: '901', to: ['Centraal Station'] },
            { time: '09:44:00', line: '901', to: ['Centraal Station'] },
            { time: '09:48:00', line: '901', to: ['Centraal Station'] },
            { time: '09:52:00', line: '901', to: ['Centraal Station'] },
            { time: '09:56:00', line: '901', to: ['Centraal Station'] },
            { time: '10:00:00', line: '901', to: ['Centraal Station'] },
            { time: '10:04:00', line: '901', to: ['Centraal Station'] },
            { time: '10:08:00', line: '901', to: ['Centraal Station'] },
            { time: '10:12:00', line: '901', to: ['Centraal Station'] },
            { time: '10:16:00', line: '901', to: ['Centraal Station'] },
            { time: '10:20:00', line: '901', to: ['Centraal Station'] },
            { time: '10:24:00', line: '901', to: ['Centraal Station'] },
            { time: '10:28:00', line: '901', to: ['Centraal Station'] },
            { time: '10:32:00', line: '901', to: ['Centraal Station'] },
            { time: '10:36:00', line: '901', to: ['Centraal Station'] },
            { time: '10:40:00', line: '901', to: ['Centraal Station'] },
            { time: '10:44:00', line: '901', to: ['Centraal Station'] },
            { time: '10:48:00', line: '901', to: ['Centraal Station'] },
            { time: '10:52:00', line: '901', to: ['Centraal Station'] },
            { time: '10:56:00', line: '901', to: ['Centraal Station'] },
            { time: '11:00:00', line: '901', to: ['Centraal Station'] },
            { time: '11:04:00', line: '901', to: ['Centraal Station'] },
            { time: '11:08:00', line: '901', to: ['Centraal Station'] },
            { time: '11:12:00', line: '901', to: ['Centraal Station'] },
            { time: '11:16:00', line: '901', to: ['Centraal Station'] },
            { time: '11:20:00', line: '901', to: ['Centraal Station'] },
            { time: '11:24:00', line: '901', to: ['Centraal Station'] },
            { time: '11:28:00', line: '901', to: ['Centraal Station'] },
            { time: '11:32:00', line: '901', to: ['Centraal Station'] },
            { time: '11:36:00', line: '901', to: ['Centraal Station'] },
            { time: '11:40:00', line: '901', to: ['Centraal Station'] },
            { time: '11:44:00', line: '901', to: ['Centraal Station'] },
            { time: '11:48:00', line: '901', to: ['Centraal Station'] },
            { time: '11:52:00', line: '901', to: ['Centraal Station'] },
            { time: '11:56:00', line: '901', to: ['Centraal Station'] },
            { time: '12:00:00', line: '901', to: ['Centraal Station'] },
            { time: '12:04:00', line: '901', to: ['Centraal Station'] },
            { time: '12:08:00', line: '901', to: ['Centraal Station'] },
            { time: '12:12:00', line: '901', to: ['Centraal Station'] },
            { time: '12:16:00', line: '901', to: ['Centraal Station'] },
            { time: '12:20:00', line: '901', to: ['Centraal Station'] },
            { time: '12:24:00', line: '901', to: ['Centraal Station'] },
            { time: '12:28:00', line: '901', to: ['Centraal Station'] },
            { time: '12:32:00', line: '901', to: ['Centraal Station'] },
            { time: '12:36:00', line: '901', to: ['Centraal Station'] },
            { time: '12:40:00', line: '901', to: ['Centraal Station'] },
            { time: '12:44:00', line: '901', to: ['Centraal Station'] },
            { time: '12:48:00', line: '901', to: ['Centraal Station'] },
            { time: '12:52:00', line: '901', to: ['Centraal Station'] },
            { time: '12:56:00', line: '901', to: ['Centraal Station'] },
            { time: '13:00:00', line: '901', to: ['Centraal Station'] },
            { time: '13:04:00', line: '901', to: ['Centraal Station'] },
            { time: '13:08:00', line: '901', to: ['Centraal Station'] },
            { time: '13:12:00', line: '901', to: ['Centraal Station'] },
            { time: '13:16:00', line: '901', to: ['Centraal Station'] },
            { time: '13:20:00', line: '901', to: ['Centraal Station'] },
            { time: '13:24:00', line: '901', to: ['Centraal Station'] },
            { time: '13:28:00', line: '901', to: ['Centraal Station'] },
            { time: '13:32:00', line: '901', to: ['Centraal Station'] },
            { time: '13:36:00', line: '901', to: ['Centraal Station'] },
            { time: '13:40:00', line: '901', to: ['Centraal Station'] },
            { time: '13:44:00', line: '901', to: ['Centraal Station'] },
            { time: '13:48:00', line: '901', to: ['Centraal Station'] },
            { time: '13:52:00', line: '901', to: ['Centraal Station'] },
            { time: '13:56:00', line: '901', to: ['Centraal Station'] },
            { time: '14:00:00', line: '901', to: ['Centraal Station'] },
            { time: '14:04:00', line: '901', to: ['Centraal Station'] },
            { time: '14:08:00', line: '901', to: ['Centraal Station'] },
            { time: '14:12:00', line: '901', to: ['Centraal Station'] },
            { time: '14:16:00', line: '901', to: ['Centraal Station'] },
            { time: '14:20:00', line: '901', to: ['Centraal Station'] },
            { time: '14:24:00', line: '901', to: ['Centraal Station'] },
            { time: '14:28:00', line: '901', to: ['Centraal Station'] },
            { time: '14:32:00', line: '901', to: ['Centraal Station'] },
            { time: '14:36:00', line: '901', to: ['Centraal Station'] },
            { time: '14:40:00', line: '901', to: ['Centraal Station'] },
            { time: '14:44:00', line: '901', to: ['Centraal Station'] },
            { time: '14:48:00', line: '901', to: ['Centraal Station'] },
            { time: '14:52:00', line: '901', to: ['Centraal Station'] },
            { time: '14:56:00', line: '901', to: ['Centraal Station'] },
            { time: '15:00:00', line: '901', to: ['Centraal Station'] },
            { time: '15:04:00', line: '901', to: ['Centraal Station'] },
            { time: '15:08:00', line: '901', to: ['Centraal Station'] },
            { time: '15:12:00', line: '901', to: ['Centraal Station'] },
            { time: '15:16:00', line: '901', to: ['Centraal Station'] },
            { time: '15:20:00', line: '901', to: ['Centraal Station'] },
            { time: '15:24:00', line: '901', to: ['Centraal Station'] },
            { time: '15:28:00', line: '901', to: ['Centraal Station'] },
            { time: '15:32:00', line: '901', to: ['Centraal Station'] },
            { time: '15:36:00', line: '901', to: ['Centraal Station'] },
            { time: '15:40:00', line: '901', to: ['Centraal Station'] },
            { time: '15:44:00', line: '901', to: ['Centraal Station'] },
            { time: '15:48:00', line: '901', to: ['Centraal Station'] },
            { time: '15:52:00', line: '901', to: ['Centraal Station'] },
            { time: '15:56:00', line: '901', to: ['Centraal Station'] },
            { time: '16:00:00', line: '901', to: ['Centraal Station'] },
            { time: '16:04:00', line: '901', to: ['Centraal Station'] },
            { time: '16:08:00', line: '901', to: ['Centraal Station'] },
            { time: '16:12:00', line: '901', to: ['Centraal Station'] },
            { time: '16:16:00', line: '901', to: ['Centraal Station'] },
            { time: '16:20:00', line: '901', to: ['Centraal Station'] },
            { time: '16:24:00', line: '901', to: ['Centraal Station'] },
            { time: '16:28:00', line: '901', to: ['Centraal Station'] },
            { time: '16:32:00', line: '901', to: ['Centraal Station'] },
            { time: '16:36:00', line: '901', to: ['Centraal Station'] },
            { time: '16:40:00', line: '901', to: ['Centraal Station'] },
            { time: '16:44:00', line: '901', to: ['Centraal Station'] },
            { time: '16:48:00', line: '901', to: ['Centraal Station'] },
            { time: '16:52:00', line: '901', to: ['Centraal Station'] },
            { time: '16:56:00', line: '901', to: ['Centraal Station'] },
            { time: '17:00:00', line: '901', to: ['Centraal Station'] },
            { time: '17:04:00', line: '901', to: ['Centraal Station'] },
            { time: '17:08:00', line: '901', to: ['Centraal Station'] },
            { time: '17:12:00', line: '901', to: ['Centraal Station'] },
            { time: '17:16:00', line: '901', to: ['Centraal Station'] },
            { time: '17:20:00', line: '901', to: ['Centraal Station'] },
            { time: '17:24:00', line: '901', to: ['Centraal Station'] },
            { time: '17:28:00', line: '901', to: ['Centraal Station'] },
            { time: '17:32:00', line: '901', to: ['Centraal Station'] },
            { time: '17:36:00', line: '901', to: ['Centraal Station'] },
            { time: '17:40:00', line: '901', to: ['Centraal Station'] },
            { time: '17:44:00', line: '901', to: ['Centraal Station'] },
            { time: '17:48:00', line: '901', to: ['Centraal Station'] },
            { time: '17:52:00', line: '901', to: ['Centraal Station'] },
            { time: '17:56:00', line: '901', to: ['Centraal Station'] },
            { time: '18:00:00', line: '901', to: ['Centraal Station'] },
            { time: '18:04:00', line: '901', to: ['Centraal Station'] },
            { time: '18:08:00', line: '901', to: ['Centraal Station'] },
            { time: '18:12:00', line: '901', to: ['Centraal Station'] },
            { time: '18:16:00', line: '901', to: ['Centraal Station'] },
            { time: '18:20:00', line: '901', to: ['Centraal Station'] },
            { time: '18:24:00', line: '901', to: ['Centraal Station'] },
            { time: '18:28:00', line: '901', to: ['Centraal Station'] },
            { time: '18:32:00', line: '901', to: ['Centraal Station'] },
            { time: '18:36:00', line: '901', to: ['Centraal Station'] },
            { time: '18:40:00', line: '901', to: ['Centraal Station'] },
            { time: '18:44:00', line: '901', to: ['Centraal Station'] },
            { time: '18:48:00', line: '901', to: ['Centraal Station'] },
            { time: '18:52:00', line: '901', to: ['Centraal Station'] },
            { time: '18:56:00', line: '901', to: ['Centraal Station'] },
            { time: '19:00:00', line: '901', to: ['Centraal Station'] },
            { time: '19:04:00', line: '901', to: ['Centraal Station'] },
            { time: '19:08:00', line: '901', to: ['Centraal Station'] },
            { time: '19:12:00', line: '901', to: ['Centraal Station'] },
            { time: '19:16:00', line: '901', to: ['Centraal Station'] },
            { time: '19:20:00', line: '901', to: ['Centraal Station'] },
            { time: '19:24:00', line: '901', to: ['Centraal Station'] },
            { time: '19:28:00', line: '901', to: ['Centraal Station'] },
            { time: '19:32:00', line: '901', to: ['Centraal Station'] },
            { time: '19:36:00', line: '901', to: ['Centraal Station'] },
            { time: '19:40:00', line: '901', to: ['Centraal Station'] },
            { time: '19:44:00', line: '901', to: ['Centraal Station'] },
            { time: '19:48:00', line: '901', to: ['Centraal Station'] },
            { time: '19:52:00', line: '901', to: ['Centraal Station'] },
            { time: '19:56:00', line: '901', to: ['Centraal Station'] },
            { time: '20:04:00', line: '901', to: ['Centraal Station'] },
            { time: '20:10:00', line: '901', to: ['Centraal Station'] },
            { time: '20:16:00', line: '901', to: ['Centraal Station'] },
            { time: '20:22:00', line: '901', to: ['Centraal Station'] },
            { time: '20:28:00', line: '901', to: ['Centraal Station'] },
            { time: '20:34:00', line: '901', to: ['Centraal Station'] },
            { time: '20:40:00', line: '901', to: ['Centraal Station'] },
            { time: '20:46:00', line: '901', to: ['Centraal Station'] },
            { time: '20:52:00', line: '901', to: ['Centraal Station'] },
            { time: '20:58:00', line: '901', to: ['Centraal Station'] },
            { time: '21:04:00', line: '901', to: ['Centraal Station'] },
            { time: '21:10:00', line: '901', to: ['Centraal Station'] },
            { time: '21:16:00', line: '901', to: ['Centraal Station'] },
            { time: '21:22:00', line: '901', to: ['Centraal Station'] },
            { time: '21:28:00', line: '901', to: ['Centraal Station'] },
            { time: '21:34:00', line: '901', to: ['Centraal Station'] },
            { time: '21:40:00', line: '901', to: ['Centraal Station'] },
            { time: '21:46:00', line: '901', to: ['Centraal Station'] },
            { time: '21:52:00', line: '901', to: ['Centraal Station'] },
            { time: '21:58:00', line: '901', to: ['Centraal Station'] },
            { time: '22:04:00', line: '901', to: ['Centraal Station'] },
            { time: '22:10:00', line: '901', to: ['Centraal Station'] },
            { time: '22:16:00', line: '901', to: ['Centraal Station'] },
            { time: '22:22:00', line: '901', to: ['Centraal Station'] },
            { time: '22:28:00', line: '901', to: ['Centraal Station'] },
            { time: '22:34:00', line: '901', to: ['Centraal Station'] },
            { time: '22:40:00', line: '901', to: ['Centraal Station'] },
            { time: '22:46:00', line: '901', to: ['Centraal Station'] },
            { time: '22:52:00', line: '901', to: ['Centraal Station'] },
            { time: '22:58:00', line: '901', to: ['Centraal Station'] },
            { time: '23:04:00', line: '901', to: ['Centraal Station'] },
            { time: '23:10:00', line: '901', to: ['Centraal Station'] },
            { time: '23:16:00', line: '901', to: ['Centraal Station'] },
            { time: '23:22:00', line: '901', to: ['Centraal Station'] },
            { time: '23:28:00', line: '901', to: ['Centraal Station'] },
            { time: '23:34:00', line: '901', to: ['Centraal Station'] },
            { time: '23:40:00', line: '901', to: ['Centraal Station'] },
            { time: '23:46:00', line: '901', to: ['Centraal Station'] },
            { time: '23:52:00', line: '901', to: ['Centraal Station'] },
            { time: '24:04:00', line: '901', to: ['Centraal Station'] },
            { time: '24:16:00', line: '901', to: ['Centraal Station'] },
            { time: '24:28:00', line: '901', to: ['Centraal Station'] },
            { time: '24:40:00', line: '901', to: ['Centraal Station'] },
            { time: '24:52:00', line: '901', to: ['Centraal Station'] },
            { time: '25:04:00', line: '901', to: ['Centraal Station'] },
            { time: '25:16:00', line: '901', to: ['Centraal Station'] },
            { time: '25:28:00', line: '901', to: ['Centraal Station'] },
            { time: '25:40:00', line: '901', to: ['Centraal Station'] },
            { time: '25:52:00', line: '901', to: ['Centraal Station'] },
            { time: '26:04:00', line: '901', to: ['Centraal Station'] },
            { time: '26:16:00', line: '901', to: ['Centraal Station'] },
            { time: '26:28:00', line: '901', to: ['Centraal Station'] },
            { time: '26:40:00', line: '901', to: ['Centraal Station'] },
            { time: '26:52:00', line: '901', to: ['Centraal Station'] },
            { time: '27:04:00', line: '901', to: ['Centraal Station'] },
            { time: '27:18:00', line: '901', to: ['Centraal Station'] },
            { time: '27:30:00', line: '901', to: ['Centraal Station'] },
            { time: '27:42:00', line: '901', to: ['Centraal Station'] },
            { time: '27:54:00', line: '901', to: ['Centraal Station'] },
            { time: '28:06:00', line: '901', to: ['Centraal Station'] },
            { time: '28:20:00', line: '901', to: ['Centraal Station'] },
            { time: '28:32:00', line: '901', to: ['Centraal Station'] },
            { time: '28:44:00', line: '901', to: ['Centraal Station'] },
            { time: '28:56:00', line: '901', to: ['Centraal Station'] },
            { time: '29:08:00', line: '901', to: ['Centraal Station'] },
            { time: '29:22:00', line: '901', to: ['Centraal Station'] },
            { time: '29:34:00', line: '901', to: ['Centraal Station'] },
            { time: '29:46:00', line: '901', to: ['Centraal Station'] },
        ],
        wednesday: [
            { time: '05:58:00', line: '901', to: ['Centraal Station'] },
            { time: '06:04:00', line: '901', to: ['Centraal Station'] },
            { time: '06:10:00', line: '901', to: ['Centraal Station'] },
            { time: '06:16:00', line: '901', to: ['Centraal Station'] },
            { time: '06:22:00', line: '901', to: ['Centraal Station'] },
            { time: '06:28:00', line: '901', to: ['Centraal Station'] },
            { time: '06:34:00', line: '901', to: ['Centraal Station'] },
            { time: '06:40:00', line: '901', to: ['Centraal Station'] },
            { time: '06:46:00', line: '901', to: ['Centraal Station'] },
            { time: '06:52:00', line: '901', to: ['Centraal Station'] },
            { time: '06:58:00', line: '901', to: ['Centraal Station'] },
            { time: '07:04:00', line: '901', to: ['Centraal Station'] },
            { time: '07:10:00', line: '901', to: ['Centraal Station'] },
            { time: '07:16:00', line: '901', to: ['Centraal Station'] },
            { time: '07:22:00', line: '901', to: ['Centraal Station'] },
            { time: '07:28:00', line: '901', to: ['Centraal Station'] },
            { time: '07:32:00', line: '901', to: ['Centraal Station'] },
            { time: '07:36:00', line: '901', to: ['Centraal Station'] },
            { time: '07:40:00', line: '901', to: ['Centraal Station'] },
            { time: '07:44:00', line: '901', to: ['Centraal Station'] },
            { time: '07:48:00', line: '901', to: ['Centraal Station'] },
            { time: '07:52:00', line: '901', to: ['Centraal Station'] },
            { time: '07:56:00', line: '901', to: ['Centraal Station'] },
            { time: '08:00:00', line: '901', to: ['Centraal Station'] },
            { time: '08:04:00', line: '901', to: ['Centraal Station'] },
            { time: '08:08:00', line: '901', to: ['Centraal Station'] },
            { time: '08:12:00', line: '901', to: ['Centraal Station'] },
            { time: '08:16:00', line: '901', to: ['Centraal Station'] },
            { time: '08:20:00', line: '901', to: ['Centraal Station'] },
            { time: '08:24:00', line: '901', to: ['Centraal Station'] },
            { time: '08:28:00', line: '901', to: ['Centraal Station'] },
            { time: '08:32:00', line: '901', to: ['Centraal Station'] },
            { time: '08:36:00', line: '901', to: ['Centraal Station'] },
            { time: '08:40:00', line: '901', to: ['Centraal Station'] },
            { time: '08:44:00', line: '901', to: ['Centraal Station'] },
            { time: '08:48:00', line: '901', to: ['Centraal Station'] },
            { time: '08:52:00', line: '901', to: ['Centraal Station'] },
            { time: '08:56:00', line: '901', to: ['Centraal Station'] },
            { time: '09:00:00', line: '901', to: ['Centraal Station'] },
            { time: '09:04:00', line: '901', to: ['Centraal Station'] },
            { time: '09:08:00', line: '901', to: ['Centraal Station'] },
            { time: '09:12:00', line: '901', to: ['Centraal Station'] },
            { time: '09:16:00', line: '901', to: ['Centraal Station'] },
            { time: '09:20:00', line: '901', to: ['Centraal Station'] },
            { time: '09:24:00', line: '901', to: ['Centraal Station'] },
            { time: '09:28:00', line: '901', to: ['Centraal Station'] },
            { time: '09:32:00', line: '901', to: ['Centraal Station'] },
            { time: '09:36:00', line: '901', to: ['Centraal Station'] },
            { time: '09:40:00', line: '901', to: ['Centraal Station'] },
            { time: '09:44:00', line: '901', to: ['Centraal Station'] },
            { time: '09:48:00', line: '901', to: ['Centraal Station'] },
            { time: '09:52:00', line: '901', to: ['Centraal Station'] },
            { time: '09:56:00', line: '901', to: ['Centraal Station'] },
            { time: '10:00:00', line: '901', to: ['Centraal Station'] },
            { time: '10:04:00', line: '901', to: ['Centraal Station'] },
            { time: '10:08:00', line: '901', to: ['Centraal Station'] },
            { time: '10:12:00', line: '901', to: ['Centraal Station'] },
            { time: '10:16:00', line: '901', to: ['Centraal Station'] },
            { time: '10:20:00', line: '901', to: ['Centraal Station'] },
            { time: '10:24:00', line: '901', to: ['Centraal Station'] },
            { time: '10:28:00', line: '901', to: ['Centraal Station'] },
            { time: '10:32:00', line: '901', to: ['Centraal Station'] },
            { time: '10:36:00', line: '901', to: ['Centraal Station'] },
            { time: '10:40:00', line: '901', to: ['Centraal Station'] },
            { time: '10:44:00', line: '901', to: ['Centraal Station'] },
            { time: '10:48:00', line: '901', to: ['Centraal Station'] },
            { time: '10:52:00', line: '901', to: ['Centraal Station'] },
            { time: '10:56:00', line: '901', to: ['Centraal Station'] },
            { time: '11:00:00', line: '901', to: ['Centraal Station'] },
            { time: '11:04:00', line: '901', to: ['Centraal Station'] },
            { time: '11:08:00', line: '901', to: ['Centraal Station'] },
            { time: '11:12:00', line: '901', to: ['Centraal Station'] },
            { time: '11:16:00', line: '901', to: ['Centraal Station'] },
            { time: '11:20:00', line: '901', to: ['Centraal Station'] },
            { time: '11:24:00', line: '901', to: ['Centraal Station'] },
            { time: '11:28:00', line: '901', to: ['Centraal Station'] },
            { time: '11:32:00', line: '901', to: ['Centraal Station'] },
            { time: '11:36:00', line: '901', to: ['Centraal Station'] },
            { time: '11:40:00', line: '901', to: ['Centraal Station'] },
            { time: '11:44:00', line: '901', to: ['Centraal Station'] },
            { time: '11:48:00', line: '901', to: ['Centraal Station'] },
            { time: '11:52:00', line: '901', to: ['Centraal Station'] },
            { time: '11:56:00', line: '901', to: ['Centraal Station'] },
            { time: '12:00:00', line: '901', to: ['Centraal Station'] },
            { time: '12:04:00', line: '901', to: ['Centraal Station'] },
            { time: '12:08:00', line: '901', to: ['Centraal Station'] },
            { time: '12:12:00', line: '901', to: ['Centraal Station'] },
            { time: '12:16:00', line: '901', to: ['Centraal Station'] },
            { time: '12:20:00', line: '901', to: ['Centraal Station'] },
            { time: '12:24:00', line: '901', to: ['Centraal Station'] },
            { time: '12:28:00', line: '901', to: ['Centraal Station'] },
            { time: '12:32:00', line: '901', to: ['Centraal Station'] },
            { time: '12:36:00', line: '901', to: ['Centraal Station'] },
            { time: '12:40:00', line: '901', to: ['Centraal Station'] },
            { time: '12:44:00', line: '901', to: ['Centraal Station'] },
            { time: '12:48:00', line: '901', to: ['Centraal Station'] },
            { time: '12:52:00', line: '901', to: ['Centraal Station'] },
            { time: '12:56:00', line: '901', to: ['Centraal Station'] },
            { time: '13:00:00', line: '901', to: ['Centraal Station'] },
            { time: '13:04:00', line: '901', to: ['Centraal Station'] },
            { time: '13:08:00', line: '901', to: ['Centraal Station'] },
            { time: '13:12:00', line: '901', to: ['Centraal Station'] },
            { time: '13:16:00', line: '901', to: ['Centraal Station'] },
            { time: '13:20:00', line: '901', to: ['Centraal Station'] },
            { time: '13:24:00', line: '901', to: ['Centraal Station'] },
            { time: '13:28:00', line: '901', to: ['Centraal Station'] },
            { time: '13:32:00', line: '901', to: ['Centraal Station'] },
            { time: '13:36:00', line: '901', to: ['Centraal Station'] },
            { time: '13:40:00', line: '901', to: ['Centraal Station'] },
            { time: '13:44:00', line: '901', to: ['Centraal Station'] },
            { time: '13:48:00', line: '901', to: ['Centraal Station'] },
            { time: '13:52:00', line: '901', to: ['Centraal Station'] },
            { time: '13:56:00', line: '901', to: ['Centraal Station'] },
            { time: '14:00:00', line: '901', to: ['Centraal Station'] },
            { time: '14:04:00', line: '901', to: ['Centraal Station'] },
            { time: '14:08:00', line: '901', to: ['Centraal Station'] },
            { time: '14:12:00', line: '901', to: ['Centraal Station'] },
            { time: '14:16:00', line: '901', to: ['Centraal Station'] },
            { time: '14:20:00', line: '901', to: ['Centraal Station'] },
            { time: '14:24:00', line: '901', to: ['Centraal Station'] },
            { time: '14:28:00', line: '901', to: ['Centraal Station'] },
            { time: '14:32:00', line: '901', to: ['Centraal Station'] },
            { time: '14:36:00', line: '901', to: ['Centraal Station'] },
            { time: '14:40:00', line: '901', to: ['Centraal Station'] },
            { time: '14:44:00', line: '901', to: ['Centraal Station'] },
            { time: '14:48:00', line: '901', to: ['Centraal Station'] },
            { time: '14:52:00', line: '901', to: ['Centraal Station'] },
            { time: '14:56:00', line: '901', to: ['Centraal Station'] },
            { time: '15:00:00', line: '901', to: ['Centraal Station'] },
            { time: '15:04:00', line: '901', to: ['Centraal Station'] },
            { time: '15:08:00', line: '901', to: ['Centraal Station'] },
            { time: '15:12:00', line: '901', to: ['Centraal Station'] },
            { time: '15:16:00', line: '901', to: ['Centraal Station'] },
            { time: '15:20:00', line: '901', to: ['Centraal Station'] },
            { time: '15:24:00', line: '901', to: ['Centraal Station'] },
            { time: '15:28:00', line: '901', to: ['Centraal Station'] },
            { time: '15:32:00', line: '901', to: ['Centraal Station'] },
            { time: '15:36:00', line: '901', to: ['Centraal Station'] },
            { time: '15:40:00', line: '901', to: ['Centraal Station'] },
            { time: '15:44:00', line: '901', to: ['Centraal Station'] },
            { time: '15:48:00', line: '901', to: ['Centraal Station'] },
            { time: '15:52:00', line: '901', to: ['Centraal Station'] },
            { time: '15:56:00', line: '901', to: ['Centraal Station'] },
            { time: '16:00:00', line: '901', to: ['Centraal Station'] },
            { time: '16:04:00', line: '901', to: ['Centraal Station'] },
            { time: '16:08:00', line: '901', to: ['Centraal Station'] },
            { time: '16:12:00', line: '901', to: ['Centraal Station'] },
            { time: '16:16:00', line: '901', to: ['Centraal Station'] },
            { time: '16:20:00', line: '901', to: ['Centraal Station'] },
            { time: '16:24:00', line: '901', to: ['Centraal Station'] },
            { time: '16:28:00', line: '901', to: ['Centraal Station'] },
            { time: '16:32:00', line: '901', to: ['Centraal Station'] },
            { time: '16:36:00', line: '901', to: ['Centraal Station'] },
            { time: '16:40:00', line: '901', to: ['Centraal Station'] },
            { time: '16:44:00', line: '901', to: ['Centraal Station'] },
            { time: '16:48:00', line: '901', to: ['Centraal Station'] },
            { time: '16:52:00', line: '901', to: ['Centraal Station'] },
            { time: '16:56:00', line: '901', to: ['Centraal Station'] },
            { time: '17:00:00', line: '901', to: ['Centraal Station'] },
            { time: '17:04:00', line: '901', to: ['Centraal Station'] },
            { time: '17:08:00', line: '901', to: ['Centraal Station'] },
            { time: '17:12:00', line: '901', to: ['Centraal Station'] },
            { time: '17:16:00', line: '901', to: ['Centraal Station'] },
            { time: '17:20:00', line: '901', to: ['Centraal Station'] },
            { time: '17:24:00', line: '901', to: ['Centraal Station'] },
            { time: '17:28:00', line: '901', to: ['Centraal Station'] },
            { time: '17:32:00', line: '901', to: ['Centraal Station'] },
            { time: '17:36:00', line: '901', to: ['Centraal Station'] },
            { time: '17:40:00', line: '901', to: ['Centraal Station'] },
            { time: '17:44:00', line: '901', to: ['Centraal Station'] },
            { time: '17:48:00', line: '901', to: ['Centraal Station'] },
            { time: '17:52:00', line: '901', to: ['Centraal Station'] },
            { time: '17:56:00', line: '901', to: ['Centraal Station'] },
            { time: '18:00:00', line: '901', to: ['Centraal Station'] },
            { time: '18:04:00', line: '901', to: ['Centraal Station'] },
            { time: '18:08:00', line: '901', to: ['Centraal Station'] },
            { time: '18:12:00', line: '901', to: ['Centraal Station'] },
            { time: '18:16:00', line: '901', to: ['Centraal Station'] },
            { time: '18:20:00', line: '901', to: ['Centraal Station'] },
            { time: '18:24:00', line: '901', to: ['Centraal Station'] },
            { time: '18:28:00', line: '901', to: ['Centraal Station'] },
            { time: '18:32:00', line: '901', to: ['Centraal Station'] },
            { time: '18:36:00', line: '901', to: ['Centraal Station'] },
            { time: '18:40:00', line: '901', to: ['Centraal Station'] },
            { time: '18:44:00', line: '901', to: ['Centraal Station'] },
            { time: '18:48:00', line: '901', to: ['Centraal Station'] },
            { time: '18:52:00', line: '901', to: ['Centraal Station'] },
            { time: '18:56:00', line: '901', to: ['Centraal Station'] },
            { time: '19:00:00', line: '901', to: ['Centraal Station'] },
            { time: '19:04:00', line: '901', to: ['Centraal Station'] },
            { time: '19:08:00', line: '901', to: ['Centraal Station'] },
            { time: '19:12:00', line: '901', to: ['Centraal Station'] },
            { time: '19:16:00', line: '901', to: ['Centraal Station'] },
            { time: '19:20:00', line: '901', to: ['Centraal Station'] },
            { time: '19:24:00', line: '901', to: ['Centraal Station'] },
            { time: '19:28:00', line: '901', to: ['Centraal Station'] },
            { time: '19:32:00', line: '901', to: ['Centraal Station'] },
            { time: '19:36:00', line: '901', to: ['Centraal Station'] },
            { time: '19:40:00', line: '901', to: ['Centraal Station'] },
            { time: '19:44:00', line: '901', to: ['Centraal Station'] },
            { time: '19:48:00', line: '901', to: ['Centraal Station'] },
            { time: '19:52:00', line: '901', to: ['Centraal Station'] },
            { time: '19:56:00', line: '901', to: ['Centraal Station'] },
            { time: '20:04:00', line: '901', to: ['Centraal Station'] },
            { time: '20:10:00', line: '901', to: ['Centraal Station'] },
            { time: '20:16:00', line: '901', to: ['Centraal Station'] },
            { time: '20:22:00', line: '901', to: ['Centraal Station'] },
            { time: '20:28:00', line: '901', to: ['Centraal Station'] },
            { time: '20:34:00', line: '901', to: ['Centraal Station'] },
            { time: '20:40:00', line: '901', to: ['Centraal Station'] },
            { time: '20:46:00', line: '901', to: ['Centraal Station'] },
            { time: '20:52:00', line: '901', to: ['Centraal Station'] },
            { time: '20:58:00', line: '901', to: ['Centraal Station'] },
            { time: '21:04:00', line: '901', to: ['Centraal Station'] },
            { time: '21:10:00', line: '901', to: ['Centraal Station'] },
            { time: '21:16:00', line: '901', to: ['Centraal Station'] },
            { time: '21:22:00', line: '901', to: ['Centraal Station'] },
            { time: '21:28:00', line: '901', to: ['Centraal Station'] },
            { time: '21:34:00', line: '901', to: ['Centraal Station'] },
            { time: '21:40:00', line: '901', to: ['Centraal Station'] },
            { time: '21:46:00', line: '901', to: ['Centraal Station'] },
            { time: '21:52:00', line: '901', to: ['Centraal Station'] },
            { time: '21:58:00', line: '901', to: ['Centraal Station'] },
            { time: '22:04:00', line: '901', to: ['Centraal Station'] },
            { time: '22:10:00', line: '901', to: ['Centraal Station'] },
            { time: '22:16:00', line: '901', to: ['Centraal Station'] },
            { time: '22:22:00', line: '901', to: ['Centraal Station'] },
            { time: '22:28:00', line: '901', to: ['Centraal Station'] },
            { time: '22:34:00', line: '901', to: ['Centraal Station'] },
            { time: '22:40:00', line: '901', to: ['Centraal Station'] },
            { time: '22:46:00', line: '901', to: ['Centraal Station'] },
            { time: '22:52:00', line: '901', to: ['Centraal Station'] },
            { time: '22:58:00', line: '901', to: ['Centraal Station'] },
            { time: '23:04:00', line: '901', to: ['Centraal Station'] },
            { time: '23:10:00', line: '901', to: ['Centraal Station'] },
            { time: '23:16:00', line: '901', to: ['Centraal Station'] },
            { time: '23:22:00', line: '901', to: ['Centraal Station'] },
            { time: '23:28:00', line: '901', to: ['Centraal Station'] },
            { time: '23:34:00', line: '901', to: ['Centraal Station'] },
            { time: '23:40:00', line: '901', to: ['Centraal Station'] },
            { time: '23:46:00', line: '901', to: ['Centraal Station'] },
            { time: '23:52:00', line: '901', to: ['Centraal Station'] },
            { time: '24:04:00', line: '901', to: ['Centraal Station'] },
            { time: '24:16:00', line: '901', to: ['Centraal Station'] },
            { time: '24:28:00', line: '901', to: ['Centraal Station'] },
            { time: '24:40:00', line: '901', to: ['Centraal Station'] },
            { time: '24:52:00', line: '901', to: ['Centraal Station'] },
            { time: '25:04:00', line: '901', to: ['Centraal Station'] },
            { time: '25:16:00', line: '901', to: ['Centraal Station'] },
            { time: '25:28:00', line: '901', to: ['Centraal Station'] },
            { time: '25:40:00', line: '901', to: ['Centraal Station'] },
            { time: '25:52:00', line: '901', to: ['Centraal Station'] },
            { time: '26:04:00', line: '901', to: ['Centraal Station'] },
            { time: '26:16:00', line: '901', to: ['Centraal Station'] },
            { time: '26:28:00', line: '901', to: ['Centraal Station'] },
            { time: '26:40:00', line: '901', to: ['Centraal Station'] },
            { time: '26:52:00', line: '901', to: ['Centraal Station'] },
            { time: '27:04:00', line: '901', to: ['Centraal Station'] },
            { time: '27:18:00', line: '901', to: ['Centraal Station'] },
            { time: '27:30:00', line: '901', to: ['Centraal Station'] },
            { time: '27:42:00', line: '901', to: ['Centraal Station'] },
            { time: '27:54:00', line: '901', to: ['Centraal Station'] },
            { time: '28:06:00', line: '901', to: ['Centraal Station'] },
            { time: '28:20:00', line: '901', to: ['Centraal Station'] },
            { time: '28:32:00', line: '901', to: ['Centraal Station'] },
            { time: '28:44:00', line: '901', to: ['Centraal Station'] },
            { time: '28:56:00', line: '901', to: ['Centraal Station'] },
            { time: '29:08:00', line: '901', to: ['Centraal Station'] },
            { time: '29:22:00', line: '901', to: ['Centraal Station'] },
            { time: '29:34:00', line: '901', to: ['Centraal Station'] },
            { time: '29:46:00', line: '901', to: ['Centraal Station'] },
        ],
        thursday: [
            { time: '05:58:00', line: '901', to: ['Centraal Station'] },
            { time: '06:04:00', line: '901', to: ['Centraal Station'] },
            { time: '06:10:00', line: '901', to: ['Centraal Station'] },
            { time: '06:16:00', line: '901', to: ['Centraal Station'] },
            { time: '06:22:00', line: '901', to: ['Centraal Station'] },
            { time: '06:28:00', line: '901', to: ['Centraal Station'] },
            { time: '06:34:00', line: '901', to: ['Centraal Station'] },
            { time: '06:40:00', line: '901', to: ['Centraal Station'] },
            { time: '06:46:00', line: '901', to: ['Centraal Station'] },
            { time: '06:52:00', line: '901', to: ['Centraal Station'] },
            { time: '06:58:00', line: '901', to: ['Centraal Station'] },
            { time: '07:04:00', line: '901', to: ['Centraal Station'] },
            { time: '07:10:00', line: '901', to: ['Centraal Station'] },
            { time: '07:16:00', line: '901', to: ['Centraal Station'] },
            { time: '07:22:00', line: '901', to: ['Centraal Station'] },
            { time: '07:28:00', line: '901', to: ['Centraal Station'] },
            { time: '07:32:00', line: '901', to: ['Centraal Station'] },
            { time: '07:36:00', line: '901', to: ['Centraal Station'] },
            { time: '07:40:00', line: '901', to: ['Centraal Station'] },
            { time: '07:44:00', line: '901', to: ['Centraal Station'] },
            { time: '07:48:00', line: '901', to: ['Centraal Station'] },
            { time: '07:52:00', line: '901', to: ['Centraal Station'] },
            { time: '07:56:00', line: '901', to: ['Centraal Station'] },
            { time: '08:00:00', line: '901', to: ['Centraal Station'] },
            { time: '08:04:00', line: '901', to: ['Centraal Station'] },
            { time: '08:08:00', line: '901', to: ['Centraal Station'] },
            { time: '08:12:00', line: '901', to: ['Centraal Station'] },
            { time: '08:16:00', line: '901', to: ['Centraal Station'] },
            { time: '08:20:00', line: '901', to: ['Centraal Station'] },
            { time: '08:24:00', line: '901', to: ['Centraal Station'] },
            { time: '08:28:00', line: '901', to: ['Centraal Station'] },
            { time: '08:32:00', line: '901', to: ['Centraal Station'] },
            { time: '08:36:00', line: '901', to: ['Centraal Station'] },
            { time: '08:40:00', line: '901', to: ['Centraal Station'] },
            { time: '08:44:00', line: '901', to: ['Centraal Station'] },
            { time: '08:48:00', line: '901', to: ['Centraal Station'] },
            { time: '08:52:00', line: '901', to: ['Centraal Station'] },
            { time: '08:56:00', line: '901', to: ['Centraal Station'] },
            { time: '09:00:00', line: '901', to: ['Centraal Station'] },
            { time: '09:04:00', line: '901', to: ['Centraal Station'] },
            { time: '09:08:00', line: '901', to: ['Centraal Station'] },
            { time: '09:12:00', line: '901', to: ['Centraal Station'] },
            { time: '09:16:00', line: '901', to: ['Centraal Station'] },
            { time: '09:20:00', line: '901', to: ['Centraal Station'] },
            { time: '09:24:00', line: '901', to: ['Centraal Station'] },
            { time: '09:28:00', line: '901', to: ['Centraal Station'] },
            { time: '09:32:00', line: '901', to: ['Centraal Station'] },
            { time: '09:36:00', line: '901', to: ['Centraal Station'] },
            { time: '09:40:00', line: '901', to: ['Centraal Station'] },
            { time: '09:44:00', line: '901', to: ['Centraal Station'] },
            { time: '09:48:00', line: '901', to: ['Centraal Station'] },
            { time: '09:52:00', line: '901', to: ['Centraal Station'] },
            { time: '09:56:00', line: '901', to: ['Centraal Station'] },
            { time: '10:00:00', line: '901', to: ['Centraal Station'] },
            { time: '10:04:00', line: '901', to: ['Centraal Station'] },
            { time: '10:08:00', line: '901', to: ['Centraal Station'] },
            { time: '10:12:00', line: '901', to: ['Centraal Station'] },
            { time: '10:16:00', line: '901', to: ['Centraal Station'] },
            { time: '10:20:00', line: '901', to: ['Centraal Station'] },
            { time: '10:24:00', line: '901', to: ['Centraal Station'] },
            { time: '10:28:00', line: '901', to: ['Centraal Station'] },
            { time: '10:32:00', line: '901', to: ['Centraal Station'] },
            { time: '10:36:00', line: '901', to: ['Centraal Station'] },
            { time: '10:40:00', line: '901', to: ['Centraal Station'] },
            { time: '10:44:00', line: '901', to: ['Centraal Station'] },
            { time: '10:48:00', line: '901', to: ['Centraal Station'] },
            { time: '10:52:00', line: '901', to: ['Centraal Station'] },
            { time: '10:56:00', line: '901', to: ['Centraal Station'] },
            { time: '11:00:00', line: '901', to: ['Centraal Station'] },
            { time: '11:04:00', line: '901', to: ['Centraal Station'] },
            { time: '11:08:00', line: '901', to: ['Centraal Station'] },
            { time: '11:12:00', line: '901', to: ['Centraal Station'] },
            { time: '11:16:00', line: '901', to: ['Centraal Station'] },
            { time: '11:20:00', line: '901', to: ['Centraal Station'] },
            { time: '11:24:00', line: '901', to: ['Centraal Station'] },
            { time: '11:28:00', line: '901', to: ['Centraal Station'] },
            { time: '11:32:00', line: '901', to: ['Centraal Station'] },
            { time: '11:36:00', line: '901', to: ['Centraal Station'] },
            { time: '11:40:00', line: '901', to: ['Centraal Station'] },
            { time: '11:44:00', line: '901', to: ['Centraal Station'] },
            { time: '11:48:00', line: '901', to: ['Centraal Station'] },
            { time: '11:52:00', line: '901', to: ['Centraal Station'] },
            { time: '11:56:00', line: '901', to: ['Centraal Station'] },
            { time: '12:00:00', line: '901', to: ['Centraal Station'] },
            { time: '12:04:00', line: '901', to: ['Centraal Station'] },
            { time: '12:08:00', line: '901', to: ['Centraal Station'] },
            { time: '12:12:00', line: '901', to: ['Centraal Station'] },
            { time: '12:16:00', line: '901', to: ['Centraal Station'] },
            { time: '12:20:00', line: '901', to: ['Centraal Station'] },
            { time: '12:24:00', line: '901', to: ['Centraal Station'] },
            { time: '12:28:00', line: '901', to: ['Centraal Station'] },
            { time: '12:32:00', line: '901', to: ['Centraal Station'] },
            { time: '12:36:00', line: '901', to: ['Centraal Station'] },
            { time: '12:40:00', line: '901', to: ['Centraal Station'] },
            { time: '12:44:00', line: '901', to: ['Centraal Station'] },
            { time: '12:48:00', line: '901', to: ['Centraal Station'] },
            { time: '12:52:00', line: '901', to: ['Centraal Station'] },
            { time: '12:56:00', line: '901', to: ['Centraal Station'] },
            { time: '13:00:00', line: '901', to: ['Centraal Station'] },
            { time: '13:04:00', line: '901', to: ['Centraal Station'] },
            { time: '13:08:00', line: '901', to: ['Centraal Station'] },
            { time: '13:12:00', line: '901', to: ['Centraal Station'] },
            { time: '13:16:00', line: '901', to: ['Centraal Station'] },
            { time: '13:20:00', line: '901', to: ['Centraal Station'] },
            { time: '13:24:00', line: '901', to: ['Centraal Station'] },
            { time: '13:28:00', line: '901', to: ['Centraal Station'] },
            { time: '13:32:00', line: '901', to: ['Centraal Station'] },
            { time: '13:36:00', line: '901', to: ['Centraal Station'] },
            { time: '13:40:00', line: '901', to: ['Centraal Station'] },
            { time: '13:44:00', line: '901', to: ['Centraal Station'] },
            { time: '13:48:00', line: '901', to: ['Centraal Station'] },
            { time: '13:52:00', line: '901', to: ['Centraal Station'] },
            { time: '13:56:00', line: '901', to: ['Centraal Station'] },
            { time: '14:00:00', line: '901', to: ['Centraal Station'] },
            { time: '14:04:00', line: '901', to: ['Centraal Station'] },
            { time: '14:08:00', line: '901', to: ['Centraal Station'] },
            { time: '14:12:00', line: '901', to: ['Centraal Station'] },
            { time: '14:16:00', line: '901', to: ['Centraal Station'] },
            { time: '14:20:00', line: '901', to: ['Centraal Station'] },
            { time: '14:24:00', line: '901', to: ['Centraal Station'] },
            { time: '14:28:00', line: '901', to: ['Centraal Station'] },
            { time: '14:32:00', line: '901', to: ['Centraal Station'] },
            { time: '14:36:00', line: '901', to: ['Centraal Station'] },
            { time: '14:40:00', line: '901', to: ['Centraal Station'] },
            { time: '14:44:00', line: '901', to: ['Centraal Station'] },
            { time: '14:48:00', line: '901', to: ['Centraal Station'] },
            { time: '14:52:00', line: '901', to: ['Centraal Station'] },
            { time: '14:56:00', line: '901', to: ['Centraal Station'] },
            { time: '15:00:00', line: '901', to: ['Centraal Station'] },
            { time: '15:04:00', line: '901', to: ['Centraal Station'] },
            { time: '15:08:00', line: '901', to: ['Centraal Station'] },
            { time: '15:12:00', line: '901', to: ['Centraal Station'] },
            { time: '15:16:00', line: '901', to: ['Centraal Station'] },
            { time: '15:20:00', line: '901', to: ['Centraal Station'] },
            { time: '15:24:00', line: '901', to: ['Centraal Station'] },
            { time: '15:28:00', line: '901', to: ['Centraal Station'] },
            { time: '15:32:00', line: '901', to: ['Centraal Station'] },
            { time: '15:36:00', line: '901', to: ['Centraal Station'] },
            { time: '15:40:00', line: '901', to: ['Centraal Station'] },
            { time: '15:44:00', line: '901', to: ['Centraal Station'] },
            { time: '15:48:00', line: '901', to: ['Centraal Station'] },
            { time: '15:52:00', line: '901', to: ['Centraal Station'] },
            { time: '15:56:00', line: '901', to: ['Centraal Station'] },
            { time: '16:00:00', line: '901', to: ['Centraal Station'] },
            { time: '16:04:00', line: '901', to: ['Centraal Station'] },
            { time: '16:08:00', line: '901', to: ['Centraal Station'] },
            { time: '16:12:00', line: '901', to: ['Centraal Station'] },
            { time: '16:16:00', line: '901', to: ['Centraal Station'] },
            { time: '16:20:00', line: '901', to: ['Centraal Station'] },
            { time: '16:24:00', line: '901', to: ['Centraal Station'] },
            { time: '16:28:00', line: '901', to: ['Centraal Station'] },
            { time: '16:32:00', line: '901', to: ['Centraal Station'] },
            { time: '16:36:00', line: '901', to: ['Centraal Station'] },
            { time: '16:40:00', line: '901', to: ['Centraal Station'] },
            { time: '16:44:00', line: '901', to: ['Centraal Station'] },
            { time: '16:48:00', line: '901', to: ['Centraal Station'] },
            { time: '16:52:00', line: '901', to: ['Centraal Station'] },
            { time: '16:56:00', line: '901', to: ['Centraal Station'] },
            { time: '17:00:00', line: '901', to: ['Centraal Station'] },
            { time: '17:04:00', line: '901', to: ['Centraal Station'] },
            { time: '17:08:00', line: '901', to: ['Centraal Station'] },
            { time: '17:12:00', line: '901', to: ['Centraal Station'] },
            { time: '17:16:00', line: '901', to: ['Centraal Station'] },
            { time: '17:20:00', line: '901', to: ['Centraal Station'] },
            { time: '17:24:00', line: '901', to: ['Centraal Station'] },
            { time: '17:28:00', line: '901', to: ['Centraal Station'] },
            { time: '17:32:00', line: '901', to: ['Centraal Station'] },
            { time: '17:36:00', line: '901', to: ['Centraal Station'] },
            { time: '17:40:00', line: '901', to: ['Centraal Station'] },
            { time: '17:44:00', line: '901', to: ['Centraal Station'] },
            { time: '17:48:00', line: '901', to: ['Centraal Station'] },
            { time: '17:52:00', line: '901', to: ['Centraal Station'] },
            { time: '17:56:00', line: '901', to: ['Centraal Station'] },
            { time: '18:00:00', line: '901', to: ['Centraal Station'] },
            { time: '18:04:00', line: '901', to: ['Centraal Station'] },
            { time: '18:08:00', line: '901', to: ['Centraal Station'] },
            { time: '18:12:00', line: '901', to: ['Centraal Station'] },
            { time: '18:16:00', line: '901', to: ['Centraal Station'] },
            { time: '18:20:00', line: '901', to: ['Centraal Station'] },
            { time: '18:24:00', line: '901', to: ['Centraal Station'] },
            { time: '18:28:00', line: '901', to: ['Centraal Station'] },
            { time: '18:32:00', line: '901', to: ['Centraal Station'] },
            { time: '18:36:00', line: '901', to: ['Centraal Station'] },
            { time: '18:40:00', line: '901', to: ['Centraal Station'] },
            { time: '18:44:00', line: '901', to: ['Centraal Station'] },
            { time: '18:48:00', line: '901', to: ['Centraal Station'] },
            { time: '18:52:00', line: '901', to: ['Centraal Station'] },
            { time: '18:56:00', line: '901', to: ['Centraal Station'] },
            { time: '19:00:00', line: '901', to: ['Centraal Station'] },
            { time: '19:04:00', line: '901', to: ['Centraal Station'] },
            { time: '19:08:00', line: '901', to: ['Centraal Station'] },
            { time: '19:12:00', line: '901', to: ['Centraal Station'] },
            { time: '19:16:00', line: '901', to: ['Centraal Station'] },
            { time: '19:20:00', line: '901', to: ['Centraal Station'] },
            { time: '19:24:00', line: '901', to: ['Centraal Station'] },
            { time: '19:28:00', line: '901', to: ['Centraal Station'] },
            { time: '19:32:00', line: '901', to: ['Centraal Station'] },
            { time: '19:36:00', line: '901', to: ['Centraal Station'] },
            { time: '19:40:00', line: '901', to: ['Centraal Station'] },
            { time: '19:44:00', line: '901', to: ['Centraal Station'] },
            { time: '19:48:00', line: '901', to: ['Centraal Station'] },
            { time: '19:52:00', line: '901', to: ['Centraal Station'] },
            { time: '19:56:00', line: '901', to: ['Centraal Station'] },
            { time: '20:00:00', line: '901', to: ['Centraal Station'] },
            { time: '20:04:00', line: '901', to: ['Centraal Station'] },
            { time: '20:08:00', line: '901', to: ['Centraal Station'] },
            { time: '20:12:00', line: '901', to: ['Centraal Station'] },
            { time: '20:16:00', line: '901', to: ['Centraal Station'] },
            { time: '20:20:00', line: '901', to: ['Centraal Station'] },
            { time: '20:24:00', line: '901', to: ['Centraal Station'] },
            { time: '20:28:00', line: '901', to: ['Centraal Station'] },
            { time: '20:32:00', line: '901', to: ['Centraal Station'] },
            { time: '20:36:00', line: '901', to: ['Centraal Station'] },
            { time: '20:40:00', line: '901', to: ['Centraal Station'] },
            { time: '20:44:00', line: '901', to: ['Centraal Station'] },
            { time: '20:48:00', line: '901', to: ['Centraal Station'] },
            { time: '20:52:00', line: '901', to: ['Centraal Station'] },
            { time: '20:56:00', line: '901', to: ['Centraal Station'] },
            { time: '21:00:00', line: '901', to: ['Centraal Station'] },
            { time: '21:04:00', line: '901', to: ['Centraal Station'] },
            { time: '21:08:00', line: '901', to: ['Centraal Station'] },
            { time: '21:12:00', line: '901', to: ['Centraal Station'] },
            { time: '21:16:00', line: '901', to: ['Centraal Station'] },
            { time: '21:20:00', line: '901', to: ['Centraal Station'] },
            { time: '21:24:00', line: '901', to: ['Centraal Station'] },
            { time: '21:28:00', line: '901', to: ['Centraal Station'] },
            { time: '21:32:00', line: '901', to: ['Centraal Station'] },
            { time: '21:36:00', line: '901', to: ['Centraal Station'] },
            { time: '21:40:00', line: '901', to: ['Centraal Station'] },
            { time: '21:44:00', line: '901', to: ['Centraal Station'] },
            { time: '21:48:00', line: '901', to: ['Centraal Station'] },
            { time: '21:52:00', line: '901', to: ['Centraal Station'] },
            { time: '21:56:00', line: '901', to: ['Centraal Station'] },
            { time: '22:00:00', line: '901', to: ['Centraal Station'] },
            { time: '22:04:00', line: '901', to: ['Centraal Station'] },
            { time: '22:08:00', line: '901', to: ['Centraal Station'] },
            { time: '22:12:00', line: '901', to: ['Centraal Station'] },
            { time: '22:16:00', line: '901', to: ['Centraal Station'] },
            { time: '22:20:00', line: '901', to: ['Centraal Station'] },
            { time: '22:24:00', line: '901', to: ['Centraal Station'] },
            { time: '22:28:00', line: '901', to: ['Centraal Station'] },
            { time: '22:32:00', line: '901', to: ['Centraal Station'] },
            { time: '22:36:00', line: '901', to: ['Centraal Station'] },
            { time: '22:40:00', line: '901', to: ['Centraal Station'] },
            { time: '22:44:00', line: '901', to: ['Centraal Station'] },
            { time: '22:48:00', line: '901', to: ['Centraal Station'] },
            { time: '22:52:00', line: '901', to: ['Centraal Station'] },
            { time: '22:56:00', line: '901', to: ['Centraal Station'] },
            { time: '23:00:00', line: '901', to: ['Centraal Station'] },
            { time: '23:04:00', line: '901', to: ['Centraal Station'] },
            { time: '23:08:00', line: '901', to: ['Centraal Station'] },
            { time: '23:12:00', line: '901', to: ['Centraal Station'] },
            { time: '23:16:00', line: '901', to: ['Centraal Station'] },
            { time: '23:20:00', line: '901', to: ['Centraal Station'] },
            { time: '23:24:00', line: '901', to: ['Centraal Station'] },
            { time: '23:28:00', line: '901', to: ['Centraal Station'] },
            { time: '23:32:00', line: '901', to: ['Centraal Station'] },
            { time: '23:36:00', line: '901', to: ['Centraal Station'] },
            { time: '23:40:00', line: '901', to: ['Centraal Station'] },
            { time: '23:44:00', line: '901', to: ['Centraal Station'] },
            { time: '23:48:00', line: '901', to: ['Centraal Station'] },
            { time: '23:52:00', line: '901', to: ['Centraal Station'] },
            { time: '23:56:00', line: '901', to: ['Centraal Station'] },
            { time: '24:04:00', line: '901', to: ['Centraal Station'] },
            { time: '24:16:00', line: '901', to: ['Centraal Station'] },
            { time: '24:28:00', line: '901', to: ['Centraal Station'] },
            { time: '24:40:00', line: '901', to: ['Centraal Station'] },
            { time: '24:52:00', line: '901', to: ['Centraal Station'] },
            { time: '25:04:00', line: '901', to: ['Centraal Station'] },
            { time: '25:16:00', line: '901', to: ['Centraal Station'] },
            { time: '25:28:00', line: '901', to: ['Centraal Station'] },
            { time: '25:40:00', line: '901', to: ['Centraal Station'] },
            { time: '25:52:00', line: '901', to: ['Centraal Station'] },
            { time: '26:04:00', line: '901', to: ['Centraal Station'] },
            { time: '26:16:00', line: '901', to: ['Centraal Station'] },
            { time: '26:28:00', line: '901', to: ['Centraal Station'] },
            { time: '26:40:00', line: '901', to: ['Centraal Station'] },
            { time: '26:52:00', line: '901', to: ['Centraal Station'] },
            { time: '27:04:00', line: '901', to: ['Centraal Station'] },
            { time: '27:18:00', line: '901', to: ['Centraal Station'] },
            { time: '27:30:00', line: '901', to: ['Centraal Station'] },
            { time: '27:42:00', line: '901', to: ['Centraal Station'] },
            { time: '27:54:00', line: '901', to: ['Centraal Station'] },
            { time: '28:06:00', line: '901', to: ['Centraal Station'] },
            { time: '28:20:00', line: '901', to: ['Centraal Station'] },
            { time: '28:32:00', line: '901', to: ['Centraal Station'] },
            { time: '28:44:00', line: '901', to: ['Centraal Station'] },
            { time: '28:56:00', line: '901', to: ['Centraal Station'] },
            { time: '29:08:00', line: '901', to: ['Centraal Station'] },
            { time: '29:22:00', line: '901', to: ['Centraal Station'] },
            { time: '29:34:00', line: '901', to: ['Centraal Station'] },
            { time: '29:46:00', line: '901', to: ['Centraal Station'] },
        ],
        friday: [
            { time: '05:58:00', line: '901', to: ['Centraal Station'] },
            { time: '06:04:00', line: '901', to: ['Centraal Station'] },
            { time: '06:10:00', line: '901', to: ['Centraal Station'] },
            { time: '06:16:00', line: '901', to: ['Centraal Station'] },
            { time: '06:22:00', line: '901', to: ['Centraal Station'] },
            { time: '06:28:00', line: '901', to: ['Centraal Station'] },
            { time: '06:34:00', line: '901', to: ['Centraal Station'] },
            { time: '06:40:00', line: '901', to: ['Centraal Station'] },
            { time: '06:46:00', line: '901', to: ['Centraal Station'] },
            { time: '06:52:00', line: '901', to: ['Centraal Station'] },
            { time: '06:58:00', line: '901', to: ['Centraal Station'] },
            { time: '07:04:00', line: '901', to: ['Centraal Station'] },
            { time: '07:10:00', line: '901', to: ['Centraal Station'] },
            { time: '07:16:00', line: '901', to: ['Centraal Station'] },
            { time: '07:22:00', line: '901', to: ['Centraal Station'] },
            { time: '07:28:00', line: '901', to: ['Centraal Station'] },
            { time: '07:32:00', line: '901', to: ['Centraal Station'] },
            { time: '07:36:00', line: '901', to: ['Centraal Station'] },
            { time: '07:40:00', line: '901', to: ['Centraal Station'] },
            { time: '07:44:00', line: '901', to: ['Centraal Station'] },
            { time: '07:48:00', line: '901', to: ['Centraal Station'] },
            { time: '07:52:00', line: '901', to: ['Centraal Station'] },
            { time: '07:56:00', line: '901', to: ['Centraal Station'] },
            { time: '08:00:00', line: '901', to: ['Centraal Station'] },
            { time: '08:04:00', line: '901', to: ['Centraal Station'] },
            { time: '08:08:00', line: '901', to: ['Centraal Station'] },
            { time: '08:12:00', line: '901', to: ['Centraal Station'] },
            { time: '08:16:00', line: '901', to: ['Centraal Station'] },
            { time: '08:20:00', line: '901', to: ['Centraal Station'] },
            { time: '08:24:00', line: '901', to: ['Centraal Station'] },
            { time: '08:28:00', line: '901', to: ['Centraal Station'] },
            { time: '08:32:00', line: '901', to: ['Centraal Station'] },
            { time: '08:36:00', line: '901', to: ['Centraal Station'] },
            { time: '08:40:00', line: '901', to: ['Centraal Station'] },
            { time: '08:44:00', line: '901', to: ['Centraal Station'] },
            { time: '08:48:00', line: '901', to: ['Centraal Station'] },
            { time: '08:52:00', line: '901', to: ['Centraal Station'] },
            { time: '08:56:00', line: '901', to: ['Centraal Station'] },
            { time: '09:00:00', line: '901', to: ['Centraal Station'] },
            { time: '09:04:00', line: '901', to: ['Centraal Station'] },
            { time: '09:08:00', line: '901', to: ['Centraal Station'] },
            { time: '09:12:00', line: '901', to: ['Centraal Station'] },
            { time: '09:16:00', line: '901', to: ['Centraal Station'] },
            { time: '09:20:00', line: '901', to: ['Centraal Station'] },
            { time: '09:24:00', line: '901', to: ['Centraal Station'] },
            { time: '09:28:00', line: '901', to: ['Centraal Station'] },
            { time: '09:32:00', line: '901', to: ['Centraal Station'] },
            { time: '09:36:00', line: '901', to: ['Centraal Station'] },
            { time: '09:40:00', line: '901', to: ['Centraal Station'] },
            { time: '09:44:00', line: '901', to: ['Centraal Station'] },
            { time: '09:48:00', line: '901', to: ['Centraal Station'] },
            { time: '09:52:00', line: '901', to: ['Centraal Station'] },
            { time: '09:56:00', line: '901', to: ['Centraal Station'] },
            { time: '10:00:00', line: '901', to: ['Centraal Station'] },
            { time: '10:04:00', line: '901', to: ['Centraal Station'] },
            { time: '10:08:00', line: '901', to: ['Centraal Station'] },
            { time: '10:12:00', line: '901', to: ['Centraal Station'] },
            { time: '10:16:00', line: '901', to: ['Centraal Station'] },
            { time: '10:20:00', line: '901', to: ['Centraal Station'] },
            { time: '10:24:00', line: '901', to: ['Centraal Station'] },
            { time: '10:28:00', line: '901', to: ['Centraal Station'] },
            { time: '10:32:00', line: '901', to: ['Centraal Station'] },
            { time: '10:36:00', line: '901', to: ['Centraal Station'] },
            { time: '10:40:00', line: '901', to: ['Centraal Station'] },
            { time: '10:44:00', line: '901', to: ['Centraal Station'] },
            { time: '10:48:00', line: '901', to: ['Centraal Station'] },
            { time: '10:52:00', line: '901', to: ['Centraal Station'] },
            { time: '10:56:00', line: '901', to: ['Centraal Station'] },
            { time: '11:00:00', line: '901', to: ['Centraal Station'] },
            { time: '11:04:00', line: '901', to: ['Centraal Station'] },
            { time: '11:08:00', line: '901', to: ['Centraal Station'] },
            { time: '11:12:00', line: '901', to: ['Centraal Station'] },
            { time: '11:16:00', line: '901', to: ['Centraal Station'] },
            { time: '11:20:00', line: '901', to: ['Centraal Station'] },
            { time: '11:24:00', line: '901', to: ['Centraal Station'] },
            { time: '11:28:00', line: '901', to: ['Centraal Station'] },
            { time: '11:32:00', line: '901', to: ['Centraal Station'] },
            { time: '11:36:00', line: '901', to: ['Centraal Station'] },
            { time: '11:40:00', line: '901', to: ['Centraal Station'] },
            { time: '11:44:00', line: '901', to: ['Centraal Station'] },
            { time: '11:48:00', line: '901', to: ['Centraal Station'] },
            { time: '11:52:00', line: '901', to: ['Centraal Station'] },
            { time: '11:56:00', line: '901', to: ['Centraal Station'] },
            { time: '12:00:00', line: '901', to: ['Centraal Station'] },
            { time: '12:04:00', line: '901', to: ['Centraal Station'] },
            { time: '12:08:00', line: '901', to: ['Centraal Station'] },
            { time: '12:12:00', line: '901', to: ['Centraal Station'] },
            { time: '12:16:00', line: '901', to: ['Centraal Station'] },
            { time: '12:20:00', line: '901', to: ['Centraal Station'] },
            { time: '12:24:00', line: '901', to: ['Centraal Station'] },
            { time: '12:28:00', line: '901', to: ['Centraal Station'] },
            { time: '12:32:00', line: '901', to: ['Centraal Station'] },
            { time: '12:36:00', line: '901', to: ['Centraal Station'] },
            { time: '12:40:00', line: '901', to: ['Centraal Station'] },
            { time: '12:44:00', line: '901', to: ['Centraal Station'] },
            { time: '12:48:00', line: '901', to: ['Centraal Station'] },
            { time: '12:52:00', line: '901', to: ['Centraal Station'] },
            { time: '12:56:00', line: '901', to: ['Centraal Station'] },
            { time: '13:00:00', line: '901', to: ['Centraal Station'] },
            { time: '13:04:00', line: '901', to: ['Centraal Station'] },
            { time: '13:08:00', line: '901', to: ['Centraal Station'] },
            { time: '13:12:00', line: '901', to: ['Centraal Station'] },
            { time: '13:16:00', line: '901', to: ['Centraal Station'] },
            { time: '13:20:00', line: '901', to: ['Centraal Station'] },
            { time: '13:24:00', line: '901', to: ['Centraal Station'] },
            { time: '13:28:00', line: '901', to: ['Centraal Station'] },
            { time: '13:32:00', line: '901', to: ['Centraal Station'] },
            { time: '13:36:00', line: '901', to: ['Centraal Station'] },
            { time: '13:40:00', line: '901', to: ['Centraal Station'] },
            { time: '13:44:00', line: '901', to: ['Centraal Station'] },
            { time: '13:48:00', line: '901', to: ['Centraal Station'] },
            { time: '13:52:00', line: '901', to: ['Centraal Station'] },
            { time: '13:56:00', line: '901', to: ['Centraal Station'] },
            { time: '14:00:00', line: '901', to: ['Centraal Station'] },
            { time: '14:04:00', line: '901', to: ['Centraal Station'] },
            { time: '14:08:00', line: '901', to: ['Centraal Station'] },
            { time: '14:12:00', line: '901', to: ['Centraal Station'] },
            { time: '14:16:00', line: '901', to: ['Centraal Station'] },
            { time: '14:20:00', line: '901', to: ['Centraal Station'] },
            { time: '14:24:00', line: '901', to: ['Centraal Station'] },
            { time: '14:28:00', line: '901', to: ['Centraal Station'] },
            { time: '14:32:00', line: '901', to: ['Centraal Station'] },
            { time: '14:36:00', line: '901', to: ['Centraal Station'] },
            { time: '14:40:00', line: '901', to: ['Centraal Station'] },
            { time: '14:44:00', line: '901', to: ['Centraal Station'] },
            { time: '14:48:00', line: '901', to: ['Centraal Station'] },
            { time: '14:52:00', line: '901', to: ['Centraal Station'] },
            { time: '14:56:00', line: '901', to: ['Centraal Station'] },
            { time: '15:00:00', line: '901', to: ['Centraal Station'] },
            { time: '15:04:00', line: '901', to: ['Centraal Station'] },
            { time: '15:08:00', line: '901', to: ['Centraal Station'] },
            { time: '15:12:00', line: '901', to: ['Centraal Station'] },
            { time: '15:16:00', line: '901', to: ['Centraal Station'] },
            { time: '15:20:00', line: '901', to: ['Centraal Station'] },
            { time: '15:24:00', line: '901', to: ['Centraal Station'] },
            { time: '15:28:00', line: '901', to: ['Centraal Station'] },
            { time: '15:32:00', line: '901', to: ['Centraal Station'] },
            { time: '15:36:00', line: '901', to: ['Centraal Station'] },
            { time: '15:40:00', line: '901', to: ['Centraal Station'] },
            { time: '15:44:00', line: '901', to: ['Centraal Station'] },
            { time: '15:48:00', line: '901', to: ['Centraal Station'] },
            { time: '15:52:00', line: '901', to: ['Centraal Station'] },
            { time: '15:56:00', line: '901', to: ['Centraal Station'] },
            { time: '16:00:00', line: '901', to: ['Centraal Station'] },
            { time: '16:04:00', line: '901', to: ['Centraal Station'] },
            { time: '16:08:00', line: '901', to: ['Centraal Station'] },
            { time: '16:12:00', line: '901', to: ['Centraal Station'] },
            { time: '16:16:00', line: '901', to: ['Centraal Station'] },
            { time: '16:20:00', line: '901', to: ['Centraal Station'] },
            { time: '16:24:00', line: '901', to: ['Centraal Station'] },
            { time: '16:28:00', line: '901', to: ['Centraal Station'] },
            { time: '16:32:00', line: '901', to: ['Centraal Station'] },
            { time: '16:36:00', line: '901', to: ['Centraal Station'] },
            { time: '16:40:00', line: '901', to: ['Centraal Station'] },
            { time: '16:44:00', line: '901', to: ['Centraal Station'] },
            { time: '16:48:00', line: '901', to: ['Centraal Station'] },
            { time: '16:52:00', line: '901', to: ['Centraal Station'] },
            { time: '16:56:00', line: '901', to: ['Centraal Station'] },
            { time: '17:00:00', line: '901', to: ['Centraal Station'] },
            { time: '17:04:00', line: '901', to: ['Centraal Station'] },
            { time: '17:08:00', line: '901', to: ['Centraal Station'] },
            { time: '17:12:00', line: '901', to: ['Centraal Station'] },
            { time: '17:16:00', line: '901', to: ['Centraal Station'] },
            { time: '17:20:00', line: '901', to: ['Centraal Station'] },
            { time: '17:24:00', line: '901', to: ['Centraal Station'] },
            { time: '17:28:00', line: '901', to: ['Centraal Station'] },
            { time: '17:32:00', line: '901', to: ['Centraal Station'] },
            { time: '17:36:00', line: '901', to: ['Centraal Station'] },
            { time: '17:40:00', line: '901', to: ['Centraal Station'] },
            { time: '17:44:00', line: '901', to: ['Centraal Station'] },
            { time: '17:48:00', line: '901', to: ['Centraal Station'] },
            { time: '17:52:00', line: '901', to: ['Centraal Station'] },
            { time: '17:56:00', line: '901', to: ['Centraal Station'] },
            { time: '18:00:00', line: '901', to: ['Centraal Station'] },
            { time: '18:04:00', line: '901', to: ['Centraal Station'] },
            { time: '18:08:00', line: '901', to: ['Centraal Station'] },
            { time: '18:12:00', line: '901', to: ['Centraal Station'] },
            { time: '18:16:00', line: '901', to: ['Centraal Station'] },
            { time: '18:20:00', line: '901', to: ['Centraal Station'] },
            { time: '18:24:00', line: '901', to: ['Centraal Station'] },
            { time: '18:28:00', line: '901', to: ['Centraal Station'] },
            { time: '18:32:00', line: '901', to: ['Centraal Station'] },
            { time: '18:36:00', line: '901', to: ['Centraal Station'] },
            { time: '18:40:00', line: '901', to: ['Centraal Station'] },
            { time: '18:44:00', line: '901', to: ['Centraal Station'] },
            { time: '18:48:00', line: '901', to: ['Centraal Station'] },
            { time: '18:52:00', line: '901', to: ['Centraal Station'] },
            { time: '18:56:00', line: '901', to: ['Centraal Station'] },
            { time: '19:00:00', line: '901', to: ['Centraal Station'] },
            { time: '19:04:00', line: '901', to: ['Centraal Station'] },
            { time: '19:08:00', line: '901', to: ['Centraal Station'] },
            { time: '19:12:00', line: '901', to: ['Centraal Station'] },
            { time: '19:16:00', line: '901', to: ['Centraal Station'] },
            { time: '19:20:00', line: '901', to: ['Centraal Station'] },
            { time: '19:24:00', line: '901', to: ['Centraal Station'] },
            { time: '19:28:00', line: '901', to: ['Centraal Station'] },
            { time: '19:32:00', line: '901', to: ['Centraal Station'] },
            { time: '19:36:00', line: '901', to: ['Centraal Station'] },
            { time: '19:40:00', line: '901', to: ['Centraal Station'] },
            { time: '19:44:00', line: '901', to: ['Centraal Station'] },
            { time: '19:48:00', line: '901', to: ['Centraal Station'] },
            { time: '19:52:00', line: '901', to: ['Centraal Station'] },
            { time: '19:56:00', line: '901', to: ['Centraal Station'] },
            { time: '20:00:00', line: '901', to: ['Centraal Station'] },
            { time: '20:04:00', line: '901', to: ['Centraal Station'] },
            { time: '20:08:00', line: '901', to: ['Centraal Station'] },
            { time: '20:12:00', line: '901', to: ['Centraal Station'] },
            { time: '20:16:00', line: '901', to: ['Centraal Station'] },
            { time: '20:20:00', line: '901', to: ['Centraal Station'] },
            { time: '20:24:00', line: '901', to: ['Centraal Station'] },
            { time: '20:28:00', line: '901', to: ['Centraal Station'] },
            { time: '20:32:00', line: '901', to: ['Centraal Station'] },
            { time: '20:36:00', line: '901', to: ['Centraal Station'] },
            { time: '20:40:00', line: '901', to: ['Centraal Station'] },
            { time: '20:44:00', line: '901', to: ['Centraal Station'] },
            { time: '20:48:00', line: '901', to: ['Centraal Station'] },
            { time: '20:52:00', line: '901', to: ['Centraal Station'] },
            { time: '20:56:00', line: '901', to: ['Centraal Station'] },
            { time: '21:00:00', line: '901', to: ['Centraal Station'] },
            { time: '21:04:00', line: '901', to: ['Centraal Station'] },
            { time: '21:08:00', line: '901', to: ['Centraal Station'] },
            { time: '21:12:00', line: '901', to: ['Centraal Station'] },
            { time: '21:16:00', line: '901', to: ['Centraal Station'] },
            { time: '21:20:00', line: '901', to: ['Centraal Station'] },
            { time: '21:24:00', line: '901', to: ['Centraal Station'] },
            { time: '21:28:00', line: '901', to: ['Centraal Station'] },
            { time: '21:32:00', line: '901', to: ['Centraal Station'] },
            { time: '21:36:00', line: '901', to: ['Centraal Station'] },
            { time: '21:40:00', line: '901', to: ['Centraal Station'] },
            { time: '21:44:00', line: '901', to: ['Centraal Station'] },
            { time: '21:48:00', line: '901', to: ['Centraal Station'] },
            { time: '21:52:00', line: '901', to: ['Centraal Station'] },
            { time: '21:56:00', line: '901', to: ['Centraal Station'] },
            { time: '22:00:00', line: '901', to: ['Centraal Station'] },
            { time: '22:04:00', line: '901', to: ['Centraal Station'] },
            { time: '22:08:00', line: '901', to: ['Centraal Station'] },
            { time: '22:12:00', line: '901', to: ['Centraal Station'] },
            { time: '22:16:00', line: '901', to: ['Centraal Station'] },
            { time: '22:20:00', line: '901', to: ['Centraal Station'] },
            { time: '22:24:00', line: '901', to: ['Centraal Station'] },
            { time: '22:28:00', line: '901', to: ['Centraal Station'] },
            { time: '22:32:00', line: '901', to: ['Centraal Station'] },
            { time: '22:36:00', line: '901', to: ['Centraal Station'] },
            { time: '22:40:00', line: '901', to: ['Centraal Station'] },
            { time: '22:44:00', line: '901', to: ['Centraal Station'] },
            { time: '22:48:00', line: '901', to: ['Centraal Station'] },
            { time: '22:52:00', line: '901', to: ['Centraal Station'] },
            { time: '22:56:00', line: '901', to: ['Centraal Station'] },
            { time: '23:00:00', line: '901', to: ['Centraal Station'] },
            { time: '23:04:00', line: '901', to: ['Centraal Station'] },
            { time: '23:08:00', line: '901', to: ['Centraal Station'] },
            { time: '23:12:00', line: '901', to: ['Centraal Station'] },
            { time: '23:16:00', line: '901', to: ['Centraal Station'] },
            { time: '23:20:00', line: '901', to: ['Centraal Station'] },
            { time: '23:24:00', line: '901', to: ['Centraal Station'] },
            { time: '23:28:00', line: '901', to: ['Centraal Station'] },
            { time: '23:32:00', line: '901', to: ['Centraal Station'] },
            { time: '23:36:00', line: '901', to: ['Centraal Station'] },
            { time: '23:40:00', line: '901', to: ['Centraal Station'] },
            { time: '23:44:00', line: '901', to: ['Centraal Station'] },
            { time: '23:48:00', line: '901', to: ['Centraal Station'] },
            { time: '23:52:00', line: '901', to: ['Centraal Station'] },
            { time: '23:56:00', line: '901', to: ['Centraal Station'] },
            { time: '24:04:00', line: '901', to: ['Centraal Station'] },
            { time: '24:10:00', line: '901', to: ['Centraal Station'] },
            { time: '24:16:00', line: '901', to: ['Centraal Station'] },
            { time: '24:22:00', line: '901', to: ['Centraal Station'] },
            { time: '24:28:00', line: '901', to: ['Centraal Station'] },
            { time: '24:34:00', line: '901', to: ['Centraal Station'] },
            { time: '24:40:00', line: '901', to: ['Centraal Station'] },
            { time: '24:46:00', line: '901', to: ['Centraal Station'] },
            { time: '24:52:00', line: '901', to: ['Centraal Station'] },
            { time: '24:58:00', line: '901', to: ['Centraal Station'] },
            { time: '25:04:00', line: '901', to: ['Centraal Station'] },
            { time: '25:10:00', line: '901', to: ['Centraal Station'] },
            { time: '25:16:00', line: '901', to: ['Centraal Station'] },
            { time: '25:22:00', line: '901', to: ['Centraal Station'] },
            { time: '25:28:00', line: '901', to: ['Centraal Station'] },
            { time: '25:34:00', line: '901', to: ['Centraal Station'] },
            { time: '25:40:00', line: '901', to: ['Centraal Station'] },
            { time: '25:46:00', line: '901', to: ['Centraal Station'] },
            { time: '25:52:00', line: '901', to: ['Centraal Station'] },
            { time: '25:58:00', line: '901', to: ['Centraal Station'] },
            { time: '26:04:00', line: '901', to: ['Centraal Station'] },
            { time: '26:10:00', line: '901', to: ['Centraal Station'] },
            { time: '26:16:00', line: '901', to: ['Centraal Station'] },
            { time: '26:22:00', line: '901', to: ['Centraal Station'] },
            { time: '26:28:00', line: '901', to: ['Centraal Station'] },
            { time: '26:34:00', line: '901', to: ['Centraal Station'] },
            { time: '26:40:00', line: '901', to: ['Centraal Station'] },
            { time: '26:52:00', line: '901', to: ['Centraal Station'] },
            { time: '27:04:00', line: '901', to: ['Centraal Station'] },
            { time: '27:18:00', line: '901', to: ['Centraal Station'] },
            { time: '27:30:00', line: '901', to: ['Centraal Station'] },
            { time: '27:42:00', line: '901', to: ['Centraal Station'] },
            { time: '27:54:00', line: '901', to: ['Centraal Station'] },
            { time: '28:06:00', line: '901', to: ['Centraal Station'] },
            { time: '28:20:00', line: '901', to: ['Centraal Station'] },
            { time: '28:32:00', line: '901', to: ['Centraal Station'] },
            { time: '28:44:00', line: '901', to: ['Centraal Station'] },
            { time: '28:56:00', line: '901', to: ['Centraal Station'] },
            { time: '29:08:00', line: '901', to: ['Centraal Station'] },
            { time: '29:22:00', line: '901', to: ['Centraal Station'] },
            { time: '29:34:00', line: '901', to: ['Centraal Station'] },
            { time: '29:46:00', line: '901', to: ['Centraal Station'] },
        ],
        saturday: [
            { time: '05:58:00', line: '901', to: ['Centraal Station'] },
            { time: '06:04:00', line: '901', to: ['Centraal Station'] },
            { time: '06:10:00', line: '901', to: ['Centraal Station'] },
            { time: '06:16:00', line: '901', to: ['Centraal Station'] },
            { time: '06:22:00', line: '901', to: ['Centraal Station'] },
            { time: '06:28:00', line: '901', to: ['Centraal Station'] },
            { time: '06:34:00', line: '901', to: ['Centraal Station'] },
            { time: '06:40:00', line: '901', to: ['Centraal Station'] },
            { time: '06:46:00', line: '901', to: ['Centraal Station'] },
            { time: '06:52:00', line: '901', to: ['Centraal Station'] },
            { time: '06:58:00', line: '901', to: ['Centraal Station'] },
            { time: '07:04:00', line: '901', to: ['Centraal Station'] },
            { time: '07:10:00', line: '901', to: ['Centraal Station'] },
            { time: '07:16:00', line: '901', to: ['Centraal Station'] },
            { time: '07:22:00', line: '901', to: ['Centraal Station'] },
            { time: '07:28:00', line: '901', to: ['Centraal Station'] },
            { time: '07:34:00', line: '901', to: ['Centraal Station'] },
            { time: '07:40:00', line: '901', to: ['Centraal Station'] },
            { time: '07:46:00', line: '901', to: ['Centraal Station'] },
            { time: '07:52:00', line: '901', to: ['Centraal Station'] },
            { time: '07:58:00', line: '901', to: ['Centraal Station'] },
            { time: '08:04:00', line: '901', to: ['Centraal Station'] },
            { time: '08:10:00', line: '901', to: ['Centraal Station'] },
            { time: '08:16:00', line: '901', to: ['Centraal Station'] },
            { time: '08:22:00', line: '901', to: ['Centraal Station'] },
            { time: '08:28:00', line: '901', to: ['Centraal Station'] },
            { time: '08:34:00', line: '901', to: ['Centraal Station'] },
            { time: '08:40:00', line: '901', to: ['Centraal Station'] },
            { time: '08:46:00', line: '901', to: ['Centraal Station'] },
            { time: '08:52:00', line: '901', to: ['Centraal Station'] },
            { time: '08:58:00', line: '901', to: ['Centraal Station'] },
            { time: '09:04:00', line: '901', to: ['Centraal Station'] },
            { time: '09:10:00', line: '901', to: ['Centraal Station'] },
            { time: '09:16:00', line: '901', to: ['Centraal Station'] },
            { time: '09:22:00', line: '901', to: ['Centraal Station'] },
            { time: '09:28:00', line: '901', to: ['Centraal Station'] },
            { time: '09:34:00', line: '901', to: ['Centraal Station'] },
            { time: '09:40:00', line: '901', to: ['Centraal Station'] },
            { time: '09:46:00', line: '901', to: ['Centraal Station'] },
            { time: '09:52:00', line: '901', to: ['Centraal Station'] },
            { time: '09:58:00', line: '901', to: ['Centraal Station'] },
            { time: '10:04:00', line: '901', to: ['Centraal Station'] },
            { time: '10:10:00', line: '901', to: ['Centraal Station'] },
            { time: '10:16:00', line: '901', to: ['Centraal Station'] },
            { time: '10:22:00', line: '901', to: ['Centraal Station'] },
            { time: '10:28:00', line: '901', to: ['Centraal Station'] },
            { time: '10:34:00', line: '901', to: ['Centraal Station'] },
            { time: '10:40:00', line: '901', to: ['Centraal Station'] },
            { time: '10:46:00', line: '901', to: ['Centraal Station'] },
            { time: '10:52:00', line: '901', to: ['Centraal Station'] },
            { time: '10:58:00', line: '901', to: ['Centraal Station'] },
            { time: '11:04:00', line: '901', to: ['Centraal Station'] },
            { time: '11:10:00', line: '901', to: ['Centraal Station'] },
            { time: '11:16:00', line: '901', to: ['Centraal Station'] },
            { time: '11:22:00', line: '901', to: ['Centraal Station'] },
            { time: '11:28:00', line: '901', to: ['Centraal Station'] },
            { time: '11:34:00', line: '901', to: ['Centraal Station'] },
            { time: '11:40:00', line: '901', to: ['Centraal Station'] },
            { time: '11:46:00', line: '901', to: ['Centraal Station'] },
            { time: '11:52:00', line: '901', to: ['Centraal Station'] },
            { time: '11:58:00', line: '901', to: ['Centraal Station'] },
            { time: '12:04:00', line: '901', to: ['Centraal Station'] },
            { time: '12:08:00', line: '901', to: ['Centraal Station'] },
            { time: '12:12:00', line: '901', to: ['Centraal Station'] },
            { time: '12:16:00', line: '901', to: ['Centraal Station'] },
            { time: '12:20:00', line: '901', to: ['Centraal Station'] },
            { time: '12:24:00', line: '901', to: ['Centraal Station'] },
            { time: '12:28:00', line: '901', to: ['Centraal Station'] },
            { time: '12:32:00', line: '901', to: ['Centraal Station'] },
            { time: '12:36:00', line: '901', to: ['Centraal Station'] },
            { time: '12:40:00', line: '901', to: ['Centraal Station'] },
            { time: '12:44:00', line: '901', to: ['Centraal Station'] },
            { time: '12:48:00', line: '901', to: ['Centraal Station'] },
            { time: '12:52:00', line: '901', to: ['Centraal Station'] },
            { time: '12:56:00', line: '901', to: ['Centraal Station'] },
            { time: '13:00:00', line: '901', to: ['Centraal Station'] },
            { time: '13:04:00', line: '901', to: ['Centraal Station'] },
            { time: '13:08:00', line: '901', to: ['Centraal Station'] },
            { time: '13:12:00', line: '901', to: ['Centraal Station'] },
            { time: '13:16:00', line: '901', to: ['Centraal Station'] },
            { time: '13:20:00', line: '901', to: ['Centraal Station'] },
            { time: '13:24:00', line: '901', to: ['Centraal Station'] },
            { time: '13:28:00', line: '901', to: ['Centraal Station'] },
            { time: '13:32:00', line: '901', to: ['Centraal Station'] },
            { time: '13:36:00', line: '901', to: ['Centraal Station'] },
            { time: '13:40:00', line: '901', to: ['Centraal Station'] },
            { time: '13:44:00', line: '901', to: ['Centraal Station'] },
            { time: '13:48:00', line: '901', to: ['Centraal Station'] },
            { time: '13:52:00', line: '901', to: ['Centraal Station'] },
            { time: '13:56:00', line: '901', to: ['Centraal Station'] },
            { time: '14:00:00', line: '901', to: ['Centraal Station'] },
            { time: '14:04:00', line: '901', to: ['Centraal Station'] },
            { time: '14:08:00', line: '901', to: ['Centraal Station'] },
            { time: '14:12:00', line: '901', to: ['Centraal Station'] },
            { time: '14:16:00', line: '901', to: ['Centraal Station'] },
            { time: '14:20:00', line: '901', to: ['Centraal Station'] },
            { time: '14:24:00', line: '901', to: ['Centraal Station'] },
            { time: '14:28:00', line: '901', to: ['Centraal Station'] },
            { time: '14:32:00', line: '901', to: ['Centraal Station'] },
            { time: '14:36:00', line: '901', to: ['Centraal Station'] },
            { time: '14:40:00', line: '901', to: ['Centraal Station'] },
            { time: '14:44:00', line: '901', to: ['Centraal Station'] },
            { time: '14:48:00', line: '901', to: ['Centraal Station'] },
            { time: '14:52:00', line: '901', to: ['Centraal Station'] },
            { time: '14:56:00', line: '901', to: ['Centraal Station'] },
            { time: '15:00:00', line: '901', to: ['Centraal Station'] },
            { time: '15:04:00', line: '901', to: ['Centraal Station'] },
            { time: '15:08:00', line: '901', to: ['Centraal Station'] },
            { time: '15:12:00', line: '901', to: ['Centraal Station'] },
            { time: '15:16:00', line: '901', to: ['Centraal Station'] },
            { time: '15:20:00', line: '901', to: ['Centraal Station'] },
            { time: '15:24:00', line: '901', to: ['Centraal Station'] },
            { time: '15:28:00', line: '901', to: ['Centraal Station'] },
            { time: '15:32:00', line: '901', to: ['Centraal Station'] },
            { time: '15:36:00', line: '901', to: ['Centraal Station'] },
            { time: '15:40:00', line: '901', to: ['Centraal Station'] },
            { time: '15:44:00', line: '901', to: ['Centraal Station'] },
            { time: '15:48:00', line: '901', to: ['Centraal Station'] },
            { time: '15:52:00', line: '901', to: ['Centraal Station'] },
            { time: '15:56:00', line: '901', to: ['Centraal Station'] },
            { time: '16:00:00', line: '901', to: ['Centraal Station'] },
            { time: '16:04:00', line: '901', to: ['Centraal Station'] },
            { time: '16:08:00', line: '901', to: ['Centraal Station'] },
            { time: '16:12:00', line: '901', to: ['Centraal Station'] },
            { time: '16:16:00', line: '901', to: ['Centraal Station'] },
            { time: '16:20:00', line: '901', to: ['Centraal Station'] },
            { time: '16:24:00', line: '901', to: ['Centraal Station'] },
            { time: '16:28:00', line: '901', to: ['Centraal Station'] },
            { time: '16:32:00', line: '901', to: ['Centraal Station'] },
            { time: '16:36:00', line: '901', to: ['Centraal Station'] },
            { time: '16:40:00', line: '901', to: ['Centraal Station'] },
            { time: '16:44:00', line: '901', to: ['Centraal Station'] },
            { time: '16:48:00', line: '901', to: ['Centraal Station'] },
            { time: '16:52:00', line: '901', to: ['Centraal Station'] },
            { time: '16:56:00', line: '901', to: ['Centraal Station'] },
            { time: '17:00:00', line: '901', to: ['Centraal Station'] },
            { time: '17:04:00', line: '901', to: ['Centraal Station'] },
            { time: '17:08:00', line: '901', to: ['Centraal Station'] },
            { time: '17:12:00', line: '901', to: ['Centraal Station'] },
            { time: '17:16:00', line: '901', to: ['Centraal Station'] },
            { time: '17:20:00', line: '901', to: ['Centraal Station'] },
            { time: '17:24:00', line: '901', to: ['Centraal Station'] },
            { time: '17:28:00', line: '901', to: ['Centraal Station'] },
            { time: '17:32:00', line: '901', to: ['Centraal Station'] },
            { time: '17:36:00', line: '901', to: ['Centraal Station'] },
            { time: '17:40:00', line: '901', to: ['Centraal Station'] },
            { time: '17:44:00', line: '901', to: ['Centraal Station'] },
            { time: '17:48:00', line: '901', to: ['Centraal Station'] },
            { time: '17:52:00', line: '901', to: ['Centraal Station'] },
            { time: '17:56:00', line: '901', to: ['Centraal Station'] },
            { time: '18:00:00', line: '901', to: ['Centraal Station'] },
            { time: '18:04:00', line: '901', to: ['Centraal Station'] },
            { time: '18:08:00', line: '901', to: ['Centraal Station'] },
            { time: '18:12:00', line: '901', to: ['Centraal Station'] },
            { time: '18:16:00', line: '901', to: ['Centraal Station'] },
            { time: '18:20:00', line: '901', to: ['Centraal Station'] },
            { time: '18:24:00', line: '901', to: ['Centraal Station'] },
            { time: '18:28:00', line: '901', to: ['Centraal Station'] },
            { time: '18:32:00', line: '901', to: ['Centraal Station'] },
            { time: '18:36:00', line: '901', to: ['Centraal Station'] },
            { time: '18:40:00', line: '901', to: ['Centraal Station'] },
            { time: '18:44:00', line: '901', to: ['Centraal Station'] },
            { time: '18:48:00', line: '901', to: ['Centraal Station'] },
            { time: '18:52:00', line: '901', to: ['Centraal Station'] },
            { time: '18:56:00', line: '901', to: ['Centraal Station'] },
            { time: '19:00:00', line: '901', to: ['Centraal Station'] },
            { time: '19:04:00', line: '901', to: ['Centraal Station'] },
            { time: '19:08:00', line: '901', to: ['Centraal Station'] },
            { time: '19:12:00', line: '901', to: ['Centraal Station'] },
            { time: '19:16:00', line: '901', to: ['Centraal Station'] },
            { time: '19:20:00', line: '901', to: ['Centraal Station'] },
            { time: '19:24:00', line: '901', to: ['Centraal Station'] },
            { time: '19:28:00', line: '901', to: ['Centraal Station'] },
            { time: '19:32:00', line: '901', to: ['Centraal Station'] },
            { time: '19:36:00', line: '901', to: ['Centraal Station'] },
            { time: '19:40:00', line: '901', to: ['Centraal Station'] },
            { time: '19:44:00', line: '901', to: ['Centraal Station'] },
            { time: '19:48:00', line: '901', to: ['Centraal Station'] },
            { time: '19:52:00', line: '901', to: ['Centraal Station'] },
            { time: '19:56:00', line: '901', to: ['Centraal Station'] },
            { time: '20:00:00', line: '901', to: ['Centraal Station'] },
            { time: '20:04:00', line: '901', to: ['Centraal Station'] },
            { time: '20:08:00', line: '901', to: ['Centraal Station'] },
            { time: '20:12:00', line: '901', to: ['Centraal Station'] },
            { time: '20:18:00', line: '901', to: ['Centraal Station'] },
            { time: '20:24:00', line: '901', to: ['Centraal Station'] },
            { time: '20:30:00', line: '901', to: ['Centraal Station'] },
            { time: '20:36:00', line: '901', to: ['Centraal Station'] },
            { time: '20:42:00', line: '901', to: ['Centraal Station'] },
            { time: '20:48:00', line: '901', to: ['Centraal Station'] },
            { time: '20:54:00', line: '901', to: ['Centraal Station'] },
            { time: '21:00:00', line: '901', to: ['Centraal Station'] },
            { time: '21:06:00', line: '901', to: ['Centraal Station'] },
            { time: '21:12:00', line: '901', to: ['Centraal Station'] },
            { time: '21:18:00', line: '901', to: ['Centraal Station'] },
            { time: '21:24:00', line: '901', to: ['Centraal Station'] },
            { time: '21:30:00', line: '901', to: ['Centraal Station'] },
            { time: '21:36:00', line: '901', to: ['Centraal Station'] },
            { time: '21:42:00', line: '901', to: ['Centraal Station'] },
            { time: '21:48:00', line: '901', to: ['Centraal Station'] },
            { time: '21:54:00', line: '901', to: ['Centraal Station'] },
            { time: '22:00:00', line: '901', to: ['Centraal Station'] },
            { time: '22:06:00', line: '901', to: ['Centraal Station'] },
            { time: '22:12:00', line: '901', to: ['Centraal Station'] },
            { time: '22:18:00', line: '901', to: ['Centraal Station'] },
            { time: '22:24:00', line: '901', to: ['Centraal Station'] },
            { time: '22:30:00', line: '901', to: ['Centraal Station'] },
            { time: '22:36:00', line: '901', to: ['Centraal Station'] },
            { time: '22:42:00', line: '901', to: ['Centraal Station'] },
            { time: '22:48:00', line: '901', to: ['Centraal Station'] },
            { time: '22:54:00', line: '901', to: ['Centraal Station'] },
            { time: '23:00:00', line: '901', to: ['Centraal Station'] },
            { time: '23:06:00', line: '901', to: ['Centraal Station'] },
            { time: '23:12:00', line: '901', to: ['Centraal Station'] },
            { time: '23:18:00', line: '901', to: ['Centraal Station'] },
            { time: '23:24:00', line: '901', to: ['Centraal Station'] },
            { time: '23:30:00', line: '901', to: ['Centraal Station'] },
            { time: '23:36:00', line: '901', to: ['Centraal Station'] },
            { time: '23:42:00', line: '901', to: ['Centraal Station'] },
            { time: '23:48:00', line: '901', to: ['Centraal Station'] },
            { time: '23:54:00', line: '901', to: ['Centraal Station'] },
            { time: '24:00:00', line: '901', to: ['Centraal Station'] },
            { time: '24:06:00', line: '901', to: ['Centraal Station'] },
            { time: '24:12:00', line: '901', to: ['Centraal Station'] },
            { time: '24:18:00', line: '901', to: ['Centraal Station'] },
            { time: '24:24:00', line: '901', to: ['Centraal Station'] },
            { time: '24:30:00', line: '901', to: ['Centraal Station'] },
            { time: '24:36:00', line: '901', to: ['Centraal Station'] },
            { time: '24:42:00', line: '901', to: ['Centraal Station'] },
            { time: '24:48:00', line: '901', to: ['Centraal Station'] },
            { time: '24:54:00', line: '901', to: ['Centraal Station'] },
            { time: '25:00:00', line: '901', to: ['Centraal Station'] },
            { time: '25:06:00', line: '901', to: ['Centraal Station'] },
            { time: '25:12:00', line: '901', to: ['Centraal Station'] },
            { time: '25:18:00', line: '901', to: ['Centraal Station'] },
            { time: '25:24:00', line: '901', to: ['Centraal Station'] },
            { time: '25:30:00', line: '901', to: ['Centraal Station'] },
            { time: '25:36:00', line: '901', to: ['Centraal Station'] },
            { time: '25:42:00', line: '901', to: ['Centraal Station'] },
            { time: '25:48:00', line: '901', to: ['Centraal Station'] },
            { time: '25:54:00', line: '901', to: ['Centraal Station'] },
            { time: '26:00:00', line: '901', to: ['Centraal Station'] },
            { time: '26:06:00', line: '901', to: ['Centraal Station'] },
            { time: '26:12:00', line: '901', to: ['Centraal Station'] },
            { time: '26:18:00', line: '901', to: ['Centraal Station'] },
            { time: '26:24:00', line: '901', to: ['Centraal Station'] },
            { time: '26:30:00', line: '901', to: ['Centraal Station'] },
            { time: '26:42:00', line: '901', to: ['Centraal Station'] },
            { time: '26:54:00', line: '901', to: ['Centraal Station'] },
            { time: '27:06:00', line: '901', to: ['Centraal Station'] },
            { time: '27:18:00', line: '901', to: ['Centraal Station'] },
            { time: '27:30:00', line: '901', to: ['Centraal Station'] },
            { time: '27:42:00', line: '901', to: ['Centraal Station'] },
            { time: '27:54:00', line: '901', to: ['Centraal Station'] },
            { time: '28:06:00', line: '901', to: ['Centraal Station'] },
            { time: '28:20:00', line: '901', to: ['Centraal Station'] },
            { time: '28:32:00', line: '901', to: ['Centraal Station'] },
            { time: '28:44:00', line: '901', to: ['Centraal Station'] },
            { time: '28:56:00', line: '901', to: ['Centraal Station'] },
            { time: '29:08:00', line: '901', to: ['Centraal Station'] },
            { time: '29:22:00', line: '901', to: ['Centraal Station'] },
            { time: '29:34:00', line: '901', to: ['Centraal Station'] },
            { time: '29:46:00', line: '901', to: ['Centraal Station'] },
        ],
        sunday: [
            { time: '05:58:00', line: '901', to: ['Centraal Station'] },
            { time: '06:04:00', line: '901', to: ['Centraal Station'] },
            { time: '06:10:00', line: '901', to: ['Centraal Station'] },
            { time: '06:16:00', line: '901', to: ['Centraal Station'] },
            { time: '06:22:00', line: '901', to: ['Centraal Station'] },
            { time: '06:28:00', line: '901', to: ['Centraal Station'] },
            { time: '06:34:00', line: '901', to: ['Centraal Station'] },
            { time: '06:40:00', line: '901', to: ['Centraal Station'] },
            { time: '06:46:00', line: '901', to: ['Centraal Station'] },
            { time: '06:52:00', line: '901', to: ['Centraal Station'] },
            { time: '06:58:00', line: '901', to: ['Centraal Station'] },
            { time: '07:04:00', line: '901', to: ['Centraal Station'] },
            { time: '07:10:00', line: '901', to: ['Centraal Station'] },
            { time: '07:16:00', line: '901', to: ['Centraal Station'] },
            { time: '07:22:00', line: '901', to: ['Centraal Station'] },
            { time: '07:28:00', line: '901', to: ['Centraal Station'] },
            { time: '07:34:00', line: '901', to: ['Centraal Station'] },
            { time: '07:40:00', line: '901', to: ['Centraal Station'] },
            { time: '07:46:00', line: '901', to: ['Centraal Station'] },
            { time: '07:52:00', line: '901', to: ['Centraal Station'] },
            { time: '07:58:00', line: '901', to: ['Centraal Station'] },
            { time: '08:04:00', line: '901', to: ['Centraal Station'] },
            { time: '08:10:00', line: '901', to: ['Centraal Station'] },
            { time: '08:16:00', line: '901', to: ['Centraal Station'] },
            { time: '08:22:00', line: '901', to: ['Centraal Station'] },
            { time: '08:28:00', line: '901', to: ['Centraal Station'] },
            { time: '08:34:00', line: '901', to: ['Centraal Station'] },
            { time: '08:40:00', line: '901', to: ['Centraal Station'] },
            { time: '08:46:00', line: '901', to: ['Centraal Station'] },
            { time: '08:52:00', line: '901', to: ['Centraal Station'] },
            { time: '08:58:00', line: '901', to: ['Centraal Station'] },
            { time: '09:04:00', line: '901', to: ['Centraal Station'] },
            { time: '09:10:00', line: '901', to: ['Centraal Station'] },
            { time: '09:16:00', line: '901', to: ['Centraal Station'] },
            { time: '09:22:00', line: '901', to: ['Centraal Station'] },
            { time: '09:28:00', line: '901', to: ['Centraal Station'] },
            { time: '09:34:00', line: '901', to: ['Centraal Station'] },
            { time: '09:40:00', line: '901', to: ['Centraal Station'] },
            { time: '09:46:00', line: '901', to: ['Centraal Station'] },
            { time: '09:52:00', line: '901', to: ['Centraal Station'] },
            { time: '09:58:00', line: '901', to: ['Centraal Station'] },
            { time: '10:04:00', line: '901', to: ['Centraal Station'] },
            { time: '10:10:00', line: '901', to: ['Centraal Station'] },
            { time: '10:16:00', line: '901', to: ['Centraal Station'] },
            { time: '10:22:00', line: '901', to: ['Centraal Station'] },
            { time: '10:28:00', line: '901', to: ['Centraal Station'] },
            { time: '10:34:00', line: '901', to: ['Centraal Station'] },
            { time: '10:40:00', line: '901', to: ['Centraal Station'] },
            { time: '10:46:00', line: '901', to: ['Centraal Station'] },
            { time: '10:52:00', line: '901', to: ['Centraal Station'] },
            { time: '10:58:00', line: '901', to: ['Centraal Station'] },
            { time: '11:04:00', line: '901', to: ['Centraal Station'] },
            { time: '11:10:00', line: '901', to: ['Centraal Station'] },
            { time: '11:16:00', line: '901', to: ['Centraal Station'] },
            { time: '11:22:00', line: '901', to: ['Centraal Station'] },
            { time: '11:28:00', line: '901', to: ['Centraal Station'] },
            { time: '11:34:00', line: '901', to: ['Centraal Station'] },
            { time: '11:40:00', line: '901', to: ['Centraal Station'] },
            { time: '11:46:00', line: '901', to: ['Centraal Station'] },
            { time: '11:52:00', line: '901', to: ['Centraal Station'] },
            { time: '11:58:00', line: '901', to: ['Centraal Station'] },
            { time: '12:04:00', line: '901', to: ['Centraal Station'] },
            { time: '12:08:00', line: '901', to: ['Centraal Station'] },
            { time: '12:12:00', line: '901', to: ['Centraal Station'] },
            { time: '12:16:00', line: '901', to: ['Centraal Station'] },
            { time: '12:20:00', line: '901', to: ['Centraal Station'] },
            { time: '12:24:00', line: '901', to: ['Centraal Station'] },
            { time: '12:28:00', line: '901', to: ['Centraal Station'] },
            { time: '12:32:00', line: '901', to: ['Centraal Station'] },
            { time: '12:36:00', line: '901', to: ['Centraal Station'] },
            { time: '12:40:00', line: '901', to: ['Centraal Station'] },
            { time: '12:44:00', line: '901', to: ['Centraal Station'] },
            { time: '12:48:00', line: '901', to: ['Centraal Station'] },
            { time: '12:52:00', line: '901', to: ['Centraal Station'] },
            { time: '12:56:00', line: '901', to: ['Centraal Station'] },
            { time: '13:00:00', line: '901', to: ['Centraal Station'] },
            { time: '13:04:00', line: '901', to: ['Centraal Station'] },
            { time: '13:08:00', line: '901', to: ['Centraal Station'] },
            { time: '13:12:00', line: '901', to: ['Centraal Station'] },
            { time: '13:16:00', line: '901', to: ['Centraal Station'] },
            { time: '13:20:00', line: '901', to: ['Centraal Station'] },
            { time: '13:24:00', line: '901', to: ['Centraal Station'] },
            { time: '13:28:00', line: '901', to: ['Centraal Station'] },
            { time: '13:32:00', line: '901', to: ['Centraal Station'] },
            { time: '13:36:00', line: '901', to: ['Centraal Station'] },
            { time: '13:40:00', line: '901', to: ['Centraal Station'] },
            { time: '13:44:00', line: '901', to: ['Centraal Station'] },
            { time: '13:48:00', line: '901', to: ['Centraal Station'] },
            { time: '13:52:00', line: '901', to: ['Centraal Station'] },
            { time: '13:56:00', line: '901', to: ['Centraal Station'] },
            { time: '14:00:00', line: '901', to: ['Centraal Station'] },
            { time: '14:04:00', line: '901', to: ['Centraal Station'] },
            { time: '14:08:00', line: '901', to: ['Centraal Station'] },
            { time: '14:12:00', line: '901', to: ['Centraal Station'] },
            { time: '14:16:00', line: '901', to: ['Centraal Station'] },
            { time: '14:20:00', line: '901', to: ['Centraal Station'] },
            { time: '14:24:00', line: '901', to: ['Centraal Station'] },
            { time: '14:28:00', line: '901', to: ['Centraal Station'] },
            { time: '14:32:00', line: '901', to: ['Centraal Station'] },
            { time: '14:36:00', line: '901', to: ['Centraal Station'] },
            { time: '14:40:00', line: '901', to: ['Centraal Station'] },
            { time: '14:44:00', line: '901', to: ['Centraal Station'] },
            { time: '14:48:00', line: '901', to: ['Centraal Station'] },
            { time: '14:52:00', line: '901', to: ['Centraal Station'] },
            { time: '14:56:00', line: '901', to: ['Centraal Station'] },
            { time: '15:00:00', line: '901', to: ['Centraal Station'] },
            { time: '15:04:00', line: '901', to: ['Centraal Station'] },
            { time: '15:08:00', line: '901', to: ['Centraal Station'] },
            { time: '15:12:00', line: '901', to: ['Centraal Station'] },
            { time: '15:16:00', line: '901', to: ['Centraal Station'] },
            { time: '15:20:00', line: '901', to: ['Centraal Station'] },
            { time: '15:24:00', line: '901', to: ['Centraal Station'] },
            { time: '15:28:00', line: '901', to: ['Centraal Station'] },
            { time: '15:32:00', line: '901', to: ['Centraal Station'] },
            { time: '15:36:00', line: '901', to: ['Centraal Station'] },
            { time: '15:40:00', line: '901', to: ['Centraal Station'] },
            { time: '15:44:00', line: '901', to: ['Centraal Station'] },
            { time: '15:48:00', line: '901', to: ['Centraal Station'] },
            { time: '15:52:00', line: '901', to: ['Centraal Station'] },
            { time: '15:56:00', line: '901', to: ['Centraal Station'] },
            { time: '16:00:00', line: '901', to: ['Centraal Station'] },
            { time: '16:04:00', line: '901', to: ['Centraal Station'] },
            { time: '16:08:00', line: '901', to: ['Centraal Station'] },
            { time: '16:12:00', line: '901', to: ['Centraal Station'] },
            { time: '16:16:00', line: '901', to: ['Centraal Station'] },
            { time: '16:20:00', line: '901', to: ['Centraal Station'] },
            { time: '16:24:00', line: '901', to: ['Centraal Station'] },
            { time: '16:28:00', line: '901', to: ['Centraal Station'] },
            { time: '16:32:00', line: '901', to: ['Centraal Station'] },
            { time: '16:36:00', line: '901', to: ['Centraal Station'] },
            { time: '16:40:00', line: '901', to: ['Centraal Station'] },
            { time: '16:44:00', line: '901', to: ['Centraal Station'] },
            { time: '16:48:00', line: '901', to: ['Centraal Station'] },
            { time: '16:52:00', line: '901', to: ['Centraal Station'] },
            { time: '16:56:00', line: '901', to: ['Centraal Station'] },
            { time: '17:00:00', line: '901', to: ['Centraal Station'] },
            { time: '17:04:00', line: '901', to: ['Centraal Station'] },
            { time: '17:08:00', line: '901', to: ['Centraal Station'] },
            { time: '17:12:00', line: '901', to: ['Centraal Station'] },
            { time: '17:16:00', line: '901', to: ['Centraal Station'] },
            { time: '17:20:00', line: '901', to: ['Centraal Station'] },
            { time: '17:24:00', line: '901', to: ['Centraal Station'] },
            { time: '17:28:00', line: '901', to: ['Centraal Station'] },
            { time: '17:32:00', line: '901', to: ['Centraal Station'] },
            { time: '17:36:00', line: '901', to: ['Centraal Station'] },
            { time: '17:40:00', line: '901', to: ['Centraal Station'] },
            { time: '17:44:00', line: '901', to: ['Centraal Station'] },
            { time: '17:48:00', line: '901', to: ['Centraal Station'] },
            { time: '17:52:00', line: '901', to: ['Centraal Station'] },
            { time: '17:56:00', line: '901', to: ['Centraal Station'] },
            { time: '18:00:00', line: '901', to: ['Centraal Station'] },
            { time: '18:04:00', line: '901', to: ['Centraal Station'] },
            { time: '18:08:00', line: '901', to: ['Centraal Station'] },
            { time: '18:12:00', line: '901', to: ['Centraal Station'] },
            { time: '18:16:00', line: '901', to: ['Centraal Station'] },
            { time: '18:20:00', line: '901', to: ['Centraal Station'] },
            { time: '18:24:00', line: '901', to: ['Centraal Station'] },
            { time: '18:28:00', line: '901', to: ['Centraal Station'] },
            { time: '18:32:00', line: '901', to: ['Centraal Station'] },
            { time: '18:36:00', line: '901', to: ['Centraal Station'] },
            { time: '18:40:00', line: '901', to: ['Centraal Station'] },
            { time: '18:44:00', line: '901', to: ['Centraal Station'] },
            { time: '18:48:00', line: '901', to: ['Centraal Station'] },
            { time: '18:52:00', line: '901', to: ['Centraal Station'] },
            { time: '18:56:00', line: '901', to: ['Centraal Station'] },
            { time: '19:00:00', line: '901', to: ['Centraal Station'] },
            { time: '19:04:00', line: '901', to: ['Centraal Station'] },
            { time: '19:08:00', line: '901', to: ['Centraal Station'] },
            { time: '19:12:00', line: '901', to: ['Centraal Station'] },
            { time: '19:16:00', line: '901', to: ['Centraal Station'] },
            { time: '19:20:00', line: '901', to: ['Centraal Station'] },
            { time: '19:24:00', line: '901', to: ['Centraal Station'] },
            { time: '19:28:00', line: '901', to: ['Centraal Station'] },
            { time: '19:32:00', line: '901', to: ['Centraal Station'] },
            { time: '19:36:00', line: '901', to: ['Centraal Station'] },
            { time: '19:40:00', line: '901', to: ['Centraal Station'] },
            { time: '19:44:00', line: '901', to: ['Centraal Station'] },
            { time: '19:48:00', line: '901', to: ['Centraal Station'] },
            { time: '19:52:00', line: '901', to: ['Centraal Station'] },
            { time: '19:56:00', line: '901', to: ['Centraal Station'] },
            { time: '20:00:00', line: '901', to: ['Centraal Station'] },
            { time: '20:04:00', line: '901', to: ['Centraal Station'] },
            { time: '20:08:00', line: '901', to: ['Centraal Station'] },
            { time: '20:12:00', line: '901', to: ['Centraal Station'] },
            { time: '20:18:00', line: '901', to: ['Centraal Station'] },
            { time: '20:24:00', line: '901', to: ['Centraal Station'] },
            { time: '20:30:00', line: '901', to: ['Centraal Station'] },
            { time: '20:36:00', line: '901', to: ['Centraal Station'] },
            { time: '20:42:00', line: '901', to: ['Centraal Station'] },
            { time: '20:48:00', line: '901', to: ['Centraal Station'] },
            { time: '20:54:00', line: '901', to: ['Centraal Station'] },
            { time: '21:00:00', line: '901', to: ['Centraal Station'] },
            { time: '21:06:00', line: '901', to: ['Centraal Station'] },
            { time: '21:12:00', line: '901', to: ['Centraal Station'] },
            { time: '21:18:00', line: '901', to: ['Centraal Station'] },
            { time: '21:24:00', line: '901', to: ['Centraal Station'] },
            { time: '21:30:00', line: '901', to: ['Centraal Station'] },
            { time: '21:36:00', line: '901', to: ['Centraal Station'] },
            { time: '21:42:00', line: '901', to: ['Centraal Station'] },
            { time: '21:48:00', line: '901', to: ['Centraal Station'] },
            { time: '21:54:00', line: '901', to: ['Centraal Station'] },
            { time: '22:00:00', line: '901', to: ['Centraal Station'] },
            { time: '22:06:00', line: '901', to: ['Centraal Station'] },
            { time: '22:12:00', line: '901', to: ['Centraal Station'] },
            { time: '22:18:00', line: '901', to: ['Centraal Station'] },
            { time: '22:24:00', line: '901', to: ['Centraal Station'] },
            { time: '22:30:00', line: '901', to: ['Centraal Station'] },
            { time: '22:36:00', line: '901', to: ['Centraal Station'] },
            { time: '22:42:00', line: '901', to: ['Centraal Station'] },
            { time: '22:48:00', line: '901', to: ['Centraal Station'] },
            { time: '22:54:00', line: '901', to: ['Centraal Station'] },
            { time: '23:00:00', line: '901', to: ['Centraal Station'] },
            { time: '23:06:00', line: '901', to: ['Centraal Station'] },
            { time: '23:12:00', line: '901', to: ['Centraal Station'] },
            { time: '23:18:00', line: '901', to: ['Centraal Station'] },
            { time: '23:24:00', line: '901', to: ['Centraal Station'] },
            { time: '23:30:00', line: '901', to: ['Centraal Station'] },
            { time: '23:36:00', line: '901', to: ['Centraal Station'] },
            { time: '23:42:00', line: '901', to: ['Centraal Station'] },
            { time: '23:48:00', line: '901', to: ['Centraal Station'] },
            { time: '23:54:00', line: '901', to: ['Centraal Station'] },
            { time: '24:06:00', line: '901', to: ['Centraal Station'] },
            { time: '24:18:00', line: '901', to: ['Centraal Station'] },
            { time: '24:30:00', line: '901', to: ['Centraal Station'] },
            { time: '24:42:00', line: '901', to: ['Centraal Station'] },
            { time: '24:54:00', line: '901', to: ['Centraal Station'] },
            { time: '25:06:00', line: '901', to: ['Centraal Station'] },
            { time: '25:18:00', line: '901', to: ['Centraal Station'] },
            { time: '25:30:00', line: '901', to: ['Centraal Station'] },
            { time: '25:42:00', line: '901', to: ['Centraal Station'] },
            { time: '25:54:00', line: '901', to: ['Centraal Station'] },
            { time: '26:06:00', line: '901', to: ['Centraal Station'] },
            { time: '26:18:00', line: '901', to: ['Centraal Station'] },
            { time: '26:30:00', line: '901', to: ['Centraal Station'] },
            { time: '26:42:00', line: '901', to: ['Centraal Station'] },
            { time: '26:54:00', line: '901', to: ['Centraal Station'] },
            { time: '27:06:00', line: '901', to: ['Centraal Station'] },
            { time: '27:18:00', line: '901', to: ['Centraal Station'] },
            { time: '27:30:00', line: '901', to: ['Centraal Station'] },
            { time: '27:42:00', line: '901', to: ['Centraal Station'] },
            { time: '27:54:00', line: '901', to: ['Centraal Station'] },
            { time: '28:06:00', line: '901', to: ['Centraal Station'] },
            { time: '28:20:00', line: '901', to: ['Centraal Station'] },
            { time: '28:32:00', line: '901', to: ['Centraal Station'] },
            { time: '28:44:00', line: '901', to: ['Centraal Station'] },
            { time: '28:56:00', line: '901', to: ['Centraal Station'] },
            { time: '29:08:00', line: '901', to: ['Centraal Station'] },
            { time: '29:22:00', line: '901', to: ['Centraal Station'] },
            { time: '29:34:00', line: '901', to: ['Centraal Station'] },
            { time: '29:46:00', line: '901', to: ['Centraal Station'] },
        ],
    },
    IJplein: {
        monday: [
            { time: '06:26:00', line: '902', to: ['Centraal Station'] },
            { time: '06:32:00', line: '902', to: ['Centraal Station'] },
            { time: '06:38:00', line: '902', to: ['Centraal Station'] },
            { time: '06:44:00', line: '902', to: ['Centraal Station'] },
            { time: '06:50:00', line: '902', to: ['Centraal Station'] },
            { time: '06:56:00', line: '902', to: ['Centraal Station'] },
            { time: '07:02:00', line: '902', to: ['Centraal Station'] },
            { time: '07:08:00', line: '902', to: ['Centraal Station'] },
            { time: '07:14:00', line: '902', to: ['Centraal Station'] },
            { time: '07:20:00', line: '902', to: ['Centraal Station'] },
            { time: '07:26:00', line: '902', to: ['Centraal Station'] },
            { time: '07:32:00', line: '902', to: ['Centraal Station'] },
            { time: '07:38:00', line: '902', to: ['Centraal Station'] },
            { time: '07:44:00', line: '902', to: ['Centraal Station'] },
            { time: '07:50:00', line: '902', to: ['Centraal Station'] },
            { time: '07:56:00', line: '902', to: ['Centraal Station'] },
            { time: '08:02:00', line: '902', to: ['Centraal Station'] },
            { time: '08:08:00', line: '902', to: ['Centraal Station'] },
            { time: '08:14:00', line: '902', to: ['Centraal Station'] },
            { time: '08:20:00', line: '902', to: ['Centraal Station'] },
            { time: '08:26:00', line: '902', to: ['Centraal Station'] },
            { time: '08:32:00', line: '902', to: ['Centraal Station'] },
            { time: '08:38:00', line: '902', to: ['Centraal Station'] },
            { time: '08:44:00', line: '902', to: ['Centraal Station'] },
            { time: '08:50:00', line: '902', to: ['Centraal Station'] },
            { time: '08:56:00', line: '902', to: ['Centraal Station'] },
            { time: '09:02:00', line: '902', to: ['Centraal Station'] },
            { time: '09:08:00', line: '902', to: ['Centraal Station'] },
            { time: '09:14:00', line: '902', to: ['Centraal Station'] },
            { time: '09:20:00', line: '902', to: ['Centraal Station'] },
            { time: '09:26:00', line: '902', to: ['Centraal Station'] },
            { time: '09:32:00', line: '902', to: ['Centraal Station'] },
            { time: '09:38:00', line: '902', to: ['Centraal Station'] },
            { time: '09:44:00', line: '902', to: ['Centraal Station'] },
            { time: '09:50:00', line: '902', to: ['Centraal Station'] },
            { time: '09:56:00', line: '902', to: ['Centraal Station'] },
            { time: '10:02:00', line: '902', to: ['Centraal Station'] },
            { time: '10:08:00', line: '902', to: ['Centraal Station'] },
            { time: '10:14:00', line: '902', to: ['Centraal Station'] },
            { time: '10:20:00', line: '902', to: ['Centraal Station'] },
            { time: '10:26:00', line: '902', to: ['Centraal Station'] },
            { time: '10:32:00', line: '902', to: ['Centraal Station'] },
            { time: '10:38:00', line: '902', to: ['Centraal Station'] },
            { time: '10:44:00', line: '902', to: ['Centraal Station'] },
            { time: '10:50:00', line: '902', to: ['Centraal Station'] },
            { time: '10:56:00', line: '902', to: ['Centraal Station'] },
            { time: '11:02:00', line: '902', to: ['Centraal Station'] },
            { time: '11:08:00', line: '902', to: ['Centraal Station'] },
            { time: '11:14:00', line: '902', to: ['Centraal Station'] },
            { time: '11:20:00', line: '902', to: ['Centraal Station'] },
            { time: '11:26:00', line: '902', to: ['Centraal Station'] },
            { time: '11:32:00', line: '902', to: ['Centraal Station'] },
            { time: '11:38:00', line: '902', to: ['Centraal Station'] },
            { time: '11:44:00', line: '902', to: ['Centraal Station'] },
            { time: '11:50:00', line: '902', to: ['Centraal Station'] },
            { time: '11:56:00', line: '902', to: ['Centraal Station'] },
            { time: '12:02:00', line: '902', to: ['Centraal Station'] },
            { time: '12:08:00', line: '902', to: ['Centraal Station'] },
            { time: '12:14:00', line: '902', to: ['Centraal Station'] },
            { time: '12:20:00', line: '902', to: ['Centraal Station'] },
            { time: '12:26:00', line: '902', to: ['Centraal Station'] },
            { time: '12:32:00', line: '902', to: ['Centraal Station'] },
            { time: '12:38:00', line: '902', to: ['Centraal Station'] },
            { time: '12:44:00', line: '902', to: ['Centraal Station'] },
            { time: '12:50:00', line: '902', to: ['Centraal Station'] },
            { time: '12:56:00', line: '902', to: ['Centraal Station'] },
            { time: '13:02:00', line: '902', to: ['Centraal Station'] },
            { time: '13:08:00', line: '902', to: ['Centraal Station'] },
            { time: '13:14:00', line: '902', to: ['Centraal Station'] },
            { time: '13:20:00', line: '902', to: ['Centraal Station'] },
            { time: '13:26:00', line: '902', to: ['Centraal Station'] },
            { time: '13:32:00', line: '902', to: ['Centraal Station'] },
            { time: '13:38:00', line: '902', to: ['Centraal Station'] },
            { time: '13:44:00', line: '902', to: ['Centraal Station'] },
            { time: '13:50:00', line: '902', to: ['Centraal Station'] },
            { time: '13:56:00', line: '902', to: ['Centraal Station'] },
            { time: '14:02:00', line: '902', to: ['Centraal Station'] },
            { time: '14:08:00', line: '902', to: ['Centraal Station'] },
            { time: '14:14:00', line: '902', to: ['Centraal Station'] },
            { time: '14:20:00', line: '902', to: ['Centraal Station'] },
            { time: '14:26:00', line: '902', to: ['Centraal Station'] },
            { time: '14:32:00', line: '902', to: ['Centraal Station'] },
            { time: '14:38:00', line: '902', to: ['Centraal Station'] },
            { time: '14:44:00', line: '902', to: ['Centraal Station'] },
            { time: '14:50:00', line: '902', to: ['Centraal Station'] },
            { time: '14:56:00', line: '902', to: ['Centraal Station'] },
            { time: '15:02:00', line: '902', to: ['Centraal Station'] },
            { time: '15:08:00', line: '902', to: ['Centraal Station'] },
            { time: '15:14:00', line: '902', to: ['Centraal Station'] },
            { time: '15:20:00', line: '902', to: ['Centraal Station'] },
            { time: '15:26:00', line: '902', to: ['Centraal Station'] },
            { time: '15:32:00', line: '902', to: ['Centraal Station'] },
            { time: '15:38:00', line: '902', to: ['Centraal Station'] },
            { time: '15:44:00', line: '902', to: ['Centraal Station'] },
            { time: '15:50:00', line: '902', to: ['Centraal Station'] },
            { time: '15:56:00', line: '902', to: ['Centraal Station'] },
            { time: '16:02:00', line: '902', to: ['Centraal Station'] },
            { time: '16:08:00', line: '902', to: ['Centraal Station'] },
            { time: '16:14:00', line: '902', to: ['Centraal Station'] },
            { time: '16:20:00', line: '902', to: ['Centraal Station'] },
            { time: '16:26:00', line: '902', to: ['Centraal Station'] },
            { time: '16:32:00', line: '902', to: ['Centraal Station'] },
            { time: '16:38:00', line: '902', to: ['Centraal Station'] },
            { time: '16:44:00', line: '902', to: ['Centraal Station'] },
            { time: '16:50:00', line: '902', to: ['Centraal Station'] },
            { time: '16:56:00', line: '902', to: ['Centraal Station'] },
            { time: '17:02:00', line: '902', to: ['Centraal Station'] },
            { time: '17:08:00', line: '902', to: ['Centraal Station'] },
            { time: '17:14:00', line: '902', to: ['Centraal Station'] },
            { time: '17:20:00', line: '902', to: ['Centraal Station'] },
            { time: '17:26:00', line: '902', to: ['Centraal Station'] },
            { time: '17:32:00', line: '902', to: ['Centraal Station'] },
            { time: '17:38:00', line: '902', to: ['Centraal Station'] },
            { time: '17:44:00', line: '902', to: ['Centraal Station'] },
            { time: '17:50:00', line: '902', to: ['Centraal Station'] },
            { time: '17:56:00', line: '902', to: ['Centraal Station'] },
            { time: '18:02:00', line: '902', to: ['Centraal Station'] },
            { time: '18:08:00', line: '902', to: ['Centraal Station'] },
            { time: '18:14:00', line: '902', to: ['Centraal Station'] },
            { time: '18:20:00', line: '902', to: ['Centraal Station'] },
            { time: '18:26:00', line: '902', to: ['Centraal Station'] },
            { time: '18:32:00', line: '902', to: ['Centraal Station'] },
            { time: '18:38:00', line: '902', to: ['Centraal Station'] },
            { time: '18:44:00', line: '902', to: ['Centraal Station'] },
            { time: '18:50:00', line: '902', to: ['Centraal Station'] },
            { time: '18:56:00', line: '902', to: ['Centraal Station'] },
            { time: '19:02:00', line: '902', to: ['Centraal Station'] },
            { time: '19:08:00', line: '902', to: ['Centraal Station'] },
            { time: '19:14:00', line: '902', to: ['Centraal Station'] },
            { time: '19:20:00', line: '902', to: ['Centraal Station'] },
            { time: '19:26:00', line: '902', to: ['Centraal Station'] },
            { time: '19:32:00', line: '902', to: ['Centraal Station'] },
            { time: '19:38:00', line: '902', to: ['Centraal Station'] },
            { time: '19:44:00', line: '902', to: ['Centraal Station'] },
            { time: '19:50:00', line: '902', to: ['Centraal Station'] },
            { time: '19:56:00', line: '902', to: ['Centraal Station'] },
            { time: '20:02:00', line: '902', to: ['Centraal Station'] },
            { time: '20:08:00', line: '902', to: ['Centraal Station'] },
            { time: '20:14:00', line: '902', to: ['Centraal Station'] },
            { time: '20:20:00', line: '902', to: ['Centraal Station'] },
            { time: '20:26:00', line: '902', to: ['Centraal Station'] },
            { time: '20:32:00', line: '902', to: ['Centraal Station'] },
            { time: '20:38:00', line: '902', to: ['Centraal Station'] },
            { time: '20:44:00', line: '902', to: ['Centraal Station'] },
            { time: '20:50:00', line: '902', to: ['Centraal Station'] },
            { time: '21:02:00', line: '902', to: ['Centraal Station'] },
            { time: '21:14:00', line: '902', to: ['Centraal Station'] },
            { time: '21:26:00', line: '902', to: ['Centraal Station'] },
            { time: '21:38:00', line: '902', to: ['Centraal Station'] },
            { time: '21:50:00', line: '902', to: ['Centraal Station'] },
            { time: '22:02:00', line: '902', to: ['Centraal Station'] },
            { time: '22:14:00', line: '902', to: ['Centraal Station'] },
            { time: '22:26:00', line: '902', to: ['Centraal Station'] },
            { time: '22:38:00', line: '902', to: ['Centraal Station'] },
            { time: '22:50:00', line: '902', to: ['Centraal Station'] },
            { time: '23:02:00', line: '902', to: ['Centraal Station'] },
            { time: '23:14:00', line: '902', to: ['Centraal Station'] },
            { time: '23:26:00', line: '902', to: ['Centraal Station'] },
            { time: '23:38:00', line: '902', to: ['Centraal Station'] },
            { time: '23:50:00', line: '902', to: ['Centraal Station'] },
        ],
        tuesday: [
            { time: '06:26:00', line: '902', to: ['Centraal Station'] },
            { time: '06:32:00', line: '902', to: ['Centraal Station'] },
            { time: '06:38:00', line: '902', to: ['Centraal Station'] },
            { time: '06:44:00', line: '902', to: ['Centraal Station'] },
            { time: '06:50:00', line: '902', to: ['Centraal Station'] },
            { time: '06:56:00', line: '902', to: ['Centraal Station'] },
            { time: '07:02:00', line: '902', to: ['Centraal Station'] },
            { time: '07:08:00', line: '902', to: ['Centraal Station'] },
            { time: '07:14:00', line: '902', to: ['Centraal Station'] },
            { time: '07:20:00', line: '902', to: ['Centraal Station'] },
            { time: '07:26:00', line: '902', to: ['Centraal Station'] },
            { time: '07:32:00', line: '902', to: ['Centraal Station'] },
            { time: '07:38:00', line: '902', to: ['Centraal Station'] },
            { time: '07:44:00', line: '902', to: ['Centraal Station'] },
            { time: '07:50:00', line: '902', to: ['Centraal Station'] },
            { time: '07:56:00', line: '902', to: ['Centraal Station'] },
            { time: '08:02:00', line: '902', to: ['Centraal Station'] },
            { time: '08:08:00', line: '902', to: ['Centraal Station'] },
            { time: '08:14:00', line: '902', to: ['Centraal Station'] },
            { time: '08:20:00', line: '902', to: ['Centraal Station'] },
            { time: '08:26:00', line: '902', to: ['Centraal Station'] },
            { time: '08:32:00', line: '902', to: ['Centraal Station'] },
            { time: '08:38:00', line: '902', to: ['Centraal Station'] },
            { time: '08:44:00', line: '902', to: ['Centraal Station'] },
            { time: '08:50:00', line: '902', to: ['Centraal Station'] },
            { time: '08:56:00', line: '902', to: ['Centraal Station'] },
            { time: '09:02:00', line: '902', to: ['Centraal Station'] },
            { time: '09:08:00', line: '902', to: ['Centraal Station'] },
            { time: '09:14:00', line: '902', to: ['Centraal Station'] },
            { time: '09:20:00', line: '902', to: ['Centraal Station'] },
            { time: '09:26:00', line: '902', to: ['Centraal Station'] },
            { time: '09:32:00', line: '902', to: ['Centraal Station'] },
            { time: '09:38:00', line: '902', to: ['Centraal Station'] },
            { time: '09:44:00', line: '902', to: ['Centraal Station'] },
            { time: '09:50:00', line: '902', to: ['Centraal Station'] },
            { time: '09:56:00', line: '902', to: ['Centraal Station'] },
            { time: '10:02:00', line: '902', to: ['Centraal Station'] },
            { time: '10:08:00', line: '902', to: ['Centraal Station'] },
            { time: '10:14:00', line: '902', to: ['Centraal Station'] },
            { time: '10:20:00', line: '902', to: ['Centraal Station'] },
            { time: '10:26:00', line: '902', to: ['Centraal Station'] },
            { time: '10:32:00', line: '902', to: ['Centraal Station'] },
            { time: '10:38:00', line: '902', to: ['Centraal Station'] },
            { time: '10:44:00', line: '902', to: ['Centraal Station'] },
            { time: '10:50:00', line: '902', to: ['Centraal Station'] },
            { time: '10:56:00', line: '902', to: ['Centraal Station'] },
            { time: '11:02:00', line: '902', to: ['Centraal Station'] },
            { time: '11:08:00', line: '902', to: ['Centraal Station'] },
            { time: '11:14:00', line: '902', to: ['Centraal Station'] },
            { time: '11:20:00', line: '902', to: ['Centraal Station'] },
            { time: '11:26:00', line: '902', to: ['Centraal Station'] },
            { time: '11:32:00', line: '902', to: ['Centraal Station'] },
            { time: '11:38:00', line: '902', to: ['Centraal Station'] },
            { time: '11:44:00', line: '902', to: ['Centraal Station'] },
            { time: '11:50:00', line: '902', to: ['Centraal Station'] },
            { time: '11:56:00', line: '902', to: ['Centraal Station'] },
            { time: '12:02:00', line: '902', to: ['Centraal Station'] },
            { time: '12:08:00', line: '902', to: ['Centraal Station'] },
            { time: '12:14:00', line: '902', to: ['Centraal Station'] },
            { time: '12:20:00', line: '902', to: ['Centraal Station'] },
            { time: '12:26:00', line: '902', to: ['Centraal Station'] },
            { time: '12:32:00', line: '902', to: ['Centraal Station'] },
            { time: '12:38:00', line: '902', to: ['Centraal Station'] },
            { time: '12:44:00', line: '902', to: ['Centraal Station'] },
            { time: '12:50:00', line: '902', to: ['Centraal Station'] },
            { time: '12:56:00', line: '902', to: ['Centraal Station'] },
            { time: '13:02:00', line: '902', to: ['Centraal Station'] },
            { time: '13:08:00', line: '902', to: ['Centraal Station'] },
            { time: '13:14:00', line: '902', to: ['Centraal Station'] },
            { time: '13:20:00', line: '902', to: ['Centraal Station'] },
            { time: '13:26:00', line: '902', to: ['Centraal Station'] },
            { time: '13:32:00', line: '902', to: ['Centraal Station'] },
            { time: '13:38:00', line: '902', to: ['Centraal Station'] },
            { time: '13:44:00', line: '902', to: ['Centraal Station'] },
            { time: '13:50:00', line: '902', to: ['Centraal Station'] },
            { time: '13:56:00', line: '902', to: ['Centraal Station'] },
            { time: '14:02:00', line: '902', to: ['Centraal Station'] },
            { time: '14:08:00', line: '902', to: ['Centraal Station'] },
            { time: '14:14:00', line: '902', to: ['Centraal Station'] },
            { time: '14:20:00', line: '902', to: ['Centraal Station'] },
            { time: '14:26:00', line: '902', to: ['Centraal Station'] },
            { time: '14:32:00', line: '902', to: ['Centraal Station'] },
            { time: '14:38:00', line: '902', to: ['Centraal Station'] },
            { time: '14:44:00', line: '902', to: ['Centraal Station'] },
            { time: '14:50:00', line: '902', to: ['Centraal Station'] },
            { time: '14:56:00', line: '902', to: ['Centraal Station'] },
            { time: '15:02:00', line: '902', to: ['Centraal Station'] },
            { time: '15:08:00', line: '902', to: ['Centraal Station'] },
            { time: '15:14:00', line: '902', to: ['Centraal Station'] },
            { time: '15:20:00', line: '902', to: ['Centraal Station'] },
            { time: '15:26:00', line: '902', to: ['Centraal Station'] },
            { time: '15:32:00', line: '902', to: ['Centraal Station'] },
            { time: '15:38:00', line: '902', to: ['Centraal Station'] },
            { time: '15:44:00', line: '902', to: ['Centraal Station'] },
            { time: '15:50:00', line: '902', to: ['Centraal Station'] },
            { time: '15:56:00', line: '902', to: ['Centraal Station'] },
            { time: '16:02:00', line: '902', to: ['Centraal Station'] },
            { time: '16:08:00', line: '902', to: ['Centraal Station'] },
            { time: '16:14:00', line: '902', to: ['Centraal Station'] },
            { time: '16:20:00', line: '902', to: ['Centraal Station'] },
            { time: '16:26:00', line: '902', to: ['Centraal Station'] },
            { time: '16:32:00', line: '902', to: ['Centraal Station'] },
            { time: '16:38:00', line: '902', to: ['Centraal Station'] },
            { time: '16:44:00', line: '902', to: ['Centraal Station'] },
            { time: '16:50:00', line: '902', to: ['Centraal Station'] },
            { time: '16:56:00', line: '902', to: ['Centraal Station'] },
            { time: '17:02:00', line: '902', to: ['Centraal Station'] },
            { time: '17:08:00', line: '902', to: ['Centraal Station'] },
            { time: '17:14:00', line: '902', to: ['Centraal Station'] },
            { time: '17:20:00', line: '902', to: ['Centraal Station'] },
            { time: '17:26:00', line: '902', to: ['Centraal Station'] },
            { time: '17:32:00', line: '902', to: ['Centraal Station'] },
            { time: '17:38:00', line: '902', to: ['Centraal Station'] },
            { time: '17:44:00', line: '902', to: ['Centraal Station'] },
            { time: '17:50:00', line: '902', to: ['Centraal Station'] },
            { time: '17:56:00', line: '902', to: ['Centraal Station'] },
            { time: '18:02:00', line: '902', to: ['Centraal Station'] },
            { time: '18:08:00', line: '902', to: ['Centraal Station'] },
            { time: '18:14:00', line: '902', to: ['Centraal Station'] },
            { time: '18:20:00', line: '902', to: ['Centraal Station'] },
            { time: '18:26:00', line: '902', to: ['Centraal Station'] },
            { time: '18:32:00', line: '902', to: ['Centraal Station'] },
            { time: '18:38:00', line: '902', to: ['Centraal Station'] },
            { time: '18:44:00', line: '902', to: ['Centraal Station'] },
            { time: '18:50:00', line: '902', to: ['Centraal Station'] },
            { time: '18:56:00', line: '902', to: ['Centraal Station'] },
            { time: '19:02:00', line: '902', to: ['Centraal Station'] },
            { time: '19:08:00', line: '902', to: ['Centraal Station'] },
            { time: '19:14:00', line: '902', to: ['Centraal Station'] },
            { time: '19:20:00', line: '902', to: ['Centraal Station'] },
            { time: '19:26:00', line: '902', to: ['Centraal Station'] },
            { time: '19:32:00', line: '902', to: ['Centraal Station'] },
            { time: '19:38:00', line: '902', to: ['Centraal Station'] },
            { time: '19:44:00', line: '902', to: ['Centraal Station'] },
            { time: '19:50:00', line: '902', to: ['Centraal Station'] },
            { time: '19:56:00', line: '902', to: ['Centraal Station'] },
            { time: '20:02:00', line: '902', to: ['Centraal Station'] },
            { time: '20:08:00', line: '902', to: ['Centraal Station'] },
            { time: '20:14:00', line: '902', to: ['Centraal Station'] },
            { time: '20:20:00', line: '902', to: ['Centraal Station'] },
            { time: '20:26:00', line: '902', to: ['Centraal Station'] },
            { time: '20:32:00', line: '902', to: ['Centraal Station'] },
            { time: '20:38:00', line: '902', to: ['Centraal Station'] },
            { time: '20:44:00', line: '902', to: ['Centraal Station'] },
            { time: '20:50:00', line: '902', to: ['Centraal Station'] },
            { time: '21:02:00', line: '902', to: ['Centraal Station'] },
            { time: '21:14:00', line: '902', to: ['Centraal Station'] },
            { time: '21:26:00', line: '902', to: ['Centraal Station'] },
            { time: '21:38:00', line: '902', to: ['Centraal Station'] },
            { time: '21:50:00', line: '902', to: ['Centraal Station'] },
            { time: '22:02:00', line: '902', to: ['Centraal Station'] },
            { time: '22:14:00', line: '902', to: ['Centraal Station'] },
            { time: '22:26:00', line: '902', to: ['Centraal Station'] },
            { time: '22:38:00', line: '902', to: ['Centraal Station'] },
            { time: '22:50:00', line: '902', to: ['Centraal Station'] },
            { time: '23:02:00', line: '902', to: ['Centraal Station'] },
            { time: '23:14:00', line: '902', to: ['Centraal Station'] },
            { time: '23:26:00', line: '902', to: ['Centraal Station'] },
            { time: '23:38:00', line: '902', to: ['Centraal Station'] },
            { time: '23:50:00', line: '902', to: ['Centraal Station'] },
        ],
        wednesday: [
            { time: '06:26:00', line: '902', to: ['Centraal Station'] },
            { time: '06:32:00', line: '902', to: ['Centraal Station'] },
            { time: '06:38:00', line: '902', to: ['Centraal Station'] },
            { time: '06:44:00', line: '902', to: ['Centraal Station'] },
            { time: '06:50:00', line: '902', to: ['Centraal Station'] },
            { time: '06:56:00', line: '902', to: ['Centraal Station'] },
            { time: '07:02:00', line: '902', to: ['Centraal Station'] },
            { time: '07:08:00', line: '902', to: ['Centraal Station'] },
            { time: '07:14:00', line: '902', to: ['Centraal Station'] },
            { time: '07:20:00', line: '902', to: ['Centraal Station'] },
            { time: '07:26:00', line: '902', to: ['Centraal Station'] },
            { time: '07:32:00', line: '902', to: ['Centraal Station'] },
            { time: '07:38:00', line: '902', to: ['Centraal Station'] },
            { time: '07:44:00', line: '902', to: ['Centraal Station'] },
            { time: '07:50:00', line: '902', to: ['Centraal Station'] },
            { time: '07:56:00', line: '902', to: ['Centraal Station'] },
            { time: '08:02:00', line: '902', to: ['Centraal Station'] },
            { time: '08:08:00', line: '902', to: ['Centraal Station'] },
            { time: '08:14:00', line: '902', to: ['Centraal Station'] },
            { time: '08:20:00', line: '902', to: ['Centraal Station'] },
            { time: '08:26:00', line: '902', to: ['Centraal Station'] },
            { time: '08:32:00', line: '902', to: ['Centraal Station'] },
            { time: '08:38:00', line: '902', to: ['Centraal Station'] },
            { time: '08:44:00', line: '902', to: ['Centraal Station'] },
            { time: '08:50:00', line: '902', to: ['Centraal Station'] },
            { time: '08:56:00', line: '902', to: ['Centraal Station'] },
            { time: '09:02:00', line: '902', to: ['Centraal Station'] },
            { time: '09:08:00', line: '902', to: ['Centraal Station'] },
            { time: '09:14:00', line: '902', to: ['Centraal Station'] },
            { time: '09:20:00', line: '902', to: ['Centraal Station'] },
            { time: '09:26:00', line: '902', to: ['Centraal Station'] },
            { time: '09:32:00', line: '902', to: ['Centraal Station'] },
            { time: '09:38:00', line: '902', to: ['Centraal Station'] },
            { time: '09:44:00', line: '902', to: ['Centraal Station'] },
            { time: '09:50:00', line: '902', to: ['Centraal Station'] },
            { time: '09:56:00', line: '902', to: ['Centraal Station'] },
            { time: '10:02:00', line: '902', to: ['Centraal Station'] },
            { time: '10:08:00', line: '902', to: ['Centraal Station'] },
            { time: '10:14:00', line: '902', to: ['Centraal Station'] },
            { time: '10:20:00', line: '902', to: ['Centraal Station'] },
            { time: '10:26:00', line: '902', to: ['Centraal Station'] },
            { time: '10:32:00', line: '902', to: ['Centraal Station'] },
            { time: '10:38:00', line: '902', to: ['Centraal Station'] },
            { time: '10:44:00', line: '902', to: ['Centraal Station'] },
            { time: '10:50:00', line: '902', to: ['Centraal Station'] },
            { time: '10:56:00', line: '902', to: ['Centraal Station'] },
            { time: '11:02:00', line: '902', to: ['Centraal Station'] },
            { time: '11:08:00', line: '902', to: ['Centraal Station'] },
            { time: '11:14:00', line: '902', to: ['Centraal Station'] },
            { time: '11:20:00', line: '902', to: ['Centraal Station'] },
            { time: '11:26:00', line: '902', to: ['Centraal Station'] },
            { time: '11:32:00', line: '902', to: ['Centraal Station'] },
            { time: '11:38:00', line: '902', to: ['Centraal Station'] },
            { time: '11:44:00', line: '902', to: ['Centraal Station'] },
            { time: '11:50:00', line: '902', to: ['Centraal Station'] },
            { time: '11:56:00', line: '902', to: ['Centraal Station'] },
            { time: '12:02:00', line: '902', to: ['Centraal Station'] },
            { time: '12:08:00', line: '902', to: ['Centraal Station'] },
            { time: '12:14:00', line: '902', to: ['Centraal Station'] },
            { time: '12:20:00', line: '902', to: ['Centraal Station'] },
            { time: '12:26:00', line: '902', to: ['Centraal Station'] },
            { time: '12:32:00', line: '902', to: ['Centraal Station'] },
            { time: '12:38:00', line: '902', to: ['Centraal Station'] },
            { time: '12:44:00', line: '902', to: ['Centraal Station'] },
            { time: '12:50:00', line: '902', to: ['Centraal Station'] },
            { time: '12:56:00', line: '902', to: ['Centraal Station'] },
            { time: '13:02:00', line: '902', to: ['Centraal Station'] },
            { time: '13:08:00', line: '902', to: ['Centraal Station'] },
            { time: '13:14:00', line: '902', to: ['Centraal Station'] },
            { time: '13:20:00', line: '902', to: ['Centraal Station'] },
            { time: '13:26:00', line: '902', to: ['Centraal Station'] },
            { time: '13:32:00', line: '902', to: ['Centraal Station'] },
            { time: '13:38:00', line: '902', to: ['Centraal Station'] },
            { time: '13:44:00', line: '902', to: ['Centraal Station'] },
            { time: '13:50:00', line: '902', to: ['Centraal Station'] },
            { time: '13:56:00', line: '902', to: ['Centraal Station'] },
            { time: '14:02:00', line: '902', to: ['Centraal Station'] },
            { time: '14:08:00', line: '902', to: ['Centraal Station'] },
            { time: '14:14:00', line: '902', to: ['Centraal Station'] },
            { time: '14:20:00', line: '902', to: ['Centraal Station'] },
            { time: '14:26:00', line: '902', to: ['Centraal Station'] },
            { time: '14:32:00', line: '902', to: ['Centraal Station'] },
            { time: '14:38:00', line: '902', to: ['Centraal Station'] },
            { time: '14:44:00', line: '902', to: ['Centraal Station'] },
            { time: '14:50:00', line: '902', to: ['Centraal Station'] },
            { time: '14:56:00', line: '902', to: ['Centraal Station'] },
            { time: '15:02:00', line: '902', to: ['Centraal Station'] },
            { time: '15:08:00', line: '902', to: ['Centraal Station'] },
            { time: '15:14:00', line: '902', to: ['Centraal Station'] },
            { time: '15:20:00', line: '902', to: ['Centraal Station'] },
            { time: '15:26:00', line: '902', to: ['Centraal Station'] },
            { time: '15:32:00', line: '902', to: ['Centraal Station'] },
            { time: '15:38:00', line: '902', to: ['Centraal Station'] },
            { time: '15:44:00', line: '902', to: ['Centraal Station'] },
            { time: '15:50:00', line: '902', to: ['Centraal Station'] },
            { time: '15:56:00', line: '902', to: ['Centraal Station'] },
            { time: '16:02:00', line: '902', to: ['Centraal Station'] },
            { time: '16:08:00', line: '902', to: ['Centraal Station'] },
            { time: '16:14:00', line: '902', to: ['Centraal Station'] },
            { time: '16:20:00', line: '902', to: ['Centraal Station'] },
            { time: '16:26:00', line: '902', to: ['Centraal Station'] },
            { time: '16:32:00', line: '902', to: ['Centraal Station'] },
            { time: '16:38:00', line: '902', to: ['Centraal Station'] },
            { time: '16:44:00', line: '902', to: ['Centraal Station'] },
            { time: '16:50:00', line: '902', to: ['Centraal Station'] },
            { time: '16:56:00', line: '902', to: ['Centraal Station'] },
            { time: '17:02:00', line: '902', to: ['Centraal Station'] },
            { time: '17:08:00', line: '902', to: ['Centraal Station'] },
            { time: '17:14:00', line: '902', to: ['Centraal Station'] },
            { time: '17:20:00', line: '902', to: ['Centraal Station'] },
            { time: '17:26:00', line: '902', to: ['Centraal Station'] },
            { time: '17:32:00', line: '902', to: ['Centraal Station'] },
            { time: '17:38:00', line: '902', to: ['Centraal Station'] },
            { time: '17:44:00', line: '902', to: ['Centraal Station'] },
            { time: '17:50:00', line: '902', to: ['Centraal Station'] },
            { time: '17:56:00', line: '902', to: ['Centraal Station'] },
            { time: '18:02:00', line: '902', to: ['Centraal Station'] },
            { time: '18:08:00', line: '902', to: ['Centraal Station'] },
            { time: '18:14:00', line: '902', to: ['Centraal Station'] },
            { time: '18:20:00', line: '902', to: ['Centraal Station'] },
            { time: '18:26:00', line: '902', to: ['Centraal Station'] },
            { time: '18:32:00', line: '902', to: ['Centraal Station'] },
            { time: '18:38:00', line: '902', to: ['Centraal Station'] },
            { time: '18:44:00', line: '902', to: ['Centraal Station'] },
            { time: '18:50:00', line: '902', to: ['Centraal Station'] },
            { time: '18:56:00', line: '902', to: ['Centraal Station'] },
            { time: '19:02:00', line: '902', to: ['Centraal Station'] },
            { time: '19:08:00', line: '902', to: ['Centraal Station'] },
            { time: '19:14:00', line: '902', to: ['Centraal Station'] },
            { time: '19:20:00', line: '902', to: ['Centraal Station'] },
            { time: '19:26:00', line: '902', to: ['Centraal Station'] },
            { time: '19:32:00', line: '902', to: ['Centraal Station'] },
            { time: '19:38:00', line: '902', to: ['Centraal Station'] },
            { time: '19:44:00', line: '902', to: ['Centraal Station'] },
            { time: '19:50:00', line: '902', to: ['Centraal Station'] },
            { time: '19:56:00', line: '902', to: ['Centraal Station'] },
            { time: '20:02:00', line: '902', to: ['Centraal Station'] },
            { time: '20:08:00', line: '902', to: ['Centraal Station'] },
            { time: '20:14:00', line: '902', to: ['Centraal Station'] },
            { time: '20:20:00', line: '902', to: ['Centraal Station'] },
            { time: '20:26:00', line: '902', to: ['Centraal Station'] },
            { time: '20:32:00', line: '902', to: ['Centraal Station'] },
            { time: '20:38:00', line: '902', to: ['Centraal Station'] },
            { time: '20:44:00', line: '902', to: ['Centraal Station'] },
            { time: '20:50:00', line: '902', to: ['Centraal Station'] },
            { time: '21:02:00', line: '902', to: ['Centraal Station'] },
            { time: '21:14:00', line: '902', to: ['Centraal Station'] },
            { time: '21:26:00', line: '902', to: ['Centraal Station'] },
            { time: '21:38:00', line: '902', to: ['Centraal Station'] },
            { time: '21:50:00', line: '902', to: ['Centraal Station'] },
            { time: '22:02:00', line: '902', to: ['Centraal Station'] },
            { time: '22:14:00', line: '902', to: ['Centraal Station'] },
            { time: '22:26:00', line: '902', to: ['Centraal Station'] },
            { time: '22:38:00', line: '902', to: ['Centraal Station'] },
            { time: '22:50:00', line: '902', to: ['Centraal Station'] },
            { time: '23:02:00', line: '902', to: ['Centraal Station'] },
            { time: '23:14:00', line: '902', to: ['Centraal Station'] },
            { time: '23:26:00', line: '902', to: ['Centraal Station'] },
            { time: '23:38:00', line: '902', to: ['Centraal Station'] },
            { time: '23:50:00', line: '902', to: ['Centraal Station'] },
        ],
        thursday: [
            { time: '06:26:00', line: '902', to: ['Centraal Station'] },
            { time: '06:32:00', line: '902', to: ['Centraal Station'] },
            { time: '06:38:00', line: '902', to: ['Centraal Station'] },
            { time: '06:44:00', line: '902', to: ['Centraal Station'] },
            { time: '06:50:00', line: '902', to: ['Centraal Station'] },
            { time: '06:56:00', line: '902', to: ['Centraal Station'] },
            { time: '07:02:00', line: '902', to: ['Centraal Station'] },
            { time: '07:08:00', line: '902', to: ['Centraal Station'] },
            { time: '07:14:00', line: '902', to: ['Centraal Station'] },
            { time: '07:20:00', line: '902', to: ['Centraal Station'] },
            { time: '07:26:00', line: '902', to: ['Centraal Station'] },
            { time: '07:32:00', line: '902', to: ['Centraal Station'] },
            { time: '07:38:00', line: '902', to: ['Centraal Station'] },
            { time: '07:44:00', line: '902', to: ['Centraal Station'] },
            { time: '07:50:00', line: '902', to: ['Centraal Station'] },
            { time: '07:56:00', line: '902', to: ['Centraal Station'] },
            { time: '08:02:00', line: '902', to: ['Centraal Station'] },
            { time: '08:08:00', line: '902', to: ['Centraal Station'] },
            { time: '08:14:00', line: '902', to: ['Centraal Station'] },
            { time: '08:20:00', line: '902', to: ['Centraal Station'] },
            { time: '08:26:00', line: '902', to: ['Centraal Station'] },
            { time: '08:32:00', line: '902', to: ['Centraal Station'] },
            { time: '08:38:00', line: '902', to: ['Centraal Station'] },
            { time: '08:44:00', line: '902', to: ['Centraal Station'] },
            { time: '08:50:00', line: '902', to: ['Centraal Station'] },
            { time: '08:56:00', line: '902', to: ['Centraal Station'] },
            { time: '09:02:00', line: '902', to: ['Centraal Station'] },
            { time: '09:08:00', line: '902', to: ['Centraal Station'] },
            { time: '09:14:00', line: '902', to: ['Centraal Station'] },
            { time: '09:20:00', line: '902', to: ['Centraal Station'] },
            { time: '09:26:00', line: '902', to: ['Centraal Station'] },
            { time: '09:32:00', line: '902', to: ['Centraal Station'] },
            { time: '09:38:00', line: '902', to: ['Centraal Station'] },
            { time: '09:44:00', line: '902', to: ['Centraal Station'] },
            { time: '09:50:00', line: '902', to: ['Centraal Station'] },
            { time: '09:56:00', line: '902', to: ['Centraal Station'] },
            { time: '10:02:00', line: '902', to: ['Centraal Station'] },
            { time: '10:08:00', line: '902', to: ['Centraal Station'] },
            { time: '10:14:00', line: '902', to: ['Centraal Station'] },
            { time: '10:20:00', line: '902', to: ['Centraal Station'] },
            { time: '10:26:00', line: '902', to: ['Centraal Station'] },
            { time: '10:32:00', line: '902', to: ['Centraal Station'] },
            { time: '10:38:00', line: '902', to: ['Centraal Station'] },
            { time: '10:44:00', line: '902', to: ['Centraal Station'] },
            { time: '10:50:00', line: '902', to: ['Centraal Station'] },
            { time: '10:56:00', line: '902', to: ['Centraal Station'] },
            { time: '11:02:00', line: '902', to: ['Centraal Station'] },
            { time: '11:08:00', line: '902', to: ['Centraal Station'] },
            { time: '11:14:00', line: '902', to: ['Centraal Station'] },
            { time: '11:20:00', line: '902', to: ['Centraal Station'] },
            { time: '11:26:00', line: '902', to: ['Centraal Station'] },
            { time: '11:32:00', line: '902', to: ['Centraal Station'] },
            { time: '11:38:00', line: '902', to: ['Centraal Station'] },
            { time: '11:44:00', line: '902', to: ['Centraal Station'] },
            { time: '11:50:00', line: '902', to: ['Centraal Station'] },
            { time: '11:56:00', line: '902', to: ['Centraal Station'] },
            { time: '12:02:00', line: '902', to: ['Centraal Station'] },
            { time: '12:08:00', line: '902', to: ['Centraal Station'] },
            { time: '12:14:00', line: '902', to: ['Centraal Station'] },
            { time: '12:20:00', line: '902', to: ['Centraal Station'] },
            { time: '12:26:00', line: '902', to: ['Centraal Station'] },
            { time: '12:32:00', line: '902', to: ['Centraal Station'] },
            { time: '12:38:00', line: '902', to: ['Centraal Station'] },
            { time: '12:44:00', line: '902', to: ['Centraal Station'] },
            { time: '12:50:00', line: '902', to: ['Centraal Station'] },
            { time: '12:56:00', line: '902', to: ['Centraal Station'] },
            { time: '13:02:00', line: '902', to: ['Centraal Station'] },
            { time: '13:08:00', line: '902', to: ['Centraal Station'] },
            { time: '13:14:00', line: '902', to: ['Centraal Station'] },
            { time: '13:20:00', line: '902', to: ['Centraal Station'] },
            { time: '13:26:00', line: '902', to: ['Centraal Station'] },
            { time: '13:32:00', line: '902', to: ['Centraal Station'] },
            { time: '13:38:00', line: '902', to: ['Centraal Station'] },
            { time: '13:44:00', line: '902', to: ['Centraal Station'] },
            { time: '13:50:00', line: '902', to: ['Centraal Station'] },
            { time: '13:56:00', line: '902', to: ['Centraal Station'] },
            { time: '14:02:00', line: '902', to: ['Centraal Station'] },
            { time: '14:08:00', line: '902', to: ['Centraal Station'] },
            { time: '14:14:00', line: '902', to: ['Centraal Station'] },
            { time: '14:20:00', line: '902', to: ['Centraal Station'] },
            { time: '14:26:00', line: '902', to: ['Centraal Station'] },
            { time: '14:32:00', line: '902', to: ['Centraal Station'] },
            { time: '14:38:00', line: '902', to: ['Centraal Station'] },
            { time: '14:44:00', line: '902', to: ['Centraal Station'] },
            { time: '14:50:00', line: '902', to: ['Centraal Station'] },
            { time: '14:56:00', line: '902', to: ['Centraal Station'] },
            { time: '15:02:00', line: '902', to: ['Centraal Station'] },
            { time: '15:08:00', line: '902', to: ['Centraal Station'] },
            { time: '15:14:00', line: '902', to: ['Centraal Station'] },
            { time: '15:20:00', line: '902', to: ['Centraal Station'] },
            { time: '15:26:00', line: '902', to: ['Centraal Station'] },
            { time: '15:32:00', line: '902', to: ['Centraal Station'] },
            { time: '15:38:00', line: '902', to: ['Centraal Station'] },
            { time: '15:44:00', line: '902', to: ['Centraal Station'] },
            { time: '15:50:00', line: '902', to: ['Centraal Station'] },
            { time: '15:56:00', line: '902', to: ['Centraal Station'] },
            { time: '16:02:00', line: '902', to: ['Centraal Station'] },
            { time: '16:08:00', line: '902', to: ['Centraal Station'] },
            { time: '16:14:00', line: '902', to: ['Centraal Station'] },
            { time: '16:20:00', line: '902', to: ['Centraal Station'] },
            { time: '16:26:00', line: '902', to: ['Centraal Station'] },
            { time: '16:32:00', line: '902', to: ['Centraal Station'] },
            { time: '16:38:00', line: '902', to: ['Centraal Station'] },
            { time: '16:44:00', line: '902', to: ['Centraal Station'] },
            { time: '16:50:00', line: '902', to: ['Centraal Station'] },
            { time: '16:56:00', line: '902', to: ['Centraal Station'] },
            { time: '17:02:00', line: '902', to: ['Centraal Station'] },
            { time: '17:08:00', line: '902', to: ['Centraal Station'] },
            { time: '17:14:00', line: '902', to: ['Centraal Station'] },
            { time: '17:20:00', line: '902', to: ['Centraal Station'] },
            { time: '17:26:00', line: '902', to: ['Centraal Station'] },
            { time: '17:32:00', line: '902', to: ['Centraal Station'] },
            { time: '17:38:00', line: '902', to: ['Centraal Station'] },
            { time: '17:44:00', line: '902', to: ['Centraal Station'] },
            { time: '17:50:00', line: '902', to: ['Centraal Station'] },
            { time: '17:56:00', line: '902', to: ['Centraal Station'] },
            { time: '18:02:00', line: '902', to: ['Centraal Station'] },
            { time: '18:08:00', line: '902', to: ['Centraal Station'] },
            { time: '18:14:00', line: '902', to: ['Centraal Station'] },
            { time: '18:20:00', line: '902', to: ['Centraal Station'] },
            { time: '18:26:00', line: '902', to: ['Centraal Station'] },
            { time: '18:32:00', line: '902', to: ['Centraal Station'] },
            { time: '18:38:00', line: '902', to: ['Centraal Station'] },
            { time: '18:44:00', line: '902', to: ['Centraal Station'] },
            { time: '18:50:00', line: '902', to: ['Centraal Station'] },
            { time: '18:56:00', line: '902', to: ['Centraal Station'] },
            { time: '19:02:00', line: '902', to: ['Centraal Station'] },
            { time: '19:08:00', line: '902', to: ['Centraal Station'] },
            { time: '19:14:00', line: '902', to: ['Centraal Station'] },
            { time: '19:20:00', line: '902', to: ['Centraal Station'] },
            { time: '19:26:00', line: '902', to: ['Centraal Station'] },
            { time: '19:32:00', line: '902', to: ['Centraal Station'] },
            { time: '19:38:00', line: '902', to: ['Centraal Station'] },
            { time: '19:44:00', line: '902', to: ['Centraal Station'] },
            { time: '19:50:00', line: '902', to: ['Centraal Station'] },
            { time: '19:56:00', line: '902', to: ['Centraal Station'] },
            { time: '20:02:00', line: '902', to: ['Centraal Station'] },
            { time: '20:08:00', line: '902', to: ['Centraal Station'] },
            { time: '20:14:00', line: '902', to: ['Centraal Station'] },
            { time: '20:20:00', line: '902', to: ['Centraal Station'] },
            { time: '20:26:00', line: '902', to: ['Centraal Station'] },
            { time: '20:32:00', line: '902', to: ['Centraal Station'] },
            { time: '20:38:00', line: '902', to: ['Centraal Station'] },
            { time: '20:44:00', line: '902', to: ['Centraal Station'] },
            { time: '20:50:00', line: '902', to: ['Centraal Station'] },
            { time: '21:02:00', line: '902', to: ['Centraal Station'] },
            { time: '21:14:00', line: '902', to: ['Centraal Station'] },
            { time: '21:26:00', line: '902', to: ['Centraal Station'] },
            { time: '21:38:00', line: '902', to: ['Centraal Station'] },
            { time: '21:50:00', line: '902', to: ['Centraal Station'] },
            { time: '22:02:00', line: '902', to: ['Centraal Station'] },
            { time: '22:14:00', line: '902', to: ['Centraal Station'] },
            { time: '22:26:00', line: '902', to: ['Centraal Station'] },
            { time: '22:38:00', line: '902', to: ['Centraal Station'] },
            { time: '22:50:00', line: '902', to: ['Centraal Station'] },
            { time: '23:02:00', line: '902', to: ['Centraal Station'] },
            { time: '23:14:00', line: '902', to: ['Centraal Station'] },
            { time: '23:26:00', line: '902', to: ['Centraal Station'] },
            { time: '23:38:00', line: '902', to: ['Centraal Station'] },
            { time: '23:50:00', line: '902', to: ['Centraal Station'] },
        ],
        friday: [
            { time: '06:26:00', line: '902', to: ['Centraal Station'] },
            { time: '06:32:00', line: '902', to: ['Centraal Station'] },
            { time: '06:38:00', line: '902', to: ['Centraal Station'] },
            { time: '06:44:00', line: '902', to: ['Centraal Station'] },
            { time: '06:50:00', line: '902', to: ['Centraal Station'] },
            { time: '06:56:00', line: '902', to: ['Centraal Station'] },
            { time: '07:02:00', line: '902', to: ['Centraal Station'] },
            { time: '07:08:00', line: '902', to: ['Centraal Station'] },
            { time: '07:14:00', line: '902', to: ['Centraal Station'] },
            { time: '07:20:00', line: '902', to: ['Centraal Station'] },
            { time: '07:26:00', line: '902', to: ['Centraal Station'] },
            { time: '07:32:00', line: '902', to: ['Centraal Station'] },
            { time: '07:38:00', line: '902', to: ['Centraal Station'] },
            { time: '07:44:00', line: '902', to: ['Centraal Station'] },
            { time: '07:50:00', line: '902', to: ['Centraal Station'] },
            { time: '07:56:00', line: '902', to: ['Centraal Station'] },
            { time: '08:02:00', line: '902', to: ['Centraal Station'] },
            { time: '08:08:00', line: '902', to: ['Centraal Station'] },
            { time: '08:14:00', line: '902', to: ['Centraal Station'] },
            { time: '08:20:00', line: '902', to: ['Centraal Station'] },
            { time: '08:26:00', line: '902', to: ['Centraal Station'] },
            { time: '08:32:00', line: '902', to: ['Centraal Station'] },
            { time: '08:38:00', line: '902', to: ['Centraal Station'] },
            { time: '08:44:00', line: '902', to: ['Centraal Station'] },
            { time: '08:50:00', line: '902', to: ['Centraal Station'] },
            { time: '08:56:00', line: '902', to: ['Centraal Station'] },
            { time: '09:02:00', line: '902', to: ['Centraal Station'] },
            { time: '09:08:00', line: '902', to: ['Centraal Station'] },
            { time: '09:14:00', line: '902', to: ['Centraal Station'] },
            { time: '09:20:00', line: '902', to: ['Centraal Station'] },
            { time: '09:26:00', line: '902', to: ['Centraal Station'] },
            { time: '09:32:00', line: '902', to: ['Centraal Station'] },
            { time: '09:38:00', line: '902', to: ['Centraal Station'] },
            { time: '09:44:00', line: '902', to: ['Centraal Station'] },
            { time: '09:50:00', line: '902', to: ['Centraal Station'] },
            { time: '09:56:00', line: '902', to: ['Centraal Station'] },
            { time: '10:02:00', line: '902', to: ['Centraal Station'] },
            { time: '10:08:00', line: '902', to: ['Centraal Station'] },
            { time: '10:14:00', line: '902', to: ['Centraal Station'] },
            { time: '10:20:00', line: '902', to: ['Centraal Station'] },
            { time: '10:26:00', line: '902', to: ['Centraal Station'] },
            { time: '10:32:00', line: '902', to: ['Centraal Station'] },
            { time: '10:38:00', line: '902', to: ['Centraal Station'] },
            { time: '10:44:00', line: '902', to: ['Centraal Station'] },
            { time: '10:50:00', line: '902', to: ['Centraal Station'] },
            { time: '10:56:00', line: '902', to: ['Centraal Station'] },
            { time: '11:02:00', line: '902', to: ['Centraal Station'] },
            { time: '11:08:00', line: '902', to: ['Centraal Station'] },
            { time: '11:14:00', line: '902', to: ['Centraal Station'] },
            { time: '11:20:00', line: '902', to: ['Centraal Station'] },
            { time: '11:26:00', line: '902', to: ['Centraal Station'] },
            { time: '11:32:00', line: '902', to: ['Centraal Station'] },
            { time: '11:38:00', line: '902', to: ['Centraal Station'] },
            { time: '11:44:00', line: '902', to: ['Centraal Station'] },
            { time: '11:50:00', line: '902', to: ['Centraal Station'] },
            { time: '11:56:00', line: '902', to: ['Centraal Station'] },
            { time: '12:02:00', line: '902', to: ['Centraal Station'] },
            { time: '12:08:00', line: '902', to: ['Centraal Station'] },
            { time: '12:14:00', line: '902', to: ['Centraal Station'] },
            { time: '12:20:00', line: '902', to: ['Centraal Station'] },
            { time: '12:26:00', line: '902', to: ['Centraal Station'] },
            { time: '12:32:00', line: '902', to: ['Centraal Station'] },
            { time: '12:38:00', line: '902', to: ['Centraal Station'] },
            { time: '12:44:00', line: '902', to: ['Centraal Station'] },
            { time: '12:50:00', line: '902', to: ['Centraal Station'] },
            { time: '12:56:00', line: '902', to: ['Centraal Station'] },
            { time: '13:02:00', line: '902', to: ['Centraal Station'] },
            { time: '13:08:00', line: '902', to: ['Centraal Station'] },
            { time: '13:14:00', line: '902', to: ['Centraal Station'] },
            { time: '13:20:00', line: '902', to: ['Centraal Station'] },
            { time: '13:26:00', line: '902', to: ['Centraal Station'] },
            { time: '13:32:00', line: '902', to: ['Centraal Station'] },
            { time: '13:38:00', line: '902', to: ['Centraal Station'] },
            { time: '13:44:00', line: '902', to: ['Centraal Station'] },
            { time: '13:50:00', line: '902', to: ['Centraal Station'] },
            { time: '13:56:00', line: '902', to: ['Centraal Station'] },
            { time: '14:02:00', line: '902', to: ['Centraal Station'] },
            { time: '14:08:00', line: '902', to: ['Centraal Station'] },
            { time: '14:14:00', line: '902', to: ['Centraal Station'] },
            { time: '14:20:00', line: '902', to: ['Centraal Station'] },
            { time: '14:26:00', line: '902', to: ['Centraal Station'] },
            { time: '14:32:00', line: '902', to: ['Centraal Station'] },
            { time: '14:38:00', line: '902', to: ['Centraal Station'] },
            { time: '14:44:00', line: '902', to: ['Centraal Station'] },
            { time: '14:50:00', line: '902', to: ['Centraal Station'] },
            { time: '14:56:00', line: '902', to: ['Centraal Station'] },
            { time: '15:02:00', line: '902', to: ['Centraal Station'] },
            { time: '15:08:00', line: '902', to: ['Centraal Station'] },
            { time: '15:14:00', line: '902', to: ['Centraal Station'] },
            { time: '15:20:00', line: '902', to: ['Centraal Station'] },
            { time: '15:26:00', line: '902', to: ['Centraal Station'] },
            { time: '15:32:00', line: '902', to: ['Centraal Station'] },
            { time: '15:38:00', line: '902', to: ['Centraal Station'] },
            { time: '15:44:00', line: '902', to: ['Centraal Station'] },
            { time: '15:50:00', line: '902', to: ['Centraal Station'] },
            { time: '15:56:00', line: '902', to: ['Centraal Station'] },
            { time: '16:02:00', line: '902', to: ['Centraal Station'] },
            { time: '16:08:00', line: '902', to: ['Centraal Station'] },
            { time: '16:14:00', line: '902', to: ['Centraal Station'] },
            { time: '16:20:00', line: '902', to: ['Centraal Station'] },
            { time: '16:26:00', line: '902', to: ['Centraal Station'] },
            { time: '16:32:00', line: '902', to: ['Centraal Station'] },
            { time: '16:38:00', line: '902', to: ['Centraal Station'] },
            { time: '16:44:00', line: '902', to: ['Centraal Station'] },
            { time: '16:50:00', line: '902', to: ['Centraal Station'] },
            { time: '16:56:00', line: '902', to: ['Centraal Station'] },
            { time: '17:02:00', line: '902', to: ['Centraal Station'] },
            { time: '17:08:00', line: '902', to: ['Centraal Station'] },
            { time: '17:14:00', line: '902', to: ['Centraal Station'] },
            { time: '17:20:00', line: '902', to: ['Centraal Station'] },
            { time: '17:26:00', line: '902', to: ['Centraal Station'] },
            { time: '17:32:00', line: '902', to: ['Centraal Station'] },
            { time: '17:38:00', line: '902', to: ['Centraal Station'] },
            { time: '17:44:00', line: '902', to: ['Centraal Station'] },
            { time: '17:50:00', line: '902', to: ['Centraal Station'] },
            { time: '17:56:00', line: '902', to: ['Centraal Station'] },
            { time: '18:02:00', line: '902', to: ['Centraal Station'] },
            { time: '18:08:00', line: '902', to: ['Centraal Station'] },
            { time: '18:14:00', line: '902', to: ['Centraal Station'] },
            { time: '18:20:00', line: '902', to: ['Centraal Station'] },
            { time: '18:26:00', line: '902', to: ['Centraal Station'] },
            { time: '18:32:00', line: '902', to: ['Centraal Station'] },
            { time: '18:38:00', line: '902', to: ['Centraal Station'] },
            { time: '18:44:00', line: '902', to: ['Centraal Station'] },
            { time: '18:50:00', line: '902', to: ['Centraal Station'] },
            { time: '18:56:00', line: '902', to: ['Centraal Station'] },
            { time: '19:02:00', line: '902', to: ['Centraal Station'] },
            { time: '19:08:00', line: '902', to: ['Centraal Station'] },
            { time: '19:14:00', line: '902', to: ['Centraal Station'] },
            { time: '19:20:00', line: '902', to: ['Centraal Station'] },
            { time: '19:26:00', line: '902', to: ['Centraal Station'] },
            { time: '19:32:00', line: '902', to: ['Centraal Station'] },
            { time: '19:38:00', line: '902', to: ['Centraal Station'] },
            { time: '19:44:00', line: '902', to: ['Centraal Station'] },
            { time: '19:50:00', line: '902', to: ['Centraal Station'] },
            { time: '19:56:00', line: '902', to: ['Centraal Station'] },
            { time: '20:02:00', line: '902', to: ['Centraal Station'] },
            { time: '20:08:00', line: '902', to: ['Centraal Station'] },
            { time: '20:14:00', line: '902', to: ['Centraal Station'] },
            { time: '20:20:00', line: '902', to: ['Centraal Station'] },
            { time: '20:26:00', line: '902', to: ['Centraal Station'] },
            { time: '20:32:00', line: '902', to: ['Centraal Station'] },
            { time: '20:38:00', line: '902', to: ['Centraal Station'] },
            { time: '20:44:00', line: '902', to: ['Centraal Station'] },
            { time: '20:50:00', line: '902', to: ['Centraal Station'] },
            { time: '21:02:00', line: '902', to: ['Centraal Station'] },
            { time: '21:14:00', line: '902', to: ['Centraal Station'] },
            { time: '21:26:00', line: '902', to: ['Centraal Station'] },
            { time: '21:38:00', line: '902', to: ['Centraal Station'] },
            { time: '21:50:00', line: '902', to: ['Centraal Station'] },
            { time: '22:02:00', line: '902', to: ['Centraal Station'] },
            { time: '22:14:00', line: '902', to: ['Centraal Station'] },
            { time: '22:26:00', line: '902', to: ['Centraal Station'] },
            { time: '22:38:00', line: '902', to: ['Centraal Station'] },
            { time: '22:50:00', line: '902', to: ['Centraal Station'] },
            { time: '23:02:00', line: '902', to: ['Centraal Station'] },
            { time: '23:14:00', line: '902', to: ['Centraal Station'] },
            { time: '23:26:00', line: '902', to: ['Centraal Station'] },
            { time: '23:38:00', line: '902', to: ['Centraal Station'] },
            { time: '23:50:00', line: '902', to: ['Centraal Station'] },
        ],
        saturday: [
            { time: '06:26:00', line: '902', to: ['Centraal Station'] },
            { time: '06:38:00', line: '902', to: ['Centraal Station'] },
            { time: '06:50:00', line: '902', to: ['Centraal Station'] },
            { time: '07:02:00', line: '902', to: ['Centraal Station'] },
            { time: '07:14:00', line: '902', to: ['Centraal Station'] },
            { time: '07:26:00', line: '902', to: ['Centraal Station'] },
            { time: '07:38:00', line: '902', to: ['Centraal Station'] },
            { time: '07:50:00', line: '902', to: ['Centraal Station'] },
            { time: '08:02:00', line: '902', to: ['Centraal Station'] },
            { time: '08:14:00', line: '902', to: ['Centraal Station'] },
            { time: '08:26:00', line: '902', to: ['Centraal Station'] },
            { time: '08:38:00', line: '902', to: ['Centraal Station'] },
            { time: '08:50:00', line: '902', to: ['Centraal Station'] },
            { time: '09:02:00', line: '902', to: ['Centraal Station'] },
            { time: '09:14:00', line: '902', to: ['Centraal Station'] },
            { time: '09:26:00', line: '902', to: ['Centraal Station'] },
            { time: '09:38:00', line: '902', to: ['Centraal Station'] },
            { time: '09:50:00', line: '902', to: ['Centraal Station'] },
            { time: '10:02:00', line: '902', to: ['Centraal Station'] },
            { time: '10:14:00', line: '902', to: ['Centraal Station'] },
            { time: '10:26:00', line: '902', to: ['Centraal Station'] },
            { time: '10:38:00', line: '902', to: ['Centraal Station'] },
            { time: '10:50:00', line: '902', to: ['Centraal Station'] },
            { time: '11:02:00', line: '902', to: ['Centraal Station'] },
            { time: '11:08:00', line: '902', to: ['Centraal Station'] },
            { time: '11:14:00', line: '902', to: ['Centraal Station'] },
            { time: '11:20:00', line: '902', to: ['Centraal Station'] },
            { time: '11:26:00', line: '902', to: ['Centraal Station'] },
            { time: '11:32:00', line: '902', to: ['Centraal Station'] },
            { time: '11:38:00', line: '902', to: ['Centraal Station'] },
            { time: '11:44:00', line: '902', to: ['Centraal Station'] },
            { time: '11:50:00', line: '902', to: ['Centraal Station'] },
            { time: '11:56:00', line: '902', to: ['Centraal Station'] },
            { time: '12:02:00', line: '902', to: ['Centraal Station'] },
            { time: '12:08:00', line: '902', to: ['Centraal Station'] },
            { time: '12:14:00', line: '902', to: ['Centraal Station'] },
            { time: '12:20:00', line: '902', to: ['Centraal Station'] },
            { time: '12:26:00', line: '902', to: ['Centraal Station'] },
            { time: '12:32:00', line: '902', to: ['Centraal Station'] },
            { time: '12:38:00', line: '902', to: ['Centraal Station'] },
            { time: '12:44:00', line: '902', to: ['Centraal Station'] },
            { time: '12:50:00', line: '902', to: ['Centraal Station'] },
            { time: '12:56:00', line: '902', to: ['Centraal Station'] },
            { time: '13:02:00', line: '902', to: ['Centraal Station'] },
            { time: '13:08:00', line: '902', to: ['Centraal Station'] },
            { time: '13:14:00', line: '902', to: ['Centraal Station'] },
            { time: '13:20:00', line: '902', to: ['Centraal Station'] },
            { time: '13:26:00', line: '902', to: ['Centraal Station'] },
            { time: '13:32:00', line: '902', to: ['Centraal Station'] },
            { time: '13:38:00', line: '902', to: ['Centraal Station'] },
            { time: '13:44:00', line: '902', to: ['Centraal Station'] },
            { time: '13:50:00', line: '902', to: ['Centraal Station'] },
            { time: '13:56:00', line: '902', to: ['Centraal Station'] },
            { time: '14:02:00', line: '902', to: ['Centraal Station'] },
            { time: '14:08:00', line: '902', to: ['Centraal Station'] },
            { time: '14:14:00', line: '902', to: ['Centraal Station'] },
            { time: '14:20:00', line: '902', to: ['Centraal Station'] },
            { time: '14:26:00', line: '902', to: ['Centraal Station'] },
            { time: '14:32:00', line: '902', to: ['Centraal Station'] },
            { time: '14:38:00', line: '902', to: ['Centraal Station'] },
            { time: '14:44:00', line: '902', to: ['Centraal Station'] },
            { time: '14:50:00', line: '902', to: ['Centraal Station'] },
            { time: '14:56:00', line: '902', to: ['Centraal Station'] },
            { time: '15:02:00', line: '902', to: ['Centraal Station'] },
            { time: '15:08:00', line: '902', to: ['Centraal Station'] },
            { time: '15:14:00', line: '902', to: ['Centraal Station'] },
            { time: '15:20:00', line: '902', to: ['Centraal Station'] },
            { time: '15:26:00', line: '902', to: ['Centraal Station'] },
            { time: '15:32:00', line: '902', to: ['Centraal Station'] },
            { time: '15:38:00', line: '902', to: ['Centraal Station'] },
            { time: '15:44:00', line: '902', to: ['Centraal Station'] },
            { time: '15:50:00', line: '902', to: ['Centraal Station'] },
            { time: '15:56:00', line: '902', to: ['Centraal Station'] },
            { time: '16:02:00', line: '902', to: ['Centraal Station'] },
            { time: '16:08:00', line: '902', to: ['Centraal Station'] },
            { time: '16:14:00', line: '902', to: ['Centraal Station'] },
            { time: '16:20:00', line: '902', to: ['Centraal Station'] },
            { time: '16:26:00', line: '902', to: ['Centraal Station'] },
            { time: '16:32:00', line: '902', to: ['Centraal Station'] },
            { time: '16:38:00', line: '902', to: ['Centraal Station'] },
            { time: '16:44:00', line: '902', to: ['Centraal Station'] },
            { time: '16:50:00', line: '902', to: ['Centraal Station'] },
            { time: '16:56:00', line: '902', to: ['Centraal Station'] },
            { time: '17:02:00', line: '902', to: ['Centraal Station'] },
            { time: '17:08:00', line: '902', to: ['Centraal Station'] },
            { time: '17:14:00', line: '902', to: ['Centraal Station'] },
            { time: '17:20:00', line: '902', to: ['Centraal Station'] },
            { time: '17:26:00', line: '902', to: ['Centraal Station'] },
            { time: '17:32:00', line: '902', to: ['Centraal Station'] },
            { time: '17:38:00', line: '902', to: ['Centraal Station'] },
            { time: '17:44:00', line: '902', to: ['Centraal Station'] },
            { time: '17:50:00', line: '902', to: ['Centraal Station'] },
            { time: '17:56:00', line: '902', to: ['Centraal Station'] },
            { time: '18:02:00', line: '902', to: ['Centraal Station'] },
            { time: '18:14:00', line: '902', to: ['Centraal Station'] },
            { time: '18:26:00', line: '902', to: ['Centraal Station'] },
            { time: '18:38:00', line: '902', to: ['Centraal Station'] },
            { time: '18:50:00', line: '902', to: ['Centraal Station'] },
            { time: '19:02:00', line: '902', to: ['Centraal Station'] },
            { time: '19:14:00', line: '902', to: ['Centraal Station'] },
            { time: '19:26:00', line: '902', to: ['Centraal Station'] },
            { time: '19:38:00', line: '902', to: ['Centraal Station'] },
            { time: '19:50:00', line: '902', to: ['Centraal Station'] },
            { time: '20:02:00', line: '902', to: ['Centraal Station'] },
            { time: '20:14:00', line: '902', to: ['Centraal Station'] },
            { time: '20:26:00', line: '902', to: ['Centraal Station'] },
            { time: '20:38:00', line: '902', to: ['Centraal Station'] },
            { time: '20:50:00', line: '902', to: ['Centraal Station'] },
            { time: '21:02:00', line: '902', to: ['Centraal Station'] },
            { time: '21:14:00', line: '902', to: ['Centraal Station'] },
            { time: '21:26:00', line: '902', to: ['Centraal Station'] },
            { time: '21:38:00', line: '902', to: ['Centraal Station'] },
            { time: '21:50:00', line: '902', to: ['Centraal Station'] },
            { time: '22:02:00', line: '902', to: ['Centraal Station'] },
            { time: '22:14:00', line: '902', to: ['Centraal Station'] },
            { time: '22:26:00', line: '902', to: ['Centraal Station'] },
            { time: '22:38:00', line: '902', to: ['Centraal Station'] },
            { time: '22:50:00', line: '902', to: ['Centraal Station'] },
            { time: '23:02:00', line: '902', to: ['Centraal Station'] },
            { time: '23:14:00', line: '902', to: ['Centraal Station'] },
            { time: '23:26:00', line: '902', to: ['Centraal Station'] },
            { time: '23:38:00', line: '902', to: ['Centraal Station'] },
            { time: '23:50:00', line: '902', to: ['Centraal Station'] },
        ],
        sunday: [
            { time: '09:02:00', line: '902', to: ['Centraal Station'] },
            { time: '09:14:00', line: '902', to: ['Centraal Station'] },
            { time: '09:26:00', line: '902', to: ['Centraal Station'] },
            { time: '09:38:00', line: '902', to: ['Centraal Station'] },
            { time: '09:50:00', line: '902', to: ['Centraal Station'] },
            { time: '10:02:00', line: '902', to: ['Centraal Station'] },
            { time: '10:14:00', line: '902', to: ['Centraal Station'] },
            { time: '10:26:00', line: '902', to: ['Centraal Station'] },
            { time: '10:38:00', line: '902', to: ['Centraal Station'] },
            { time: '10:50:00', line: '902', to: ['Centraal Station'] },
            { time: '11:02:00', line: '902', to: ['Centraal Station'] },
            { time: '11:08:00', line: '902', to: ['Centraal Station'] },
            { time: '11:14:00', line: '902', to: ['Centraal Station'] },
            { time: '11:20:00', line: '902', to: ['Centraal Station'] },
            { time: '11:26:00', line: '902', to: ['Centraal Station'] },
            { time: '11:32:00', line: '902', to: ['Centraal Station'] },
            { time: '11:38:00', line: '902', to: ['Centraal Station'] },
            { time: '11:44:00', line: '902', to: ['Centraal Station'] },
            { time: '11:50:00', line: '902', to: ['Centraal Station'] },
            { time: '11:56:00', line: '902', to: ['Centraal Station'] },
            { time: '12:02:00', line: '902', to: ['Centraal Station'] },
            { time: '12:08:00', line: '902', to: ['Centraal Station'] },
            { time: '12:14:00', line: '902', to: ['Centraal Station'] },
            { time: '12:20:00', line: '902', to: ['Centraal Station'] },
            { time: '12:26:00', line: '902', to: ['Centraal Station'] },
            { time: '12:32:00', line: '902', to: ['Centraal Station'] },
            { time: '12:38:00', line: '902', to: ['Centraal Station'] },
            { time: '12:44:00', line: '902', to: ['Centraal Station'] },
            { time: '12:50:00', line: '902', to: ['Centraal Station'] },
            { time: '12:56:00', line: '902', to: ['Centraal Station'] },
            { time: '13:02:00', line: '902', to: ['Centraal Station'] },
            { time: '13:08:00', line: '902', to: ['Centraal Station'] },
            { time: '13:14:00', line: '902', to: ['Centraal Station'] },
            { time: '13:20:00', line: '902', to: ['Centraal Station'] },
            { time: '13:26:00', line: '902', to: ['Centraal Station'] },
            { time: '13:32:00', line: '902', to: ['Centraal Station'] },
            { time: '13:38:00', line: '902', to: ['Centraal Station'] },
            { time: '13:44:00', line: '902', to: ['Centraal Station'] },
            { time: '13:50:00', line: '902', to: ['Centraal Station'] },
            { time: '13:56:00', line: '902', to: ['Centraal Station'] },
            { time: '14:02:00', line: '902', to: ['Centraal Station'] },
            { time: '14:08:00', line: '902', to: ['Centraal Station'] },
            { time: '14:14:00', line: '902', to: ['Centraal Station'] },
            { time: '14:20:00', line: '902', to: ['Centraal Station'] },
            { time: '14:26:00', line: '902', to: ['Centraal Station'] },
            { time: '14:32:00', line: '902', to: ['Centraal Station'] },
            { time: '14:38:00', line: '902', to: ['Centraal Station'] },
            { time: '14:44:00', line: '902', to: ['Centraal Station'] },
            { time: '14:50:00', line: '902', to: ['Centraal Station'] },
            { time: '14:56:00', line: '902', to: ['Centraal Station'] },
            { time: '15:02:00', line: '902', to: ['Centraal Station'] },
            { time: '15:08:00', line: '902', to: ['Centraal Station'] },
            { time: '15:14:00', line: '902', to: ['Centraal Station'] },
            { time: '15:20:00', line: '902', to: ['Centraal Station'] },
            { time: '15:26:00', line: '902', to: ['Centraal Station'] },
            { time: '15:32:00', line: '902', to: ['Centraal Station'] },
            { time: '15:38:00', line: '902', to: ['Centraal Station'] },
            { time: '15:44:00', line: '902', to: ['Centraal Station'] },
            { time: '15:50:00', line: '902', to: ['Centraal Station'] },
            { time: '15:56:00', line: '902', to: ['Centraal Station'] },
            { time: '16:02:00', line: '902', to: ['Centraal Station'] },
            { time: '16:08:00', line: '902', to: ['Centraal Station'] },
            { time: '16:14:00', line: '902', to: ['Centraal Station'] },
            { time: '16:20:00', line: '902', to: ['Centraal Station'] },
            { time: '16:26:00', line: '902', to: ['Centraal Station'] },
            { time: '16:32:00', line: '902', to: ['Centraal Station'] },
            { time: '16:38:00', line: '902', to: ['Centraal Station'] },
            { time: '16:44:00', line: '902', to: ['Centraal Station'] },
            { time: '16:50:00', line: '902', to: ['Centraal Station'] },
            { time: '16:56:00', line: '902', to: ['Centraal Station'] },
            { time: '17:02:00', line: '902', to: ['Centraal Station'] },
            { time: '17:08:00', line: '902', to: ['Centraal Station'] },
            { time: '17:14:00', line: '902', to: ['Centraal Station'] },
            { time: '17:20:00', line: '902', to: ['Centraal Station'] },
            { time: '17:26:00', line: '902', to: ['Centraal Station'] },
            { time: '17:32:00', line: '902', to: ['Centraal Station'] },
            { time: '17:38:00', line: '902', to: ['Centraal Station'] },
            { time: '17:44:00', line: '902', to: ['Centraal Station'] },
            { time: '17:50:00', line: '902', to: ['Centraal Station'] },
            { time: '17:56:00', line: '902', to: ['Centraal Station'] },
            { time: '18:02:00', line: '902', to: ['Centraal Station'] },
            { time: '18:14:00', line: '902', to: ['Centraal Station'] },
            { time: '18:26:00', line: '902', to: ['Centraal Station'] },
            { time: '18:38:00', line: '902', to: ['Centraal Station'] },
            { time: '18:50:00', line: '902', to: ['Centraal Station'] },
            { time: '19:02:00', line: '902', to: ['Centraal Station'] },
            { time: '19:14:00', line: '902', to: ['Centraal Station'] },
            { time: '19:26:00', line: '902', to: ['Centraal Station'] },
            { time: '19:38:00', line: '902', to: ['Centraal Station'] },
            { time: '19:50:00', line: '902', to: ['Centraal Station'] },
            { time: '20:02:00', line: '902', to: ['Centraal Station'] },
            { time: '20:14:00', line: '902', to: ['Centraal Station'] },
            { time: '20:26:00', line: '902', to: ['Centraal Station'] },
            { time: '20:38:00', line: '902', to: ['Centraal Station'] },
            { time: '20:50:00', line: '902', to: ['Centraal Station'] },
            { time: '21:02:00', line: '902', to: ['Centraal Station'] },
            { time: '21:14:00', line: '902', to: ['Centraal Station'] },
            { time: '21:26:00', line: '902', to: ['Centraal Station'] },
            { time: '21:38:00', line: '902', to: ['Centraal Station'] },
            { time: '21:50:00', line: '902', to: ['Centraal Station'] },
            { time: '22:02:00', line: '902', to: ['Centraal Station'] },
            { time: '22:14:00', line: '902', to: ['Centraal Station'] },
            { time: '22:26:00', line: '902', to: ['Centraal Station'] },
            { time: '22:38:00', line: '902', to: ['Centraal Station'] },
            { time: '22:50:00', line: '902', to: ['Centraal Station'] },
            { time: '23:02:00', line: '902', to: ['Centraal Station'] },
            { time: '23:14:00', line: '902', to: ['Centraal Station'] },
            { time: '23:26:00', line: '902', to: ['Centraal Station'] },
            { time: '23:38:00', line: '902', to: ['Centraal Station'] },
            { time: '23:50:00', line: '902', to: ['Centraal Station'] },
        ],
    },
    NDSM: {
        monday: [
            {
                time: '00:00:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            {
                time: '00:38:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            {
                time: '01:16:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            {
                time: '01:54:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            { time: '06:45:00', line: '906', to: ['Centraal Station'] },
            { time: '06:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '07:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '07:15:00', line: '906', to: ['Centraal Station'] },
            { time: '07:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '07:45:00', line: '906', to: ['Centraal Station'] },
            { time: '07:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '08:00:00', line: '906', to: ['Centraal Station'] },
            { time: '08:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '08:15:00', line: '906', to: ['Centraal Station'] },
            { time: '08:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '08:30:00', line: '906', to: ['Centraal Station'] },
            { time: '08:45:00', line: '906', to: ['Centraal Station'] },
            { time: '08:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '09:00:00', line: '906', to: ['Centraal Station'] },
            { time: '09:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '09:15:00', line: '906', to: ['Centraal Station'] },
            { time: '09:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '09:30:00', line: '906', to: ['Centraal Station'] },
            { time: '09:45:00', line: '906', to: ['Centraal Station'] },
            { time: '09:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '10:00:00', line: '906', to: ['Centraal Station'] },
            { time: '10:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '10:15:00', line: '906', to: ['Centraal Station'] },
            { time: '10:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '10:30:00', line: '906', to: ['Centraal Station'] },
            { time: '10:45:00', line: '906', to: ['Centraal Station'] },
            { time: '10:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '11:00:00', line: '906', to: ['Centraal Station'] },
            { time: '11:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '11:15:00', line: '906', to: ['Centraal Station'] },
            { time: '11:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '11:30:00', line: '906', to: ['Centraal Station'] },
            { time: '11:45:00', line: '906', to: ['Centraal Station'] },
            { time: '11:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:00:00', line: '906', to: ['Centraal Station'] },
            { time: '12:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:15:00', line: '906', to: ['Centraal Station'] },
            { time: '12:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:30:00', line: '906', to: ['Centraal Station'] },
            { time: '12:45:00', line: '906', to: ['Centraal Station'] },
            { time: '12:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:00:00', line: '906', to: ['Centraal Station'] },
            { time: '13:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:15:00', line: '906', to: ['Centraal Station'] },
            { time: '13:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:30:00', line: '906', to: ['Centraal Station'] },
            { time: '13:45:00', line: '906', to: ['Centraal Station'] },
            { time: '13:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:00:00', line: '906', to: ['Centraal Station'] },
            { time: '14:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:15:00', line: '906', to: ['Centraal Station'] },
            { time: '14:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:30:00', line: '906', to: ['Centraal Station'] },
            { time: '14:45:00', line: '906', to: ['Centraal Station'] },
            { time: '14:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:00:00', line: '906', to: ['Centraal Station'] },
            { time: '15:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:15:00', line: '906', to: ['Centraal Station'] },
            { time: '15:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:30:00', line: '906', to: ['Centraal Station'] },
            { time: '15:45:00', line: '906', to: ['Centraal Station'] },
            { time: '15:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:00:00', line: '906', to: ['Centraal Station'] },
            { time: '16:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:15:00', line: '906', to: ['Centraal Station'] },
            { time: '16:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:30:00', line: '906', to: ['Centraal Station'] },
            { time: '16:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:45:00', line: '906', to: ['Centraal Station'] },
            { time: '16:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:00:00', line: '906', to: ['Centraal Station'] },
            { time: '17:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:15:00', line: '906', to: ['Centraal Station'] },
            { time: '17:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:30:00', line: '906', to: ['Centraal Station'] },
            { time: '17:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:45:00', line: '906', to: ['Centraal Station'] },
            { time: '17:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:00:00', line: '906', to: ['Centraal Station'] },
            { time: '18:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:15:00', line: '906', to: ['Centraal Station'] },
            { time: '18:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:30:00', line: '906', to: ['Centraal Station'] },
            { time: '18:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:45:00', line: '906', to: ['Centraal Station'] },
            { time: '18:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:00:00', line: '906', to: ['Centraal Station'] },
            { time: '19:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:30:00', line: '906', to: ['Centraal Station'] },
            { time: '19:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:00:00', line: '906', to: ['Centraal Station'] },
            { time: '20:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:30:00', line: '906', to: ['Centraal Station'] },
            { time: '20:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '21:00:00', line: '906', to: ['Centraal Station'] },
            { time: '21:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '21:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '21:30:00', line: '906', to: ['Centraal Station'] },
            { time: '21:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '22:00:00', line: '906', to: ['Centraal Station'] },
            { time: '22:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '22:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '22:30:00', line: '906', to: ['Centraal Station'] },
            { time: '22:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '23:00:00', line: '906', to: ['Centraal Station'] },
            { time: '23:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '23:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '23:30:00', line: '906', to: ['Centraal Station'] },
            { time: '23:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '24:00:00', line: '906', to: ['Centraal Station'] },
            { time: '24:30:00', line: '906', to: ['Centraal Station'] },
            { time: '25:00:00', line: '906', to: ['Centraal Station'] },
            { time: '25:30:00', line: '906', to: ['Centraal Station'] },
            { time: '26:00:00', line: '906', to: ['Centraal Station'] },
        ],
        tuesday: [
            {
                time: '00:00:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            {
                time: '00:38:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            {
                time: '01:16:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            {
                time: '01:54:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            { time: '06:45:00', line: '906', to: ['Centraal Station'] },
            { time: '06:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '07:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '07:15:00', line: '906', to: ['Centraal Station'] },
            { time: '07:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '07:45:00', line: '906', to: ['Centraal Station'] },
            { time: '07:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '08:00:00', line: '906', to: ['Centraal Station'] },
            { time: '08:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '08:15:00', line: '906', to: ['Centraal Station'] },
            { time: '08:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '08:30:00', line: '906', to: ['Centraal Station'] },
            { time: '08:45:00', line: '906', to: ['Centraal Station'] },
            { time: '08:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '09:00:00', line: '906', to: ['Centraal Station'] },
            { time: '09:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '09:15:00', line: '906', to: ['Centraal Station'] },
            { time: '09:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '09:30:00', line: '906', to: ['Centraal Station'] },
            { time: '09:45:00', line: '906', to: ['Centraal Station'] },
            { time: '09:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '10:00:00', line: '906', to: ['Centraal Station'] },
            { time: '10:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '10:15:00', line: '906', to: ['Centraal Station'] },
            { time: '10:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '10:30:00', line: '906', to: ['Centraal Station'] },
            { time: '10:45:00', line: '906', to: ['Centraal Station'] },
            { time: '10:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '11:00:00', line: '906', to: ['Centraal Station'] },
            { time: '11:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '11:15:00', line: '906', to: ['Centraal Station'] },
            { time: '11:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '11:30:00', line: '906', to: ['Centraal Station'] },
            { time: '11:45:00', line: '906', to: ['Centraal Station'] },
            { time: '11:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:00:00', line: '906', to: ['Centraal Station'] },
            { time: '12:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:15:00', line: '906', to: ['Centraal Station'] },
            { time: '12:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:30:00', line: '906', to: ['Centraal Station'] },
            { time: '12:45:00', line: '906', to: ['Centraal Station'] },
            { time: '12:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:00:00', line: '906', to: ['Centraal Station'] },
            { time: '13:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:15:00', line: '906', to: ['Centraal Station'] },
            { time: '13:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:30:00', line: '906', to: ['Centraal Station'] },
            { time: '13:45:00', line: '906', to: ['Centraal Station'] },
            { time: '13:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:00:00', line: '906', to: ['Centraal Station'] },
            { time: '14:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:15:00', line: '906', to: ['Centraal Station'] },
            { time: '14:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:30:00', line: '906', to: ['Centraal Station'] },
            { time: '14:45:00', line: '906', to: ['Centraal Station'] },
            { time: '14:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:00:00', line: '906', to: ['Centraal Station'] },
            { time: '15:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:15:00', line: '906', to: ['Centraal Station'] },
            { time: '15:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:30:00', line: '906', to: ['Centraal Station'] },
            { time: '15:45:00', line: '906', to: ['Centraal Station'] },
            { time: '15:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:00:00', line: '906', to: ['Centraal Station'] },
            { time: '16:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:15:00', line: '906', to: ['Centraal Station'] },
            { time: '16:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:30:00', line: '906', to: ['Centraal Station'] },
            { time: '16:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:45:00', line: '906', to: ['Centraal Station'] },
            { time: '16:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:00:00', line: '906', to: ['Centraal Station'] },
            { time: '17:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:15:00', line: '906', to: ['Centraal Station'] },
            { time: '17:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:30:00', line: '906', to: ['Centraal Station'] },
            { time: '17:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:45:00', line: '906', to: ['Centraal Station'] },
            { time: '17:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:00:00', line: '906', to: ['Centraal Station'] },
            { time: '18:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:15:00', line: '906', to: ['Centraal Station'] },
            { time: '18:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:30:00', line: '906', to: ['Centraal Station'] },
            { time: '18:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:45:00', line: '906', to: ['Centraal Station'] },
            { time: '18:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:00:00', line: '906', to: ['Centraal Station'] },
            { time: '19:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:30:00', line: '906', to: ['Centraal Station'] },
            { time: '19:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:00:00', line: '906', to: ['Centraal Station'] },
            { time: '20:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:30:00', line: '906', to: ['Centraal Station'] },
            { time: '20:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '21:00:00', line: '906', to: ['Centraal Station'] },
            { time: '21:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '21:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '21:30:00', line: '906', to: ['Centraal Station'] },
            { time: '21:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '22:00:00', line: '906', to: ['Centraal Station'] },
            { time: '22:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '22:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '22:30:00', line: '906', to: ['Centraal Station'] },
            { time: '22:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '23:00:00', line: '906', to: ['Centraal Station'] },
            { time: '23:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '23:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '23:30:00', line: '906', to: ['Centraal Station'] },
            { time: '23:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '24:00:00', line: '906', to: ['Centraal Station'] },
            { time: '24:30:00', line: '906', to: ['Centraal Station'] },
            { time: '25:00:00', line: '906', to: ['Centraal Station'] },
            { time: '25:30:00', line: '906', to: ['Centraal Station'] },
            { time: '26:00:00', line: '906', to: ['Centraal Station'] },
        ],
        wednesday: [
            {
                time: '00:00:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            {
                time: '00:38:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            {
                time: '01:16:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            {
                time: '01:54:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            { time: '06:45:00', line: '906', to: ['Centraal Station'] },
            { time: '06:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '07:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '07:15:00', line: '906', to: ['Centraal Station'] },
            { time: '07:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '07:45:00', line: '906', to: ['Centraal Station'] },
            { time: '07:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '08:00:00', line: '906', to: ['Centraal Station'] },
            { time: '08:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '08:15:00', line: '906', to: ['Centraal Station'] },
            { time: '08:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '08:30:00', line: '906', to: ['Centraal Station'] },
            { time: '08:45:00', line: '906', to: ['Centraal Station'] },
            { time: '08:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '09:00:00', line: '906', to: ['Centraal Station'] },
            { time: '09:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '09:15:00', line: '906', to: ['Centraal Station'] },
            { time: '09:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '09:30:00', line: '906', to: ['Centraal Station'] },
            { time: '09:45:00', line: '906', to: ['Centraal Station'] },
            { time: '09:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '10:00:00', line: '906', to: ['Centraal Station'] },
            { time: '10:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '10:15:00', line: '906', to: ['Centraal Station'] },
            { time: '10:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '10:30:00', line: '906', to: ['Centraal Station'] },
            { time: '10:45:00', line: '906', to: ['Centraal Station'] },
            { time: '10:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '11:00:00', line: '906', to: ['Centraal Station'] },
            { time: '11:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '11:15:00', line: '906', to: ['Centraal Station'] },
            { time: '11:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '11:30:00', line: '906', to: ['Centraal Station'] },
            { time: '11:45:00', line: '906', to: ['Centraal Station'] },
            { time: '11:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:00:00', line: '906', to: ['Centraal Station'] },
            { time: '12:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:15:00', line: '906', to: ['Centraal Station'] },
            { time: '12:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:30:00', line: '906', to: ['Centraal Station'] },
            { time: '12:45:00', line: '906', to: ['Centraal Station'] },
            { time: '12:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:00:00', line: '906', to: ['Centraal Station'] },
            { time: '13:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:15:00', line: '906', to: ['Centraal Station'] },
            { time: '13:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:30:00', line: '906', to: ['Centraal Station'] },
            { time: '13:45:00', line: '906', to: ['Centraal Station'] },
            { time: '13:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:00:00', line: '906', to: ['Centraal Station'] },
            { time: '14:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:15:00', line: '906', to: ['Centraal Station'] },
            { time: '14:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:30:00', line: '906', to: ['Centraal Station'] },
            { time: '14:45:00', line: '906', to: ['Centraal Station'] },
            { time: '14:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:00:00', line: '906', to: ['Centraal Station'] },
            { time: '15:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:15:00', line: '906', to: ['Centraal Station'] },
            { time: '15:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:30:00', line: '906', to: ['Centraal Station'] },
            { time: '15:45:00', line: '906', to: ['Centraal Station'] },
            { time: '15:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:00:00', line: '906', to: ['Centraal Station'] },
            { time: '16:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:15:00', line: '906', to: ['Centraal Station'] },
            { time: '16:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:30:00', line: '906', to: ['Centraal Station'] },
            { time: '16:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:45:00', line: '906', to: ['Centraal Station'] },
            { time: '16:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:00:00', line: '906', to: ['Centraal Station'] },
            { time: '17:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:15:00', line: '906', to: ['Centraal Station'] },
            { time: '17:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:30:00', line: '906', to: ['Centraal Station'] },
            { time: '17:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:45:00', line: '906', to: ['Centraal Station'] },
            { time: '17:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:00:00', line: '906', to: ['Centraal Station'] },
            { time: '18:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:15:00', line: '906', to: ['Centraal Station'] },
            { time: '18:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:30:00', line: '906', to: ['Centraal Station'] },
            { time: '18:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:45:00', line: '906', to: ['Centraal Station'] },
            { time: '18:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:00:00', line: '906', to: ['Centraal Station'] },
            { time: '19:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:30:00', line: '906', to: ['Centraal Station'] },
            { time: '19:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:00:00', line: '906', to: ['Centraal Station'] },
            { time: '20:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:30:00', line: '906', to: ['Centraal Station'] },
            { time: '20:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '21:00:00', line: '906', to: ['Centraal Station'] },
            { time: '21:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '21:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '21:30:00', line: '906', to: ['Centraal Station'] },
            { time: '21:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '22:00:00', line: '906', to: ['Centraal Station'] },
            { time: '22:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '22:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '22:30:00', line: '906', to: ['Centraal Station'] },
            { time: '22:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '23:00:00', line: '906', to: ['Centraal Station'] },
            { time: '23:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '23:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '23:30:00', line: '906', to: ['Centraal Station'] },
            { time: '23:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '24:00:00', line: '906', to: ['Centraal Station'] },
            { time: '24:30:00', line: '906', to: ['Centraal Station'] },
            { time: '25:00:00', line: '906', to: ['Centraal Station'] },
            { time: '25:30:00', line: '906', to: ['Centraal Station'] },
            { time: '26:00:00', line: '906', to: ['Centraal Station'] },
        ],
        thursday: [
            {
                time: '00:00:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            {
                time: '00:38:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            {
                time: '01:16:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            {
                time: '01:54:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            { time: '06:45:00', line: '906', to: ['Centraal Station'] },
            { time: '06:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '07:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '07:15:00', line: '906', to: ['Centraal Station'] },
            { time: '07:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '07:45:00', line: '906', to: ['Centraal Station'] },
            { time: '07:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '08:00:00', line: '906', to: ['Centraal Station'] },
            { time: '08:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '08:15:00', line: '906', to: ['Centraal Station'] },
            { time: '08:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '08:30:00', line: '906', to: ['Centraal Station'] },
            { time: '08:45:00', line: '906', to: ['Centraal Station'] },
            { time: '08:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '09:00:00', line: '906', to: ['Centraal Station'] },
            { time: '09:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '09:15:00', line: '906', to: ['Centraal Station'] },
            { time: '09:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '09:30:00', line: '906', to: ['Centraal Station'] },
            { time: '09:45:00', line: '906', to: ['Centraal Station'] },
            { time: '09:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '10:00:00', line: '906', to: ['Centraal Station'] },
            { time: '10:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '10:15:00', line: '906', to: ['Centraal Station'] },
            { time: '10:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '10:30:00', line: '906', to: ['Centraal Station'] },
            { time: '10:45:00', line: '906', to: ['Centraal Station'] },
            { time: '10:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '11:00:00', line: '906', to: ['Centraal Station'] },
            { time: '11:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '11:15:00', line: '906', to: ['Centraal Station'] },
            { time: '11:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '11:30:00', line: '906', to: ['Centraal Station'] },
            { time: '11:45:00', line: '906', to: ['Centraal Station'] },
            { time: '11:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:00:00', line: '906', to: ['Centraal Station'] },
            { time: '12:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:15:00', line: '906', to: ['Centraal Station'] },
            { time: '12:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:30:00', line: '906', to: ['Centraal Station'] },
            { time: '12:45:00', line: '906', to: ['Centraal Station'] },
            { time: '12:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:00:00', line: '906', to: ['Centraal Station'] },
            { time: '13:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:15:00', line: '906', to: ['Centraal Station'] },
            { time: '13:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:30:00', line: '906', to: ['Centraal Station'] },
            { time: '13:45:00', line: '906', to: ['Centraal Station'] },
            { time: '13:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:00:00', line: '906', to: ['Centraal Station'] },
            { time: '14:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:15:00', line: '906', to: ['Centraal Station'] },
            { time: '14:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:30:00', line: '906', to: ['Centraal Station'] },
            { time: '14:45:00', line: '906', to: ['Centraal Station'] },
            { time: '14:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:00:00', line: '906', to: ['Centraal Station'] },
            { time: '15:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:15:00', line: '906', to: ['Centraal Station'] },
            { time: '15:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:30:00', line: '906', to: ['Centraal Station'] },
            { time: '15:45:00', line: '906', to: ['Centraal Station'] },
            { time: '15:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:00:00', line: '906', to: ['Centraal Station'] },
            { time: '16:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:15:00', line: '906', to: ['Centraal Station'] },
            { time: '16:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:30:00', line: '906', to: ['Centraal Station'] },
            { time: '16:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:45:00', line: '906', to: ['Centraal Station'] },
            { time: '16:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:00:00', line: '906', to: ['Centraal Station'] },
            { time: '17:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:15:00', line: '906', to: ['Centraal Station'] },
            { time: '17:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:30:00', line: '906', to: ['Centraal Station'] },
            { time: '17:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:45:00', line: '906', to: ['Centraal Station'] },
            { time: '17:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:00:00', line: '906', to: ['Centraal Station'] },
            { time: '18:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:15:00', line: '906', to: ['Centraal Station'] },
            { time: '18:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:30:00', line: '906', to: ['Centraal Station'] },
            { time: '18:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:45:00', line: '906', to: ['Centraal Station'] },
            { time: '18:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:00:00', line: '906', to: ['Centraal Station'] },
            { time: '19:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:30:00', line: '906', to: ['Centraal Station'] },
            { time: '19:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:00:00', line: '906', to: ['Centraal Station'] },
            { time: '20:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:30:00', line: '906', to: ['Centraal Station'] },
            { time: '20:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '21:00:00', line: '906', to: ['Centraal Station'] },
            { time: '21:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '21:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '21:30:00', line: '906', to: ['Centraal Station'] },
            { time: '21:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '22:00:00', line: '906', to: ['Centraal Station'] },
            { time: '22:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '22:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '22:30:00', line: '906', to: ['Centraal Station'] },
            { time: '22:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '23:00:00', line: '906', to: ['Centraal Station'] },
            { time: '23:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '23:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '23:30:00', line: '906', to: ['Centraal Station'] },
            { time: '23:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '24:00:00', line: '906', to: ['Centraal Station'] },
            { time: '24:30:00', line: '906', to: ['Centraal Station'] },
            { time: '25:00:00', line: '906', to: ['Centraal Station'] },
            { time: '25:30:00', line: '906', to: ['Centraal Station'] },
            { time: '26:00:00', line: '906', to: ['Centraal Station'] },
        ],
        friday: [
            {
                time: '00:00:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            {
                time: '00:38:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            {
                time: '01:16:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            {
                time: '01:54:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            { time: '06:45:00', line: '906', to: ['Centraal Station'] },
            { time: '06:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '07:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '07:15:00', line: '906', to: ['Centraal Station'] },
            { time: '07:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '07:45:00', line: '906', to: ['Centraal Station'] },
            { time: '07:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '08:00:00', line: '906', to: ['Centraal Station'] },
            { time: '08:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '08:15:00', line: '906', to: ['Centraal Station'] },
            { time: '08:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '08:30:00', line: '906', to: ['Centraal Station'] },
            { time: '08:45:00', line: '906', to: ['Centraal Station'] },
            { time: '08:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '09:00:00', line: '906', to: ['Centraal Station'] },
            { time: '09:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '09:15:00', line: '906', to: ['Centraal Station'] },
            { time: '09:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '09:30:00', line: '906', to: ['Centraal Station'] },
            { time: '09:45:00', line: '906', to: ['Centraal Station'] },
            { time: '09:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '10:00:00', line: '906', to: ['Centraal Station'] },
            { time: '10:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '10:15:00', line: '906', to: ['Centraal Station'] },
            { time: '10:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '10:30:00', line: '906', to: ['Centraal Station'] },
            { time: '10:45:00', line: '906', to: ['Centraal Station'] },
            { time: '10:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '11:00:00', line: '906', to: ['Centraal Station'] },
            { time: '11:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '11:15:00', line: '906', to: ['Centraal Station'] },
            { time: '11:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '11:30:00', line: '906', to: ['Centraal Station'] },
            { time: '11:45:00', line: '906', to: ['Centraal Station'] },
            { time: '11:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:00:00', line: '906', to: ['Centraal Station'] },
            { time: '12:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:15:00', line: '906', to: ['Centraal Station'] },
            { time: '12:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:30:00', line: '906', to: ['Centraal Station'] },
            { time: '12:45:00', line: '906', to: ['Centraal Station'] },
            { time: '12:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:00:00', line: '906', to: ['Centraal Station'] },
            { time: '13:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:15:00', line: '906', to: ['Centraal Station'] },
            { time: '13:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:30:00', line: '906', to: ['Centraal Station'] },
            { time: '13:45:00', line: '906', to: ['Centraal Station'] },
            { time: '13:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:00:00', line: '906', to: ['Centraal Station'] },
            { time: '14:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:15:00', line: '906', to: ['Centraal Station'] },
            { time: '14:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:30:00', line: '906', to: ['Centraal Station'] },
            { time: '14:45:00', line: '906', to: ['Centraal Station'] },
            { time: '14:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:00:00', line: '906', to: ['Centraal Station'] },
            { time: '15:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:15:00', line: '906', to: ['Centraal Station'] },
            { time: '15:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:30:00', line: '906', to: ['Centraal Station'] },
            { time: '15:45:00', line: '906', to: ['Centraal Station'] },
            { time: '15:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:00:00', line: '906', to: ['Centraal Station'] },
            { time: '16:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:15:00', line: '906', to: ['Centraal Station'] },
            { time: '16:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:30:00', line: '906', to: ['Centraal Station'] },
            { time: '16:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:45:00', line: '906', to: ['Centraal Station'] },
            { time: '16:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:00:00', line: '906', to: ['Centraal Station'] },
            { time: '17:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:15:00', line: '906', to: ['Centraal Station'] },
            { time: '17:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:30:00', line: '906', to: ['Centraal Station'] },
            { time: '17:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:45:00', line: '906', to: ['Centraal Station'] },
            { time: '17:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:00:00', line: '906', to: ['Centraal Station'] },
            { time: '18:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:15:00', line: '906', to: ['Centraal Station'] },
            { time: '18:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:30:00', line: '906', to: ['Centraal Station'] },
            { time: '18:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:45:00', line: '906', to: ['Centraal Station'] },
            { time: '18:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:00:00', line: '906', to: ['Centraal Station'] },
            { time: '19:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:30:00', line: '906', to: ['Centraal Station'] },
            { time: '19:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:00:00', line: '906', to: ['Centraal Station'] },
            { time: '20:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:30:00', line: '906', to: ['Centraal Station'] },
            { time: '20:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '21:00:00', line: '906', to: ['Centraal Station'] },
            { time: '21:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '21:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '21:30:00', line: '906', to: ['Centraal Station'] },
            { time: '21:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '22:00:00', line: '906', to: ['Centraal Station'] },
            { time: '22:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '22:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '22:30:00', line: '906', to: ['Centraal Station'] },
            { time: '22:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '23:00:00', line: '906', to: ['Centraal Station'] },
            { time: '23:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '23:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '23:30:00', line: '906', to: ['Centraal Station'] },
            { time: '23:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '24:00:00', line: '906', to: ['Centraal Station'] },
            { time: '24:30:00', line: '906', to: ['Centraal Station'] },
            { time: '25:00:00', line: '906', to: ['Centraal Station'] },
            { time: '25:30:00', line: '906', to: ['Centraal Station'] },
            { time: '26:00:00', line: '906', to: ['Centraal Station'] },
            { time: '26:30:00', line: '906', to: ['Centraal Station'] },
            { time: '27:00:00', line: '906', to: ['Centraal Station'] },
            { time: '27:30:00', line: '906', to: ['Centraal Station'] },
        ],
        saturday: [
            {
                time: '00:00:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            {
                time: '00:38:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            {
                time: '01:15:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            {
                time: '01:54:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            {
                time: '02:32:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            {
                time: '03:10:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            { time: '07:15:00', line: '906', to: ['Centraal Station'] },
            { time: '07:45:00', line: '906', to: ['Centraal Station'] },
            { time: '08:15:00', line: '906', to: ['Centraal Station'] },
            { time: '08:45:00', line: '906', to: ['Centraal Station'] },
            { time: '09:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '09:15:00', line: '906', to: ['Centraal Station'] },
            { time: '09:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '09:30:00', line: '906', to: ['Centraal Station'] },
            { time: '09:45:00', line: '906', to: ['Centraal Station'] },
            { time: '09:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '10:00:00', line: '906', to: ['Centraal Station'] },
            { time: '10:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '10:15:00', line: '906', to: ['Centraal Station'] },
            { time: '10:25:00', line: '906', to: ['Centraal Station'] },
            { time: '10:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '10:35:00', line: '906', to: ['Centraal Station'] },
            { time: '10:45:00', line: '906', to: ['Centraal Station'] },
            { time: '10:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '10:55:00', line: '906', to: ['Centraal Station'] },
            { time: '11:05:00', line: '906', to: ['Centraal Station'] },
            { time: '11:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '11:15:00', line: '906', to: ['Centraal Station'] },
            { time: '11:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '11:25:00', line: '906', to: ['Centraal Station'] },
            { time: '11:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '11:35:00', line: '906', to: ['Centraal Station'] },
            { time: '11:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '11:45:00', line: '906', to: ['Centraal Station'] },
            { time: '11:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '11:55:00', line: '906', to: ['Centraal Station'] },
            { time: '12:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:05:00', line: '906', to: ['Centraal Station'] },
            { time: '12:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:15:00', line: '906', to: ['Centraal Station'] },
            { time: '12:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:25:00', line: '906', to: ['Centraal Station'] },
            { time: '12:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:35:00', line: '906', to: ['Centraal Station'] },
            { time: '12:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:45:00', line: '906', to: ['Centraal Station'] },
            { time: '12:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:55:00', line: '906', to: ['Centraal Station'] },
            { time: '13:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:05:00', line: '906', to: ['Centraal Station'] },
            { time: '13:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:15:00', line: '906', to: ['Centraal Station'] },
            { time: '13:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:25:00', line: '906', to: ['Centraal Station'] },
            { time: '13:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:35:00', line: '906', to: ['Centraal Station'] },
            { time: '13:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:45:00', line: '906', to: ['Centraal Station'] },
            { time: '13:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:55:00', line: '906', to: ['Centraal Station'] },
            { time: '14:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:05:00', line: '906', to: ['Centraal Station'] },
            { time: '14:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:15:00', line: '906', to: ['Centraal Station'] },
            { time: '14:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:25:00', line: '906', to: ['Centraal Station'] },
            { time: '14:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:35:00', line: '906', to: ['Centraal Station'] },
            { time: '14:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:45:00', line: '906', to: ['Centraal Station'] },
            { time: '14:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:55:00', line: '906', to: ['Centraal Station'] },
            { time: '15:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:05:00', line: '906', to: ['Centraal Station'] },
            { time: '15:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:15:00', line: '906', to: ['Centraal Station'] },
            { time: '15:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:25:00', line: '906', to: ['Centraal Station'] },
            { time: '15:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:35:00', line: '906', to: ['Centraal Station'] },
            { time: '15:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:45:00', line: '906', to: ['Centraal Station'] },
            { time: '15:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:55:00', line: '906', to: ['Centraal Station'] },
            { time: '16:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:05:00', line: '906', to: ['Centraal Station'] },
            { time: '16:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:15:00', line: '906', to: ['Centraal Station'] },
            { time: '16:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:25:00', line: '906', to: ['Centraal Station'] },
            { time: '16:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:35:00', line: '906', to: ['Centraal Station'] },
            { time: '16:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:45:00', line: '906', to: ['Centraal Station'] },
            { time: '16:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:55:00', line: '906', to: ['Centraal Station'] },
            { time: '17:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:05:00', line: '906', to: ['Centraal Station'] },
            { time: '17:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:15:00', line: '906', to: ['Centraal Station'] },
            { time: '17:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:25:00', line: '906', to: ['Centraal Station'] },
            { time: '17:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:35:00', line: '906', to: ['Centraal Station'] },
            { time: '17:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:45:00', line: '906', to: ['Centraal Station'] },
            { time: '17:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:00:00', line: '906', to: ['Centraal Station'] },
            { time: '18:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:15:00', line: '906', to: ['Centraal Station'] },
            { time: '18:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:30:00', line: '906', to: ['Centraal Station'] },
            { time: '18:45:00', line: '906', to: ['Centraal Station'] },
            { time: '18:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:00:00', line: '906', to: ['Centraal Station'] },
            { time: '19:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:15:00', line: '906', to: ['Centraal Station'] },
            { time: '19:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:30:00', line: '906', to: ['Centraal Station'] },
            { time: '19:45:00', line: '906', to: ['Centraal Station'] },
            { time: '19:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:00:00', line: '906', to: ['Centraal Station'] },
            { time: '20:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:15:00', line: '906', to: ['Centraal Station'] },
            { time: '20:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:30:00', line: '906', to: ['Centraal Station'] },
            { time: '20:45:00', line: '906', to: ['Centraal Station'] },
            { time: '20:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '21:00:00', line: '906', to: ['Centraal Station'] },
            { time: '21:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '21:15:00', line: '906', to: ['Centraal Station'] },
            { time: '21:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '21:30:00', line: '906', to: ['Centraal Station'] },
            { time: '21:45:00', line: '906', to: ['Centraal Station'] },
            { time: '21:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '22:00:00', line: '906', to: ['Centraal Station'] },
            { time: '22:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '22:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '22:30:00', line: '906', to: ['Centraal Station'] },
            { time: '22:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '23:00:00', line: '906', to: ['Centraal Station'] },
            { time: '23:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '23:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '23:30:00', line: '906', to: ['Centraal Station'] },
            { time: '23:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '24:00:00', line: '906', to: ['Centraal Station'] },
            { time: '24:30:00', line: '906', to: ['Centraal Station'] },
            { time: '25:00:00', line: '906', to: ['Centraal Station'] },
            { time: '25:30:00', line: '906', to: ['Centraal Station'] },
            { time: '26:00:00', line: '906', to: ['Centraal Station'] },
            { time: '26:30:00', line: '906', to: ['Centraal Station'] },
            { time: '27:00:00', line: '906', to: ['Centraal Station'] },
            { time: '27:30:00', line: '906', to: ['Centraal Station'] },
        ],
        sunday: [
            {
                time: '00:00:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            {
                time: '00:38:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            {
                time: '01:16:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            {
                time: '01:54:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            {
                time: '02:32:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            {
                time: '03:10:00',
                line: '905',
                to: ['Pontsteiger', 'Centraal Station'],
            },
            { time: '07:15:00', line: '906', to: ['Centraal Station'] },
            { time: '07:45:00', line: '906', to: ['Centraal Station'] },
            { time: '08:15:00', line: '906', to: ['Centraal Station'] },
            { time: '08:45:00', line: '906', to: ['Centraal Station'] },
            { time: '09:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '09:15:00', line: '906', to: ['Centraal Station'] },
            { time: '09:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '09:30:00', line: '906', to: ['Centraal Station'] },
            { time: '09:45:00', line: '906', to: ['Centraal Station'] },
            { time: '09:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '10:00:00', line: '906', to: ['Centraal Station'] },
            { time: '10:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '10:15:00', line: '906', to: ['Centraal Station'] },
            { time: '10:25:00', line: '906', to: ['Centraal Station'] },
            { time: '10:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '10:35:00', line: '906', to: ['Centraal Station'] },
            { time: '10:45:00', line: '906', to: ['Centraal Station'] },
            { time: '10:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '10:55:00', line: '906', to: ['Centraal Station'] },
            { time: '11:05:00', line: '906', to: ['Centraal Station'] },
            { time: '11:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '11:15:00', line: '906', to: ['Centraal Station'] },
            { time: '11:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '11:25:00', line: '906', to: ['Centraal Station'] },
            { time: '11:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '11:35:00', line: '906', to: ['Centraal Station'] },
            { time: '11:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '11:45:00', line: '906', to: ['Centraal Station'] },
            { time: '11:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '11:55:00', line: '906', to: ['Centraal Station'] },
            { time: '12:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:05:00', line: '906', to: ['Centraal Station'] },
            { time: '12:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:15:00', line: '906', to: ['Centraal Station'] },
            { time: '12:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:25:00', line: '906', to: ['Centraal Station'] },
            { time: '12:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:35:00', line: '906', to: ['Centraal Station'] },
            { time: '12:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:45:00', line: '906', to: ['Centraal Station'] },
            { time: '12:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '12:55:00', line: '906', to: ['Centraal Station'] },
            { time: '13:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:05:00', line: '906', to: ['Centraal Station'] },
            { time: '13:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:15:00', line: '906', to: ['Centraal Station'] },
            { time: '13:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:25:00', line: '906', to: ['Centraal Station'] },
            { time: '13:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:35:00', line: '906', to: ['Centraal Station'] },
            { time: '13:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:45:00', line: '906', to: ['Centraal Station'] },
            { time: '13:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '13:55:00', line: '906', to: ['Centraal Station'] },
            { time: '14:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:05:00', line: '906', to: ['Centraal Station'] },
            { time: '14:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:15:00', line: '906', to: ['Centraal Station'] },
            { time: '14:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:25:00', line: '906', to: ['Centraal Station'] },
            { time: '14:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:35:00', line: '906', to: ['Centraal Station'] },
            { time: '14:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:45:00', line: '906', to: ['Centraal Station'] },
            { time: '14:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '14:55:00', line: '906', to: ['Centraal Station'] },
            { time: '15:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:05:00', line: '906', to: ['Centraal Station'] },
            { time: '15:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:15:00', line: '906', to: ['Centraal Station'] },
            { time: '15:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:25:00', line: '906', to: ['Centraal Station'] },
            { time: '15:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:35:00', line: '906', to: ['Centraal Station'] },
            { time: '15:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:45:00', line: '906', to: ['Centraal Station'] },
            { time: '15:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '15:55:00', line: '906', to: ['Centraal Station'] },
            { time: '16:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:05:00', line: '906', to: ['Centraal Station'] },
            { time: '16:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:15:00', line: '906', to: ['Centraal Station'] },
            { time: '16:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:25:00', line: '906', to: ['Centraal Station'] },
            { time: '16:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:35:00', line: '906', to: ['Centraal Station'] },
            { time: '16:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:45:00', line: '906', to: ['Centraal Station'] },
            { time: '16:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '16:55:00', line: '906', to: ['Centraal Station'] },
            { time: '17:00:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:05:00', line: '906', to: ['Centraal Station'] },
            { time: '17:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:15:00', line: '906', to: ['Centraal Station'] },
            { time: '17:20:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:25:00', line: '906', to: ['Centraal Station'] },
            { time: '17:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:35:00', line: '906', to: ['Centraal Station'] },
            { time: '17:40:00', line: '903', to: ['Pontsteiger'] },
            { time: '17:45:00', line: '906', to: ['Centraal Station'] },
            { time: '17:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:00:00', line: '906', to: ['Centraal Station'] },
            { time: '18:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:15:00', line: '906', to: ['Centraal Station'] },
            { time: '18:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '18:30:00', line: '906', to: ['Centraal Station'] },
            { time: '18:45:00', line: '906', to: ['Centraal Station'] },
            { time: '18:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:00:00', line: '906', to: ['Centraal Station'] },
            { time: '19:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:15:00', line: '906', to: ['Centraal Station'] },
            { time: '19:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '19:30:00', line: '906', to: ['Centraal Station'] },
            { time: '19:45:00', line: '906', to: ['Centraal Station'] },
            { time: '19:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:00:00', line: '906', to: ['Centraal Station'] },
            { time: '20:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:15:00', line: '906', to: ['Centraal Station'] },
            { time: '20:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '20:30:00', line: '906', to: ['Centraal Station'] },
            { time: '20:45:00', line: '906', to: ['Centraal Station'] },
            { time: '20:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '21:00:00', line: '906', to: ['Centraal Station'] },
            { time: '21:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '21:15:00', line: '906', to: ['Centraal Station'] },
            { time: '21:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '21:30:00', line: '906', to: ['Centraal Station'] },
            { time: '21:45:00', line: '906', to: ['Centraal Station'] },
            { time: '21:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '22:00:00', line: '906', to: ['Centraal Station'] },
            { time: '22:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '22:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '22:30:00', line: '906', to: ['Centraal Station'] },
            { time: '22:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '23:00:00', line: '906', to: ['Centraal Station'] },
            { time: '23:10:00', line: '903', to: ['Pontsteiger'] },
            { time: '23:30:00', line: '903', to: ['Pontsteiger'] },
            { time: '23:30:00', line: '906', to: ['Centraal Station'] },
            { time: '23:50:00', line: '903', to: ['Pontsteiger'] },
            { time: '24:00:00', line: '906', to: ['Centraal Station'] },
            { time: '24:30:00', line: '906', to: ['Centraal Station'] },
            { time: '25:00:00', line: '906', to: ['Centraal Station'] },
            { time: '25:30:00', line: '906', to: ['Centraal Station'] },
            { time: '26:00:00', line: '906', to: ['Centraal Station'] },
        ],
    },
    Azartplein: {
        monday: [
            { time: '06:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '06:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '07:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '07:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '07:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '08:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '08:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '08:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '09:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '09:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '09:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '10:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '10:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '10:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '11:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '11:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '11:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '12:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '12:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '12:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '13:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '13:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '13:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '14:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '14:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '14:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '15:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '15:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '15:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '16:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '16:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '16:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '17:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '17:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '17:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '18:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '18:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '18:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '19:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '19:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '19:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '20:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '20:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '20:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '21:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '21:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '21:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '22:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '22:30:00', line: '915', to: ['Zamenhofstraat'] },
        ],
        tuesday: [
            { time: '06:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '06:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '07:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '07:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '07:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '08:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '08:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '08:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '09:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '09:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '09:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '10:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '10:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '10:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '11:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '11:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '11:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '12:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '12:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '12:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '13:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '13:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '13:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '14:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '14:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '14:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '15:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '15:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '15:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '16:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '16:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '16:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '17:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '17:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '17:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '18:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '18:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '18:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '19:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '19:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '19:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '20:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '20:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '20:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '21:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '21:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '21:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '22:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '22:30:00', line: '915', to: ['Zamenhofstraat'] },
        ],
        wednesday: [
            { time: '06:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '06:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '07:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '07:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '07:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '08:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '08:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '08:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '09:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '09:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '09:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '10:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '10:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '10:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '11:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '11:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '11:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '12:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '12:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '12:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '13:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '13:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '13:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '14:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '14:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '14:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '15:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '15:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '15:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '16:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '16:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '16:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '17:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '17:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '17:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '18:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '18:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '18:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '19:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '19:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '19:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '20:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '20:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '20:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '21:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '21:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '21:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '22:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '22:30:00', line: '915', to: ['Zamenhofstraat'] },
        ],
        thursday: [
            { time: '06:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '06:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '07:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '07:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '07:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '08:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '08:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '08:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '09:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '09:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '09:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '10:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '10:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '10:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '11:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '11:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '11:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '12:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '12:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '12:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '13:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '13:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '13:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '14:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '14:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '14:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '15:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '15:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '15:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '16:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '16:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '16:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '17:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '17:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '17:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '18:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '18:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '18:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '19:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '19:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '19:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '20:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '20:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '20:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '21:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '21:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '21:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '22:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '22:30:00', line: '915', to: ['Zamenhofstraat'] },
        ],
        friday: [
            { time: '06:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '06:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '07:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '07:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '07:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '08:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '08:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '08:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '09:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '09:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '09:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '10:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '10:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '10:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '11:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '11:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '11:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '12:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '12:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '12:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '13:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '13:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '13:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '14:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '14:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '14:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '15:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '15:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '15:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '16:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '16:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '16:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '17:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '17:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '17:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '18:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '18:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '18:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '19:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '19:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '19:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '20:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '20:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '20:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '21:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '21:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '21:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '22:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '22:30:00', line: '915', to: ['Zamenhofstraat'] },
        ],
        saturday: [
            { time: '08:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '08:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '09:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '09:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '09:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '10:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '10:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '10:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '11:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '11:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '11:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '12:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '12:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '12:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '13:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '13:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '13:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '14:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '14:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '14:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '15:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '15:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '15:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '16:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '16:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '16:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '17:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '17:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '17:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '18:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '18:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '18:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '19:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '19:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '19:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '20:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '20:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '20:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '21:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '21:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '21:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '22:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '22:30:00', line: '915', to: ['Zamenhofstraat'] },
        ],
        sunday: [
            { time: '08:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '08:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '09:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '09:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '09:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '10:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '10:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '10:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '11:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '11:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '11:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '12:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '12:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '12:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '13:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '13:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '13:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '14:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '14:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '14:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '15:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '15:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '15:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '16:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '16:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '16:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '17:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '17:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '17:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '18:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '18:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '18:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '19:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '19:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '19:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '20:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '20:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '20:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '21:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '21:30:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '21:50:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '22:10:00', line: '915', to: ['Zamenhofstraat'] },
            { time: '22:30:00', line: '915', to: ['Zamenhofstraat'] },
        ],
    },
    Zamenhofstraat: {
        monday: [
            { time: '06:20:00', line: '915', to: ['Azartplein'] },
            { time: '06:40:00', line: '915', to: ['Azartplein'] },
            { time: '07:00:00', line: '915', to: ['Azartplein'] },
            { time: '07:20:00', line: '915', to: ['Azartplein'] },
            { time: '07:40:00', line: '915', to: ['Azartplein'] },
            { time: '08:00:00', line: '915', to: ['Azartplein'] },
            { time: '08:20:00', line: '915', to: ['Azartplein'] },
            { time: '08:40:00', line: '915', to: ['Azartplein'] },
            { time: '09:00:00', line: '915', to: ['Azartplein'] },
            { time: '09:20:00', line: '915', to: ['Azartplein'] },
            { time: '09:40:00', line: '915', to: ['Azartplein'] },
            { time: '10:00:00', line: '915', to: ['Azartplein'] },
            { time: '10:20:00', line: '915', to: ['Azartplein'] },
            { time: '10:40:00', line: '915', to: ['Azartplein'] },
            { time: '11:00:00', line: '915', to: ['Azartplein'] },
            { time: '11:20:00', line: '915', to: ['Azartplein'] },
            { time: '11:40:00', line: '915', to: ['Azartplein'] },
            { time: '12:00:00', line: '915', to: ['Azartplein'] },
            { time: '12:20:00', line: '915', to: ['Azartplein'] },
            { time: '12:40:00', line: '915', to: ['Azartplein'] },
            { time: '13:00:00', line: '915', to: ['Azartplein'] },
            { time: '13:20:00', line: '915', to: ['Azartplein'] },
            { time: '13:40:00', line: '915', to: ['Azartplein'] },
            { time: '14:00:00', line: '915', to: ['Azartplein'] },
            { time: '14:20:00', line: '915', to: ['Azartplein'] },
            { time: '14:40:00', line: '915', to: ['Azartplein'] },
            { time: '15:00:00', line: '915', to: ['Azartplein'] },
            { time: '15:20:00', line: '915', to: ['Azartplein'] },
            { time: '15:40:00', line: '915', to: ['Azartplein'] },
            { time: '16:00:00', line: '915', to: ['Azartplein'] },
            { time: '16:20:00', line: '915', to: ['Azartplein'] },
            { time: '16:40:00', line: '915', to: ['Azartplein'] },
            { time: '17:00:00', line: '915', to: ['Azartplein'] },
            { time: '17:20:00', line: '915', to: ['Azartplein'] },
            { time: '17:40:00', line: '915', to: ['Azartplein'] },
            { time: '18:00:00', line: '915', to: ['Azartplein'] },
            { time: '18:20:00', line: '915', to: ['Azartplein'] },
            { time: '18:40:00', line: '915', to: ['Azartplein'] },
            { time: '19:00:00', line: '915', to: ['Azartplein'] },
            { time: '19:20:00', line: '915', to: ['Azartplein'] },
            { time: '19:40:00', line: '915', to: ['Azartplein'] },
            { time: '20:00:00', line: '915', to: ['Azartplein'] },
            { time: '20:20:00', line: '915', to: ['Azartplein'] },
            { time: '20:40:00', line: '915', to: ['Azartplein'] },
            { time: '21:00:00', line: '915', to: ['Azartplein'] },
            { time: '21:20:00', line: '915', to: ['Azartplein'] },
            { time: '21:40:00', line: '915', to: ['Azartplein'] },
            { time: '22:00:00', line: '915', to: ['Azartplein'] },
            { time: '22:20:00', line: '915', to: ['Azartplein'] },
        ],
        tuesday: [
            { time: '06:20:00', line: '915', to: ['Azartplein'] },
            { time: '06:40:00', line: '915', to: ['Azartplein'] },
            { time: '07:00:00', line: '915', to: ['Azartplein'] },
            { time: '07:20:00', line: '915', to: ['Azartplein'] },
            { time: '07:40:00', line: '915', to: ['Azartplein'] },
            { time: '08:00:00', line: '915', to: ['Azartplein'] },
            { time: '08:20:00', line: '915', to: ['Azartplein'] },
            { time: '08:40:00', line: '915', to: ['Azartplein'] },
            { time: '09:00:00', line: '915', to: ['Azartplein'] },
            { time: '09:20:00', line: '915', to: ['Azartplein'] },
            { time: '09:40:00', line: '915', to: ['Azartplein'] },
            { time: '10:00:00', line: '915', to: ['Azartplein'] },
            { time: '10:20:00', line: '915', to: ['Azartplein'] },
            { time: '10:40:00', line: '915', to: ['Azartplein'] },
            { time: '11:00:00', line: '915', to: ['Azartplein'] },
            { time: '11:20:00', line: '915', to: ['Azartplein'] },
            { time: '11:40:00', line: '915', to: ['Azartplein'] },
            { time: '12:00:00', line: '915', to: ['Azartplein'] },
            { time: '12:20:00', line: '915', to: ['Azartplein'] },
            { time: '12:40:00', line: '915', to: ['Azartplein'] },
            { time: '13:00:00', line: '915', to: ['Azartplein'] },
            { time: '13:20:00', line: '915', to: ['Azartplein'] },
            { time: '13:40:00', line: '915', to: ['Azartplein'] },
            { time: '14:00:00', line: '915', to: ['Azartplein'] },
            { time: '14:20:00', line: '915', to: ['Azartplein'] },
            { time: '14:40:00', line: '915', to: ['Azartplein'] },
            { time: '15:00:00', line: '915', to: ['Azartplein'] },
            { time: '15:20:00', line: '915', to: ['Azartplein'] },
            { time: '15:40:00', line: '915', to: ['Azartplein'] },
            { time: '16:00:00', line: '915', to: ['Azartplein'] },
            { time: '16:20:00', line: '915', to: ['Azartplein'] },
            { time: '16:40:00', line: '915', to: ['Azartplein'] },
            { time: '17:00:00', line: '915', to: ['Azartplein'] },
            { time: '17:20:00', line: '915', to: ['Azartplein'] },
            { time: '17:40:00', line: '915', to: ['Azartplein'] },
            { time: '18:00:00', line: '915', to: ['Azartplein'] },
            { time: '18:20:00', line: '915', to: ['Azartplein'] },
            { time: '18:40:00', line: '915', to: ['Azartplein'] },
            { time: '19:00:00', line: '915', to: ['Azartplein'] },
            { time: '19:20:00', line: '915', to: ['Azartplein'] },
            { time: '19:40:00', line: '915', to: ['Azartplein'] },
            { time: '20:00:00', line: '915', to: ['Azartplein'] },
            { time: '20:20:00', line: '915', to: ['Azartplein'] },
            { time: '20:40:00', line: '915', to: ['Azartplein'] },
            { time: '21:00:00', line: '915', to: ['Azartplein'] },
            { time: '21:20:00', line: '915', to: ['Azartplein'] },
            { time: '21:40:00', line: '915', to: ['Azartplein'] },
            { time: '22:00:00', line: '915', to: ['Azartplein'] },
            { time: '22:20:00', line: '915', to: ['Azartplein'] },
        ],
        wednesday: [
            { time: '06:20:00', line: '915', to: ['Azartplein'] },
            { time: '06:40:00', line: '915', to: ['Azartplein'] },
            { time: '07:00:00', line: '915', to: ['Azartplein'] },
            { time: '07:20:00', line: '915', to: ['Azartplein'] },
            { time: '07:40:00', line: '915', to: ['Azartplein'] },
            { time: '08:00:00', line: '915', to: ['Azartplein'] },
            { time: '08:20:00', line: '915', to: ['Azartplein'] },
            { time: '08:40:00', line: '915', to: ['Azartplein'] },
            { time: '09:00:00', line: '915', to: ['Azartplein'] },
            { time: '09:20:00', line: '915', to: ['Azartplein'] },
            { time: '09:40:00', line: '915', to: ['Azartplein'] },
            { time: '10:00:00', line: '915', to: ['Azartplein'] },
            { time: '10:20:00', line: '915', to: ['Azartplein'] },
            { time: '10:40:00', line: '915', to: ['Azartplein'] },
            { time: '11:00:00', line: '915', to: ['Azartplein'] },
            { time: '11:20:00', line: '915', to: ['Azartplein'] },
            { time: '11:40:00', line: '915', to: ['Azartplein'] },
            { time: '12:00:00', line: '915', to: ['Azartplein'] },
            { time: '12:20:00', line: '915', to: ['Azartplein'] },
            { time: '12:40:00', line: '915', to: ['Azartplein'] },
            { time: '13:00:00', line: '915', to: ['Azartplein'] },
            { time: '13:20:00', line: '915', to: ['Azartplein'] },
            { time: '13:40:00', line: '915', to: ['Azartplein'] },
            { time: '14:00:00', line: '915', to: ['Azartplein'] },
            { time: '14:20:00', line: '915', to: ['Azartplein'] },
            { time: '14:40:00', line: '915', to: ['Azartplein'] },
            { time: '15:00:00', line: '915', to: ['Azartplein'] },
            { time: '15:20:00', line: '915', to: ['Azartplein'] },
            { time: '15:40:00', line: '915', to: ['Azartplein'] },
            { time: '16:00:00', line: '915', to: ['Azartplein'] },
            { time: '16:20:00', line: '915', to: ['Azartplein'] },
            { time: '16:40:00', line: '915', to: ['Azartplein'] },
            { time: '17:00:00', line: '915', to: ['Azartplein'] },
            { time: '17:20:00', line: '915', to: ['Azartplein'] },
            { time: '17:40:00', line: '915', to: ['Azartplein'] },
            { time: '18:00:00', line: '915', to: ['Azartplein'] },
            { time: '18:20:00', line: '915', to: ['Azartplein'] },
            { time: '18:40:00', line: '915', to: ['Azartplein'] },
            { time: '19:00:00', line: '915', to: ['Azartplein'] },
            { time: '19:20:00', line: '915', to: ['Azartplein'] },
            { time: '19:40:00', line: '915', to: ['Azartplein'] },
            { time: '20:00:00', line: '915', to: ['Azartplein'] },
            { time: '20:20:00', line: '915', to: ['Azartplein'] },
            { time: '20:40:00', line: '915', to: ['Azartplein'] },
            { time: '21:00:00', line: '915', to: ['Azartplein'] },
            { time: '21:20:00', line: '915', to: ['Azartplein'] },
            { time: '21:40:00', line: '915', to: ['Azartplein'] },
            { time: '22:00:00', line: '915', to: ['Azartplein'] },
            { time: '22:20:00', line: '915', to: ['Azartplein'] },
        ],
        thursday: [
            { time: '06:20:00', line: '915', to: ['Azartplein'] },
            { time: '06:40:00', line: '915', to: ['Azartplein'] },
            { time: '07:00:00', line: '915', to: ['Azartplein'] },
            { time: '07:20:00', line: '915', to: ['Azartplein'] },
            { time: '07:40:00', line: '915', to: ['Azartplein'] },
            { time: '08:00:00', line: '915', to: ['Azartplein'] },
            { time: '08:20:00', line: '915', to: ['Azartplein'] },
            { time: '08:40:00', line: '915', to: ['Azartplein'] },
            { time: '09:00:00', line: '915', to: ['Azartplein'] },
            { time: '09:20:00', line: '915', to: ['Azartplein'] },
            { time: '09:40:00', line: '915', to: ['Azartplein'] },
            { time: '10:00:00', line: '915', to: ['Azartplein'] },
            { time: '10:20:00', line: '915', to: ['Azartplein'] },
            { time: '10:40:00', line: '915', to: ['Azartplein'] },
            { time: '11:00:00', line: '915', to: ['Azartplein'] },
            { time: '11:20:00', line: '915', to: ['Azartplein'] },
            { time: '11:40:00', line: '915', to: ['Azartplein'] },
            { time: '12:00:00', line: '915', to: ['Azartplein'] },
            { time: '12:20:00', line: '915', to: ['Azartplein'] },
            { time: '12:40:00', line: '915', to: ['Azartplein'] },
            { time: '13:00:00', line: '915', to: ['Azartplein'] },
            { time: '13:20:00', line: '915', to: ['Azartplein'] },
            { time: '13:40:00', line: '915', to: ['Azartplein'] },
            { time: '14:00:00', line: '915', to: ['Azartplein'] },
            { time: '14:20:00', line: '915', to: ['Azartplein'] },
            { time: '14:40:00', line: '915', to: ['Azartplein'] },
            { time: '15:00:00', line: '915', to: ['Azartplein'] },
            { time: '15:20:00', line: '915', to: ['Azartplein'] },
            { time: '15:40:00', line: '915', to: ['Azartplein'] },
            { time: '16:00:00', line: '915', to: ['Azartplein'] },
            { time: '16:20:00', line: '915', to: ['Azartplein'] },
            { time: '16:40:00', line: '915', to: ['Azartplein'] },
            { time: '17:00:00', line: '915', to: ['Azartplein'] },
            { time: '17:20:00', line: '915', to: ['Azartplein'] },
            { time: '17:40:00', line: '915', to: ['Azartplein'] },
            { time: '18:00:00', line: '915', to: ['Azartplein'] },
            { time: '18:20:00', line: '915', to: ['Azartplein'] },
            { time: '18:40:00', line: '915', to: ['Azartplein'] },
            { time: '19:00:00', line: '915', to: ['Azartplein'] },
            { time: '19:20:00', line: '915', to: ['Azartplein'] },
            { time: '19:40:00', line: '915', to: ['Azartplein'] },
            { time: '20:00:00', line: '915', to: ['Azartplein'] },
            { time: '20:20:00', line: '915', to: ['Azartplein'] },
            { time: '20:40:00', line: '915', to: ['Azartplein'] },
            { time: '21:00:00', line: '915', to: ['Azartplein'] },
            { time: '21:20:00', line: '915', to: ['Azartplein'] },
            { time: '21:40:00', line: '915', to: ['Azartplein'] },
            { time: '22:00:00', line: '915', to: ['Azartplein'] },
            { time: '22:20:00', line: '915', to: ['Azartplein'] },
        ],
        friday: [
            { time: '06:20:00', line: '915', to: ['Azartplein'] },
            { time: '06:40:00', line: '915', to: ['Azartplein'] },
            { time: '07:00:00', line: '915', to: ['Azartplein'] },
            { time: '07:20:00', line: '915', to: ['Azartplein'] },
            { time: '07:40:00', line: '915', to: ['Azartplein'] },
            { time: '08:00:00', line: '915', to: ['Azartplein'] },
            { time: '08:20:00', line: '915', to: ['Azartplein'] },
            { time: '08:40:00', line: '915', to: ['Azartplein'] },
            { time: '09:00:00', line: '915', to: ['Azartplein'] },
            { time: '09:20:00', line: '915', to: ['Azartplein'] },
            { time: '09:40:00', line: '915', to: ['Azartplein'] },
            { time: '10:00:00', line: '915', to: ['Azartplein'] },
            { time: '10:20:00', line: '915', to: ['Azartplein'] },
            { time: '10:40:00', line: '915', to: ['Azartplein'] },
            { time: '11:00:00', line: '915', to: ['Azartplein'] },
            { time: '11:20:00', line: '915', to: ['Azartplein'] },
            { time: '11:40:00', line: '915', to: ['Azartplein'] },
            { time: '12:00:00', line: '915', to: ['Azartplein'] },
            { time: '12:20:00', line: '915', to: ['Azartplein'] },
            { time: '12:40:00', line: '915', to: ['Azartplein'] },
            { time: '13:00:00', line: '915', to: ['Azartplein'] },
            { time: '13:20:00', line: '915', to: ['Azartplein'] },
            { time: '13:40:00', line: '915', to: ['Azartplein'] },
            { time: '14:00:00', line: '915', to: ['Azartplein'] },
            { time: '14:20:00', line: '915', to: ['Azartplein'] },
            { time: '14:40:00', line: '915', to: ['Azartplein'] },
            { time: '15:00:00', line: '915', to: ['Azartplein'] },
            { time: '15:20:00', line: '915', to: ['Azartplein'] },
            { time: '15:40:00', line: '915', to: ['Azartplein'] },
            { time: '16:00:00', line: '915', to: ['Azartplein'] },
            { time: '16:20:00', line: '915', to: ['Azartplein'] },
            { time: '16:40:00', line: '915', to: ['Azartplein'] },
            { time: '17:00:00', line: '915', to: ['Azartplein'] },
            { time: '17:20:00', line: '915', to: ['Azartplein'] },
            { time: '17:40:00', line: '915', to: ['Azartplein'] },
            { time: '18:00:00', line: '915', to: ['Azartplein'] },
            { time: '18:20:00', line: '915', to: ['Azartplein'] },
            { time: '18:40:00', line: '915', to: ['Azartplein'] },
            { time: '19:00:00', line: '915', to: ['Azartplein'] },
            { time: '19:20:00', line: '915', to: ['Azartplein'] },
            { time: '19:40:00', line: '915', to: ['Azartplein'] },
            { time: '20:00:00', line: '915', to: ['Azartplein'] },
            { time: '20:20:00', line: '915', to: ['Azartplein'] },
            { time: '20:40:00', line: '915', to: ['Azartplein'] },
            { time: '21:00:00', line: '915', to: ['Azartplein'] },
            { time: '21:20:00', line: '915', to: ['Azartplein'] },
            { time: '21:40:00', line: '915', to: ['Azartplein'] },
            { time: '22:00:00', line: '915', to: ['Azartplein'] },
            { time: '22:20:00', line: '915', to: ['Azartplein'] },
        ],
        saturday: [
            { time: '08:20:00', line: '915', to: ['Azartplein'] },
            { time: '08:40:00', line: '915', to: ['Azartplein'] },
            { time: '09:00:00', line: '915', to: ['Azartplein'] },
            { time: '09:20:00', line: '915', to: ['Azartplein'] },
            { time: '09:40:00', line: '915', to: ['Azartplein'] },
            { time: '10:00:00', line: '915', to: ['Azartplein'] },
            { time: '10:20:00', line: '915', to: ['Azartplein'] },
            { time: '10:40:00', line: '915', to: ['Azartplein'] },
            { time: '11:00:00', line: '915', to: ['Azartplein'] },
            { time: '11:20:00', line: '915', to: ['Azartplein'] },
            { time: '11:40:00', line: '915', to: ['Azartplein'] },
            { time: '12:00:00', line: '915', to: ['Azartplein'] },
            { time: '12:20:00', line: '915', to: ['Azartplein'] },
            { time: '12:40:00', line: '915', to: ['Azartplein'] },
            { time: '13:00:00', line: '915', to: ['Azartplein'] },
            { time: '13:20:00', line: '915', to: ['Azartplein'] },
            { time: '13:40:00', line: '915', to: ['Azartplein'] },
            { time: '14:00:00', line: '915', to: ['Azartplein'] },
            { time: '14:20:00', line: '915', to: ['Azartplein'] },
            { time: '14:40:00', line: '915', to: ['Azartplein'] },
            { time: '15:00:00', line: '915', to: ['Azartplein'] },
            { time: '15:20:00', line: '915', to: ['Azartplein'] },
            { time: '15:40:00', line: '915', to: ['Azartplein'] },
            { time: '16:00:00', line: '915', to: ['Azartplein'] },
            { time: '16:20:00', line: '915', to: ['Azartplein'] },
            { time: '16:40:00', line: '915', to: ['Azartplein'] },
            { time: '17:00:00', line: '915', to: ['Azartplein'] },
            { time: '17:20:00', line: '915', to: ['Azartplein'] },
            { time: '17:40:00', line: '915', to: ['Azartplein'] },
            { time: '18:00:00', line: '915', to: ['Azartplein'] },
            { time: '18:20:00', line: '915', to: ['Azartplein'] },
            { time: '18:40:00', line: '915', to: ['Azartplein'] },
            { time: '19:00:00', line: '915', to: ['Azartplein'] },
            { time: '19:20:00', line: '915', to: ['Azartplein'] },
            { time: '19:40:00', line: '915', to: ['Azartplein'] },
            { time: '20:00:00', line: '915', to: ['Azartplein'] },
            { time: '20:20:00', line: '915', to: ['Azartplein'] },
            { time: '20:40:00', line: '915', to: ['Azartplein'] },
            { time: '21:00:00', line: '915', to: ['Azartplein'] },
            { time: '21:20:00', line: '915', to: ['Azartplein'] },
            { time: '21:40:00', line: '915', to: ['Azartplein'] },
            { time: '22:00:00', line: '915', to: ['Azartplein'] },
            { time: '22:20:00', line: '915', to: ['Azartplein'] },
        ],
        sunday: [
            { time: '08:20:00', line: '915', to: ['Azartplein'] },
            { time: '08:40:00', line: '915', to: ['Azartplein'] },
            { time: '09:00:00', line: '915', to: ['Azartplein'] },
            { time: '09:20:00', line: '915', to: ['Azartplein'] },
            { time: '09:40:00', line: '915', to: ['Azartplein'] },
            { time: '10:00:00', line: '915', to: ['Azartplein'] },
            { time: '10:20:00', line: '915', to: ['Azartplein'] },
            { time: '10:40:00', line: '915', to: ['Azartplein'] },
            { time: '11:00:00', line: '915', to: ['Azartplein'] },
            { time: '11:20:00', line: '915', to: ['Azartplein'] },
            { time: '11:40:00', line: '915', to: ['Azartplein'] },
            { time: '12:00:00', line: '915', to: ['Azartplein'] },
            { time: '12:20:00', line: '915', to: ['Azartplein'] },
            { time: '12:40:00', line: '915', to: ['Azartplein'] },
            { time: '13:00:00', line: '915', to: ['Azartplein'] },
            { time: '13:20:00', line: '915', to: ['Azartplein'] },
            { time: '13:40:00', line: '915', to: ['Azartplein'] },
            { time: '14:00:00', line: '915', to: ['Azartplein'] },
            { time: '14:20:00', line: '915', to: ['Azartplein'] },
            { time: '14:40:00', line: '915', to: ['Azartplein'] },
            { time: '15:00:00', line: '915', to: ['Azartplein'] },
            { time: '15:20:00', line: '915', to: ['Azartplein'] },
            { time: '15:40:00', line: '915', to: ['Azartplein'] },
            { time: '16:00:00', line: '915', to: ['Azartplein'] },
            { time: '16:20:00', line: '915', to: ['Azartplein'] },
            { time: '16:40:00', line: '915', to: ['Azartplein'] },
            { time: '17:00:00', line: '915', to: ['Azartplein'] },
            { time: '17:20:00', line: '915', to: ['Azartplein'] },
            { time: '17:40:00', line: '915', to: ['Azartplein'] },
            { time: '18:00:00', line: '915', to: ['Azartplein'] },
            { time: '18:20:00', line: '915', to: ['Azartplein'] },
            { time: '18:40:00', line: '915', to: ['Azartplein'] },
            { time: '19:00:00', line: '915', to: ['Azartplein'] },
            { time: '19:20:00', line: '915', to: ['Azartplein'] },
            { time: '19:40:00', line: '915', to: ['Azartplein'] },
            { time: '20:00:00', line: '915', to: ['Azartplein'] },
            { time: '20:20:00', line: '915', to: ['Azartplein'] },
            { time: '20:40:00', line: '915', to: ['Azartplein'] },
            { time: '21:00:00', line: '915', to: ['Azartplein'] },
            { time: '21:20:00', line: '915', to: ['Azartplein'] },
            { time: '21:40:00', line: '915', to: ['Azartplein'] },
            { time: '22:00:00', line: '915', to: ['Azartplein'] },
            { time: '22:20:00', line: '915', to: ['Azartplein'] },
        ],
    },
};
