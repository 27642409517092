import { __decorate } from "tslib";
import { html, LitElement, property } from 'lit-element';
import { mainStyle } from '../../../mainStyle';
import '../../lazy-trip/lazy-trip';
import { getDistance } from './getDistance';
import { boatIcon } from './icons/boat-icon';
import { githubIcon } from './icons/github-icon';
import { locationIcon } from './icons/location-icon';
import { lazyFerryStyle } from './lazyFerryStyle';
import { stops } from './stops';
import { timetable } from './timetable';
import { weekdays } from './weekdays';
export class LazyFerry extends LitElement {
    constructor() {
        super(...arguments);
        this.from = window.localStorage.getItem('from') || stops[0].name;
        this.today = weekdays[new Date().getDay()];
        this.tomorrow = weekdays[(new Date().getDay() + 1) % 7];
    }
    $(selector) {
        const element = this.renderRoot.querySelector(selector);
        if (!element) {
            throw new Error(`Could not find element with selector: ${selector}`);
        }
        return element;
    }
    $$(selector) {
        return Array.from(this.renderRoot.querySelectorAll(selector));
    }
    firstUpdated(changedProperties) {
        super.firstUpdated(changedProperties);
        this.$('select[name=from]').value = this.from;
    }
    refresh() {
        this.today = weekdays[new Date().getDay()];
        this.tomorrow = weekdays[(new Date().getDay() + 1) % 7];
        // Re-render trips and then re-focus next trip
        this.requestUpdate();
    }
    render() {
        return html `
      <header>
        <span>
          <a
            href="#"
            @click="${(event) => {
            event.preventDefault();
            this.refresh();
        }}"
          >
            ${boatIcon} Lazy Ferry
          </a>
        </span>

        <span>
          <a
            href="#"
            @click="${(event) => {
            event.preventDefault();
            this.setFromClosest();
        }}"
          >
            ${locationIcon}
          </a>
          <select
            name="from"
            @input="${(event) => {
            this.setFrom(event.target.value);
        }}"
          >
            ${stops.map(stop => html `
                  <option>${stop.name}</option>
                `)}
          </select>
        </span>
      </header>

      <main>
        <div class="main-trips">
          <!-- TODO: Replace by infinite scrolling -->
          ${this.renderDay(this.today)} ${this.renderDay(this.tomorrow)}
        </div>
      </main>

      <footer>
        Made with ♥ by
        <a
          target="_blank"
          rel="noopener"
          title="difosfor on GitHub"
          href="https://github.com/difosfor"
          >difosfor</a
        >
        <a
          target="_blank"
          rel="noopener"
          title="Lazy Ferry on GitHub"
          href="https://github.com/difosfor/lazy-ferry"
        >
          ${githubIcon}
        </a>
      </footer>
    `;
    }
    renderDay(day) {
        return html `
      <div class="main-day">${day}</div>
      ${timetable[this.from][day].map(trip => html `
            <lazy-trip .trip="${trip}"></lazy-trip>
          `)}
    `;
    }
    setFrom(value) {
        this.from = value;
        for (const option of this.$$('select[name=from] option')) {
            option.selected = option.value === this.from;
        }
        // Re-render trips and then re-focus next trip
        this.requestUpdate();
        window.localStorage.setItem('from', this.from);
    }
    setFromClosest() {
        navigator.geolocation.getCurrentPosition(pos => {
            const { coords: { latitude: lat, longitude: lon }, } = pos;
            let closestStop = stops.find(stop => stop.name === this.from);
            if (!closestStop) {
                throw new Error(`Could not find stop with name: ${this.from}`);
            }
            let closestDist = getDistance(lat, lon, closestStop.lat, closestStop.lon);
            for (const stop of stops) {
                const dist = getDistance(lat, lon, stop.lat, stop.lon);
                if (dist < closestDist) {
                    closestStop = stop;
                    closestDist = dist;
                }
            }
            this.setFrom(closestStop.name);
        });
    }
    updated(changedProperties) {
        super.updated(changedProperties);
        const lazyTrips = this.$$('lazy-trip');
        if (!lazyTrips) {
            return;
        }
        const now = new Date().toTimeString().slice(0, 8);
        let foundNext = false;
        for (const lazyTrip of lazyTrips) {
            if (!foundNext && lazyTrip.trip.time > now) {
                foundNext = true;
                lazyTrip.setIsNext(true);
            }
            else {
                lazyTrip.setIsNext(false);
            }
        }
    }
}
LazyFerry.styles = [mainStyle, lazyFerryStyle];
__decorate([
    property({ type: String })
], LazyFerry.prototype, "from", void 0);
__decorate([
    property({ type: String })
], LazyFerry.prototype, "today", void 0);
__decorate([
    property({ type: String })
], LazyFerry.prototype, "tomorrow", void 0);
